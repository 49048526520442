const LOGIN = 'LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const CLEAR_LOGIN = 'CLEAR_LOGIN';
const CLEAR_ERROR = 'CLEAR_ERROR';

const LoginSymbols = {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN,
  CLEAR_LOGIN,
  CLEAR_ERROR
};

export default LoginSymbols;
