import { Checkbox, FormControlLabel, Typography, makeStyles, MenuItem } from '@material-ui/core';
import { textButtonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles({
  root: { padding: '12px', ...textButtonHoverAndActive }
});
interface IProps {
  title: string;
  checked: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (checked: boolean) => void;
}
export function FilterItems({ title, checked, onChange }: IProps) {
  const classes = useStyles();

  return (
    <MenuItem onClick={() => onChange(!checked)} alignItems="center" className={classes.root}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            value="remember"
            color="primary"
            onChange={(e) => onChange(e.target.checked)}
          />
        }
        label=""
        style={{ marginRight: 0 }}
      />
      <Typography variant="body2" component="p">
        {title}
      </Typography>
    </MenuItem>
  );
}
