const GET_INVOICES = 'GET_INVOICES';
const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';
const CLEAR_GET_INVOICES = 'CLEAR_GET_INVOICES';

const GetInvoicesSymbols = {
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  GET_INVOICES,
  CLEAR_GET_INVOICES
};

export default GetInvoicesSymbols;
