import CREATE_PAYMENT_ID from '../symbols/createPaymentId';
import { HttpRequestPost } from '../../service';

const createPaymentIdAction = () => ({
  type: CREATE_PAYMENT_ID.CREATE_PAYMENT_ID
});
const createPaymentIdSuccessAction = (data: any) => {
  return {
    type: CREATE_PAYMENT_ID.CREATE_PAYMENT_ID_SUCCESS,
    payload: data
  };
};
const createPaymentIdFailureAction = (data: any) => ({
  type: CREATE_PAYMENT_ID.CREATE_PAYMENT_ID_FAILURE,
  payload: data
});
const clearCreatePaymentIdAction = () => ({
  type: CREATE_PAYMENT_ID.CLEAR_CREATE_PAYMENT_ID
});
const setCreatePaymentLoadingAction = (loading: boolean) => ({
  type: CREATE_PAYMENT_ID.CREATE_PAYMENT_ID_SET_LOADING,
  payload: loading
});
interface CreatePaymentIdRequestModel {
  StripeCheckoutSessionId: string;
}
// eslint-disable-next-line no-unused-vars
export function CreatePaymentId(data: CreatePaymentIdRequestModel, callback?: (resp: any) => void) {
  return (dispatch: any) => {
    dispatch(createPaymentIdAction());
    HttpRequestPost(
      'pro/payment/create-payment-identifier',
      dispatch,
      createPaymentIdSuccessAction,
      createPaymentIdFailureAction,
      false,
      data,
      callback
    );
  };
}

export function clearCreatePaymentId() {
  return (dispatch: any) => {
    dispatch(clearCreatePaymentIdAction());
  };
}

export function SetCreatePaymentIdLoading(loading: boolean) {
  return (dispatch: any) => {
    dispatch(setCreatePaymentLoadingAction(loading));
  };
}
