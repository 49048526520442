import { SVGProps } from 'react';

function CreditCard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="credit-card"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={1}
        y={3}
        width={16}
        height={12}>
        <path
          d="M13.5 3a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h9ZM15 9H3v3a1.5 1.5 0 0 0 1.388 1.496l.112.004h9a1.5 1.5 0 0 0 1.496-1.388L15 12V9Zm-1.5-4.5h-9a1.5 1.5 0 0 0-1.496 1.388L3 6h12a1.5 1.5 0 0 0-1.388-1.496L13.5 4.5Z"
          fill="#000"
        />
      </mask>
      <g mask="url(#credit-card)">
        <path d="M18 0H0v18h18V0Z" fill="#FF0EAF" />
      </g>
    </svg>
  );
}

export default CreditCard;
