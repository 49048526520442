/* eslint-disable react/destructuring-assignment */
import RESET_PASSWORD from '../symbols/resetPassword';

interface State {
  loading: boolean;
  data: string;
  error: any;
}
const initialState: State = {
  loading: false,
  error: false,
  data: ''
};

// eslint-disable-next-line default-param-last
function ResetPasswordReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case RESET_PASSWORD.RESET_PASSWORD:
      return { ...state, loading: true, error: false };
    case RESET_PASSWORD.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false
      };
    case RESET_PASSWORD.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case RESET_PASSWORD.CLEAR_RESET_PASSWORD:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default ResetPasswordReducer;
