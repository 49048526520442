import { Box, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { RoundedCheckbox } from '../components-atoms';
import { Invoice } from '../redux/reducers/getInvoices';
import { textButtonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '24px'
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: '24px 16px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'all 200ms ease',
    '@media (min-width: 900px)': {
      padding: 24,
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '20px'
    },
    ...textButtonHoverAndActive
  }
}));
interface IProps {
  data: Invoice;
  checked: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}
export function InvoiceItem({ data, checked, onChange }: IProps) {
  const classes = useStyles();
  return (
    <Box data-order="order-item" className={classes.container}>
      <Container className={classes.root}>
        <Grid item container justifyContent="flex-start" direction="row">
          <Grid item style={{ marginRight: 8 }}>
            <RoundedCheckbox color="primary" checked={checked} onChange={onChange} />
          </Grid>
          <Grid
            item
            style={{
              alignItems: 'center',
              justifyContent: 'flex-start',
              display: 'flex',
              flexGrow: 1
            }}
            onClick={() => window.open(data.InvoiceFileUrl, '_blank')}>
            <Typography variant="h6" component="span" color="textPrimary">
              {data.InvoiceNo}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
