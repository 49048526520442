import { makeStyles, Typography, Button, ButtonProps } from '@material-ui/core';
import { ITheme } from '../constants/theme';

const useStyles = makeStyles((theme: ITheme) => ({
  button: {
    minWidth: 64,
    height: 36,
    borderRadius: 8,
    padding: '6px 8px',
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error[8]
    },
    '&:active': {
      backgroundColor: theme.palette.error[24]
    }
  },
  disabled: {
    minWidth: 64,
    height: 36,
    borderRadius: 8,
    padding: '6px 8px',
    color: theme.palette.action.disabled
  }
}));
export function ButtonTextError({ onClick, startIcon, disabled, ...rest }: ButtonProps) {
  const classes = useStyles();

  return (
    <Button
      className={!disabled ? classes.button : classes.disabled}
      onClick={onClick}
      startIcon={startIcon}
      {...rest}>
      <Typography variant="button" component="p" color="inherit">
        {rest.children}
      </Typography>
    </Button>
  );
}
