import CREATE_SESSION from '../symbols/createSession';
import { HttpRequestPost } from '../../service';
import { OrganisationForm, SetupForm } from '../../components-pages/Setup';

const createSessionAction = (user: SetupForm, organisation: OrganisationForm) => ({
  type: CREATE_SESSION.CREATE_SESSION,
  payload: { data: { user, organisation } }
});
const createSessionSuccessAction = (data: any) => {
  return {
    type: CREATE_SESSION.CREATE_SESSION_SUCCESS,
    payload: data
  };
};
const createSessionFailureAction = (data: any) => ({
  type: CREATE_SESSION.CREATE_SESSION_FAILURE,
  payload: data
});
const clearCreateSessionAction = () => ({
  type: CREATE_SESSION.CLEAR_CREATE_SESSION
});

interface CreateSessionRequestModel {
  InvitationToken: string;
  TeamName: string;
}
export function CreateSession(
  data: CreateSessionRequestModel,
  userInfo: SetupForm,
  organisationInfo: OrganisationForm,
  // eslint-disable-next-line no-unused-vars
  callback?: (resp: any) => void
) {
  return (dispatch: any) => {
    dispatch(createSessionAction(userInfo, organisationInfo));
    HttpRequestPost(
      'pro/payment/create-setup-session',
      dispatch,
      createSessionSuccessAction,
      createSessionFailureAction,
      false,
      data,
      callback
    );
  };
}

export function clearCreateSession() {
  return (dispatch: any) => {
    dispatch(clearCreateSessionAction());
  };
}
