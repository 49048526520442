const CHECK_FORGOT_PASSWORD_TOKEN = 'CHECK_FORGOT_PASSWORD_TOKEN';
const CHECK_FORGOT_PASSWORD_TOKEN_SUCCESS = 'CHECK_FORGOT_PASSWORD_TOKEN_SUCCESS';
const CHECK_FORGOT_PASSWORD_TOKEN_FAILURE = 'CHECK_FORGOT_PASSWORD_TOKEN_FAILURE';
const CLEAR_CHECK_FORGOT_PASSWORD_TOKEN = 'CLEAR_CHECK_FORGOT_PASSWORD_TOKEN';

const CheckForgotPasswordTokenSymbols = {
  CHECK_FORGOT_PASSWORD_TOKEN_SUCCESS,
  CHECK_FORGOT_PASSWORD_TOKEN_FAILURE,
  CHECK_FORGOT_PASSWORD_TOKEN,
  CLEAR_CHECK_FORGOT_PASSWORD_TOKEN
};

export default CheckForgotPasswordTokenSymbols;
