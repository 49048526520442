const SEND_FORGOT_PASSWORD_EMAIL = 'SEND_FORGOT_PASSWORD_EMAIL';
const SEND_FORGOT_PASSWORD_EMAIL_SUCCESS = 'SEND_FORGOT_PASSWORD_EMAIL_SUCCESS';
const SEND_FORGOT_PASSWORD_EMAIL_FAILURE = 'SEND_FORGOT_PASSWORD_EMAIL_FAILURE';
const CLEAR_SEND_FORGOT_PASSWORD_EMAIL = 'CLEAR_SEND_FORGOT_PASSWORD_EMAIL';

const SendForgotPasswordEmailSymbols = {
  SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
  SEND_FORGOT_PASSWORD_EMAIL_FAILURE,
  SEND_FORGOT_PASSWORD_EMAIL,
  CLEAR_SEND_FORGOT_PASSWORD_EMAIL
};

export default SendForgotPasswordEmailSymbols;
