const EDIT_USER = 'EDIT_USER';
const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';
const CLEAR_EDIT_USER = 'CLEAR_EDIT_USER';

const EditUserSymbols = {
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  EDIT_USER,
  CLEAR_EDIT_USER
};

export default EditUserSymbols;
