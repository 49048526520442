import INVITE_MEMBER from '../symbols/inviteMember';
import { HttpRequestPost } from '../../service';

const inviteMemberAction = () => ({
  type: INVITE_MEMBER.INVITE_MEMBER
});
const inviteMemberSuccessAction = (data: any) => {
  return {
    type: INVITE_MEMBER.INVITE_MEMBER_SUCCESS,
    payload: {
      data
    }
  };
};
const inviteMemberFailureAction = (data: any) => ({
  type: INVITE_MEMBER.INVITE_MEMBER_FAILURE,
  payload: data
});
const clearInviteMemberAction = () => ({
  type: INVITE_MEMBER.CLEAR_INVITE_MEMBER
});

interface InviteMemberRequestModel {
  FirstName: string;
  LastName: string;
  Email: string;
  ApprovalThreshold?: number;
  ApprovalRequired: boolean;
}
export function InviteMember(data: InviteMemberRequestModel, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(inviteMemberAction());
    HttpRequestPost(
      '/pro/invitation/member',
      dispatch,
      inviteMemberSuccessAction,
      inviteMemberFailureAction,
      true,
      data,
      callback
    );
  };
}

export function clearInviteMember() {
  return (dispatch: any) => {
    dispatch(clearInviteMemberAction());
  };
}
export function clearInviteMemberError() {
  return (dispatch: any) => {
    dispatch({ type: INVITE_MEMBER.CLEAR_INVITE_MEMBER_ERROR });
  };
}
