import LOGIN from '../symbols/login';
import { HttpRequestPostForLogin } from '../../service';

const loginAction = (payload: any) => ({
  type: LOGIN.LOGIN,
  payload
});
export const loginSuccessAction = (data: any) => {
  return {
    type: LOGIN.LOGIN_SUCCESS,
    payload: {
      data
    }
  };
};
const loginFailureAction = (data: any) => ({
  type: LOGIN.LOGIN_FAILURE,
  payload: {
    data,
    isUserAdmin: false
  }
});
const clearErrorAction = () => ({
  type: LOGIN.CLEAR_ERROR
});
const clearLoginAction = () => ({
  type: LOGIN.CLEAR_LOGIN
});

export function Login(Email: string, Password: string, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(loginAction({ Email }));
    HttpRequestPostForLogin(
      '/pro/login',
      dispatch,
      loginSuccessAction,
      loginFailureAction,
      false,
      {
        // request body
        Email,
        Password
      },
      callback
    );
  };
}
export function clearLoginError() {
  return (dispatch: any) => dispatch(clearErrorAction());
}
export function clearShopLogin() {
  return (dispatch: any) => {
    dispatch(clearLoginAction());
  };
}
