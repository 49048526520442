/* eslint-disable react/destructuring-assignment */
import REMOVE_DELIVERY_ADDRESS from '../symbols/removeDeliveryAddress';

const initialState = {
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function RemoveDeliveryAddressReducer(state = initialState, action: any) {
  switch (action.type) {
    case REMOVE_DELIVERY_ADDRESS.REMOVE_DELIVERY_ADDRESS:
      return { ...state, loading: true, error: false };
    case REMOVE_DELIVERY_ADDRESS.REMOVE_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };
    case REMOVE_DELIVERY_ADDRESS.REMOVE_DELIVERY_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case REMOVE_DELIVERY_ADDRESS.CLEAR_REMOVE_DELIVERY_ADDRESS:
      return {
        ...state,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default RemoveDeliveryAddressReducer;
