import {
  ThemeProvider,
  Container,
  Typography,
  Grid,
  makeStyles,
  FormControlLabel,
  CircularProgress
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import {
  ButtonPrimary,
  ButtonTextPrimary,
  ButtonWithLoading,
  ControlledInput,
  RoundedCheckbox
} from '../components-atoms';
import { Header } from '../components-molecules';
import { colours, theme } from '../constants/theme';
import { PASSWORD_VALIDATION, REQUIRED_VALIDATION } from '../constants/validation';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { CheckForgotPasswordToken } from '../redux/actions/checkForgotPasswordToken';
import { clearResetPassword, ResetPasswordRequest } from '../redux/actions/resetPassword';

const useStyles = makeStyles(() => ({
  paper: {
    padding: ' 24px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    borderRadius: '20px',
    margin: '0',
    position: 'absolute',
    top: '250px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 'inherit'
  },
  resetPaper: {
    padding: ' 24px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    borderRadius: '20px',
    marginTop: '70px',
    position: 'absolute',
    top: '250px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 'inherit'
  },
  signIntitle: {
    marginBottom: 12
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '56px',
    alignItems: 'center'
  },
  submit: {
    backgroundColor: colours.primary,
    borderRadius: '8px',
    padding: '6px 16px'
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '166px',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '16px'
  },
  successContainer: {
    padding: ' 24px',
    display: 'flex',
    marginTop: '-50px',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    borderRadius: '20px',
    position: 'absolute',
    top: '250px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 'inherit'
  }
}));
interface ResetPasswordForm {
  password: string;
  confirm: string;
}
export function ResetPassword() {
  const classes = useStyles();
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(0);
  const dispatch = useAppDispatch();
  const [loading, checkLoading, checkData, error] = useAppSelector((state) => [
    (state.resetPassword as any).loading,
    (state.checkForgotPasswordToken as any).loading,
    (state.checkForgotPasswordToken as any).data,
    (state.resetPassword as any).error
  ]);
  useEffect(() => {
    if (token) {
      dispatch(CheckForgotPasswordToken({ Token: token }));
    }
  }, [token, dispatch]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { isValid }
  } = useForm<ResetPasswordForm>({ mode: 'all', reValidateMode: 'onBlur' });
  const resetPassword: SubmitHandler<ResetPasswordForm> = (data) => {
    dispatch(
      ResetPasswordRequest(
        {
          Token: token as string,
          Password: data.password
        },
        () => {
          setStep(1);
        }
      )
    );
  };
  useEffect(() => {
    if (error) {
      setError('password', { message: error });
    } else {
      clearErrors('password');
    }
  }, [error, setError, clearErrors]);
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <Header />

      {checkLoading ? (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '96px' }}>
          <CircularProgress />
        </Grid>
      ) : (
        <Container component="main" maxWidth="xs">
          {checkData ? (
            step === 0 ? (
              <Container className={classes.resetPaper}>
                <Typography component="h6" variant="h6" className={classes.signIntitle}>
                  Reset Password
                </Typography>
                <Typography component="p" variant="body2" color="textSecondary">
                  Choose a new, secure password. Password should contain at least 8 characters, at
                  least one number and at least one uppercase letter.
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit(resetPassword)}>
                  <ControlledInput
                    control={control}
                    value={watch('password')}
                    onChange={(e) => {
                      setValue('password', e.target.value, {
                        shouldValidate: true,
                        shouldDirty: true
                      });
                      if (error) {
                        dispatch(clearResetPassword());
                      }
                    }}
                    type={showPassword ? 'text' : 'password'}
                    style={{ marginTop: 0 }}
                    rules={{ required: REQUIRED_VALIDATION, pattern: PASSWORD_VALIDATION }}
                    label="Enter new password"
                    name="password"
                  />
                  <ControlledInput
                    control={control}
                    name="confirm"
                    type={showPassword ? 'text' : 'password'}
                    label="Confirm new password"
                    rules={{
                      required: REQUIRED_VALIDATION,
                      pattern: PASSWORD_VALIDATION,
                      validate: (val) => val === watch('password') || 'Passwords must be identical'
                    }}
                  />
                  <Grid item container xs={12}>
                    <FormControlLabel
                      control={
                        <RoundedCheckbox
                          color="primary"
                          checked={showPassword}
                          onChange={(e) => setShowPassword(e.target.checked)}
                        />
                      }
                      label="Show password"
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ marginTop: '32px' }}>
                    <ButtonTextPrimary
                      style={{ marginRight: '8px' }}
                      onClick={() => navigate('/login')}
                      type="submit">
                      Back to Sign In
                    </ButtonTextPrimary>
                    <ButtonWithLoading
                      type="submit"
                      loading={loading}
                      disabled={!isValid || loading}
                      title="Submit"
                    />
                  </Grid>
                </form>
              </Container>
            ) : (
              <Container className={classes.successContainer}>
                <Typography component="h6" variant="h6" className={classes.signIntitle}>
                  Reset Password
                </Typography>
                <Typography component="p" variant="body2" color="textSecondary">
                  Thank you, your password has been reset.
                </Typography>
                <Grid container item direction="row-reverse" style={{ marginTop: '60px' }}>
                  <ButtonPrimary onClick={() => navigate('/login')}>Back to Sign In</ButtonPrimary>
                </Grid>
              </Container>
            )
          ) : (
            <Container className={classes.successContainer}>
              <Typography variant="h6">This link is no longer active</Typography>
              <Typography variant="body2" color="textSecondary" style={{ paddingTop: '12px' }}>
                The recovery link you are using is invalid or expired. Please use the button below
                to generate a new code
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{ marginTop: '60px' }}>
                {step === 0 && (
                  <ButtonTextPrimary
                    style={{ marginRight: '8px' }}
                    onClick={() => navigate('/login')}
                    type="submit">
                    Back to Sign In
                  </ButtonTextPrimary>
                )}
                <ButtonPrimary onClick={() => navigate('/forgotPassword')}>
                  Resend Recovery Link
                </ButtonPrimary>
              </Grid>
            </Container>
          )}
        </Container>
      )}
    </ThemeProvider>
  );
}
