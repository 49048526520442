/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, IconButton, Typography, makeStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { colours } from '../constants/theme';
import {
  IGetDeliveryTypeModel,
  VehicleType,
  getDeliveryTypes
} from '../redux/actions/collectOrders';
import { Caption } from '../components-atoms/Caption';
import { ErrorModal } from './ErrorModal';

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'auto',
    display: 'flex',
    scrollSnapType: 'x mandatory',
    gap: 12
  },
  item: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    cursor: 'pointer',
    transition: '0.2s all',
    minWidth: 212,
    marginBottom: 12,
    padding: 16,
    backgroundColor: colours.highEmphasis
  },
  infoButtonContainer: {
    marginTop: -6
  },
  infoButton: {
    width: 40,
    height: 40
  }
}));

export interface IVehicleTypeSelectorProps {
  onChange(e: VehicleType): void;
  style: CSSProperties;
  hasError?: boolean;
}

export const CONTACT_PHONE = '+44 1202 653 305';

export function DeliveryTypeSelector(props: IVehicleTypeSelectorProps) {
  const { style, onChange } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState<VehicleType>();
  const [loading, setLoading] = useState(false);
  const [deliveryTypes, setDeliveryTypes] = useState<IGetDeliveryTypeModel[]>([]);
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  useEffect(() => {
    getDeliveryTypes(
      (res: IGetDeliveryTypeModel[]) => {
        setDeliveryTypes(res);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  const onSelect = (v: any) => {
    setSelectedType(v.Key);
    onChange(v.Key);
  };

  const renderInfoPopup = () => {
    return (
      <ErrorModal
        visible={infoModalVisible}
        title="Rapid Delivery"
        buttonTitle="Dismiss"
        errorBody={
          "Rapid delivery 2hr promise begins the moment the courier accepts a quote request. It excludes Long Wheel Base, XLWB, Luton Vans and deliveries over 10 miles, however we'll endeavour to deliver goods on these vehicles as a matter of urgency."
        }
        onClose={() => {
          setInfoModalVisible(false);
        }}
      />
    );
  };

  if (loading) {
    return (
      <Box mt={2} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div
      ref={containerRef}
      data-testid="vehicle-type-container"
      style={style}
      className={classes.container}>
      {deliveryTypes.map((v: any) => {
        const isSelected = selectedType?.toString() === v.Key?.toString();
        return (
          <Box key={v.Key} data-testid={v.Key} onClick={() => onSelect(v)} className={classes.item}>
            <Box mb={2} display="flex" flexDirection="column">
              <Box display="flex" gridGap={6}>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 'bold',
                    marginBottom: 8,
                    color: isSelected ? colours.primary : colours.lightEmphasis
                  }}>
                  {v.Title.toUpperCase()}
                </Typography>
                {v.Key === 'rapid' && (
                  <Box className={classes.infoButtonContainer}>
                    <IconButton
                      className={classes.infoButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        setInfoModalVisible(true);
                      }}>
                      <InfoOutlinedIcon
                        htmlColor={isSelected ? colours.primary : colours.light190}
                        fontSize="small"
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Caption
                style={{
                  color: isSelected ? colours.primary : colours.lightEmphasis
                }}>
                {v.Description}
              </Caption>
            </Box>
          </Box>
        );
      })}

      {renderInfoPopup()}
    </div>
  );
}
