import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import { buttonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: 8,
    fontWeight: 500,
    ...buttonHoverAndActive
  }
}));
export function ButtonPrimary({ onClick, startIcon, className, ...rest }: ButtonProps) {
  const classes = useStyles();
  const combinedClasses = className ? `${classes.button} ${className}` : classes.button;
  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation
      className={combinedClasses}
      startIcon={startIcon}
      onClick={onClick}
      {...rest}>
      {rest.children}
    </Button>
  );
}
