import GET_MEMBER from '../symbols/getMember';
import { HttpRequestGet } from '../../service';

const getMemberAction = () => ({
  type: GET_MEMBER.GET_MEMBER
});
const getMemberSuccessAction = (data: any) => {
  return {
    type: GET_MEMBER.GET_MEMBER_SUCCESS,
    payload: data
  };
};
const getMemberFailureAction = (data: any) => ({
  type: GET_MEMBER.GET_MEMBER_FAILURE,
  payload: {
    data
  }
});
const clearGetMemberAction = () => ({
  type: GET_MEMBER.CLEAR_GET_MEMBER
});

export function GetMember(id: string, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(getMemberAction());
    HttpRequestGet(
      `/pro/user/${id}`,
      dispatch,
      getMemberSuccessAction,
      getMemberFailureAction,
      true,
      callback
    );
  };
}
export function clearGetMember() {
  return (dispatch: any) => {
    dispatch(clearGetMemberAction());
  };
}
