/* eslint-disable react/destructuring-assignment */
import CREATE_PAYMENT_ID from '../symbols/createPaymentId';

export interface CreatePaymentIdResponseModel {
  StripePaymentId: string;
  StripeCustomerId: string;
}
interface State {
  data: CreatePaymentIdResponseModel;
  loading: boolean;
  error: boolean;
}
const initialState = {
  data: {
    StripePaymentId: '',
    StripeCustomerId: ''
  },
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function CreatePaymentIdReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case CREATE_PAYMENT_ID.CREATE_PAYMENT_ID:
      return { ...state, loading: true, error: false };
    case CREATE_PAYMENT_ID.CREATE_PAYMENT_ID_SUCCESS:
      return {
        ...state,
        data: action.payload.Result,
        loading: false,
        error: false
      };
    case CREATE_PAYMENT_ID.CREATE_PAYMENT_ID_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case CREATE_PAYMENT_ID.CLEAR_CREATE_PAYMENT_ID:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    case CREATE_PAYMENT_ID.CREATE_PAYMENT_ID_SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
export default CreatePaymentIdReducer;
