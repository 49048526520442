import USER_ROLE from '../symbols/userRole';
import { HttpRequestGet } from '../../service';

const userRoleAction = () => ({
  type: USER_ROLE.USER_ROLE
});
const userRoleSuccessAction = (data: any) => {
  return {
    type: USER_ROLE.USER_ROLE_SUCCESS,
    payload: {
      data
    }
  };
};
const userRoleFailureAction = (data: any) => ({
  type: USER_ROLE.USER_ROLE_FAILURE,
  payload: {
    data,
    isUserAdmin: false
  }
});
const clearUserRoleAction = () => ({
  type: USER_ROLE.CLEAR_USER_ROLE
});

export function GetUserRoles() {
  return (dispatch: any) => {
    dispatch(userRoleAction());
    HttpRequestGet('/pro/roles', dispatch, userRoleSuccessAction, userRoleFailureAction, true);
  };
}

export function clearUserRole() {
  return (dispatch: any) => {
    dispatch(clearUserRoleAction());
  };
}
