/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

function OrganisationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="organisation"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={3}
        width={20}
        height={18}>
        <path
          d="M10 3a2 2 0 0 1 2 2v2h6a4 4 0 0 1 4 4v6a4 4 0 0 1-4 4H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6Zm8 6h-6v2h2v2h-2v2h2v2h-2v2h6a2 2 0 0 0 1.994-1.85L20 17v-6a2 2 0 0 0-1.85-1.995L18 9ZM4 17v2h2v-2H4Zm6 0H8v2h2v-2Zm8-2v2h-2v-2h2Zm-8-2H8v2h2v-2Zm8-2v2h-2v-2h2Zm-8-2H8v2h2V9ZM6 9H4v2h2V9Zm4-4H8v2h2V5ZM6 5H4v2h2V5ZM4 15h2v-2H4v2Z"
          fill={props.color || '#000'}
        />
      </mask>
      <g mask="url(#organisation)">
        <path d="M24 0H0v24h24V0Z" fill={props.color || '#000'} fillOpacity={props.opacity || 1} />
      </g>
    </svg>
  );
}

export default OrganisationIcon;
