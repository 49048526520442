const GET_PAYMENT_ID = 'GET_PAYMENT_ID';
const GET_PAYMENT_ID_SUCCESS = 'GET_PAYMENT_ID_SUCCESS';
const GET_PAYMENT_ID_FAILURE = 'GET_PAYMENT_ID_FAILURE';
const CLEAR_GET_PAYMENT_ID = 'CLEAR_GET_PAYMENT_ID';

const GetPaymentIdSymbols = {
  GET_PAYMENT_ID_SUCCESS,
  GET_PAYMENT_ID_FAILURE,
  GET_PAYMENT_ID,
  CLEAR_GET_PAYMENT_ID
};

export default GetPaymentIdSymbols;
