const FILTERS = 'FILTERS';
const FILTER_SUCCESS = 'FILTER_SUCCESS';
const CLEAR_FILTERS = 'CLEAR_FILTERS';
const TOGGLE_SEARCHBAR = 'TOGGLE_SEARCHBAR';

const CollectOrderFiltersSymbols = {
  FILTER_SUCCESS,
  FILTERS,
  CLEAR_FILTERS,
  TOGGLE_SEARCHBAR
};

export default CollectOrderFiltersSymbols;
