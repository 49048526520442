const REJECT_ORDER = 'REJECT_ORDER';
const REJECT_ORDER_SUCCESS = 'REJECT_ORDER_SUCCESS';
const REJECT_ORDER_FAILURE = 'REJECT_ORDER_FAILURE';
const CLEAR_REJECT_ORDER = 'CLEAR_REJECT_ORDER';

const RejectOrderSymbols = {
  REJECT_ORDER_SUCCESS,
  REJECT_ORDER_FAILURE,
  REJECT_ORDER,
  CLEAR_REJECT_ORDER
};

export default RejectOrderSymbols;
