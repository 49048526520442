/* eslint-disable react/destructuring-assignment */
import CHECK_FORGOT_PASSWORD_TOKEN from '../symbols/checkForgotPasswordToken';

interface State {
  loading: boolean;
  data: string;
  error: any;
}
const initialState: State = {
  loading: false,
  error: false,
  data: ''
};

// eslint-disable-next-line default-param-last
function CheckForgotPasswordTokenReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case CHECK_FORGOT_PASSWORD_TOKEN.CHECK_FORGOT_PASSWORD_TOKEN:
      return { ...state, loading: true, error: false };
    case CHECK_FORGOT_PASSWORD_TOKEN.CHECK_FORGOT_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false
      };
    case CHECK_FORGOT_PASSWORD_TOKEN.CHECK_FORGOT_PASSWORD_TOKEN_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case CHECK_FORGOT_PASSWORD_TOKEN.CLEAR_CHECK_FORGOT_PASSWORD_TOKEN:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default CheckForgotPasswordTokenReducer;
