/* eslint-disable react/destructuring-assignment */
import GET_ORDER_STATUS from '../symbols/getOrderStatus';

interface State {
  data: { Value: string }[];
  loading: boolean;
  error: any;
}
const initialState: State = {
  data: [],
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function GetOrderStatusReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case GET_ORDER_STATUS.GET_ORDER_STATUS:
      return { ...state, loading: true, error: false };
    case GET_ORDER_STATUS.GET_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        data: action.payload.Result as { Value: string }[],
        loading: false,
        error: false
      };
    case GET_ORDER_STATUS.GET_ORDER_STATUS_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case GET_ORDER_STATUS.CLEAR_GET_ORDER_STATUS:
      return {
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default GetOrderStatusReducer;
