const RESEND_INVITATION = 'RESEND_INVITATION';
const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS';
const RESEND_INVITATION_FAILURE = 'RESEND_INVITATION_FAILURE';
const CLEAR_RESEND_INVITATION = 'CLEAR_RESEND_INVITATION';

const ResendInvitationSymbols = {
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_FAILURE,
  RESEND_INVITATION,
  CLEAR_RESEND_INVITATION
};

export default ResendInvitationSymbols;
