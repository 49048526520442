import REJECT_ORDER from '../symbols/rejectOrder';
import { HttpRequestPost } from '../../service';

const rejectOrderAction = () => ({
  type: REJECT_ORDER.REJECT_ORDER
});
const rejectOrderSuccessAction = (data: any) => {
  return {
    type: REJECT_ORDER.REJECT_ORDER_SUCCESS,
    payload: data
  };
};
const rejectOrderFailureAction = (data: any) => {
  return {
    type: REJECT_ORDER.REJECT_ORDER_FAILURE,
    payload: {
      data: data.data
    }
  };
};
const clearRejectOrderAction = () => ({
  type: REJECT_ORDER.CLEAR_REJECT_ORDER
});
// eslint-disable-next-line no-unused-vars
export function RejectOrder(id: string, callback?: (resp: any) => void) {
  return (dispatch: any) => {
    dispatch(rejectOrderAction());
    HttpRequestPost(
      `/pro/order/${id}/reject`,
      dispatch,
      rejectOrderSuccessAction,
      rejectOrderFailureAction,
      true,
      callback
    );
  };
}

export function clearRejectOrder() {
  return (dispatch: any) => {
    dispatch(clearRejectOrderAction());
  };
}
