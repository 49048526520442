import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import { ButtonTextPrimary } from '../components-atoms';
import { useAppDispatch } from '../hooks/store';
import { UpdateSession } from '../redux/actions/updateSession';
import { UpdatePaymentId } from '../redux/actions/updatePaymentId';

const useStyles = makeStyles(() => ({
  basicInformationContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    boxSizing: 'border-box',
    textAlign: 'left',
    marginTop: '24px',
    padding: '24px 16px 0',
    '@media (min-width: 900px)': {
      padding: '18px 24px 0',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '20px'
    }
  },
  infoTitle: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 30
    }
  },
  title: {
    '@media (max-width: 599px)': {
      marginBottom: 12
    }
  },
  infoTextContainer: {
    paddingBottom: 15
  }
}));

export function OrganisationPaymentMethod() {
  const classes = useStyles();
  const [searchParams] = useSearchParams();

  const stripeStatus = searchParams.get('stripeStatus');
  const stripeCheckoutSessionId = searchParams.get('stripeCheckoutSessionId');
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    if (stripeStatus && stripeStatus === 'success') {
      dispatch(UpdatePaymentId({ StripeCheckoutSessionId: stripeCheckoutSessionId as string }));
    }
  }, [stripeStatus, stripeCheckoutSessionId, dispatch]);
  const updateDetails = () => {
    dispatch(
      UpdateSession((resp) => {
        window.open(resp.Result.Url, '_self');
      })
    );
  };
  return (
    <Container className={classes.basicInformationContainer}>
      <Grid container>
        <Grid container className={classes.infoTitle}>
          <Typography variant="h6" component="span" className={classes.title}>
            Payment method
          </Typography>
          <ButtonTextPrimary onClick={() => updateDetails()}>
            Update Payment Details
          </ButtonTextPrimary>
        </Grid>
        <Grid item container className={classes.infoTextContainer}>
          <Typography variant="body2" color="textSecondary">
            Your payment information is stored on Stripe. You can change your payment information by
            clicking Update Payment Details.
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{ paddingTop: '12px' }}>
            This payment method is used by your team members when checking out in the TradeKart app.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
