const REGISTER = 'REGISTER';
const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
const REGISTER_FAILURE = 'REGISTER_FAILURE';
const CLEAR_REGISTER = 'CLEAR_REGISTER';
const CLEAR_REGISTER_ERROR = 'CLEAR_REGISTER_ERROR';

const RegisterSymbols = {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER,
  CLEAR_REGISTER,
  CLEAR_REGISTER_ERROR
};

export default RegisterSymbols;
