/* eslint-disable no-unused-vars */

import { IGooglePlaceResponse } from './googlePlacesUtil';

/* eslint-disable no-shadow */
export interface ITimeSlot {
  Start: string;
  End: string;
}

export interface ISlot {
  value: Date | string;
  label: string;
}

export interface IAvailableSlots {
  day: Date | string;
  availableSlots: Array<ISlot>;
}

export const DEFAULT_COUNTRY = 'UK';
export const DEFAULT_VALIDATION_MSG = 'This field is mandatory.';
export const ADDRESS_CHANGED_PATTERN = /(?<=\bto\b).*?(?=\.)/;
export const ADDRESS_CHANGED_ISSUES = ['pickup_location_changed', 'dropoff_location_changed'];
export const ADDRESS_CHANGED_SHOULD_WAIT = ['pending', 'looking_for_driver'];
export const ORKESTRO_ERROR = {
  Price: 'Something has gone wrong while getting the price, please try again.',
  PlaceOrder: 'Something has gone wrong while placing the order, please try again.'
};
export enum CollectionOrderError {
  NoFleetFoundForGivenVehicleType = 'NoFleetFoundForGivenVehicleType'
}

export const getAddressStringFromErrorMsg = (err: string) => {
  let address;

  const matches = err.match(ADDRESS_CHANGED_PATTERN);
  if (matches && matches.length > 0) {
    address = matches[0].trim();
  }

  return address;
};

export const hasValidAddress = (address: IGooglePlaceResponse) => {
  return (
    address &&
    address.Line1 &&
    address.PostCode &&
    address.Lat &&
    address.Lat !== 0 &&
    address.Long &&
    address.Long !== 0
  );
};
