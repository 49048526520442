const GET_TEAM_DETAIL = 'GET_TEAM_DETAIL';
const GET_TEAM_DETAIL_SUCCESS = 'GET_TEAM_DETAIL_SUCCESS';
const GET_TEAM_DETAIL_FAILURE = 'GET_TEAM_DETAIL_FAILURE';
const CLEAR_GET_TEAM_DETAIL = 'CLEAR_GET_TEAM_DETAIL';

const GetTeamDetailSymbols = {
  GET_TEAM_DETAIL_SUCCESS,
  GET_TEAM_DETAIL_FAILURE,
  GET_TEAM_DETAIL,
  CLEAR_GET_TEAM_DETAIL
};

export default GetTeamDetailSymbols;
