/* eslint-disable react/no-array-index-key */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CircularProgress, Container, Grid, makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import {
  OrderBasicInfo,
  OrderedItems,
  OrderHeader,
  OrderTotal,
  OrderInvoices
} from '../components-organisms';
import config from '../config';

import { useAppDispatch, useAppSelector } from '../hooks/store';
import { GetOrderDetail } from '../redux/actions/orderDetail';
import { OrderDetail } from '../redux/reducers/orderDetail';

const stripePromise = loadStripe(config.stripeKey);

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '@media (min-width: 900px)': {
      maxWidth: '840px'
    }
  }
}));

export function Order() {
  const { id } = useParams();
  const classes = useStyles();
  const [data, loading]: [OrderDetail[], boolean] = useAppSelector((state) => [
    (state.orderDetail as any).data,
    (state.orderDetail as any).loading
  ]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) {
      dispatch(GetOrderDetail(id));
    }
  }, [id, dispatch]);
  return (
    <Elements stripe={stripePromise}>
      <Container className={classes.root}>
        {loading ? (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '96px' }}>
            <CircularProgress />
          </Grid>
        ) : (
          data &&
          data.length > 0 && (
            <>
              <OrderHeader data={data[0]} />
              <div style={{ marginTop: '24px' }}>
                <OrderBasicInfo data={data[0]} />
                {data[0].Deliveries.map((delivery, idx) => (
                  <OrderedItems data={delivery} key={idx} index={idx} />
                ))}
                <OrderTotal data={data[0]} />
                {[...data[0].DeliveryInvoiceFileUrl, ...data[0].OrderInvoiceFileUrl].length > 0 && (
                  <OrderInvoices
                    data={[...data[0].DeliveryInvoiceFileUrl, ...data[0].OrderInvoiceFileUrl]}
                  />
                )}
              </div>
            </>
          )
        )}
      </Container>
    </Elements>
  );
}
