import { useCallback } from 'react';
import { Container, Typography, makeStyles, Box } from '@material-ui/core';
import { useNavigate } from 'react-router';
import ReplayIcon from '@material-ui/icons/Replay';
import BackArrowIcon from '../components-atoms/icons/backArrow';
import { CollectOrderStamp, CollectOrderStatus } from '../components-molecules/CollectOrderStamp';
import { buttonHoverAndActive } from '../utils/styles/buttonStyles';
import { Stamp } from '../components-atoms/Stamp';
import { ButtonPrimary } from '../components-atoms';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    gap: 16,
    maxWidth: 1200,
    paddingLeft: 0,
    paddingRight: 0
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'left',
    marginRight: 16
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    '& h1': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical'
    }
  },
  backImg: {
    width: 40,
    height: 40,
    marginTop: 4,
    marginRight: 16,
    marginLeft: 24,
    borderRadius: 20,
    cursor: 'pointer',
    '@media (max-width: 899px)': {
      marginLeft: 16
    },
    ...buttonHoverAndActive
  },
  stampContainer: {
    display: 'flex',
    marginTop: 4
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16
  }
}));
interface ICollectionOrderHeaderProps {
  id?: string;
  title: string;
  status?: CollectOrderStatus;
  portalStatus?: string;
  headerRight?: any;
  premiumSelected?: boolean;
  from?: string | null;
}

export function CollectionOrderHeader({
  id,
  title,
  status,
  portalStatus,
  headerRight,
  premiumSelected,
  from
}: ICollectionOrderHeaderProps) {
  const navigate = useNavigate();
  const classes = useStyles();
  const onBack = () => {
    if (from === 'form' || window.history.length <= 2) {
      window.location.href = '/delivery-orders';
    } else {
      navigate(-1);
    }
  };

  const getStatusText = useCallback(() => {
    switch (portalStatus) {
      case 'Open':
        return 'Open Orders';
      case 'Archived':
        return 'Archived';
      case 'OutForDelivery':
        return 'Out For Delivery';
      default:
        return 'Open Orders';
    }
  }, [portalStatus]);

  const repeatBooking = () => {
    window.location.href = `/new-delivery-order?repeatBookingId=${id}`;
  };

  return (
    <Container className={classes.header}>
      <Box className={classes.backImg}>
        <BackArrowIcon onClick={onBack} />
      </Box>
      <Box className={classes.headerLeft}>
        <Typography variant="body1" component="p" color="textSecondary">
          {getStatusText()}
        </Typography>
        <Box className={classes.titleSection}>
          <Typography variant="h5" component="h1" color="textPrimary">
            {title}
          </Typography>
          <Box className={classes.stampContainer}>
            {status && <CollectOrderStamp status={status} />}
            {premiumSelected && <Stamp type="success" text="Concierge" />}
          </Box>
        </Box>
      </Box>
      <Box className={classes.headerRight}>
        {headerRight?.()}
        {id && id.length > 0 ? (
          <ButtonPrimary startIcon={<ReplayIcon />} style={{ width: 170 }} onClick={repeatBooking}>
            Repeat Booking
          </ButtonPrimary>
        ) : null}
      </Box>
    </Container>
  );
}
