import GET_ORDER_STATUS from '../symbols/getOrderStatus';
import { HttpRequestGet } from '../../service';

const getOrderStatusAction = () => ({
  type: GET_ORDER_STATUS.GET_ORDER_STATUS
});
const getOrderStatusSuccessAction = (data: any) => {
  return {
    type: GET_ORDER_STATUS.GET_ORDER_STATUS_SUCCESS,
    payload: data
  };
};
const getOrderStatusFailureAction = (data: any) => ({
  type: GET_ORDER_STATUS.GET_ORDER_STATUS_FAILURE,
  payload: {
    data
  }
});
const clearGetOrderStatusAction = () => ({
  type: GET_ORDER_STATUS.CLEAR_GET_ORDER_STATUS
});

export function GetOrderStatus(callback?: () => void) {
  return (dispatch: any) => {
    dispatch(getOrderStatusAction());
    HttpRequestGet(
      '/pro/order/status',
      dispatch,
      getOrderStatusSuccessAction,
      getOrderStatusFailureAction,
      true,
      callback
    );
  };
}

export function clearGetOrderStatus() {
  return (dispatch: any) => {
    dispatch(clearGetOrderStatusAction());
  };
}
