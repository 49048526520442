/* eslint-disable react/destructuring-assignment */
import SEARCH_ADDRESS_DETAILS from '../symbols/searchAddressDetails';

interface AddressDetail {
  BuildingName: string;
  BuildingNumber: string;
  Country: string;
  County: string;
  District: string;
  FormattedAddress: string[];
  Lat: number;
  Line1: string;
  Line2: string;
  Line3: string;
  Line4: string;
  Locality: string;
  Long: number;
  Postcode: string;
  Residential: boolean;
  SubBuildingName: string;
  SubBuildingNumber: string;
  ThoroughFare: string;
  TownOrCity: string;
}
interface State {
  loading: boolean;
  data: AddressDetail | null;
  error: any;
}
const initialState: State = {
  loading: false,
  error: false,
  data: null
};

// eslint-disable-next-line default-param-last
function SearchAddressDetailsReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case SEARCH_ADDRESS_DETAILS.SEARCH_ADDRESS_DETAILS:
      return { ...state, loading: true, error: false };
    case SEARCH_ADDRESS_DETAILS.SEARCH_ADDRESS_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload as AddressDetail,
        loading: false,
        error: false
      };
    case SEARCH_ADDRESS_DETAILS.SEARCH_ADDRESS_DETAILS_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case SEARCH_ADDRESS_DETAILS.CLEAR_SEARCH_ADDRESS_DETAILS:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default SearchAddressDetailsReducer;
