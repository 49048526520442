/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { usePlacesWidget } from 'react-google-autocomplete';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { TextFieldInput } from './TextFieldInput';
import {
  addressParser,
  GooglePlaceError,
  getErrorMsg,
  IGooglePlaceResponse,
  getVicinity
} from '../utils/googlePlacesUtil';
import config from '../config';
import { isValidPostCode } from '../utils/addressStringConvertor';

export interface IGooglePlaceInputProps {
  onPlaceChange?: (e: IGooglePlaceResponse) => void;
  placeholder?: string;
  hasError?: string;
  label?: string;
  _defaultValue?: IGooglePlaceResponse;
  style?: React.CSSProperties;
}

const DEFAULT_COUNTRY = 'UK';

export function GooglePlaceInput(props: IGooglePlaceInputProps) {
  const { onPlaceChange, hasError, _defaultValue, ...rest } = props;
  const [placeId, setPlaceId] = useState('');
  const [error, setError] = useState<string | undefined>();
  const [address, setAddress] = useState<IGooglePlaceResponse>();

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  useEffect(() => {
    if (_defaultValue?.PostCode && _defaultValue?.PostCode !== address?.PostCode) {
      setAddress(_defaultValue);
    }
  }, [address, _defaultValue]);

  const { placesService } = usePlacesService({
    apiKey: config.googleApiKey,
    options: {
      componentRestrictions: { country: DEFAULT_COUNTRY }
    }
  });

  const { ref: materialRef } = usePlacesWidget({
    apiKey: config.googleApiKey,
    options: {
      types: ['geocode', 'establishment'],
      componentRestrictions: { country: DEFAULT_COUNTRY }
    },
    onPlaceSelected: (place) => {
      setPlaceId(place.place_id);
    }
  });

  const handlePlaceChange = (e: any) => {
    const parsedAddress = addressParser(e);
    setAddress(undefined);
    setError(undefined);

    if (!parsedAddress.Line1) {
      setError(getErrorMsg(GooglePlaceError.MISSING_ADDRESS));
    }

    // If name not exist,
    // set street error.
    const isNameExist = parsedAddress.Name && parsedAddress.Name !== parsedAddress.PostCode;
    if (!isNameExist) {
      if (!parsedAddress.StreetNumber) {
        setError(getErrorMsg(GooglePlaceError.MISSING_STREET_NUMBER));
      }
    }

    if (!parsedAddress.City) {
      setError(getErrorMsg(GooglePlaceError.MISSING_CITY));
    }

    if (!parsedAddress.PostCode) {
      setError(getErrorMsg(GooglePlaceError.MISSING_POSTCODE));
    }

    if (!isValidPostCode(parsedAddress.PostCode)) {
      setError(getErrorMsg(GooglePlaceError.MISSING_POSTCODE));
    }

    if (!parsedAddress.Lat || !parsedAddress.Long) {
      setError(getErrorMsg(GooglePlaceError.MISSING_LAT_OR_LONG));
    }

    setAddress(parsedAddress);

    onPlaceChange?.({
      ...parsedAddress,
      hasError: error
    });
  };

  useEffect(() => {
    placesService?.getDetails(
      {
        placeId
      },
      (placeDetails: any) => {
        handlePlaceChange(placeDetails);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId]);

  const renderAddressText = useCallback(() => {
    if (!address?.PostCode) {
      return null;
    }

    return (
      <Box mt={4} mb={4}>
        {address?.Name !== address?.PostCode && address?.Name !== address?.Line1 && (
          <Typography>{address?.Name}</Typography>
        )}
        <Typography>{address?.Line1}</Typography>
        <Typography>{address?.City}</Typography>
        <Typography>
          {address?.PostCode} {address?.Line3}
        </Typography>
      </Box>
    );
  }, [address]);

  return (
    <Box>
      <TextFieldInput
        fullWidth
        color="primary"
        variant="outlined"
        inputRef={materialRef}
        error={!!error && Boolean(error?.length > 0)}
        defaultValue={_defaultValue?.Name}
        {...rest}
      />

      {error && (
        <Box style={{ marginTop: -3 }}>
          <Typography
            variant="caption"
            color="error"
            style={{
              fontSize: 14,
              fontWeight: 400,
              letterSpacing: 0.4,
              marginLeft: 14
            }}>
            {error}
          </Typography>
        </Box>
      )}

      {renderAddressText()}
    </Box>
  );
}
