import { InfoSection } from '../components-molecules/InfoSection';
import { ICollectOrderDetailResponseModel } from '../redux/actions/collectionOrderDetail';
import { getCollectOrderDate } from '../utils';
import { getMultipleValuesAsText } from '../utils/getMultipleValuesAsText';

export interface ICollectionOrderBasicInfoProps {
  data: ICollectOrderDetailResponseModel;
}

export function CollectionOrderBasicInfo(props: ICollectionOrderBasicInfoProps) {
  const { data } = props;

  const orderValueAsPences = data.OrderValue ? `£${(data.OrderValue / 100).toFixed(2)}` : '-';
  const gridData = [
    { title: 'Order Date', value: getCollectOrderDate(data.Drafted) },
    {
      title: 'Collection Name',
      value: data.MerchantName
    },
    {
      title: 'Collection Phone Number',
      value: data.PickUpPhone
    },
    {
      title: 'Booked By',
      value: data?.BookedBy || '-'
    },
    { title: 'Reference Number', value: data.ReferenceNumber },
    { title: 'Order Details', value: data.OrderDetails },
    {
      title: 'Collection Address',
      value: getMultipleValuesAsText([
        data.PickUpAddressLine1,
        data.PickUpAddressLine2,
        data.PickUpAddressLine3
      ]),
      helperText: getMultipleValuesAsText([data.PickUpAddressPostCode, data.PickUpAddressCity])
    },
    { title: 'Collection Time', value: getCollectOrderDate(data.PickUpTime) },
    { title: 'Collection Instructions', value: data.PickUpInstructions || '-' },
    { title: 'Vehicle Type', value: data.VehicleTypeName },
    {
      title: 'Delivery Address',
      value: getMultipleValuesAsText([
        data.DropOffAddressLine1,
        data.DropOffAddressLine2,
        data.DropOffAddressLine3
      ]),
      helperText: getMultipleValuesAsText([data.DropOffAddressPostCode, data.DropOffAddressCity])
    },
    {
      title: 'Delivery Instructions',
      value: data.CustomerInstructions || '-'
    }
  ];

  return <InfoSection title="Order details" data={gridData} sx={{ mb: 2 }} />;
}
