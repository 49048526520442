import { Typography, makeStyles, Box, BoxProps } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  basicInformationContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '18px 16px',
    boxSizing: 'border-box',
    textAlign: 'left',
    '@media (min-width: 900px)': {
      padding: '18px 24px',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '20px'
    }
  },
  infoTitle: {
    marginBottom: 4
  },
  helperText: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.4
  }
}));

interface IInfoSectionProps {
  title: string;
  helperText?: string;
  children: React.ReactNode;
  headerRight?: any;
  headerLeft?: any;
  headerStyle?: React.CSSProperties;
  sx?: BoxProps;
}
export function InfoSectionContainer({
  title,
  children,
  helperText,
  headerRight,
  headerLeft,
  headerStyle,
  sx
}: IInfoSectionProps) {
  const classes = useStyles();

  return (
    <Box
      data-testid="section-box"
      data-title={title}
      className={classes.basicInformationContainer}
      sx={sx}>
      <Box className={classes.infoTitle}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Typography variant="h6" display="block">
              {title}
            </Typography>
            {headerLeft?.()}
          </Box>

          {headerRight?.()}
        </Box>
        {helperText && (
          <Box mt={1} style={headerStyle}>
            <Typography variant="caption" color="textSecondary" className={classes.helperText}>
              {helperText}
            </Typography>
          </Box>
        )}
      </Box>

      {children}
    </Box>
  );
}
