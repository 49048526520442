import { colours } from '../../constants/theme';

export const buttonHoverAndActive = {
  backgroundColor: colours.highEmphasis,
  color: colours.primary,
  '&:hover': {
    backgroundColor: colours.highEmpasis008
  },
  '&:active': {
    backgroundColor: colours.highEmpasis24
  }
};

export const textButtonHoverAndActive = {
  '&:hover': {
    backgroundColor: colours.highEmphasis04
  },
  '&:active': {
    backgroundColor: colours.highEmphasis16
  }
};

export const headerButtonsResponsive = {
  '@media(max-width: 899px)': {
    backgroundColor: colours.highEmphasis04,
    width: '100%',
    height: 36,
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: '8px',
    padding: '6px 16px'
  }
};
