import ADD_DELIVERY_ADDRESS from '../symbols/addDeliveryAddress';
import { HttpRequestPost } from '../../service';

const addDeliveryAddressAction = () => ({
  type: ADD_DELIVERY_ADDRESS.ADD_DELIVERY_ADDRESS
});
const addDeliveryAddressSuccessAction = (data: any) => {
  return {
    type: ADD_DELIVERY_ADDRESS.ADD_DELIVERY_ADDRESS_SUCCESS,
    payload: {
      data
    }
  };
};
const addDeliveryAddressFailureAction = (data: any) => ({
  type: ADD_DELIVERY_ADDRESS.ADD_DELIVERY_ADDRESS_FAILURE,
  payload: {
    data
  }
});
const clearAddDeliveryAddressAction = () => ({
  type: ADD_DELIVERY_ADDRESS.CLEAR_ADD_DELIVERY_ADDRESS
});
interface AddDeliveryAddressRequestModel {
  AddressName: string;
  Line1: string;
  Line2?: string;
  Line3?: string;
  PostalCode: string;
  City: string;
  County: string;
  Country: string;
  DoorbellName?: string;
  InstructionsForCourier?: string;
  ContactPhoneNumber?: string;
  Note?: string;
  Long: number;
  Lat: number;
}
export function AddDeliveryAddress(data: AddDeliveryAddressRequestModel, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(addDeliveryAddressAction());
    HttpRequestPost(
      '/pro/delivery-address',
      dispatch,
      addDeliveryAddressSuccessAction,
      addDeliveryAddressFailureAction,
      true,
      data,
      callback
    );
  };
}

export function clearAddDeliveryAddress() {
  return (dispatch: any) => {
    dispatch(clearAddDeliveryAddressAction());
  };
}
