import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import { ITheme } from '../constants/theme';
import { textButtonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles((theme: ITheme) => ({
  button: {
    color: theme.palette.primary.main,
    borderRadius: 8,
    minWidth: '84px',
    ...textButtonHoverAndActive
  }
}));
export function ButtonSecondary({ onClick, startIcon, className, ...rest }: ButtonProps) {
  const classes = useStyles();
  return (
    <Button
      variant="text"
      disableElevation
      className={`${classes.button} ${className}`}
      startIcon={startIcon}
      onClick={onClick}
      {...rest}>
      {rest.children}
    </Button>
  );
}
