import { Grid, TextField, makeStyles } from '@material-ui/core';
import { ChangeEventHandler, MouseEventHandler, useState } from 'react';
import { ButtonTextPrimary } from '../components-atoms';

interface IProps {
  isOpen: boolean;
  placeholder?: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  onCancel: MouseEventHandler<HTMLButtonElement> | undefined;
  query: string;
}
const useStyles = makeStyles(() => ({
  container: {
    flexWrap: 'nowrap',
    marginTop: 25,
    paddingRight: 24,
    paddingLeft: 24,
    '@media(max-width: 899px)': {
      paddingRight: 16,
      paddingLeft: 16
    }
  },
  inputContainer: {
    width: '100%'
  },
  input: {
    width: '100%'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 16
  }
}));
export function Searchbar({ isOpen, placeholder = 'Search', onChange, onCancel, query }: IProps) {
  const classes = useStyles();
  const [tempQuery, setTempQuery] = useState<string>(query);
  return isOpen ? (
    <Grid container className={classes.container}>
      <Grid className={classes.inputContainer}>
        <TextField
          autoFocus
          className={classes.input}
          variant="outlined"
          name="Search"
          placeholder={placeholder}
          label={placeholder}
          value={tempQuery}
          onChange={(e) => {
            setTempQuery(e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
        />
      </Grid>
      <Grid className={classes.buttonContainer}>
        <ButtonTextPrimary
          onClick={(e) => {
            if (onCancel) {
              onCancel(e);
            }
            setTempQuery('');
          }}>
          Cancel
        </ButtonTextPrimary>
      </Grid>
    </Grid>
  ) : null;
}
