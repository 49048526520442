import cargoBike from '../assets/icons/vehicle-types/cargo_bike.svg';
import cargoBikeFilled from '../assets/icons/vehicle-types/cargo_bike_filled.svg';
import van from '../assets/icons/vehicle-types/van.svg';
import vanFilled from '../assets/icons/vehicle-types/van_filled.svg';
import car from '../assets/icons/vehicle-types/car.svg';
import carFilled from '../assets/icons/vehicle-types/car_filled.svg';
import motorcycle from '../assets/icons/vehicle-types/motorcycle.svg';
import motorcycleFilled from '../assets/icons/vehicle-types/motorcycle_filled.svg';
import rVan from '../assets/icons/vehicle-types/regular_van.svg';
import rVanFilled from '../assets/icons/vehicle-types/regular_van_filled.svg';
import lVan from '../assets/icons/vehicle-types/35t_van.svg';
import lVanFilled from '../assets/icons/vehicle-types/35t_van_filled.svg';
import { IGetVehicleTypeModel } from '../redux/actions/collectOrders';

export const VEHICLE_TYPES: {
  [key: string]: { image: string; fillImage: string; infoText?: string };
} = {
  bicycle_cargo: {
    image: cargoBike,
    fillImage: cargoBikeFilled
  },
  van_small: {
    image: van,
    fillImage: vanFilled
  },
  car: {
    image: car,
    fillImage: carFilled
  },
  motorcycle: {
    image: motorcycle,
    fillImage: motorcycleFilled
  },
  van: {
    image: rVan,
    fillImage: rVanFilled
  },
  xlwb: {
    image: rVan,
    fillImage: rVanFilled
  },
  '35t_van': {
    image: lVan,
    fillImage: lVanFilled
  },
  luton: {
    image: lVan,
    fillImage: lVanFilled
  }
};

export const getVehiclePopupContent = (v: IGetVehicleTypeModel) => {
  return [
    {
      title: 'Speed',
      value: v?.Speed || '-'
    },
    {
      title: 'Availability',
      value: v?.Availability || '-'
    },
    {
      title: 'Example',
      value: v?.Example || '-'
    },
    {
      title: 'Max Weight',
      value: v?.MaxWeight || '-'
    },
    {
      title: 'Max Length',
      value: v?.MaxLength || '-'
    }
  ];
};
