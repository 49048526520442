import { SVGProps } from 'react';
import { colours } from '../../constants/theme';

function BackArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={40} height={41} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y={0.5} width={40} height={40} rx={20} fill="transparent" />
      <rect y={0.5} width={40} height={40} rx={20} fill="transparent" />
      <mask
        id="backArrow"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={12}
        y={12}
        width={16}
        height={17}>
        <path
          d="M26.791 19.505h-11.17l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41l-4.88-4.88h11.17c.55 0 1-.45 1-1s-.45-1-1-1Z"
          fill="#000"
        />
      </mask>
      <g mask="url(#backArrow)">
        <path d="M32 8.5H8v24h24v-24Z" fill={colours.primary} />
      </g>
    </svg>
  );
}

export default BackArrow;
