import { CircularProgress, Container, Grid, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import multiDownload from 'multi-download';
import { ButtonPrimary, ButtonTextPrimary, GroupHeader } from '../components-atoms';
import { InvoiceItem } from '../components-molecules';
import DownloadIcon from '../components-atoms/icons/download';
import { colours, theme } from '../constants/theme';
import { useAppSelector } from '../hooks/store';

const useStyles = makeStyles(() => ({
  buttonSet: {
    paddingTop: 24,
    columnGap: 8,
    '@media (max-width: 899px)': {
      paddingRight: 16
    }
  }
}));
interface IProps {
  loadMore: () => void;
}

export function InvoiceList({ loadMore }: IProps) {
  const classes = useStyles();
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const invoices = useAppSelector((state) => state.getInvoices.data);

  const onChange = (value: boolean, id: string) => {
    if (value) {
      setCheckedList((prev) => [...prev, id]);
    } else {
      setCheckedList((prev) => prev.filter((f) => f !== id));
    }
  };

  const downloadPdfs = async () => {
    const invoiceItems = invoices.Result.map((m) => m.Result)
      .flat()
      .filter((f) => checkedList.includes(f.InvoiceNo));
    invoiceItems.forEach(async (item) => {
      const data = await fetch(item.InvoiceFileUrl, {
        method: 'GET',
        mode: 'cors',
        headers: { Accept: 'form-data' }
      });

      const convertedToBlob = await data.blob();
      const convertedToUrl = await window.URL.createObjectURL(new Blob([convertedToBlob]));

      multiDownload([convertedToUrl], {
        rename: () => `${item.InvoiceNo}.pdf`
      });
    });
  };

  const selectAll = () => {
    setCheckedList(
      invoices.Result.map((m) => m.Result)
        .flat()
        .map((m) => m.InvoiceNo)
    );
  };

  const clearAll = () => {
    setCheckedList([]);
  };
  return (
    <>
      <Grid container item direction="row" justifyContent="flex-end" className={classes.buttonSet}>
        <ButtonTextPrimary
          onClick={() => selectAll()}
          disabled={checkedList.length === invoices.Result.map((m) => m.Result).flat().length}>
          Select All
        </ButtonTextPrimary>
        <ButtonTextPrimary onClick={() => clearAll()} disabled={checkedList.length === 0}>
          Clear All
        </ButtonTextPrimary>
        <ButtonPrimary
          disabled={checkedList.length === 0}
          onClick={() => downloadPdfs()}
          startIcon={
            <DownloadIcon
              color={checkedList.length === 0 ? theme.palette.action.disabled : colours.primary}
            />
          }>
          Download
        </ButtonPrimary>
      </Grid>
      <InfiniteScroll
        style={{ overflow: 'hidden' }}
        dataLength={invoices.Result.map((m: any) => m.Result).flat().length} // This is important field to render the next data
        next={() => loadMore()}
        scrollThreshold={0.8}
        hasMore={invoices.Result.map((m: any) => m.Result).flat().length !== invoices.Count}
        loader={
          <Container style={{ width: '100%' }}>
            <CircularProgress />
          </Container>
        }>
        {invoices.Result.map((item, index: number) => (
          <div key={item.GroupName}>
            {item.Result.length > 0 && (
              <GroupHeader
                idx={index}
                header={item.GroupName || 'Other'}
                count={item.Result.length}
                data-automation="number-of-invoices"
                headlineType="Invoices"
              />
            )}
            {item.Result.map((invoice) => (
              <InvoiceItem
                key={invoice.InvoiceNo}
                data={invoice}
                checked={checkedList.includes(invoice.InvoiceNo)}
                onChange={(_, val) => onChange(val, invoice.InvoiceNo)}
              />
            ))}
          </div>
        ))}
      </InfiniteScroll>
    </>
  );
}
