/* eslint-disable react/destructuring-assignment */
import TEAM_MEMBERS from '../symbols/getTeamMembers';

export interface TeamMember {
  CustomerId: string;
  FirstName: string;
  LastName: string;
  Status: string;
}
interface State {
  loading: boolean;
  error: boolean;
  data: TeamMember[];
}
const initialState: State = {
  loading: false,
  error: false,
  data: []
};

// eslint-disable-next-line default-param-last
function GetTeamMembersReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case TEAM_MEMBERS.TEAM_MEMBERS:
      return { ...state, loading: true, error: false };
    case TEAM_MEMBERS.TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        data: action.payload.Result as TeamMember[],
        loading: false,
        error: false
      };
    case TEAM_MEMBERS.TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        token: null,
        loading: false,
        error: action.payload
      };
    case TEAM_MEMBERS.CLEAR_TEAM_MEMBERS:
      return {
        ...state,
        token: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default GetTeamMembersReducer;
