import { ThemeProvider, Container, Typography, Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { ButtonTextPrimary, ButtonWithLoading, ControlledInput } from '../components-atoms';
import { Header } from '../components-molecules';
import { colours, theme } from '../constants/theme';
import { EMAIL_VALIDATION, REQUIRED_VALIDATION } from '../constants/validation';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  clearSendForgotPasswordEmail,
  SendForgotPasswordEmail
} from '../redux/actions/sendForgotPasswordEmail';

const useStyles = makeStyles(() => ({
  paper: {
    padding: ' 24px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    borderRadius: '20px',
    margin: '0',
    position: 'absolute',
    top: '250px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 'inherit'
  },
  resetPaper: {
    padding: ' 24px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    borderRadius: '20px',
    marginTop: '70px',
    position: 'absolute',
    top: '250px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 'inherit'
  },
  signIntitle: {
    marginBottom: 12
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '56px',
    alignItems: 'center'
  },
  submit: {
    backgroundColor: colours.primary,
    borderRadius: '8px',
    padding: '6px 16px'
  }
}));
interface SendEmail {
  email: string;
}
export function ForgotPassword() {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [error, loading] = useAppSelector((state) => [
    (state.sendForgotPasswordEmail as any).error,
    (state.sendForgotPasswordEmail as any).loading
  ]);
  const submit: SubmitHandler<SendEmail> = (data) => {
    if (step === 0) {
      dispatch(
        SendForgotPasswordEmail({ Email: data.email }, () => {
          setStep(1);
        })
      );
    } else {
      navigate('/login');
    }
  };
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { isValid }
  } = useForm<SendEmail>({ mode: 'all', reValidateMode: 'onBlur' });
  useEffect(() => {
    if (error) {
      setError('email', { message: error });
    } else {
      clearErrors('email');
    }
  }, [error, setError, clearErrors]);
  useEffect(() => {
    dispatch(clearSendForgotPasswordEmail());
  }, [dispatch]);
  return (
    <ThemeProvider theme={theme}>
      <Header />

      <Container component="main" maxWidth="xs">
        <Container className={classes.paper}>
          <Typography component="h6" variant="h6" className={classes.signIntitle}>
            Forgot Password
          </Typography>
          <Typography component="p" variant="body2" color="textSecondary">
            {step === 0
              ? 'Enter your email and we will send you a link to the password reset page where you will be able to reset your password.'
              : 'We have sent you a password reset email. Please follow the instructions on your email.'}
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit(submit)}>
            {step === 0 && (
              <ControlledInput
                control={control}
                style={{ marginTop: 0 }}
                value={watch('email')}
                onChange={(e) => {
                  setValue('email', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                  });
                  if (error) {
                    dispatch(clearSendForgotPasswordEmail());
                  }
                }}
                rules={{ required: REQUIRED_VALIDATION, pattern: EMAIL_VALIDATION }}
                name="email"
                label="Email Address"
              />
            )}
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              style={{ marginTop: '32px' }}>
              {step === 0 && (
                <ButtonTextPrimary
                  style={{ marginRight: '8px' }}
                  onClick={() => navigate('/login')}
                  type="submit">
                  Back to Sign In
                </ButtonTextPrimary>
              )}
              <ButtonWithLoading
                type="submit"
                loading={step === 0 ? loading : false}
                disabled={step === 0 ? !isValid || loading : false}
                title={step === 0 ? 'Submit' : 'Back to Sign In'}
              />
            </Grid>
          </form>
        </Container>
      </Container>
    </ThemeProvider>
  );
}
