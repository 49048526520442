import {
  Container,
  Grid,
  ThemeProvider,
  Typography,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import { theme } from '../constants/theme';
import BackArrow from '../components-atoms/icons/backArrow';
import ThreeDotMenu from './ThreeDotMenu';
import { buttonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles(() => ({
  header: {
    paddingLeft: 0,
    paddingRight: 0
  },
  headerSubtitle: {
    marginBottom: 4
  },
  headerButtonContainer: {
    paddingRight: 0,
    padding: 0,
    margin: 0,
    marginRight: 0
  },
  backImg: {
    width: 40,
    height: 40,
    borderRadius: 20,
    cursor: 'pointer',
    ...buttonHoverAndActive
  },
  uPaddingLeft: {
    paddingLeft: 24,
    '@media (max-width: 899px)': {
      paddingLeft: 16
    }
  }
}));
export function ButtonArea({ children }: { children?: JSX.Element | JSX.Element[] }): JSX.Element {
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width: 899px)', { noSsr: true });

  if (mobileView && children) {
    return (
      <Grid style={{ paddingRight: 16 }}>
        <ThreeDotMenu>{children}</ThreeDotMenu>
      </Grid>
    );
  }
  return (
    <Grid
      item
      justifyContent="flex-end"
      alignItems="center"
      container
      className={classes.headerButtonContainer}
      xs={12}>
      {children || null}
    </Grid>
  );
}
interface IProps {
  children?: JSX.Element | JSX.Element[];
  noButton?: boolean;
  handleBack?: () => void;
  smallText?: string;
  title?: string;
}
export function HeaderWithButtons({ children, noButton, handleBack, smallText, title }: IProps) {
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width: 899px)', { noSsr: true });

  return noButton ? (
    <Container className={classes.header}>
      <ThemeProvider theme={theme}>
        <Grid container direction="row">
          {handleBack && (
            <Grid xs={1} item container>
              <Grid item container alignItems="center" justifyContent="center" xs={12}>
                <BackArrow className={classes.backImg} onClick={handleBack} />
              </Grid>
            </Grid>
          )}
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            xs={handleBack ? 11 : 12}>
            {smallText && smallText.trim() !== '' && (
              <Grid item>
                <Typography
                  variant="body1"
                  component="p"
                  color="textSecondary"
                  className={classes.headerSubtitle}>
                  {smallText}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Typography
                variant="h5"
                component="h1"
                color="textPrimary"
                className={classes.uPaddingLeft}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Container>
  ) : (
    <div className={classes.header}>
      <ThemeProvider theme={theme}>
        <Grid container direction="row" justifyContent="space-between">
          {handleBack && (
            <Grid xs={1} item container>
              <Grid item container alignItems="center" justifyContent="center">
                <BackArrow className={classes.backImg} onClick={handleBack} />
              </Grid>
            </Grid>
          )}
          <Grid item xs={handleBack ? 11 : 12}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={mobileView ? 11 : 5}>
                {smallText && smallText.trim() !== '' && (
                  <Grid item>
                    <Typography
                      variant="body1"
                      component="p"
                      color="textSecondary"
                      className={classes.headerSubtitle}>
                      {smallText}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Typography
                    variant="h5"
                    component="h1"
                    color="textPrimary"
                    align="left"
                    className={classes.uPaddingLeft}>
                    {title}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={mobileView ? 1 : 7} item container justifyContent="flex-end">
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}
