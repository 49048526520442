/* eslint-disable prettier/prettier */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import DateFnsUtils from '@date-io/date-fns';
import {
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import enLocale from 'date-fns/locale/en-GB';
import { ButtonPrimary, KeyboardDatePickerInput } from '../components-atoms';
import { CollectionOrderInput } from '../components-atoms/CollectionOrderInput';
import { GooglePlaceInput } from '../components-atoms/GooglePlaceInput';
import { AcceptTermsModal, ConfirmModal, ErrorModal } from '../components-molecules';
import { InfoSectionContainer } from '../components-molecules/InfoSectionContainer';
import { CONTACT_PHONE, VehicleTypeSelector } from '../components-molecules/VehicleTypeSelector';
import { CollectionOrderHeader } from '../components-organisms/CollectionOrderHeader';
import { NewCollectionOrderTotal } from '../components-organisms/NewCollectionOrderTotal';
import { EMAIL_VALIDATION, PHONE_VALIDATION_V3 } from '../constants/validation';
import {
  CreateCollectOrder,
  getCollectionContactDetails,
  getQuote,
  IGetCollectionContactDetailsModel,
  placeCollectOrder
} from '../redux/actions/collectOrders';
import { newCollectionStyles } from '../utils/styles/newCollectionStyles';
import packageJson from '../../package.json';
import { IGooglePlaceResponse } from '../utils/googlePlacesUtil';
import { useAppSelector } from '../hooks/store';
import { UserProfile } from '../redux/reducers/profile';
import { theme } from '../constants/theme';
import { useDidMountEffect } from '../utils/useDidMount';
import { clearCollectionTime, getCollectionTime } from '../redux/actions/getCollectionTime';
import { prepareAddressBody } from '../utils/addressStringConvertor';
import {
  CollectionOrderError,
  DEFAULT_VALIDATION_MSG,
  IAvailableSlots,
  ITimeSlot,
  ORKESTRO_ERROR,
  hasValidAddress
} from '../utils/collectOrderConstants';
import { getMultipleValuesAsText } from '../utils/getMultipleValuesAsText';
import { getPhoneNumber } from '../utils/phoneUtils';
import { DeliveryTypeSelector } from '../components-molecules/DeliveryTypeSelector';
import { GetCollectionOrderDetailRequest, ICollectOrderDetailResponseModel } from '../redux/actions/collectionOrderDetail';
import { prepareFormBody } from '../utils/getCollectFormBody';
// import { mockData } from '../utils/collectOrderMockData';
// import config from '../config';

export function NewCollectionOrder() {
  const isTkPortal = packageJson.name === 'tk.portal';
  const [userData] = useAppSelector((state) => [(state.profile as any).data as UserProfile]);
  const [collectionTimeData, collectionTimeLoading]: [Array<ITimeSlot>, boolean] = useAppSelector(
    (state: any) => [state.getCollectionTime.data, state.getCollectionTime.loading]
  );

  // Repeat booking feature
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const repeatBookingId = urlParams.get('repeatBookingId');
  const [detailRequestLoading, setDetailRequestLoading] = useState(false);

  const dispatch = useDispatch();
  const classes = newCollectionStyles();
  const [orderId, setOrderId] = useState<string>();
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [lastGetPrice, setLastGetPrice] = useState(new Date());
  const [getPriceLoading, setGetPriceLoading] = useState<boolean>(false);
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [quoteIds, setQuoteIds] = useState([]);

  // Error states
  const [modalOpen, setModalOpen] = useState(false);
  const [quoteError, setQuoteError] = useState('');
  const [orkestroError, setOrkestroError] = useState('');

  // T&C modal
  const [TCvisible, setTCvisible] = useState<boolean>(false);
  const [TCaccepted, setTCAccepted] = useState<boolean>(userData.TermsAccepted);

  // Is Karter popup canceled
  const [showKarterPopup, setShowKarterPopup] = useState<boolean>(false);
  const karterPopupRef = useRef(showKarterPopup);
  karterPopupRef.current = showKarterPopup;

  // Address input validations
  const [collectionAddressError, setCollectionAddressError] = useState<string>();
  const [deliveryAddressError, setDeliveryAddressError] = useState<string>();
  const [selectedCouriers, setSelectedCouriers] = useState<string[]>([]);

  const {
    control,
    formState: { isValid, errors },
    setValue,
    getValues,
    handleSubmit,
    watch,
    trigger
  } = useForm({
    mode: 'all',
    // defaultValues: config.env === 'dev' ? mockData : {}
  });

  const body = getValues();
  const hasAddressError =
    !(collectionAddressError === '' || collectionAddressError === undefined) ||
    !(deliveryAddressError === '' || deliveryAddressError === undefined) ||
    !hasValidAddress(body.CollectionAddress) ||
    !hasValidAddress(body.DeliveryAddress);

  const localDateTime = moment.utc(body.PickUpTimeSlot).local();
  const rapidCond = body.DeliveryTypeKey === 'rapid' && localDateTime.hour() > 15;
  const flexibleCond = body.DeliveryTypeKey === 'flexible' && (localDateTime.hour() > 12 || (localDateTime.hour() === 12 && localDateTime.minutes() === 30));

  const flexibleError = 'Flexible delivery is only available for orders placed before 12:00 PM';
  const rapidError = 'Rapid delivery is only available for orders placed before 3:30 PM';

  useEffect(() => {
    if (repeatBookingId) {
      setDetailRequestLoading(true);
      GetCollectionOrderDetailRequest(
        repeatBookingId,
        (res: ICollectOrderDetailResponseModel) => {
          const body = prepareFormBody(res);
          Object.entries(body).forEach(
            ([name, value]) => setValue(name, value)
          );

          setDetailRequestLoading(false);
        },
        (err: any) => {
          setDetailRequestLoading(false);
        }
      );
    }
  }, [repeatBookingId, setValue]);

  const getRemainingSlots = (selectedDate: Date) => {
    if (collectionTimeData.length > 0) {
      const result = collectionTimeData.filter(
        (slot) => new Date(slot.Start).getDate() === new Date(selectedDate).getDate()
      );
      if (result.length > 0) {
        const data: IAvailableSlots = {
          day: result[0].Start,
          availableSlots: []
        };

        result.forEach((slot) => {
          const label = `${moment.utc(slot.Start).local().format('h:mm A')}`;
          const newSlot = {
            value: slot.Start,
            label
          };
          data.availableSlots.push(newSlot);
        });
        return data;
      }
    }

    return null;
  };

  const pickupOptions = useMemo(() => {
    const currentDate = new Date();
    const data = getRemainingSlots(body.PickUpTime || currentDate);
    if (data && body.PickUpTime === undefined) {
      setValue('PickUpTime', collectionTimeData[0].Start);
    } else if (collectionTimeData.length > 0 && body.PickUpTime === undefined) {
      setValue('PickUpTime', collectionTimeData[0].Start);
    }
    if (!data) {
      return;
    }

    if (!body.PickUpTime) {
      setValue('PickUpTime', data.day);
    }
    if (data.availableSlots.length > 0) {
      setValue('PickUpTimeSlot', data.availableSlots[0].value);
    } else {
      setValue('PickUpTimeSlot', '');
    }
    // eslint-disable-next-line consistent-return
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body.PickUpTime, collectionTimeData]);

  const getCollectionContactDetailsRequest = () => {
    getCollectionContactDetails(
      (res: IGetCollectionContactDetailsModel) => {
        setValue('PickupFirstName', res.FirstName);
        trigger("PickupFirstName");

        setValue('PickupLastName', res.LastName);
        trigger("PickupLastName");

        setValue('PickupPhone', res.Phone);
        trigger("PickupPhone");
      },
      () => { }
    );
  };

  useEffect(() => {
    getCollectionContactDetailsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userData.IsCollectEnabled) {
    window.location.href = '/';
  }

  const handleFormChange = () => {
    setLastUpdate(new Date());
    setOrderId(undefined);
    setGetPriceLoading(false);
    setSelectedCouriers([]);
    setQuoteIds([]);
  };

  useEffect(() => {
    const subscription = watch(() => {
      handleFormChange();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    dispatch(getCollectionTime());
    return () => {
      dispatch(clearCollectionTime());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkFormErrors() {
    // eslint-disable-next-line func-names
    return new Promise(function (resolve) {
      const hasCollectionAddress = hasValidAddress(body.CollectionAddress);
      const hasDeliveryAddress = hasValidAddress(body.DeliveryAddress);
      const hasCollectionError = !hasCollectionAddress;
      const hasDeliveryError = !hasDeliveryAddress;

      setCollectionAddressError(hasCollectionError ? DEFAULT_VALIDATION_MSG : undefined);

      setDeliveryAddressError(hasDeliveryError ? DEFAULT_VALIDATION_MSG : undefined);

      // Reject promise
      if (hasCollectionError || hasDeliveryError) {
        resolve(true);
      }

      resolve(false);
    });
  }

  const handlePlaceOrder = async (id?: string) => {
    if (!TCaccepted) {
      setTCvisible(true);
      return;
    }

    const hasAddressError = await checkFormErrors();
    if (!isValid || hasAddressError) {
      return;
    }

    if (!orderId) {
      setModalOpen(true);
      return;
    }

    setPlaceOrderLoading(true);
    placeCollectOrder(
      {
        Id: orderId,
        SelectedQuoteIds: id ? [id] : quoteIds
      },
      () => {
        setShowKarterPopup(true);
      },
      () => {
        setOrkestroError(ORKESTRO_ERROR.PlaceOrder);
        setPlaceOrderLoading(false);
      }
    );
  };

  useDidMountEffect(() => {
    if (showKarterPopup && orderId) {
      window.location.href = `/delivery-order/${orderId}`;
    }
  }, [showKarterPopup]);

  const onCollectionAddressChange = (e: IGooglePlaceResponse) => {
    const CollectionAddress = prepareAddressBody(e);
    setValue('CollectionAddress', CollectionAddress);
    handleFormChange();
  };

  const onDeliveryAddressChange = (e: IGooglePlaceResponse) => {
    const DeliveryAddress = prepareAddressBody(e);
    setValue('DeliveryAddress', DeliveryAddress);
    handleFormChange();
  };

  const handleError = (err: any) => {
    setOrderId(undefined);
    if (err && err === CollectionOrderError.NoFleetFoundForGivenVehicleType) {
      setQuoteError(err);
    } else {
      setOrkestroError(ORKESTRO_ERROR.Price);
    }
  };

  const onGetPrice = async () => {
    const hasAddressError = await checkFormErrors();
    if (!isValid || hasAddressError || flexibleCond || rapidCond) {
      return;
    }

    // Validation passed, set loading
    setGetPriceLoading(true);

    const restriction =
      'This order includes restricted items, check ID for proof recipient is over 18 before handing over materials.';
    const puInstructions = [body.CollectionAddress.Vicinity, body.PickUpInstructions];
    const cInstructions = [body.DeliveryAddress.Vicinity, body.CustomerInstructions];
    if (body.HasRestrictedItems) {
      cInstructions.unshift(restriction);
    }

    // eslint-disable-next-line prefer-destructuring

    // Create a copy of body.
    const reqBody: any = {
      ...body,
      PickUpTime: body.PickUpTimeSlot,
      TelNo: getPhoneNumber(body.TelNo),
      PickUpInstructions: getMultipleValuesAsText(puInstructions),
      CustomerInstructions: getMultipleValuesAsText(cInstructions)
    };

    // Order already created
    CreateCollectOrder(
      reqBody,
      (id: string) => {
        getQuote(
          { orderId: id },
          () => {
            setOrderId(id);
            setGetPriceLoading(false);
            setLastGetPrice(new Date());
          },
          (err: any) => {
            handleError(err);
            handleFormChange();
          }
        );
      },
      () => {
        handleFormChange();
        setOrkestroError(ORKESTRO_ERROR.Price);
      }
    );
  };

  const onExpire = () => {
    handleFormChange();
  };

  // Terms & Conditions Part
  const onTCSubmit = () => {
    setTCvisible(false);
    setTCAccepted(true);
  };

  const onTCCancel = () => {
    setTCvisible(false);
  };

  useDidMountEffect(() => {
    if (TCaccepted) {
      handlePlaceOrder();
    }
  }, [TCaccepted]);

  const changedModal = () => {
    return (
      <ConfirmModal
        visible={modalOpen}
        title="Order preferences changed"
        buttonTitle="Get Price"
        errorBody="Order preferences have changed. Press the Get Price button again to get a new quote and proceed with the order."
        onSubmit={() => {
          onGetPrice();
          setModalOpen(false);
        }}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    );
  };

  const vehicleTypeError = () => {
    return (
      <Box className={classes.quoteErrorBody}>
        <Typography>
          Sorry, we are unable to find a courier for this vehicle type and/or location.
        </Typography>
        <Typography>
          Please contact us on{' '}
          <Link
            href={`tel:${CONTACT_PHONE.replace(/ /g, '')}`}
            style={{ color: theme.palette.primary.main }}>
            {CONTACT_PHONE}
          </Link>{' '}
          to discuss your options.
        </Typography>
      </Box>
    );
  };

  const quoteErrorModal = () => {
    return (
      <ErrorModal
        visible={Boolean(quoteError)}
        title="Unable to find a Karter"
        buttonTitle="OK"
        customBody={() => vehicleTypeError()}
        onClose={() => {
          setQuoteError('');
        }}
      />
    );
  };

  const genericErrorModal = () => {
    return (
      <ErrorModal
        visible={Boolean(orkestroError.length > 0)}
        title="An error occurred"
        errorBody={orkestroError}
        onClose={() => {
          handleFormChange();
          setOrkestroError('');
        }}
      />
    );
  };

  const headerRight = () => {
    return (
      <Box className={classes.headerContainerNoMedia} sx={{ margin: '24px 24px 0 0' }}>
        <ButtonPrimary
          disabled={
            placeOrderLoading ||
            hasAddressError ||
            Boolean(flexibleCond || rapidCond) ||
            selectedCouriers.length === 0
          }
          onClick={handleSubmit(() => handlePlaceOrder())}>
          Book Delivery
        </ButtonPrimary>
      </Box>
    );
  };

  const renderBasicInfo = () => {
    return (
      <InfoSectionContainer title="Delivery Details">
        {!isTkPortal && (
          <Grid xs={12} item>
            <CollectionOrderInput
              name="MerchantName"
              label="Collection Name"
              rules={{ required: DEFAULT_VALIDATION_MSG }}
              control={control}
              defaultValue=""
            />
          </Grid>
        )}
        <Grid xs={12} item>
          <CollectionOrderInput
            label="Enter Reference Number"
            name="ReferenceNumber"
            rules={{ required: DEFAULT_VALIDATION_MSG }}
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid xs={12} item>
          <CollectionOrderInput
            multiline
            name="OrderDetails"
            label="Order Details (Optional)"
            control={control}
            helperText="Provide additional information relating to the contents of your order."
            defaultValue=""
          />
        </Grid>
        <Grid xs={12} item>
          <Box sx={{ marginTop: '16px' }}>
            <FormControlLabel
              control={
                <Controller
                  name="HasRestrictedItems"
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      color="primary"
                      checked={props.value || false}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label="This order includes restricted items, check ID on delivery"
            />
          </Box>
        </Grid>
        <Grid xs={12} item>
          <CollectionOrderInput
            multiline
            name="PickUpInstructions"
            label="Collection Instructions (Optional)"
            control={control}
            defaultValue=""
          />
        </Grid>
      </InfoSectionContainer>
    );
  };

  const renderCollectionInfo = () => {
    return (
      <>
        <InfoSectionContainer
          title="Collection Contact Details"
          sx={{ mt: 3 }}
          helperText="Update this section if you are not the designated person to handover the materials.">
          <Grid xs={12} item container justifyContent="space-between" direction="row">
            <Grid xs={6} item style={{ paddingRight: '8px' }}>
              <CollectionOrderInput
                name="PickupFirstName"
                label="First Name"
                rules={{
                  required: DEFAULT_VALIDATION_MSG
                }}
                control={control}
                placeholder="First Name"
                defaultValue=""
                fullWidth
              />
            </Grid>
            <Grid xs={6} item style={{ paddingLeft: '8px' }}>
              <CollectionOrderInput
                name="PickupLastName"
                label="Last Name"
                rules={{
                  required: DEFAULT_VALIDATION_MSG
                }}
                control={control}
                placeholder="Last Name"
                defaultValue=""
              />
            </Grid>
            <Grid xs={12} item>
              <CollectionOrderInput
                name="PickupPhone"
                label="Collection Phone Number"
                rules={{ required: DEFAULT_VALIDATION_MSG, pattern: PHONE_VALIDATION_V3 }}
                control={control}
                placeholder="Collection Phone Number"
                defaultValue=""
              />
            </Grid>
          </Grid>
        </InfoSectionContainer>
        <InfoSectionContainer
          title="Collection address"
          sx={{ mt: 3 }}
          helperText="Start typing your address and select an option from suggested addresses.">
          <Box mt={2}>
            <GooglePlaceInput
              label="Enter collection address"
              placeholder="Collection address"
              onPlaceChange={onCollectionAddressChange}
              hasError={collectionAddressError}
              _defaultValue={body.CollectionAddress}
            />
          </Box>
        </InfoSectionContainer>
      </>
    );
  };

  const disableDate = (date: any) => {
    let disable = true;
    if (!collectionTimeLoading) {
      const result = collectionTimeData.find((item) =>
        moment(date).isSame(moment(item.Start), 'day')
      );
      if (result !== undefined) {
        disable = false;
      }
    }
    return disable;
  };

  const getMaxDate = () => {
    let maxDate = moment().format();
    if (collectionTimeData.length > 0) {
      collectionTimeData.forEach((item) => {
        const result = moment(item.Start).diff(moment(maxDate), 'days');
        if (result) {
          maxDate = moment(item.Start).format();
        }
      });
    }
    return maxDate;
  };

  const renderCollectionTime = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
        <InfoSectionContainer
          title="Collection time"
          helperText="When selecting the collection time, make sure the goods are ready for collection"
          sx={{ mt: 3 }}>
          <Controller
            name="PickUpTime"
            control={control}
            rules={{ required: DEFAULT_VALIDATION_MSG }}
            render={({ field: { ref, ...rest } }) => (
              <KeyboardDatePickerInput
                disablePast
                label="Collection Date"
                id="delivery-date"
                format="dd/MM/yyyy"
                maxDate={getMaxDate()}
                shouldDisableDate={disableDate}
                initialFocusedDate={Date()}
                invalidDateMessage="End date is required"
                InputProps={{
                  readOnly: true
                }}
                {...rest}
              />
            )}
          />

          {(pickupOptions && pickupOptions?.availableSlots?.length > 0) &&
            <FormControl data-testid="delivery-timeslot" variant="outlined" style={{ marginTop: 8 }}>
              <InputLabel id="delivery-timeslot-label">Collection from</InputLabel>
              <Controller
                name="PickUpTimeSlot"
                control={control}
                rules={{ required: DEFAULT_VALIDATION_MSG }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    labelId="delivery-timeslot"
                    label="Collection from"
                    value={value || ''}
                    onChange={(event) => onChange(event)}
                    disabled={Boolean(errors.PickUpTime)}>
                    {pickupOptions?.availableSlots.map((slot: any) => (
                      <MenuItem key={slot.value} value={slot.value}>
                        {slot.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          }
        </InfoSectionContainer>
      </MuiPickersUtilsProvider>
    );
  };

  const renderVehicleType = () => {
    return (
      <InfoSectionContainer
        title="Vehicle type"
        helperText="Select the vehicle type appropriate for this delivery."
        sx={{ mt: 3 }}>
        <Controller
          name="VehicleTypeId"
          control={control}
          rules={{ required: 'Please select a vehicle type.' }}
          render={({ field: { ref, ...rest } }) => (
            <>
              <VehicleTypeSelector
                style={{ marginTop: 12, marginBottom: errors.VehicleTypeId ? 12 : 0 }}
                hasError={Boolean(errors.VehicleTypeId)}
                {...rest}
              />
              {errors.VehicleTypeId && (
                <Typography
                  variant="caption"
                  color="error"
                  style={{
                    fontWeight: 400,
                    letterSpacing: 0.4
                  }}>
                  {errors.VehicleTypeId.message as any}
                </Typography>
              )}
            </>
          )}
        />
      </InfoSectionContainer>
    );
  };

  const renderDeliveryType = () => {
    return (
      <InfoSectionContainer
        title="Delivery type"
        helperText="Select the delivery option based on when you require your materials to be delivered."
        sx={{ mt: 3 }}>
        <Controller
          name="DeliveryTypeKey"
          control={control}
          rules={{ required: 'Please select a delivery type.' }}
          render={({ field: { ref, ...rest } }) => (
            <>
              <DeliveryTypeSelector
                style={{ marginTop: 12, marginBottom: errors.DeliveryTypeKey ? 12 : 0 }}
                hasError={Boolean(errors.DeliveryTypeKey)}
                {...rest}
              />
              {errors.DeliveryTypeKey && (
                <Typography
                  variant="caption"
                  color="error"
                  style={{
                    fontWeight: 400,
                    letterSpacing: 0.4
                  }}>
                  {errors.DeliveryTypeKey.message as any}
                </Typography>
              )}
            </>
          )}
        />
      </InfoSectionContainer>
    );
  };

  const renderCustomerInformation = () => {
    return (
      <InfoSectionContainer title="Delivery Contact Details" sx={{ mt: 3 }}>
        <Grid xs={12} item container justifyContent="space-between" direction="row">
          <Grid xs={6} item style={{ paddingRight: '8px' }}>
            <CollectionOrderInput
              name="FirstName"
              label="First Name"
              rules={{
                required: DEFAULT_VALIDATION_MSG
              }}
              control={control}
              fullWidth
            />
          </Grid>
          <Grid xs={6} item style={{ paddingLeft: '8px' }}>
            <CollectionOrderInput
              name="LastName"
              label="Last Name"
              rules={{
                required: DEFAULT_VALIDATION_MSG
              }}
              control={control}
            />
          </Grid>
          <Grid xs={12} item>
            <CollectionOrderInput
              name="TelNo"
              label="Phone Number"
              rules={{ required: DEFAULT_VALIDATION_MSG, pattern: PHONE_VALIDATION_V3 }}
              control={control}
            />
          </Grid>
          <Grid xs={12} item>
            <CollectionOrderInput
              name="Email"
              label="Email"
              control={control}
              rules={{ required: DEFAULT_VALIDATION_MSG, pattern: EMAIL_VALIDATION }}
            />
          </Grid>
          <Grid xs={12} item>
            <CollectionOrderInput
              multiline
              name="CustomerInstructions"
              label="Delivery Instructions (Optional)"
              control={control}
            />
          </Grid>
        </Grid>
      </InfoSectionContainer>
    );
  };

  const renderDeliveryAddress = () => {
    return (
      <InfoSectionContainer
        title="Delivery address"
        sx={{ mt: 3 }}
        helperText="Start typing your address and select an option from suggested addresses.">
        <Box mt={2}>
          <GooglePlaceInput
            label="Enter delivery address"
            placeholder="Delivery address"
            onPlaceChange={onDeliveryAddressChange}
            hasError={deliveryAddressError}
            _defaultValue={body.DeliveryAddress}
          />
        </Box>
      </InfoSectionContainer>
    );
  };

  const renderForm = () => {
    return (
      <form style={{ width: '100%', marginTop: 24, marginBottom: 72 }}>
        {renderBasicInfo()}
        {renderCollectionInfo()}
        {renderCollectionTime()}
        {renderVehicleType()}
        {renderDeliveryType()}
        {renderCustomerInformation()}
        {renderDeliveryAddress()}

        {(flexibleCond || rapidCond) && (
          <Box className={classes.headerContainerNoMedia} sx={{ margin: '24px 24px 0 0' }}>
            {flexibleCond && <Typography color="error">{flexibleError}</Typography>}
            {rapidCond && <Typography color="error">{rapidError}</Typography>}
          </Box>
        )}

        <NewCollectionOrderTotal
          lastUpdate={lastUpdate}
          lastGetPrice={lastGetPrice}
          getPriceLoading={getPriceLoading}
          onGetPrice={handleSubmit(() => onGetPrice())}
          onExpire={onExpire}
          orderId={orderId}
          handleError={handleError}
          hasError={hasAddressError || flexibleCond || rapidCond}
          selectedCouriers={selectedCouriers}
          setSelectedCouriers={setSelectedCouriers}
          onCourierChange={(e: any) => {
            setQuoteIds(e.ids);
            if (e.isPremium) {
              // @ts-ignore
              handleSubmit(onGetPrice());
            }
          }}
          onSubmit={(id: string) => {
            setQuoteIds([id] as never);
            handlePlaceOrder(id);
          }}
        />
        {headerRight()}
      </form>
    )
  }

  return (
    <Container className={classes.root}>
      <CollectionOrderHeader title="Add New Delivery" />

      {detailRequestLoading ? <CircularProgress /> : renderForm()}

      {changedModal()}
      {quoteErrorModal()}
      {genericErrorModal()}
      <AcceptTermsModal visible={TCvisible} onSubmit={onTCSubmit} onClose={onTCCancel} />
    </Container>
  );
}
