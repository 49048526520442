const SEARCH_ADDRESS_DETAILS = 'SEARCH_ADDRESS_DETAILS';
const SEARCH_ADDRESS_DETAILS_SUCCESS = 'SEARCH_ADDRESS_DETAILS_SUCCESS';
const SEARCH_ADDRESS_DETAILS_FAILURE = 'SEARCH_ADDRESS_DETAILS_FAILURE';
const CLEAR_SEARCH_ADDRESS_DETAILS = 'CLEAR_SEARCH_ADDRESS_DETAILS';

const SearchAddressDetailsSymbols = {
  SEARCH_ADDRESS_DETAILS_SUCCESS,
  SEARCH_ADDRESS_DETAILS_FAILURE,
  SEARCH_ADDRESS_DETAILS,
  CLEAR_SEARCH_ADDRESS_DETAILS
};

export default SearchAddressDetailsSymbols;
