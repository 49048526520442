const numberPrefix = '+44';

export const getPhoneNumber = (text: string | undefined) => {
  const phone = text ? text.replace(/ /g, '') : '';

  if (phone.startsWith(numberPrefix)) {
    return phone;
  }

  if (phone.startsWith('44')) {
    return phone.replace('44', numberPrefix);
  }

  // User types 0, skip it
  if (phone.startsWith('0')) {
    return phone.replace('0', numberPrefix);
  }

  return numberPrefix + phone;
};
