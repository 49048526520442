const GET_MEMBER = 'GET_MEMBER';
const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
const GET_MEMBER_FAILURE = 'GET_MEMBER_FAILURE';
const CLEAR_GET_MEMBER = 'CLEAR_GET_MEMBER';

const GetMemberSymbols = {
  GET_MEMBER_SUCCESS,
  GET_MEMBER_FAILURE,
  GET_MEMBER,
  CLEAR_GET_MEMBER
};

export default GetMemberSymbols;
