import {
  Box,
  CssBaseline,
  Container,
  IconButton,
  makeStyles,
  Typography,
  Grid,
  useMediaQuery,
  CircularProgress
} from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FilterButton, Searchbar, SortButton } from '../components-molecules';
import { IFilter, IFilterOption } from '../components-molecules/FilterButton';
import { ButtonArea, HeaderWithButtons } from '../components-molecules/HeaderWithButtons';
import { InvoiceList } from '../components-organisms';
import { EMPTY_GUID } from '../constants/global';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { GetInvoices } from '../redux/actions/getInvoices';
import { InvoiceResponseModel } from '../redux/reducers/getInvoices';
import { GetTeamMembersForFilter } from '../redux/actions/getTeamMembers';
import { TeamMember } from '../redux/reducers/getTeamMembers';
import { headerButtonsResponsive, textButtonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '@media (min-width: 900px)': {
      maxWidth: '840px'
    }
  },
  buttonSet: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '24px',
    gap: '8px',
    '@media (max-width: 899px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '8px 4px',
      padding: '8px 12px'
    }
  },
  searchIcon: {
    width: 24,
    height: 24,
    '@media(max-width: 899px)': {
      width: 20,
      height: 20,
      marginLeft: -4,
      marginRight: 8
    }
  },
  searchIconButton: {
    width: 40,
    height: 40,
    ...textButtonHoverAndActive,
    ...headerButtonsResponsive
  },
  uPaddingLeft: {
    paddingLeft: 24,
    '@media (max-width: 899px)': {
      paddingLeft: 16
    }
  }
}));

type SortType = 'OrderNumber' | 'AddressName' | 'JobNumber';
interface SortOption {
  value: SortType;
  title: string;
}

const sortTypes: SortOption[] = [
  { title: 'By order number', value: 'OrderNumber' },
  { title: 'By address name', value: 'AddressName' },
  { title: 'By job details', value: 'JobNumber' }
];

interface Filter extends IFilter {
  'Team Members': string[];
  'Date Range': Array<Date | null>;
}

export function Invoices() {
  const classes = useStyles();
  const timeoutDuration = 300;
  const mobileView = useMediaQuery('(max-width: 899px)', { noSsr: true });
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [sortType, setSortType] = useState<SortType>('OrderNumber');
  const [searchOpen, setSearchOpen] = useState(false);
  const [filter, setFilter] = useState<Filter>({ 'Team Members': [], 'Date Range': [] });
  const teamMembers: TeamMember[] = useAppSelector((state) => state.getTeamMembers.data);
  const [filterOptions, setFilterOptions] = useState<IFilterOption>({
    'Date Range': [
      { title: 'From', value: null },
      { title: 'To', value: null }
    ],
    'Team Members': []
  });
  const [invoices, loading]: [InvoiceResponseModel, boolean] = useAppSelector((state) => [
    state.getInvoices.data,
    state.getInvoices.loading
  ]);
  const dispatch = useAppDispatch();

  const getInvoices = useCallback(
    (currPage = 0, size = pageSize, prevData: any[] = []) => {
      dispatch(
        GetInvoices(
          {
            Page: currPage,
            Size: size,
            Query: query || undefined,
            Order: sortType,
            TeamMemberIds:
              filter['Team Members'].length > 0 ? (filter['Team Members'] as string[]) : undefined,
            StartDate: filter['Date Range'][0] ? new Date(filter['Date Range'][0]) : undefined,
            EndDate: filter['Date Range'][1] ? new Date(filter['Date Range'][1]) : undefined
          },
          (resp: any) => {
            let temp = [...prevData];
            resp.Result.forEach((m: any) => {
              const tempMatch = temp.find((f) => f.GroupName === m.GroupName);
              if (tempMatch) {
                temp[temp.indexOf(tempMatch)].Result = [
                  ...temp[temp.indexOf(tempMatch)].Result,
                  ...m.Result
                ];
              } else {
                temp = [...temp, m];
              }
            });
            return { ...resp, Result: temp };
          }
        )
      );
    },
    [query, dispatch, sortType, filter]
  );

  useEffect(() => {
    dispatch(GetTeamMembersForFilter());
  }, [dispatch]);

  useEffect(() => {
    const memberOptions = teamMembers
      .filter((f) => f.CustomerId !== EMPTY_GUID)
      .map((m) => ({ title: `${m.FirstName} ${m.LastName}`, value: m.CustomerId }));
    setFilterOptions((prev) => ({
      ...prev,
      'Team Members': [...memberOptions]
    }));
  }, [teamMembers]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  const loadMore = () => {
    getInvoices(page + 1, pageSize, invoices.Result);
    setPage((prev) => prev + 1);
  };

  const onFilterSubmit = (submittedFilter: IFilter) => {
    setFilter(submittedFilter as Filter);
  };

  return (
    <Box>
      <CssBaseline />
      <Container className={classes.root}>
        <CssBaseline />
        <HeaderWithButtons title="Invoices">
          {(invoices?.Result && invoices?.Result.length > 0) ||
          filter['Date Range'].length > 0 ||
          filter['Team Members'].length > 0 ? (
            <ButtonArea>
              <div className={classes.buttonSet}>
                <SortButton
                  data-automation="sort-button"
                  menuItems={sortTypes.map(({ title, value }: SortOption) => ({
                    title,
                    value,
                    handleSelect: () => {
                      setSortType(value);
                    }
                  }))}
                  selectedSortType={sortType}
                />
                <FilterButton
                  defaultOption="Team Members"
                  data-automation="filter-button"
                  groupedKeys={['Team Members']}
                  dateKey="Date Range"
                  onSubmit={onFilterSubmit}
                  singleDate
                  options={filterOptions}
                  filter={filter}
                />
                <IconButton
                  data-automation="search-button"
                  className={classes.searchIconButton}
                  color="primary"
                  onClick={() => setSearchOpen(!searchOpen)}>
                  <SearchRoundedIcon color="primary" className={classes.searchIcon} />
                  {mobileView && (
                    <Typography variant="button" component="span" color="primary">
                      Search
                    </Typography>
                  )}
                </IconButton>
              </div>
            </ButtonArea>
          ) : undefined}
        </HeaderWithButtons>
        <Searchbar
          query={query}
          isOpen={searchOpen}
          placeholder="Search Invoices"
          onCancel={() => {
            setSearchOpen(false);
            setQuery('');
          }}
          onChange={(event) =>
            debounce(
              (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                setQuery(e.target.value.trim()),
              timeoutDuration
            )(event)
          }
        />
        {loading && page === 0 ? (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '96px' }}>
            <CircularProgress />
          </Grid>
        ) : invoices?.Result && invoices?.Result.length > 0 ? (
          <InvoiceList loadMore={loadMore} />
        ) : query ? (
          <Grid container item className={classes.uPaddingLeft} style={{ marginTop: '40px' }}>
            <Grid container item style={{ maxWidth: '320px' }}>
              <Typography variant="h6" component="span">
                0 search results found
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{ marginTop: '12px' }}>
                Adjust your search term and try again
              </Typography>
            </Grid>
          </Grid>
        ) : Object.keys(filter).some((key) => {
            return filter[key]?.length !== 0;
          }) ? (
          <Grid container item className={classes.uPaddingLeft} style={{ marginTop: '40px' }}>
            <Grid container item style={{ maxWidth: '320px' }}>
              <Typography variant="h6" component="span">
                0 results found
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{ marginTop: '12px' }}>
                Adjust your filtering and try again
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container item justifyContent="center" style={{ marginTop: '96px' }}>
            <Typography variant="h6" component="span">
              You don’t have any invoices yet
            </Typography>
          </Grid>
        )}
      </Container>
    </Box>
  );
}
