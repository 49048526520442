/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Container,
  Grid,
  ThemeProvider,
  Typography,
  makeStyles,
  Button,
  CircularProgress,
  Box
} from '@material-ui/core';
import { useStripe } from '@stripe/react-stripe-js';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { useNavigate } from 'react-router';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import { useEffect } from 'react';
import { ConfirmCardPaymentData } from '@stripe/stripe-js';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { ITheme, theme } from '../constants/theme';
import { ButtonPrimary, ButtonTextError, Stamp } from '../components-atoms';
import BackArrowIcon from '../components-atoms/icons/backArrow';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { clearRejectOrder, RejectOrder } from '../redux/actions/rejectOrder';
import { ApproveOrder, clearApproveOrder } from '../redux/actions/approveOrder';
import { OrderDetail } from '../redux/reducers/orderDetail';
import { GetOrderDetail } from '../redux/actions/orderDetail';
import CreditCard from '../components-atoms/icons/creditCard';
import { GetPaymentId } from '../redux/actions/getPaymentId';
import { OrderStamp } from '../components-molecules';
import { clearFailedPayment, SetFailedPaymentCount } from '../redux/actions/failedPayment';
import { buttonHoverAndActive } from '../utils/styles/buttonStyles';
// import { OrderDetail } from '../redux/reducers/orderDetail';

const useStyles = makeStyles((myTheme: ITheme) => ({
  header: {
    paddingLeft: 0,
    paddingRight: 0
  },
  headerContainer: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    '@media (max-width: 899px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  headerOrderDetailSection: {
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  headerButtonContainer: {
    paddingRight: 24,
    '@media (max-width: 899px)': {
      marginTop: 4,
      marginLeft: 72,
      paddingRight: 0
    }
  },
  backImg: {
    width: 40,
    height: 40,
    marginRight: 16,
    marginLeft: 24,
    borderRadius: 20,
    cursor: 'pointer',
    '@media (max-width: 899px)': {
      marginLeft: 16
    },
    ...buttonHoverAndActive
  },
  approveButton: {
    marginLeft: 8,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: `${myTheme.palette.success[92]} !important`
    },
    '&:active': {
      backgroundColor: `${myTheme.palette.success[76]} !important`
    }
  },
  authorisationContainer: {
    width: '100%',
    backgroundColor: myTheme.palette.secondary.main,
    padding: '24px 16px',
    marginTop: '24px',
    '@media (min-width: 900px)': {
      padding: '24px',
      border: `1px solid ${myTheme.palette.primary[12]}`,
      borderRadius: '20px'
    }
  },
  authorisationTitleButtonContainer: {
    marginBottom: 8,
    '@media (max-width: 899px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 24
    }
  },
  authorisationTitle: {
    '@media (max-width: 899px)': {
      marginBottom: 16
    }
  },
  stamp: {
    '@media (max-width: 599px)': {
      alignSelf: 'flex-start',
      marginTop: 8,
      marginBottom: 8,
      marginLeft: 0
    }
  }
}));
interface IProps {
  data: OrderDetail;
}

export function OrderHeader({ data }: IProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const stripe = useStripe();
  const dispatch = useAppDispatch();
  const paymentFailed = data.OrderStatus === 'Payment Failed';
  const requiresAuthorisation = data.OrderStatus === 'Authorization Required';
  const pendingApproval = data.OrderStatus === 'Pending Approval';
  const [approveLoading, rejectLoading, approveData, rejectData, failCount]: [
    boolean,
    boolean,
    string,
    string,
    number
  ] = useAppSelector((state) => [
    state.approveOrder.loading,
    state.rejectOrder.loading,
    state.approveOrder.data,
    state.rejectOrder.data,
    state.failedPayment.count
  ]);
  useEffect(() => {
    if (approveData === 'Manager approved.' || rejectData === 'Manager rejected.') {
      dispatch(GetOrderDetail(data.OrderId));
      dispatch(clearApproveOrder());
      dispatch(clearRejectOrder());
    }
  }, [approveData, rejectData, dispatch, data]);
  const handleStripePaymentConfirmation = async () => {
    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    if (paymentFailed) {
      dispatch(
        GetPaymentId((resp) => {
          const confirmData: ConfirmCardPaymentData = {
            payment_method: resp.Result.StripePaymentIdentifier
          };
          stripe
            .confirmCardPayment(data.OrderPaymentIntentClientSecret, confirmData)
            .then((response) => {
              if ('error' in response) {
                dispatch(SetFailedPaymentCount(failCount + 1));
              } else if ('paymentIntent' in response && failCount > 0) {
                dispatch(clearFailedPayment());
              }
              dispatch(GetOrderDetail(data.OrderId));
            });
        })
      );
    } else {
      stripe
        .confirmCardPayment(data.OrderPaymentIntentClientSecret)
        .then(() => dispatch(GetOrderDetail(data.OrderId)));
    }
  };
  const rejectOrder = (id: string) => {
    dispatch(RejectOrder(id));
  };
  const approveOrder = (id: string) => {
    dispatch(ApproveOrder(id));
  };
  return (
    <Container className={classes.header}>
      <ThemeProvider theme={theme}>
        <Grid container alignItems="center" className={classes.headerContainer}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={classes.backImg}>
              <BackArrowIcon onClick={() => navigate('/orders', { state: { from: 'order' } })} />
            </div>
            <Grid item container alignItems="center" className={classes.headerOrderDetailSection}>
              <Grid item>
                <Typography variant="h5" component="h1" color="textPrimary">
                  {data.OrderNumber}
                </Typography>
              </Grid>
              {pendingApproval ? (
                <Stamp type="warningActive" text="Pending Approval" className={classes.stamp} />
              ) : null}
              {requiresAuthorisation ? (
                <Stamp type="error" text="Requires Authorisation" className={classes.stamp} />
              ) : null}
              {paymentFailed ? (
                <Stamp type="warningActive" text="Payment Failed" className={classes.stamp} />
              ) : null}
              {!pendingApproval && !requiresAuthorisation && !paymentFailed && data.OrderStatus && (
                <OrderStamp text={data.OrderStatus} className={classes.stamp} />
              )}
            </Grid>
          </div>
          {pendingApproval ? (
            <Grid className={classes.headerButtonContainer}>
              <ButtonTextError
                data-automation="reject-button"
                disabled={approveLoading || rejectLoading}
                onClick={() => rejectOrder(data.OrderId)}
                startIcon={
                  rejectLoading ? (
                    <CircularProgress style={{ height: '16px', width: '16px' }} color="inherit" />
                  ) : (
                    <BlockRoundedIcon
                      color={approveLoading || rejectLoading ? 'disabled' : 'error'}
                      fontSize="inherit"
                    />
                  )
                }>
                Reject
              </ButtonTextError>
              <Button
                data-automation="approve-button"
                variant="contained"
                disabled={approveLoading || rejectLoading}
                style={{
                  backgroundColor:
                    approveLoading || rejectLoading
                      ? theme.palette.action.disabledBackground
                      : theme.palette.success.main,
                  color: approveLoading || rejectLoading ? theme.palette.action.disabled : '#fff'
                }}
                disableElevation
                className={classes.approveButton}
                onClick={() => approveOrder(data.OrderId)}
                startIcon={
                  approveLoading ? (
                    <CircularProgress style={{ height: '16px', width: '16px' }} color="inherit" />
                  ) : (
                    <CheckRoundedIcon
                      color={approveLoading || rejectLoading ? 'disabled' : 'inherit'}
                      fontSize="inherit"
                    />
                  )
                }>
                Approve
              </Button>
            </Grid>
          ) : null}
        </Grid>
        {requiresAuthorisation ? (
          <Box className={classes.authorisationContainer}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              className={classes.authorisationTitleButtonContainer}>
              <Typography variant="h6" align="left" className={classes.authorisationTitle}>
                Your payment requires authorisation
              </Typography>

              <Grid item>
                <ButtonTextError
                  data-automation="cancel-order-button"
                  disabled={approveLoading || rejectLoading}
                  onClick={() => rejectOrder(data.OrderId)}
                  style={{ marginRight: '16px' }}
                  startIcon={
                    rejectLoading ? (
                      <CircularProgress style={{ height: '16px', width: '16px' }} color="inherit" />
                    ) : (
                      <CloseRoundedIcon
                        color={approveLoading || rejectLoading ? 'disabled' : 'error'}
                        fontSize="inherit"
                      />
                    )
                  }>
                  Cancel Order
                </ButtonTextError>
                <ButtonPrimary
                  data-automation="authenticate-button"
                  onClick={() => handleStripePaymentConfirmation()}
                  title="Authorize Now"
                  startIcon={<CreditCard />}>
                  Authorize Now
                </ButtonPrimary>
              </Grid>
            </Grid>
            <Typography variant="body2" color="textSecondary" align="left">
              This payment requires your authorisation. Authorise now to process the payment.
            </Typography>
          </Box>
        ) : null}
        {paymentFailed ? (
          <Box
            style={{
              width: '100%',
              backgroundColor: (theme as ITheme).palette.secondary.main,
              padding: '24px',
              border: `1px solid ${(theme as ITheme).palette.primary[12]}`,
              borderRadius: '20px',
              marginTop: '24px'
            }}>
            <Grid container item direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Payment failed</Typography>
              <Grid item>
                <ButtonTextError
                  data-automation="cancel-order-button"
                  disabled={approveLoading || rejectLoading}
                  onClick={() => rejectOrder(data.OrderId)}
                  style={{ marginRight: '16px' }}
                  startIcon={
                    rejectLoading ? (
                      <CircularProgress style={{ height: '16px', width: '16px' }} color="inherit" />
                    ) : (
                      <CloseRoundedIcon
                        color={approveLoading || rejectLoading ? 'disabled' : 'error'}
                        fontSize="inherit"
                      />
                    )
                  }>
                  Cancel Order
                </ButtonTextError>
                <ButtonPrimary
                  data-automation="authenticate-button"
                  onClick={() => handleStripePaymentConfirmation()}
                  title="Pay Now"
                  startIcon={<CreditCard />}>
                  Pay Now
                </ButtonPrimary>
              </Grid>
            </Grid>
            {failCount > 0 ? (
              <Typography
                style={{
                  backgroundColor: (theme as ITheme).palette.error[16],
                  padding: '8px',
                  borderRadius: '2px',
                  marginTop: '8px'
                }}
                variant="body2"
                color="error"
                align="left">
                Your payment failed again. Go to{' '}
                <a
                  style={{ fontWeight: 700, color: theme.palette.error.main }}
                  href="/organisation">
                  Organisation Profile section
                </a>{' '}
                and update your payment method or try again.
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary" align="left">
                Payment for this order has failed. Please try again.
              </Typography>
            )}
          </Box>
        ) : null}
      </ThemeProvider>
    </Container>
  );
}
