/* eslint-disable react/destructuring-assignment */
import RESEND_INVITATION from '../symbols/resendInvitation';

const initialState = {
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function ResendInvitationReducer(state = initialState, action: any) {
  switch (action.type) {
    case RESEND_INVITATION.RESEND_INVITATION:
      return { ...state, loading: true, error: false };
    case RESEND_INVITATION.RESEND_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };
    case RESEND_INVITATION.RESEND_INVITATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case RESEND_INVITATION.CLEAR_RESEND_INVITATION:
      return {
        ...state,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default ResendInvitationReducer;
