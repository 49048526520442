import { Typography, makeStyles } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import arrowRight from '../assets/icons/arrowRight.svg';
import { textButtonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles(() => ({
  buttonText: {
    textTransform: 'none'
  },
  textTruncation: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'end',
    paddingTop: 2,
    paddingLeft: 12,
    flex: 1
  },
  arrowIcon: {},
  filterButton: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: '40px',
    marginTop: '4px',
    marginBottom: '4px',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderRadius: '8px',
    ...textButtonHoverAndActive
  }
}));

interface IProps {
  title: string;
  onClick: () => void;
  selectedFilters?: string[];
  isDate?: boolean;
}

export function FilterSelectionButton({ title, onClick, selectedFilters, isDate }: IProps) {
  const classes = useStyles();
  const getSelectedFilterString = (): string => {
    let filterString = '';
    if (selectedFilters) {
      if (isDate && selectedFilters.length === 1) {
        filterString = `After ${selectedFilters[0]}`;
      } else if (isDate && selectedFilters.length === 2 && !selectedFilters[0]) {
        filterString = `Before ${selectedFilters[1]}`;
      } else {
        filterString = selectedFilters.join(!isDate ? ', ' : ' - ');
      }
    }
    return filterString;
  };
  return (
    <Button
      id="basic-button"
      aria-controls="basic-menu"
      aria-haspopup="true"
      onClick={onClick}
      className={classes.filterButton}>
      <Typography
        variant="body2"
        component="span"
        color="textPrimary"
        style={{ fontWeight: 600 }}
        className={classes.buttonText}>
        {title}
      </Typography>
      <Typography variant="caption" color="primary" className={classes.textTruncation}>
        {getSelectedFilterString()}
      </Typography>
      <img src={arrowRight} alt="arrow" className={classes.arrowIcon} />
    </Button>
  );
}
