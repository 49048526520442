import GET_PAYMENT_ID from '../symbols/getPaymentId';
import { HttpRequestGet } from '../../service';

const getPaymentIdAction = () => ({
  type: GET_PAYMENT_ID.GET_PAYMENT_ID
});
const getPaymentIdSuccessAction = (data: any) => {
  return {
    type: GET_PAYMENT_ID.GET_PAYMENT_ID_SUCCESS,
    payload: data
  };
};
const getPaymentIdFailureAction = (data: any) => ({
  type: GET_PAYMENT_ID.GET_PAYMENT_ID_FAILURE,
  payload: data
});
const clearGetPaymentIdAction = () => ({
  type: GET_PAYMENT_ID.CLEAR_GET_PAYMENT_ID
});
// eslint-disable-next-line no-unused-vars
export function GetPaymentId(callback?: (resp: any) => void) {
  return (dispatch: any) => {
    dispatch(getPaymentIdAction());
    HttpRequestGet(
      'pro/payment/payment-identifier',
      dispatch,
      getPaymentIdSuccessAction,
      getPaymentIdFailureAction,
      true,
      callback
    );
  };
}

export function clearGetPaymentId() {
  return (dispatch: any) => {
    dispatch(clearGetPaymentIdAction());
  };
}
