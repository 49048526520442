import { Container, Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  customerInformationContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '18px 24px 0',
    marginTop: '24px',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxSizing: 'border-box',
    borderRadius: '20px',
    textAlign: 'left'
  },
  infoTitle: {
    marginBottom: 20
  },
  infoTextContainer: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
  },
  infoTextContainerLastRow: {
    paddingTop: 15,
    paddingBottom: 15
  },
  colOffset: {
    marginLeft: -17.5,
    marginRight: 17.5
  }
}));
interface IProps {
  data: any;
}

export function OrderPackageInfo({ data }: IProps) {
  const classes = useStyles();
  return (
    <Container className={classes.customerInformationContainer}>
      <Grid container>
        <Grid container className={classes.infoTitle}>
          <Typography variant="h6" component="span">
            Package information
          </Typography>
        </Grid>
        <Grid container className={classes.infoTextContainer}>
          <Grid item xs={3}>
            <Typography display="inline" variant="caption" component="span" color="textSecondary">
              NUMBER OF PACKAGES
            </Typography>
          </Grid>
          <Grid item xs={7} className={classes.colOffset}>
            <Typography variant="body1" component="span" color="textPrimary">
              {data.NumberOfPackages}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.infoTextContainerLastRow}>
          <Grid item xs={3}>
            <Typography display="inline" variant="caption" component="span" color="textSecondary">
              PACKAGE NR.1
            </Typography>
          </Grid>
          <Grid item xs={7} className={classes.colOffset}>
            <Typography variant="body1" component="span" color="textPrimary">
              {data.PackageNr1}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
