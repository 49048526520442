/* eslint-disable react/destructuring-assignment */
import GET_TEAM_DETAIL from '../symbols/getTeamDetail';

export interface Organisation {
  BillingAddressName: string;
  City: string;
  County: string;
  Line1: string;
  Line2: string;
  Line3: string;
  PhoneNumber: string;
  PostalCode: string;
  ProTeamId: string;
  TeamName: string;
  VATNumber: string;
}
interface State {
  data: Organisation | null;
  loading: boolean;
  error: any;
}
const initialState: State = {
  data: null,
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function GetTeamDetailReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case GET_TEAM_DETAIL.GET_TEAM_DETAIL:
      return { ...state, loading: true, error: false };
    case GET_TEAM_DETAIL.GET_TEAM_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.payload.Result,
        loading: false,
        error: false
      };
    case GET_TEAM_DETAIL.GET_TEAM_DETAIL_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case GET_TEAM_DETAIL.CLEAR_GET_TEAM_DETAIL:
      return {
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default GetTeamDetailReducer;
