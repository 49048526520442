import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import { useLocation, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { CollectionOrderBasicInfo } from '../components-organisms/CollectionOrderBasicInfo';
import { InfoSection } from '../components-molecules/InfoSection';
import { CollectionOrderHeader } from '../components-organisms/CollectionOrderHeader';
import CollectionOrderTotal from '../components-organisms/CollectionOrderTotal';
import {
  GetCollectionOrderDetail,
  ICollectOrderDetailResponseModel
} from '../redux/actions/collectionOrderDetail';
import { cancelOrderCreation } from '../redux/actions/collectOrders';
import { ConfirmModal, ErrorModal } from '../components-molecules';
import { ButtonWithLoading } from '../components-atoms';
import { CONTACT_PHONE } from '../components-molecules/VehicleTypeSelector';
import { colours, theme } from '../constants/theme';
import { Caption } from '../components-atoms/Caption';
import { useAppWebSocket } from '../hooks/useAppWebSocket';
import CollectionOrderDeliveryInfo from '../components-organisms/CollectionOrderDeliveryInfo';
import { getMultipleValuesAsText } from '../utils/getMultipleValuesAsText';
import { CollectPricing } from '../redux/reducers/orderDetail';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'visible',
    paddingLeft: 0,
    paddingRight: 0,
    '@media (min-width: 900px)': {
      maxWidth: '840px'
    }
  },
  imageText: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4
  },
  infoText: {
    fontWeight: 'normal',
    color: colours.highEmphasis54
  }
}));

type Steps =
  | 'Ordered'
  | 'Queued'
  | 'Dispatched'
  | 'Out for Delivery'
  | 'Delivered'
  | 'Failed'
  | 'Cancelled';

interface ProofOfDelivery {
  ImageName: string;
  ImageUrl: string;
}

export interface DetailsSocketMessage {
  DriverInformation?: {
    Name: string;
    LastUpdate?: string;
  };
  FleetInformation?: {
    Name: string;
    LogoUrl?: string;
  };
  ETA?: string;
  Status: {
    Step: Steps;
    DeliveredDate?: string;
    FailedDate?: string;
    CancelledDate?: string;
  };
  ProofOfDelivery?: ProofOfDelivery;
  WinningQuote?: CollectPricing;
  MapWidgetUrl: string | null;
}

export function CollectionOrder() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id } = useParams();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const classes = useStyles();
  const [cancelOrderModalVisible, setCancelOrderModalVisible] = useState(false);
  const [cancelOrderErrorModalVisible, setCancelOrderErrorModalVisible] = useState(false);
  const [cancelOrderErrorModalText, setCancelOrderErrorModalText] = useState('');
  const [cancelOrderLoading, setCancelOrderLoading] = useState(false);
  const [data, loading]: [ICollectOrderDetailResponseModel, boolean] = useAppSelector((state) => [
    (state.orderDetail as any).data,
    (state.orderDetail as any).loading
  ]);

  const { lastJsonMessage } = useAppWebSocket<DetailsSocketMessage | null>(
    '/ws/collect-details',
    { queryParams: { pcid: id as string } },
    Boolean(id)
  );

  const showCancelBtn =
    (lastJsonMessage?.Status.Step === 'Ordered' || lastJsonMessage?.Status.Step === 'Queued') &&
    !lastJsonMessage?.DriverInformation?.Name;

  useEffect(() => {
    if (id) {
      dispatch(GetCollectionOrderDetail(id));
    }
  }, [id, dispatch]);

  const proofOfDeliveryData = useCallback(
    (proofOfDelivery: ProofOfDelivery | undefined) => {
      if (proofOfDelivery) {
        return [
          {
            title: 'Photo',
            imageUrl: proofOfDelivery?.ImageUrl,
            customComponent: (
              <Box className={classes.imageText}>
                <Typography variant="body1">{proofOfDelivery?.ImageName}</Typography>
                <Caption display="block" className={classes.infoText}>
                  Click to see a larger version.
                </Caption>
              </Box>
            )
          }
        ];
      }
      return [
        {
          title: 'Photo',
          customComponent: (
            <Typography>
              The courier who completed this delivery did not automatically provide us with a copy
              of the POD. If you require it, please{' '}
              <Link
                href={`tel:${CONTACT_PHONE.replace(/ /g, '')}`}
                style={{ color: theme.palette.primary.main }}>
                contact the Live Operations team
              </Link>{' '}
              who can attempt to secure it.
            </Typography>
          )
        }
      ];
    },
    [classes.imageText, classes.infoText]
  );

  const renderProofOfDelivery = useCallback(
    () => (
      <InfoSection
        title="Proof of delivery"
        sx={{ mb: 2 }}
        data={proofOfDeliveryData(lastJsonMessage?.ProofOfDelivery)}
      />
    ),
    [lastJsonMessage?.ProofOfDelivery, proofOfDeliveryData]
  );

  const renderPricing = useCallback(
    () =>
      lastJsonMessage?.WinningQuote && (
        <CollectionOrderTotal price={lastJsonMessage?.WinningQuote} />
      ),
    [lastJsonMessage?.WinningQuote]
  );

  const CancelOrderButtonPressed = () => {
    setCancelOrderModalVisible(true);
  };

  const CancelOrderConfirmButtonPressed = () => {
    setCancelOrderLoading(true);
    cancelOrderCreation(
      { orderId: id },
      () => {
        setCancelOrderModalVisible(false);
        if (id) {
          dispatch(GetCollectionOrderDetail(id));
        }
        setCancelOrderLoading(false);
      },
      (err) => {
        setCancelOrderErrorModalText(err);
        setCancelOrderErrorModalVisible(true);
        setCancelOrderLoading(false);
      }
    );
  };

  const renderCustomerInfo = useCallback(
    () => (
      <InfoSection
        title="Delivery Contact Details"
        sx={{ mb: 2 }}
        data={[
          {
            title: 'Name',
            value: `${data.CustomerFirstName} ${data.CustomerLastName}`
          },
          {
            title: 'Email',
            value: data.CustomerEmail
          },
          {
            title: 'Phone',
            value: data.CustomerTelNo
          }
        ]}
      />
    ),
    [data]
  );

  const customCancelModalBody = () => {
    return (
      <Grid item>
        <Grid item container direction="row">
          <Typography variant="body2" component="p" color="textPrimary">
            {'You are about to cancel the order '}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            data-automation="order-number"
            color="textPrimary"
            style={{ fontWeight: 'bold', marginLeft: 3 }}>
            {data.ReferenceNumber}
          </Typography>
        </Grid>
        <Typography variant="body2" component="p" color="textPrimary">
          Are you sure you want to cancel this order?
        </Typography>
      </Grid>
    );
  };

  const cancelOrderButton = () => {
    return (
      <ButtonWithLoading
        title="Cancel Order"
        data-automation="reject-button"
        onClick={() => CancelOrderButtonPressed()}
        startIcon={<BlockRoundedIcon color="error" fontSize="inherit" />}
        disabled={cancelOrderLoading}
        loading={cancelOrderLoading}
      />
    );
  };

  return (
    <Container className={classes.root}>
      {loading ? (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '96px' }}>
          <CircularProgress />
        </Grid>
      ) : (
        data && (
          <>
            <ConfirmModal
              visible={cancelOrderModalVisible}
              title="Cancel order"
              buttonTitle="Yes, cancel order"
              loading={cancelOrderLoading}
              customBody={customCancelModalBody}
              onSubmit={() => {
                CancelOrderConfirmButtonPressed();
              }}
              onClose={() => {
                setCancelOrderModalVisible(false);
              }}
            />
            <ErrorModal
              visible={cancelOrderErrorModalVisible}
              title="Error"
              buttonTitle="Dismiss"
              errorBody={cancelOrderErrorModalText}
              onClose={() => {
                setCancelOrderErrorModalText('');
                setCancelOrderErrorModalVisible(false);
              }}
            />
            <CollectionOrderHeader
              id={data.Id}
              title={data.ReferenceNumber}
              portalStatus={data.PortalStatus}
              headerRight={showCancelBtn ? cancelOrderButton : null}
              premiumSelected={Boolean(data.PremiumSelected)}
              from={urlParams.get('from')}
            />
            <div style={{ marginTop: '24px' }}>
              <CollectionOrderDeliveryInfo
                orderDate={data.Drafted}
                token={data.ChatJwtToken}
                orkestroOrderId={data.OrkestroOrderId}
                deliveryAddress={getMultipleValuesAsText([
                  data.DropOffAddressLine1,
                  data.DropOffAddressLine2,
                  data.DropOffAddressLine3
                ])}
                deliveryInstructions={data.CustomerInstructions}
                deliveryType={data.DeliveryTypeKey}
                socketMessage={lastJsonMessage}
                showPremiumWarning={data.PremiumSelected && !data.Placed}
              />
              {data.Delivered && renderProofOfDelivery()}
              <CollectionOrderBasicInfo data={data} />
              {renderCustomerInfo()}

              {renderPricing()}
            </div>
          </>
        )
      )}
    </Container>
  );
}
