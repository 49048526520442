import {
  Box,
  CssBaseline,
  Container,
  IconButton,
  makeStyles,
  Grid,
  CircularProgress,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { debounce } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { ButtonSecondary } from '../components-atoms';
import { ConfirmModal, Searchbar } from '../components-molecules';
import { ButtonArea, HeaderWithButtons } from '../components-molecules/HeaderWithButtons';
import { AddressList, EditAddressModal } from '../components-organisms';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { GetDeliveryAddresses } from '../redux/actions/deliveryAddresses';
import {
  DeliveryAddress,
  DeliveryAddressGroup,
  DeliveryAddressItem
} from '../redux/reducers/deliveryAddresses';
import { AddressSearchModal } from '../components-organisms/AddressSearchModal';
import { RemoveDeliveryAddress } from '../redux/actions/removeDeliveryAddress';
import { headerButtonsResponsive, textButtonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '@media (min-width: 900px)': {
      maxWidth: '840px'
    }
  },
  buttonSet: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '24px',
    gap: '8px',
    '@media (max-width: 899px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '8px 4px',
      padding: '8px 12px'
    }
  },
  searchIcon: {
    width: 24,
    height: 24,
    '@media(max-width: 899px)': {
      width: 20,
      height: 20,
      marginLeft: -4,
      marginRight: 8
    }
  },
  searchIconButton: {
    width: 40,
    height: 40,
    ...textButtonHoverAndActive,
    ...headerButtonsResponsive
  },
  secondaryButton: {
    '@media(max-width: 899px)': {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start'
    }
  },
  uPaddingLeft: {
    paddingLeft: 24,
    '@media (max-width: 899px)': {
      paddingLeft: 16
    }
  }
}));
export function DeliveryAddresses() {
  const classes = useStyles();
  const timeoutDuration = 300;
  const mobileView = useMediaQuery('(max-width: 899px)', { noSsr: true });
  const pageSize = 5;
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [modal, setModal] = useState<'add' | 'edit' | 'remove' | ''>('');
  const [selectedAddress, setSelectedAddress] = useState<DeliveryAddress | null>(null);
  const [addressToRemove, setAddressToRemove] = useState('');
  const addresses = useAppSelector((state) => state.deliveryAddresses.data);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.deliveryAddresses.loading);

  const getDeliveryAddresses = useCallback(
    (currPage = 0, size = pageSize, prevData: DeliveryAddressGroup[] = []) => {
      dispatch(
        GetDeliveryAddresses(
          { Page: currPage, Size: size, Query: query },
          (resp: DeliveryAddressItem) => {
            let temp = [...prevData];
            resp.Result.forEach((m) => {
              const tempMatch = temp.find((f) => f.GroupName === m.GroupName);
              if (tempMatch) {
                temp[temp.indexOf(tempMatch)].Result = [
                  ...temp[temp.indexOf(tempMatch)].Result,
                  ...m.Result
                ];
              } else {
                temp = [...temp, m];
              }
            });
            return { ...resp, Result: temp };
          }
        )
      );
    },
    [query, dispatch]
  );

  useEffect(() => {
    getDeliveryAddresses();
  }, [getDeliveryAddresses]);

  const loadMore = () => {
    getDeliveryAddresses(page + 1, pageSize, addresses.Result);
    setPage((prev) => prev + 1);
  };

  const removeAddress = (id: string) => {
    if (addressToRemove) {
      setPage(0);
      dispatch(RemoveDeliveryAddress(id, () => getDeliveryAddresses()));
    }
  };

  return (
    <Box>
      <CssBaseline />
      <Container className={classes.root}>
        <CssBaseline />
        <HeaderWithButtons title="Delivery Addresses">
          <ButtonArea>
            <div className={classes.buttonSet}>
              {page === 0 && addresses.Result.length > 0 && (
                <IconButton
                  data-automation="search-button"
                  className={classes.searchIconButton}
                  color="primary"
                  onClick={() => setSearchOpen(!searchOpen)}>
                  <SearchRoundedIcon color="primary" className={classes.searchIcon} />
                  {mobileView && (
                    <Typography variant="button" component="span" color="primary">
                      Search
                    </Typography>
                  )}
                </IconButton>
              )}
              <ButtonSecondary
                onClick={() => setModal('add')}
                startIcon={<AddIcon />}
                className={classes.secondaryButton}>
                Add Address
              </ButtonSecondary>
            </div>
          </ButtonArea>
        </HeaderWithButtons>
        <Searchbar
          query={query}
          isOpen={searchOpen}
          placeholder="Search Delivery Addresses"
          onCancel={() => {
            setSearchOpen(false);
            setQuery('');
          }}
          onChange={(event) => debounce((e) => setQuery(e.target.value), timeoutDuration)(event)}
        />
        {page === 0 && loading ? (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '96px' }}>
            <CircularProgress />
          </Grid>
        ) : addresses?.Result && addresses?.Result.length === 0 ? (
          query ? (
            <Grid container item className={classes.uPaddingLeft} style={{ marginTop: '40px' }}>
              <Grid container item style={{ maxWidth: '320px' }}>
                <Typography variant="h6" component="span">
                  0 search results found
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span"
                  style={{ marginTop: '12px' }}>
                  Adjust your search term and try again
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Container maxWidth="xs" style={{ paddingTop: '120px' }}>
              <Typography variant="h6" align="center">
                You don’t have any delivery addresses yet
              </Typography>
              <Typography
                variant="body2"
                align="center"
                color="textSecondary"
                style={{ paddingTop: '12px' }}>
                Organizational delivery addresses are shared among your team. Once added, your team
                will be able to order to this addresses.
              </Typography>
            </Container>
          )
        ) : (
          <AddressList
            onEdit={(address) => {
              setSelectedAddress(address);
              setModal('edit');
            }}
            onRemove={(id) => {
              setAddressToRemove(id);
              setModal('remove');
            }}
            loadMore={loadMore}
          />
        )}
      </Container>
      <AddressSearchModal
        onSubmitSuccess={() => {
          setPage(0);
          getDeliveryAddresses();
        }}
        visible={modal === 'add'}
        onClose={() => setModal('')}
      />
      <EditAddressModal
        address={selectedAddress}
        onSubmitSuccess={() => {
          setPage(0);
          getDeliveryAddresses();
        }}
        visible={modal === 'edit'}
        onClose={() => setModal('')}
      />
      <ConfirmModal
        title="Delete address"
        buttonTitle="Yes, delete address"
        errorBody="Are you sure you want to delete this address?"
        onSubmit={() => {
          removeAddress(addressToRemove);
          setModal('');
          setAddressToRemove('');
        }}
        onClose={() => {
          setModal('');
          setAddressToRemove('');
        }}
        visible={modal === 'remove'}
      />
    </Box>
  );
}
