const PROFILE = 'PROFILE';
const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
const PROFILE_FAILURE = 'PROFILE_FAILURE';
const CLEAR_PROFILE = 'CLEAR_PROFILE';

const ProfileSymbols = {
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  PROFILE,
  CLEAR_PROFILE
};

export default ProfileSymbols;
