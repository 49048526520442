export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const ORDER_WARNING_STATUSES = [
  'Pending Approval',
  'Authorization Required',
  'Payment Failed'
];
export const ORDER_STAMP_WARNING_STATUSES = [
  'Pending Approval',
  'Authorization Required',
  'Payment Failed'
];
export const ORDER_STAMP_ERROR_STATUSES = ['Rejected By Manager'];
export const DELIVERY_STATUSES: { [key: string]: string } = {
  PendingDelivery: 'Pending',
  RejectedDelivery: 'Rejected',
  CancelledDelivery: 'Cancelled',
  PackedDelivery: 'Packed',
  QueuedDelivery: 'Packed',
  AssignedDelivery: 'Packed',
  DispatchedDelivery: 'Dispatched',
  OutForDeliveryDelivery: 'Out For Delivery',
  FailedDelivery: 'Failed',
  DeliveredDelivery: 'Delivered',
  ReturnedDelivery: 'Returned',
  ManuallyRefundedDelivery: 'Manually Refunded'
};
