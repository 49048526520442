import { useState } from 'react';
import { ThemeProvider, createStyles, makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
import AppMenuItemComponent from './AppMenuItemComponent';
import { colours, theme } from '../constants/theme';

interface IProps {
  active: boolean;
  name: string;
  link: string;
  icon: React.FC;
  isSecondLevel?: boolean;
}

const useStyles = makeStyles(
  createStyles({
    menuItem: ({ isSecondLevel }: any) => ({
      height: '41px',
      marginTop: '4px',
      marginBottom: '4px',
      paddingLeft: '12px',
      paddingRight: '12px',
      borderRadius: '8px',
      color: colours.lightEmphasis,
      background: isSecondLevel && colours.light12,
      // Selected - First level
      '&.active': {
        color: theme.palette.primary.main,
        // @ts-ignore
        background: theme.palette.primary[32]
      },
      // Hover
      '&:hover, &.active:hover': {
        // @ts-ignore
        background: theme.palette.primary[16]
      }
    }),
    menuListItemText: {
      height: '20px',
      display: 'flex',
      alignItems: 'stretch'
    },
    menuItemText: {
      fontWeight: 600,
      letterSpacing: '.3px'
    }
  })
);
function AppMenuItem({ active, name, link, icon, isSecondLevel }: IProps) {
  const IconComponent: any = icon;
  const classes = useStyles({ active, isSecondLevel });
  const [open, setOpen] = useState(false);
  function handleClick() {
    setOpen(!open);
  }

  return (
    <ThemeProvider theme={theme}>
      <AppMenuItemComponent className={classes.menuItem} link={link} onClick={() => handleClick()}>
        <>
          {!!icon && (
            <ListItemIcon>
              <IconComponent color={active ? theme.palette.primary.main : colours.lightEmphasis} />
            </ListItemIcon>
          )}
          <ListItemText inset={!icon} className={classes.menuListItemText}>
            <Typography variant="body2" component="span" className={classes.menuItemText}>
              {name}
            </Typography>
          </ListItemText>
        </>
      </AppMenuItemComponent>
    </ThemeProvider>
  );
}

export default AppMenuItem;
