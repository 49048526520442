const INVITE_MEMBER = 'INVITE_MEMBER';
const INVITE_MEMBER_SUCCESS = 'INVITE_MEMBER_SUCCESS';
const INVITE_MEMBER_FAILURE = 'INVITE_MEMBER_FAILURE';
const CLEAR_INVITE_MEMBER = 'CLEAR_INVITE_MEMBER';
const CLEAR_INVITE_MEMBER_ERROR = 'CLEAR_INVITE_MEMBER_ERROR';
const InviteMemberSymbols = {
  INVITE_MEMBER_SUCCESS,
  INVITE_MEMBER_FAILURE,
  INVITE_MEMBER,
  CLEAR_INVITE_MEMBER,
  CLEAR_INVITE_MEMBER_ERROR
};

export default InviteMemberSymbols;
