const EDIT_DELIVERY_ADDRESS = 'EDIT_DELIVERY_ADDRESS';
const EDIT_DELIVERY_ADDRESS_SUCCESS = 'EDIT_DELIVERY_ADDRESS_SUCCESS';
const EDIT_DELIVERY_ADDRESS_FAILURE = 'EDIT_DELIVERY_ADDRESS_FAILURE';
const CLEAR_EDIT_DELIVERY_ADDRESS = 'CLEAR_EDIT_DELIVERY_ADDRESS';

const EditDeliveryAddressSymbols = {
  EDIT_DELIVERY_ADDRESS_SUCCESS,
  EDIT_DELIVERY_ADDRESS_FAILURE,
  EDIT_DELIVERY_ADDRESS,
  CLEAR_EDIT_DELIVERY_ADDRESS
};

export default EditDeliveryAddressSymbols;
