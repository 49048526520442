const CREATE_SESSION = 'CREATE_SESSION';
const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
const CREATE_SESSION_FAILURE = 'CREATE_SESSION_FAILURE';
const CLEAR_CREATE_SESSION = 'CLEAR_CREATE_SESSION';

const CreateSessionSymbols = {
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAILURE,
  CREATE_SESSION,
  CLEAR_CREATE_SESSION
};

export default CreateSessionSymbols;
