/* eslint-disable react/destructuring-assignment */

import CreditSymbols from '../symbols/credits';

interface State {
  credits: number;
}
const initialState: State = {
  credits: 0
};

// eslint-disable-next-line default-param-last
function CreditsReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case CreditSymbols.CREDIT_USE:
      return { ...state, credits: state.credits > 0 ? state.credits - 1 : 0 };
    case CreditSymbols.CREDIT_FILL:
      return {
        ...state,
        credits: state.credits + action.payload
      };
    default:
      return state;
  }
}
export default CreditsReducer;
