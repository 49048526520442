/* eslint-disable react/destructuring-assignment */
function CollectionOrdersIcon(props: any) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="a"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={1}
        y={4}
        width={22}
        height={16}>
        <path
          d="M19.5 8H17V6c0-1.1-.9-2-2-2H3a2 2 0 0 0-2 2v9c0 1.1.9 2 2 2 0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h1c.55 0 1-.45 1-1v-3.33c0-.43-.14-.85-.4-1.2L20.3 8.4c-.19-.25-.49-.4-.8-.4Zm0 1.5 1.96 2.5H17V9.5h2.5ZM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Zm2.22-3c-.55-.61-1.33-1-2.22-1-.89 0-1.67.39-2.22 1H3V6h12v9H8.22ZM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Z"
          fill={props.color || '#000'}
        />
      </mask>
      <g mask="url(#a)">
        <path d="M24 0H0v24h24V0Z" fill={props.color || '#000'} fillOpacity={props.opacity || 1} />
      </g>
    </svg>
  );
}

export default CollectionOrdersIcon;
