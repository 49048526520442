/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

function DownloadIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="download"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={2}
        width={12}
        height={14}>
        <path
          d="M12.443 7.125H11.25v-3a1.5 1.5 0 0 0-1.5-1.5h-1.5a1.5 1.5 0 0 0-1.5 1.5v3H5.558c-.668 0-1.005.81-.533 1.283l3.442 3.442a.747.747 0 0 0 1.058 0l3.442-3.442c.473-.473.143-1.283-.524-1.283Zm-8.693 7.5c0 .412.338.75.75.75h9c.412 0 .75-.338.75-.75a.752.752 0 0 0-.75-.75h-9a.752.752 0 0 0-.75.75Zm4.5-6v-4.5h1.5v4.5h.877L9 10.252 7.372 8.625h.878Z"
          fill={props.color || '#000'}
        />
      </mask>
      <g mask="url(#download)">
        <path d="M18 0H0v18h18V0Z" fill={props.color || '#000'} />
      </g>
    </svg>
  );
}

export default DownloadIcon;
