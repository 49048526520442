/* eslint-disable react/destructuring-assignment */
import EDIT_DELIVERY_ADDRESS from '../symbols/editDeliveryAddress';

const initialState = {
  data: '',
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function EditDeliveryAddressReducer(state = initialState, action: any) {
  switch (action.type) {
    case EDIT_DELIVERY_ADDRESS.EDIT_DELIVERY_ADDRESS:
      return { ...state, loading: true, error: false };
    case EDIT_DELIVERY_ADDRESS.EDIT_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        data: action.payload.Result,
        loading: false,
        error: false
      };
    case EDIT_DELIVERY_ADDRESS.EDIT_DELIVERY_ADDRESS_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case EDIT_DELIVERY_ADDRESS.CLEAR_EDIT_DELIVERY_ADDRESS:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default EditDeliveryAddressReducer;
