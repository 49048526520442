const TEAM = 'TEAM';
const TEAM_SUCCESS = 'TEAM_SUCCESS';
const TEAM_FAILURE = 'TEAM_FAILURE';
const CLEAR_TEAM = 'CLEAR_TEAM';

const TeamSymbols = {
  TEAM_SUCCESS,
  TEAM_FAILURE,
  TEAM,
  CLEAR_TEAM
};

export default TeamSymbols;
