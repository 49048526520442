const EDIT_TEAM = 'EDIT_TEAM';
const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
const EDIT_TEAM_FAILURE = 'EDIT_TEAM_FAILURE';
const CLEAR_EDIT_TEAM = 'CLEAR_EDIT_TEAM';

const EditTeamSymbols = {
  EDIT_TEAM_SUCCESS,
  EDIT_TEAM_FAILURE,
  EDIT_TEAM,
  CLEAR_EDIT_TEAM
};

export default EditTeamSymbols;
