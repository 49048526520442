/* eslint-disable no-console */
import axios, { AxiosResponse, AxiosError, CancelToken } from 'axios';
import store from '../redux/store';
import { setError } from '../redux/actions/error';
import { clearShopLogin } from '../redux/actions/login';

function getHeaders(
  withAuth: boolean
): { 'TK-token': string; 'Content-Type': string } | { 'Content-Type': string } {
  if (withAuth) {
    const { token } = store.getState().login;
    return {
      'TK-token': token,
      'Content-Type': 'application/json'
    };
  }
  return {
    'Content-Type': 'application/json'
  };
}

export const HttpRequestGet = (
  url: string,
  dispatch: Function,
  actionSuccess: Function,
  actionFailure: Function,
  withAuthentication: boolean,
  callback?: Function,
  cancelToken?: CancelToken,
  // eslint-disable-next-line no-unused-vars
  errorCallback?: (error: AxiosResponse | undefined) => AxiosResponse
) => {
  const headers = getHeaders(withAuthentication);

  axios
    .get(url, { headers, cancelToken })
    .then((response: AxiosResponse) => {
      dispatch(actionSuccess(response.data));
      if (callback) callback(response.data);
    })
    .catch((error: AxiosError) => {
      dispatch(actionFailure(error.message));

      if (errorCallback && typeof errorCallback === 'function') {
        errorCallback(error.response);
        return;
      }

      if (error.response?.status === 401) {
        dispatch(clearShopLogin());
      } else if (error.response?.data === undefined) {
        dispatch(setError(error.message));
      } else if ((error.response?.data as any).Error !== undefined) {
        dispatch(setError((error.response?.data as any).Error));
      } else {
        dispatch(setError(error.response?.data));
      }
    });
};

export const HttpRequestPost = (
  url: string,
  dispatch: Function,
  actionSuccess: Function,
  actionFailure: Function,
  withAuthentication: boolean,
  requestBody?: object,
  // eslint-disable-next-line no-unused-vars
  callback?: (response: AxiosResponse | undefined) => void
): void => {
  const headers = getHeaders(withAuthentication);
  axios
    .post(url, requestBody, {
      headers
    })
    .then((response: AxiosResponse) => {
      dispatch(actionSuccess(response.data));
      if (callback) {
        callback(response.data);
      }
    })
    .catch((error: AxiosError) => {
      dispatch(actionFailure((error.response?.data as any)?.Error || error.response?.data));

      if (error.response?.status === 401) {
        dispatch(clearShopLogin());
      } else if (error.response?.data === undefined) {
        dispatch(setError(error.message));
      } else if ((error.response?.data as any).Error !== undefined) {
        dispatch(setError((error.response?.data as any).Error));
      } else {
        dispatch(setError(error.response?.data));
      }
    });
};
export const HttpRequestPut = (
  url: string,
  dispatch: Function,
  actionSuccess: Function,
  actionFailure: Function,
  withAuthentication: boolean,
  requestBody?: object,
  // eslint-disable-next-line no-unused-vars
  callback?: (resp?: any) => void
) => {
  const headers = getHeaders(withAuthentication);
  axios
    .put(url, requestBody, {
      headers
    })
    .then((response: AxiosResponse) => {
      dispatch(actionSuccess(response.data));
      if (callback) callback(response.data);
    })
    .catch((error: AxiosError) => {
      dispatch(actionFailure(error.response));

      if (error.response?.status === 401) {
        dispatch(clearShopLogin());
      } else if (error.response?.data === undefined) {
        dispatch(setError(error.message));
      } else if ((error.response?.data as any).Error !== undefined) {
        dispatch(setError((error.response?.data as any).Error));
      } else {
        dispatch(setError(error.response?.data));
      }
    });
};
export const HttpRequestPostForLogin = (
  url: string,
  dispatch: Function,
  actionSuccess: Function,
  actionFailure: Function,
  withAuthentication: boolean,
  requestBody: object,
  callback?: () => void
) => {
  const headers = getHeaders(withAuthentication);
  axios
    .post(url, requestBody, {
      headers
    })
    .then((response: AxiosResponse) => {
      if (response.data.Result === 'UserNotRegistered') {
        dispatch(actionFailure('User mail or password is wrong'));
        dispatch(setError('~~User mail or password wrong~~'));
      } else {
        dispatch(actionSuccess(response.data));
        if (callback) callback();
      }
    })
    .catch((error: AxiosError) => {
      dispatch(actionFailure(error.response?.data));
      if ((error.response?.data as any).Error !== undefined) {
        if ((error.response?.data as any).Error === 'UserNotRegistered') {
          dispatch(setError('User mail or password is wrong'));
        } else {
          dispatch(setError((error.response?.data as any).Error));
        }
      } else {
        dispatch(setError(error.response?.data));
      }
    });
};

export const HttpRegularPost = (
  url: string,
  reqBody: any,
  thunkDetails?: {
    dispatch: any;
    actionSuccess: any;
    actionFailure?: any; // If action failure is not passed then it will display error message that comes from backend
    callback?: any;
  }
) => {
  const { token } = store.getState().login;
  const headers = {
    'TK-token': token,
    'Content-Type': 'application/json'
  };

  if (thunkDetails) {
    const { dispatch, actionSuccess, actionFailure, callback } = thunkDetails;

    axios
      .post(url, reqBody, {
        headers
      })
      .then(() => {
        dispatch(actionSuccess());
        if (callback) callback();
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          actionFailure
            ? actionFailure()
            : setError(error?.response?.data?.Error ?? 'Error Occured')
        );
      });
  } else {
    axios
      .post(url, reqBody, {
        headers
      })
      .then(() => {
        console.log();
      })
      .catch((error) => console.log(error));
  }
};

export const HttpRegularPut = (
  url: string,
  reqBody: any,
  thunkDetails?: {
    dispatch: any;
    actionSuccess: any;
    actionFailure?: any; // If action failure is not passed then it will display error message that comes from backend
    callback?: any;
  }
) => {
  const { token } = store.getState().login;
  const headers = {
    'TK-token': token,
    'Content-Type': 'application/json'
  };

  if (thunkDetails) {
    const { dispatch, actionSuccess, actionFailure, callback } = thunkDetails;

    axios
      .put(url, reqBody, {
        headers
      })
      .then(() => {
        dispatch(actionSuccess());
        if (callback) callback();
      })
      .catch((error) => {
        dispatch(
          actionFailure
            ? actionFailure()
            : setError(error?.response?.data?.Error ?? 'Error Occured')
        );
        console.log(error);
      });
  } else {
    axios
      .put(url, reqBody, {
        headers
      })
      .then(() => {
        console.log();
      })
      .catch((error) => console.log(error));
  }
};

export const HttpRegularDelete = (
  url: string,
  thunkDetails?: {
    dispatch: any;
    actionSuccess?: any;
    actionFailure?: any; // If action failure is not passed then it will display error message that comes from backend
    callback?: any;
  }
) => {
  const { token } = store.getState().login;
  const headers = {
    'TK-token': token,
    'Content-Type': 'application/json'
  };

  if (thunkDetails) {
    const { dispatch, actionSuccess, actionFailure, callback } = thunkDetails;

    axios
      .delete(url, {
        headers
      })
      .then(() => {
        if (actionSuccess) {
          dispatch(actionSuccess());
        }
        if (callback) callback();
      })
      .catch((error) => {
        dispatch(
          actionFailure
            ? actionFailure()
            : setError(error?.response?.data?.Error ?? 'Error Occured')
        );
        console.log(error);
      });
  } else {
    axios
      .delete(url, {
        headers
      })
      .then(() => {
        console.log();
      })
      .catch((error) => console.log(error));
  }
};

export const HttpRegularGet = (url: string, callback?: Function, failCallback?: Function) => {
  const headers = getHeaders(true);

  axios
    .get(url, { headers })
    .then((response: AxiosResponse) => {
      if (callback) callback(response.data);
    })
    .catch(() => {
      if (failCallback) failCallback();
    });
};
