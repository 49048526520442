import SEND_FORGOT_PASSWORD_EMAIL from '../symbols/sendForgotPasswordEmail';
import { HttpRequestPost } from '../../service';

const sendForgotPasswordEmailAction = () => ({
  type: SEND_FORGOT_PASSWORD_EMAIL.SEND_FORGOT_PASSWORD_EMAIL
});
const sendForgotPasswordEmailSuccessAction = (data: any) => {
  return {
    type: SEND_FORGOT_PASSWORD_EMAIL.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
    payload: data.Result
  };
};
const sendForgotPasswordEmailFailureAction = (data: any) => ({
  type: SEND_FORGOT_PASSWORD_EMAIL.SEND_FORGOT_PASSWORD_EMAIL_FAILURE,
  payload: data
});
const clearSendForgotPasswordEmailAction = () => ({
  type: SEND_FORGOT_PASSWORD_EMAIL.CLEAR_SEND_FORGOT_PASSWORD_EMAIL
});
interface SendForgotPasswordEmailRequestModel {
  Email: string;
}
export function SendForgotPasswordEmail(
  data: SendForgotPasswordEmailRequestModel,
  callback?: () => void
) {
  return (dispatch: any) => {
    dispatch(sendForgotPasswordEmailAction());
    HttpRequestPost(
      '/pro/forgot-password/send-mail',
      dispatch,
      sendForgotPasswordEmailSuccessAction,
      sendForgotPasswordEmailFailureAction,
      true,
      data,
      callback
    );
  };
}

export function clearSendForgotPasswordEmail() {
  return (dispatch: any) => {
    dispatch(clearSendForgotPasswordEmailAction());
  };
}
