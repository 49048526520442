import ORDERS from '../symbols/orders';
import { HttpRequestPost } from '../../service';
import { OrderResponseModel } from '../reducers/orders';

const ordersAction = () => ({
  type: ORDERS.ORDERS
});
const ordersSuccessAction = (data: any) => {
  return {
    type: ORDERS.ORDERS_SUCCESS,
    payload: data
  };
};
const ordersFailureAction = (data: any) => ({
  type: ORDERS.ORDERS_FAILURE,
  payload: {
    data
  }
});
const clearOrdersAction = () => ({
  type: ORDERS.CLEAR_ORDERS
});
interface GetOrdersRequestModel {
  Query?: string;
  Size: number;
  Page: number;
  Order: string;
  Status?: string[];
  StartDate?: Date;
  EndDate?: Date;
  TeamMemberIds?: string[];
}
export function GetOrders(
  data: GetOrdersRequestModel,
  // eslint-disable-next-line no-unused-vars
  callback: (resp: OrderResponseModel) => OrderResponseModel
) {
  return (dispatch: any) => {
    dispatch(ordersAction());
    HttpRequestPost(
      '/pro/order',
      dispatch,
      (response: any) => {
        const formattedData = callback(response.Result);
        return ordersSuccessAction(formattedData);
      },
      ordersFailureAction,
      true,
      data
    );
  };
}

export function clearOrders() {
  return (dispatch: any) => {
    dispatch(clearOrdersAction());
  };
}
