const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
const VALIDATE_TOKEN_FAILURE = 'VALIDATE_TOKEN_FAILURE';
const CLEAR_VALIDATE_TOKEN = 'CLEAR_VALIDATE_TOKEN';

const ValidateTokenSymbols = {
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE,
  VALIDATE_TOKEN,
  CLEAR_VALIDATE_TOKEN
};

export default ValidateTokenSymbols;
