/* eslint-disable react/destructuring-assignment */
import TEAM from '../symbols/team';

export interface TeamMember {
  Email: string;
  FirstName: string;
  LastName: string;
  ProUserRole: string;
  CustomerId: string;
  Active: boolean;
  ApprovalThreshold?: number;
}
export interface TeamMemberResponse {
  GroupName: string;
  Result: TeamMember[];
}
interface State {
  loading: boolean;
  error: boolean;
  data: TeamMemberResponse[];
}
const initialState: State = {
  loading: false,
  error: false,
  data: []
};

// eslint-disable-next-line default-param-last
function TeamReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case TEAM.TEAM:
      return { ...state, loading: true, error: false };
    case TEAM.TEAM_SUCCESS:
      return {
        ...state,
        data: action.payload.data.Result,
        loading: false,
        error: false
      };
    case TEAM.TEAM_FAILURE:
      return {
        ...state,
        token: null,
        loading: false,
        error: action.payload
      };
    case TEAM.CLEAR_TEAM:
      return {
        ...state,
        token: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default TeamReducer;
