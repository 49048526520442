import moment from 'moment';

export function getOrderDate(createdDate: string) {
  return moment.utc(createdDate).local().format('DD MMM YYYY - h:mm:ssA');
}

export function getCollectOrderDate(createdDate: string) {
  return moment.utc(createdDate).local().format('DD MMM YYYY - h:mmA');
}

export function getOrderFilterDate(createdDate: string) {
  if (!createdDate) {
    return null;
  }
  return moment.utc(createdDate).local().format('DD MMM YYYY');
}
