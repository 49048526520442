import { Grid, Typography, makeStyles, BoxProps, Link } from '@material-ui/core';
import { ITheme } from '../constants/theme';
import { InfoSectionContainer } from './InfoSectionContainer';

const useStyles = makeStyles((theme: ITheme) => ({
  infoTextContainer: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  infoTextContainerLastRow: {
    paddingTop: 15,
    paddingBottom: 15,
    alignItems: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  infoTextTitle: {
    minWidth: 164,
    '@media (max-width: 599px)': {
      marginBottom: 4
    }
  },
  infoBodyContainer: {
    width: '100%',
    '@media (min-width: 600px)': {
      marginLeft: 16
    }
  },
  helperText: {
    display: 'block',
    marginTop: 4,
    color: theme.palette.text.secondary
  },
  multiLineText: {
    whiteSpace: 'pre-wrap'
  },
  imagePreview: {
    width: '128px',
    height: '128px',
    objectFit: 'contain',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderRadius: 4
  }
}));

interface IInfoSectionData {
  title: string;
  value?: string | number;
  href?: string;
  imageUrl?: string;
  customComponent?: JSX.Element;
  helperText?: string;
}

interface IInfoSectionProps {
  title: string;
  data: IInfoSectionData[];
  sx?: BoxProps;
}
export function InfoSection({ title, data, sx }: IInfoSectionProps) {
  const classes = useStyles();

  return (
    <InfoSectionContainer title={title} sx={sx}>
      {data.map((item, index) => (
        <Grid
          key={item.title}
          container
          className={
            index === data.length - 1 ? classes.infoTextContainerLastRow : classes.infoTextContainer
          }>
          <Typography
            display="inline"
            variant="caption"
            component="span"
            color="textSecondary"
            data-testid={item.title}
            className={classes.infoTextTitle}>
            {item.title.toUpperCase()}
          </Typography>
          <Grid item className={classes.infoBodyContainer}>
            {item.value && (
              <Typography
                variant="body1"
                component="span"
                color="textPrimary"
                className={classes.multiLineText}>
                {item.value}
              </Typography>
            )}
            {item.href && (
              <Link href={item.href} underline="none" target="_blank">
                {item.href}
              </Link>
            )}
            {item.imageUrl && (
              <a href={item.imageUrl} target="_blank" rel="noreferrer">
                <img src={item.imageUrl} alt={item.title} className={classes.imagePreview} />
              </a>
            )}
            {item.customComponent && item.customComponent}
            {item.helperText && (
              <Typography
                variant="body2"
                component="span"
                color="textPrimary"
                className={classes.helperText}>
                {item.helperText}
              </Typography>
            )}
          </Grid>
        </Grid>
      ))}
    </InfoSectionContainer>
  );
}
