import React from 'react';
import { Button, Container, makeStyles, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import UnfoldMoreRoundedIcon from '@material-ui/icons/UnfoldMoreRounded';
import { DropdownMenuButton } from '../components-atoms';
import { theme } from '../constants/theme';
import { headerButtonsResponsive, textButtonHoverAndActive } from '../utils/styles/buttonStyles';

interface IProps {
  selectedSortType: string;
  menuItems: Array<{
    title: string;
    value: string;
    handleSelect: Function;
  }>;
}
const useStyles = makeStyles(() => ({
  buttonIcon: {
    width: 18,
    height: 18,
    marginRight: 8
  },
  menu: {
    marginTop: 120,
    '& [class*=MuiPaper-rounded-]': {
      top: 79,
      borderRadius: 12
    }
  },
  menuPaper: {
    borderRadius: 12,
    overflow: 'hidden',
    '@media(max-height: 700px)': { top: '30px !important' }
  },
  root: {
    width: 256,
    padding: '28px 4px',
    borderRadius: 8,
    '@media(max-height: 700px)': {
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  },
  button: {
    borderRadius: '8px',
    ...headerButtonsResponsive,
    ...textButtonHoverAndActive
  }
}));

export default function SortButton({ selectedSortType, menuItems }: IProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        startIcon={<UnfoldMoreRoundedIcon color="primary" />}
        onClick={handleClick}
        className={classes.button}
        style={{
          backgroundColor: open ? theme.palette.secondary.main : undefined
        }}>
        <Typography variant="button" component="span" color="primary">
          Sort
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        PaperProps={{ className: classes.menuPaper }}
        open={open}
        onClose={handleClose}
        className={classes.menu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          role: 'listbox'
        }}>
        <Container className={classes.root}>
          {menuItems &&
            menuItems.map((i: any) => (
              <DropdownMenuButton
                key={i.title}
                title={i.title}
                value={i.value}
                handleClick={() => {
                  i.handleSelect();
                  handleClose();
                }}
                selected={selectedSortType === i.value}
              />
            ))}
        </Container>
      </Menu>
    </>
  );
}
