/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
import {
  Box,
  CssBaseline,
  Container,
  IconButton,
  Typography,
  Grid,
  useMediaQuery,
  CircularProgress
} from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Add from '@material-ui/icons/Add';
import { FilterButton, Searchbar, SortButton } from '../components-molecules';
import { IFilter, IFilterOption } from '../components-molecules/FilterButton';
import { ButtonArea, HeaderWithButtons } from '../components-molecules/HeaderWithButtons';
import { useAppSelector } from '../hooks/store';
import { ButtonPrimary } from '../components-atoms';
import { CollectionOrdersList } from '../components-organisms/CollectionOrdersList';
import { CustomTabs } from '../components-molecules/Tabs';
import { CustomTab } from '../components-molecules/Tab';
import { ASC_SORT, SortOption, SortType } from '../utils/sortType';
import { UserProfile } from '../redux/reducers/profile';
import { collectionOrdersStyles } from '../utils/styles/collectionOrdersStyles';
import {
  GetCollectOrders,
  GetOrderResultModel,
  getDeliveryStatusTypes,
  CollectOrderRootModel
} from '../redux/actions/collectOrders';
import { CollectionOrdersFilterOptions } from '../redux/reducers/collectOrdersFilters';
import { getTabText, getTabUrl } from '../redux/symbols/collectionOrders';
import CollectionOrderHubspotWidget from '../components-molecules/CollectionOrderHubspotWidget';

const DELIVERY_ORDERS_TABS = ['Open orders', 'Out for delivery', 'Archive'];
const pageSize = 10;

export function CollectionOrders() {
  const location = useLocation();
  const navigate = useNavigate();

  // URL Filters
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const page = useMemo(() => Number(urlParams.get('page')), [location.search]) || 1;
  const query = useMemo(() => urlParams.get('query'), [location.search]) || '';
  const searchOpen = useMemo(() => urlParams.get('searchOpen') === 'true', [location.search]) || false;
  const sort = useMemo(() => urlParams.get('sort') || SortType.asc, [location.search]) || '';
  const tabIndex = useMemo(() => Number(urlParams.get('tabIndex')), [location.search]) || 0;
  const status = useMemo(() => urlParams.getAll('status'), [location.search]) || [];
  const startDate = useMemo(() => urlParams.get('startDate'), [location.search]) || '';
  const endDate = useMemo(() => urlParams.get('endDate'), [location.search]) || '';
  const ListingFilters: any = {
    "Date Range": [startDate ?? undefined, endDate ?? undefined].filter(o => o),
    "Order Status": status
  }
  const setParam = (key: string, value: any) => {
    urlParams.delete(key);
    if (value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          urlParams.append(key, v);
        });
      } else {
        urlParams.set(key, value);
      }
      navigate({ pathname: location.pathname, search: urlParams.toString() });
    }
  }

  const deleteParam = (key: string) => {
    urlParams.delete(key);
    navigate({ pathname: location.pathname, search: urlParams.toString() });
  }

  const setPage = (p: number) => {
    setParam('page', p);
  }

  // URL Filters
  const classes = collectionOrdersStyles();
  const timeoutDuration = 300;
  const mobileView = useMediaQuery('(max-width: 899px)', { noSsr: true });
  const [userData] = useAppSelector((state) => [(state.profile as any).data as UserProfile]);
  const [loading, setLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<CollectOrderRootModel[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filterOptions, setFilterOptions] = useState<IFilterOption>(CollectionOrdersFilterOptions);

  // If Delivery Orders feature not toggled on
  // for organization
  if (!userData.IsCollectEnabled) {
    navigate('/');
  }

  useEffect(() => {
    getDeliveryStatusTypes(
      (res) => {
        setFilterOptions((prev: any) => ({
          ...prev,
          // eslint-disable-next-line no-useless-computed-key
          ['Order Status']: res.map((i) => ({
            title: i.Name,
            value: i.Code
          }))
        }));
      },
      () => { }
    );
  }, []);

  const getCollectOrders = useCallback(
    (size = pageSize, currentPage = 1, prevData: CollectOrderRootModel[] = []) => {
      const url = getTabUrl(tabIndex);

      const filters: any = {}
      if (startDate) {
        filters.StartDate = new Date(startDate).toISOString();
      }
      if (endDate) {
        filters.EndDate = new Date(endDate).toISOString();
      }
      if (status?.length > 0) {
        filters.Status = status;
      }

      GetCollectOrders(
        url,
        {
          Page: currentPage,
          Size: size,
          Order: sort,
          Query: query,
          Filter: filters
        },
        (res: GetOrderResultModel) => {
          setTotalCount(res.Count);
          let temp: CollectOrderRootModel[] = [...prevData];
          res.Data.forEach((m) => {
            const tempMatch = temp.find((f) => f.Merchant === m.Merchant);
            if (tempMatch) {
              temp[temp.indexOf(tempMatch)].Deliveries = [
                ...temp[temp.indexOf(tempMatch)].Deliveries,
                ...m.Deliveries
              ];
            } else {
              temp = [...temp, m];
            }
          });
          setOrders(temp);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabIndex, query, sort, startDate, endDate, page, status.length]
  );

  useEffect(() => {
    setPage(1);
  }, []);

  useEffect(() => {
    getCollectOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex, query, sort, startDate, endDate, status.length]);

  const loadMore = () => {
    getCollectOrders(pageSize, page + 1, orders);
    setPage(page + 1);
  };

  const onFilterSubmit = (submittedFilter: IFilter) => {
    setLoading(true);
    setPage(1);
    deleteParam('from');
    if (submittedFilter['Date Range']) {
      setParam('startDate', submittedFilter['Date Range'][0]);
      setParam('endDate', submittedFilter['Date Range'][1]);
    }
    if (submittedFilter['Order Status']) {
      setParam('status', submittedFilter['Order Status']);
    }
  };

  const onTabChange = (_: number, newValue: number) => {
    if (newValue !== tabIndex) {
      setLoading(true);
      setParam('tabIndex', newValue);
      setPage(1);
    }
  };

  const handleClickNew = () => {
    navigate(`/new-delivery-order`);
  };

  const renderTabs = () => {
    return (
      <Container className={classes.tabContainer}>
        <CustomTabs value={tabIndex} onChange={onTabChange}>
          {DELIVERY_ORDERS_TABS.map((tab: string) => (
            <CustomTab key={tab} label={tab} />
          ))}
        </CustomTabs>
      </Container>
    );
  };

  const renderNewBtn = (style?: React.CSSProperties) => {
    return (
      <ButtonPrimary
        className={classes.addNewBtn}
        onClick={handleClickNew}
        startIcon={<Add />}
        style={style}>
        New Delivery
      </ButtonPrimary>
    );
  };

  const renderHeader = () => {
    return (
      <HeaderWithButtons title="Delivery Orders">
        <ButtonArea>
          <div className={classes.buttonSet}>
            <SortButton
              data-automation="sort-button"
              menuItems={ASC_SORT.map(({ title, value }: SortOption) => ({
                title,
                value,
                handleSelect: () => {
                  setLoading(true);
                  setPage(1);
                  setParam('sort', value);
                }
              }))}
              selectedSortType={sort}
            />
            <FilterButton
              clearAll
              defaultOption="Date Range"
              dateKey="Date Range"
              data-automation="filter-button"
              onSubmit={onFilterSubmit}
              options={filterOptions}
              filter={ListingFilters}
              singleDate
            />
            <IconButton
              data-automation="search-button"
              className={classes.searchIconButton}
              color="primary"
              onClick={() => setParam('searchOpen', true)}>
              <SearchRoundedIcon color="primary" className={classes.searchIcon} />
              {mobileView && (
                <Typography variant="button" component="span" color="primary">
                  Search
                </Typography>
              )}
            </IconButton>

            {renderNewBtn()}
          </div>
        </ButtonArea>
      </HeaderWithButtons>
    );
  };

  const renderSearchbar = () => {
    return (
      <Searchbar
        query={query}
        isOpen={searchOpen}
        placeholder="Search Delivery Orders"
        onCancel={() => {
          deleteParam('searchOpen');
          deleteParam('query');
          setPage(1);
        }}
        onChange={(e) => {
          setPage(1);
          if (e.target.value.length > 0) {
            const debouncedFunc = debounce((_e) => {
              setParam('query', _e.target.value);
            }, timeoutDuration);

            debouncedFunc(e);
          } else {
            deleteParam('query');
          }
        }}
      />
    );
  };

  const renderNoOrders = () => {
    return (
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: '96px' }}>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          style={{ maxWidth: '320px' }}>
          <Typography variant="h6" component="span" align="center">
            {getTabText(tabIndex)}
          </Typography>
          {tabIndex === 0 && (
            <>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                align="center"
                style={{ marginTop: '12px' }}>
                To get started, create a new Collection order
              </Typography>

              {renderNewBtn({ marginTop: 32 })}
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      <CssBaseline />
      <Container className={classes.root}>
        <CssBaseline />
        {renderHeader()}
        {renderSearchbar()}

        {renderTabs()}
        {loading && page === 1 ? (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '96px' }}>
            <CircularProgress />
          </Grid>
        ) : orders && orders?.length > 0 ? (
          <CollectionOrdersList data={orders} totalCount={totalCount} loadMore={loadMore} />
        ) : query ? (
          <Grid container item className={classes.uPaddingLeft} style={{ marginTop: '40px' }}>
            <Grid container item style={{ maxWidth: '320px' }}>
              <Typography variant="h6" component="span">
                0 search results found
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{ marginTop: '12px' }}>
                Adjust your search term and try again
              </Typography>
            </Grid>
          </Grid>
        ) : Object.keys(ListingFilters).some((key) => {
          return ListingFilters[key]?.length !== 0;
        }) ? (
          <Grid container item className={classes.uPaddingLeft} style={{ marginTop: '40px' }}>
            <Grid container item style={{ maxWidth: '320px' }}>
              <Typography variant="h6" component="span">
                0 results found
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{ marginTop: '12px' }}>
                Adjust your filtering and try again
              </Typography>
            </Grid>
          </Grid>
        ) : (
          renderNoOrders()
        )}
      </Container>
    </Box>
  );
}
