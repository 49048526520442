import { Button, Container, Grid, Modal, Typography, makeStyles } from '@material-ui/core';
import { buttonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles(() => ({
  modal: { alignItems: 'center', justifyContent: 'center' },
  modalContainer: {
    backgroundColor: 'white',
    maxWidth: '480px',
    boxShadow:
      '0px 6px 36px rgba(0, 0, 0, 0.15), 0px 2.21728px 14.2392px rgba(0, 0, 0, 0.111884), 0px 0.93179px 6.99399px rgba(0, 0, 0, 0.0997219), 0px 0.37078px 3.36556px rgba(0, 0, 0, 0.0894965), 0px 0.120827px 1.42249px rgba(0, 0, 0, 0.0753326), 0px 0.0225271px 0.422798px rgba(0, 0, 0, 0.0508791)',
    borderRadius: '20px',
    padding: '24px',
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },

  titleSection: {
    marginBottom: '8px'
  },
  bodySection: {
    marginBottom: '24px'
  },
  okayButton: {
    borderRadius: '8px',
    padding: '6px 16px',
    ...buttonHoverAndActive
  }
}));

interface IProps {
  visible: boolean;
  onClose: () => void;
  title: string;
  errorBody?: string;
  buttonTitle?: string;
  buttonVisible?: boolean;
  customBody?: Function;
}

export function ErrorModal({
  visible,
  customBody,
  onClose,
  title,
  errorBody,
  buttonTitle,
  buttonVisible = true
}: IProps) {
  const classes = useStyles();
  return (
    <Modal open={visible} onClose={onClose} className={classes.modal}>
      <Container className={classes.modalContainer}>
        <Grid container spacing={1} />
        <Grid item className={classes.titleSection}>
          <Typography variant="h6" component="h6" color="textPrimary">
            {title}
          </Typography>
        </Grid>
        <Grid item className={classes.bodySection}>
          {customBody ? (
            customBody()
          ) : (
            <Typography variant="body2" component="p" color="textPrimary">
              {errorBody}
            </Typography>
          )}
        </Grid>
        {buttonVisible && (
          <Grid item container xs={12} justifyContent="flex-end">
            <Button onClick={onClose} className={classes.okayButton}>
              <Typography variant="button" component="p" color="inherit">
                {buttonTitle || 'OK'}
              </Typography>
            </Button>
          </Grid>
        )}
      </Container>
    </Modal>
  );
}
