/* eslint-disable react/destructuring-assignment */
import EDIT_TEAM from '../symbols/editTeam';

const initialState = {
  data: '',
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function EditTeamReducer(state = initialState, action: any) {
  switch (action.type) {
    case EDIT_TEAM.EDIT_TEAM:
      return { ...state, loading: true, error: false };
    case EDIT_TEAM.EDIT_TEAM_SUCCESS:
      return {
        ...state,
        data: action.payload.Result,
        loading: false,
        error: false
      };
    case EDIT_TEAM.EDIT_TEAM_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case EDIT_TEAM.CLEAR_EDIT_TEAM:
      return {
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default EditTeamReducer;
