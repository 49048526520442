import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Add from '@material-ui/icons/Add';
import { HeaderWithButtons } from '../components-molecules/HeaderWithButtons';
import CollectionDashboardItem from '../components-molecules/CollectionDashboardItem';
import { colours } from '../constants/theme';
import { useAppWebSocket } from '../hooks/useAppWebSocket';
import { ButtonPrimary } from '../components-atoms';
import { useAppSelector } from '../hooks/store';
import { UserProfile } from '../redux/reducers/profile';

const useStyles = makeStyles(() => ({
  container: {},
  kpiContainer: {
    marginTop: 16
  },
  kpiSkeleton: {
    backgroundColor: colours.highEmphasis,
    color: colours.primary,
    padding: 16,
    borderRadius: 8,
    minHeight: 178
  },
  addNewBtn: {
    height: 36,
    width: 160
  }
}));

interface SocketMessage {
  Open: number;
  OpenPremium?: number;
  OutForDelivery: number;
  Delivered: number;
  Failed: number;
}

export function CollectionDashboard() {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const userData = useSelector((state: any) => state.login);
  const [userDetailedData] = useAppSelector((state) => [
    (state.profile as any).data as UserProfile
  ]);

  const { lastJsonMessage } = useAppWebSocket<SocketMessage | null>('/ws/dashboard', {
    queryParams: { t: userData.token }
  });

  if (!userDetailedData.IsCollectEnabled) {
    window.location.href = '/';
  }

  useEffect(() => {
    if (typeof lastJsonMessage?.Open === 'number') {
      setLoading(false);
    }
  }, [lastJsonMessage]);

  const handleClickNew = () => {
    window.location.href = `/new-delivery-order`;
  };

  const renderNewBtn = (style?: React.CSSProperties) => {
    return (
      <ButtonPrimary
        className={classes.addNewBtn}
        onClick={handleClickNew}
        startIcon={<Add />}
        style={style}>
        New Delivery
      </ButtonPrimary>
    );
  };

  const renderHeader = () => {
    return (
      <Box mb={4}>
        <HeaderWithButtons title="Delivery Dashboard">{renderNewBtn()}</HeaderWithButtons>
      </Box>
    );
  };

  return (
    <Container className={classes.container}>
      {renderHeader()}

      {loading ? (
        <Grid container spacing={2} className={classes.kpiContainer}>
          {[...Array(4)].map((_, i) => (
            <Grid key={i} item xs={6}>
              <div className={classes.kpiSkeleton} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2} className={classes.kpiContainer}>
          <Grid item xs={6}>
            <CollectionDashboardItem title="Open" count={lastJsonMessage?.Open || 0} />
          </Grid>
          <Grid item xs={6}>
            <CollectionDashboardItem
              title="Out for Delivery"
              count={lastJsonMessage?.OutForDelivery || 0}
            />
          </Grid>
          <Grid item xs={6}>
            <CollectionDashboardItem title="Delivered" count={lastJsonMessage?.Delivered || 0} />
          </Grid>
          <Grid item xs={6}>
            <CollectionDashboardItem title="Failed" count={lastJsonMessage?.Failed || 0} />
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
