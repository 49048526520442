/* eslint-disable react/jsx-no-bind */
import { Container, Grid, Typography, makeStyles, FormControlLabel } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import config from '../config';
import {
  ButtonSecondary,
  ButtonTextPrimary,
  ButtonWithLoading,
  ControlledInput,
  RoundedCheckbox,
  TextFieldInput
} from '../components-atoms';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { UserProfile } from '../redux/reducers/profile';
import { GeneralModal } from '../components-molecules';
import {
  PASSWORD_VALIDATION,
  PHONE_NUMBER_PATTERN,
  REQUIRED_VALIDATION
} from '../constants/validation';
import { ChangePassword, clearPasswordError } from '../redux/actions/changePassword';
import { EditUser } from '../redux/actions/editUser';
import { GetProfile } from '../redux/actions/profile';
import RightArrow from '../components-atoms/icons/rightArrow';

const useStyles = makeStyles(() => ({
  basicInformationContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    boxSizing: 'border-box',
    textAlign: 'left',
    marginTop: '24px',
    padding: '24px 16px 0',
    '@media (min-width: 900px)': {
      padding: '18px 24px 0',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '20px'
    }
  },
  infoTitle: {
    marginBottom: 20
  },
  infoTextContainer: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    '&:hover [data-arrow=arrowContainer]': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:active [data-arrow=arrowContainer]': {
      backgroundColor: 'rgba(0, 0, 0, 0.16)'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  infoTextContainerLastRow: {
    paddingTop: 15,
    paddingBottom: 15,
    alignItems: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  infoTextTitle: {
    minWidth: 164,
    '@media (max-width: 599px)': {
      marginBottom: 4
    }
  },
  infoTextButtonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (min-width: 600px)': {
      marginLeft: 16
    }
  },
  infoBodyContainer: {
    width: '100%',
    '@media (min-width: 600px)': {
      marginLeft: 16
    }
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 20,
    transition: 'transform 0.15s ease-in-out',
    marginLeft: 16
  }
}));

interface ChangePasswordForm {
  oldPassword: string;
  newPassword: string;
}
export function UserBasicInfo() {
  const classes = useStyles();
  const [data, passwordLoading, passwordError] = useAppSelector((state) => [
    (state.profile as any).data as UserProfile,
    (state.changePassword as any).loading,
    (state.changePassword as any).error
  ]);
  const [modal, setModal] = useState<'name' | 'phone' | 'password' | ''>('');
  const [user, setUser] = useState(data);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setUser(data);
  }, [data]);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const { control, reset, watch, setValue, handleSubmit, setError, clearErrors } =
    useForm<ChangePasswordForm>({ reValidateMode: 'onSubmit', mode: 'onSubmit' });

  const changePassword: SubmitHandler<ChangePasswordForm> = (formData) => {
    dispatch(
      ChangePassword(
        { NewPassword: formData.newPassword, OldPassword: formData.oldPassword },
        () => {
          dispatch(GetProfile());
          setModal('');
          reset();
          dispatch(clearPasswordError());
        }
      )
    );
  };

  const editUser = (key: string, value: string | { FirstName: string; LastName: string }) => {
    if (key === 'name') {
      const val = value as { FirstName: string; LastName: string };
      dispatch(
        EditUser({ FirstName: val.FirstName, LastName: val.LastName }, () => {
          dispatch(GetProfile());
          setModal('');
        })
      );
    } else {
      dispatch(
        EditUser({ PhoneNumber: value as string }, () => {
          dispatch(GetProfile());
          setModal('');
        })
      );
    }
  };
  useEffect(() => {
    if (passwordError) {
      setError('oldPassword', { message: passwordError, type: 'value' });
    } else {
      clearErrors('oldPassword');
    }
  }, [passwordError, setError, clearErrors]);

  return (
    <Container className={classes.basicInformationContainer}>
      <Grid container>
        <Grid container className={classes.infoTitle}>
          <Typography variant="h6" component="span">
            Basic information
          </Typography>
        </Grid>
        <Grid container className={classes.infoTextContainer} onClick={() => setModal('name')}>
          <Typography
            display="inline"
            variant="caption"
            component="span"
            color="textSecondary"
            data-automation="order-id-main"
            className={classes.infoTextTitle}>
            NAME
          </Typography>
          <Grid item className={classes.infoTextButtonContainer}>
            <Typography variant="body1" component="span" color="textPrimary">
              {data.FirstName} {data.LastName}
            </Typography>
            <div className={classes.arrowContainer} data-arrow="arrowContainer">
              <RightArrow onClick={() => setModal('name')} />
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.infoTextContainer} onClick={() => setModal('phone')}>
          <Typography
            display="inline"
            variant="caption"
            component="span"
            color="textSecondary"
            className={classes.infoTextTitle}>
            CONTACT PHONE
          </Typography>
          <Grid item className={classes.infoTextButtonContainer}>
            <Typography variant="body1" component="span" color="textPrimary">
              {data.PhoneNumber}
            </Typography>
            <div className={classes.arrowContainer} data-arrow="arrowContainer">
              <RightArrow onClick={() => setModal('phone')} />
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.infoTextContainer} style={{ cursor: 'default' }}>
          <Typography
            variant="caption"
            component="span"
            color="textSecondary"
            className={classes.infoTextTitle}>
            EMAIL
          </Typography>
          <Grid item className={classes.infoBodyContainer}>
            <Grid item xs={12} container direction="column">
              <Typography
                variant="body1"
                component="span"
                color="textPrimary"
                style={{ wordBreak: 'break-all' }}>
                {data.Email}
              </Typography>
              <Typography
                variant="body2"
                component="span"
                color="textSecondary"
                style={{ paddingTop: '8px' }}>
                Account email can not be changed
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.infoTextContainer}>
          <Typography
            variant="caption"
            component="span"
            color="textSecondary"
            className={classes.infoTextTitle}>
            PASSWORD
          </Typography>
          <Grid item className={classes.infoTextButtonContainer}>
            <Typography variant="body1" component="span" color="textPrimary">
              *******
            </Typography>
            <ButtonTextPrimary onClick={() => setModal('password')}>
              Change Password
            </ButtonTextPrimary>
          </Grid>
        </Grid>
      </Grid>
      <GeneralModal
        visible={modal === 'name'}
        onClose={() => {
          setModal('');
          setUser((prev) => ({ ...prev, FirstName: data.FirstName, LastName: data.LastName }));
        }}
        title="Edit user name">
        <Grid container item xs={12}>
          <form style={{ width: '100%' }}>
            <Grid xs={12} item>
              <TextFieldInput
                value={user.FirstName}
                name="FirstName"
                onChange={onChange}
                label="First Name"
              />
            </Grid>
            <Grid xs={12} item>
              <TextFieldInput
                value={user.LastName}
                name="LastName"
                onChange={onChange}
                label="Last Name"
              />
            </Grid>
            <Grid
              xs={12}
              container
              item
              justifyContent="flex-end"
              style={{ paddingTop: '24px', columnGap: '8px' }}>
              <ButtonSecondary
                onClick={() => {
                  setModal('');
                  setUser((prev) => ({
                    ...prev,
                    FirstName: data.FirstName,
                    LastName: data.LastName
                  }));
                }}
                style={{ height: '48px' }}>
                Cancel
              </ButtonSecondary>
              <ButtonWithLoading
                title="Submit"
                type="button"
                onClick={() =>
                  editUser('name', { FirstName: user.FirstName, LastName: user.LastName })
                }
                disabled={!user.FirstName || !user.LastName}
                loading={false}
              />
            </Grid>
          </form>
        </Grid>
      </GeneralModal>
      <GeneralModal
        visible={modal === 'phone'}
        onClose={() => {
          setModal('');
          setUser((prev) => ({ ...prev, PhoneNumber: data.PhoneNumber }));
        }}
        title="Edit user contact phone">
        <Grid container item xs={12}>
          <form style={{ width: '100%' }}>
            <Grid xs={12} item>
              <TextFieldInput
                error={!PHONE_NUMBER_PATTERN.test(user.PhoneNumber)}
                helperText={
                  !PHONE_NUMBER_PATTERN.test(user.PhoneNumber) ? 'Enter a valid phone number' : ''
                }
                value={user.PhoneNumber}
                name="PhoneNumber"
                onChange={onChange}
                label="Contact phone"
              />
            </Grid>
            <Grid
              xs={12}
              container
              item
              justifyContent="flex-end"
              style={{ paddingTop: '24px', columnGap: '8px' }}>
              <ButtonSecondary
                onClick={() => {
                  setModal('');
                  setUser((prev) => ({
                    ...prev,
                    PhoneNumber: data.PhoneNumber
                  }));
                }}
                style={{ height: '48px' }}>
                Cancel
              </ButtonSecondary>
              <ButtonWithLoading
                title="Submit"
                type="button"
                onClick={() => editUser('PhoneNumber', user.PhoneNumber)}
                disabled={!user.PhoneNumber || !PHONE_NUMBER_PATTERN.test(user.PhoneNumber)}
                loading={false}
              />
            </Grid>
          </form>
        </Grid>
      </GeneralModal>
      <GeneralModal
        visible={modal === 'password'}
        onClose={() => {
          setModal('');
          reset();
          dispatch(clearPasswordError());
        }}
        title="Change Password"
        description="Choose a new, secure password. Password should contain at least 8 characters, at least one number and at least one uppercase letter.">
        <Grid container item xs={12}>
          <form onSubmit={handleSubmit(changePassword)} style={{ width: '100%' }}>
            <Grid xs={12} item>
              <ControlledInput
                control={control}
                type={showPassword ? 'text' : 'password'}
                value={watch('oldPassword')}
                onChange={(e) => {
                  setValue('oldPassword', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                  });
                  if (passwordError) {
                    dispatch(clearPasswordError());
                  }
                }}
                rules={{ required: REQUIRED_VALIDATION }}
                name="oldPassword"
                label="Enter old password"
              />
            </Grid>
            <Grid xs={12} item>
              <ControlledInput
                control={control}
                type={showPassword ? 'text' : 'password'}
                rules={{ required: REQUIRED_VALIDATION, pattern: PASSWORD_VALIDATION }}
                name="newPassword"
                label="Enter new password"
              />
            </Grid>
            <Grid item container xs={12}>
              <FormControlLabel
                control={
                  <RoundedCheckbox
                    color="primary"
                    checked={showPassword}
                    onChange={(e) => setShowPassword(e.target.checked)}
                  />
                }
                label="Show password"
              />
            </Grid>
            <Grid xs={12} container item justifyContent="flex-end" style={{ columnGap: '8px' }}>
              <ButtonSecondary
                onClick={() => {
                  setModal('');
                  reset();
                  dispatch(clearPasswordError());
                }}
                style={{ height: '48px' }}>
                Cancel
              </ButtonSecondary>
              <ButtonWithLoading
                title="Submit"
                type="submit"
                disabled={passwordLoading}
                loading={passwordLoading}
              />
            </Grid>
          </form>
        </Grid>
      </GeneralModal>
    </Container>
  );
}
