/* eslint-disable react/destructuring-assignment */
import VALIDATE_TOKEN from '../symbols/validateToken';

export interface ValidateTokenResponse {
  IsRegistered: boolean;
  IsPasswordSet: boolean;
  UserInfo: {
    CustomerId: string | null;
    PhoneNumber: string | null;
    FirstName: string;
    LastName: string;
    Email: string;
    ProUserRoleId: string;
    ApprovalRequired: boolean;
  };
}
interface State {
  data: ValidateTokenResponse | null;
  loading: boolean;
  error: any;
}
const initialState: State = {
  data: null,
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function ValidateTokenReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case VALIDATE_TOKEN.VALIDATE_TOKEN:
      return { ...state, loading: true, error: false };
    case VALIDATE_TOKEN.VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        data: action.payload.Result,
        loading: false,
        error: false
      };
    case VALIDATE_TOKEN.VALIDATE_TOKEN_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case VALIDATE_TOKEN.CLEAR_VALIDATE_TOKEN:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default ValidateTokenReducer;
