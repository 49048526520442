/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Grid, Typography, makeStyles } from '@material-ui/core';

import React from 'react';
import { ITheme } from '../constants/theme';
import arrowRight from '../assets/icons/arrowRight.svg';

interface PropTypesI {
  name: string;
  value: any;
  onClick?: Function;
  children?: React.ReactNode;
  clickable?: boolean;
  hasBorder?: boolean;
}

const useStyles = makeStyles((theme: ITheme) => ({
  basicInformationContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '18px 24px',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxSizing: 'border-box',
    borderRadius: '20px'
  },
  infoTitle: {
    marginBottom: 20
  },
  infoTextContainer: {
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover [data-arrow=arrowContainer]': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:active [data-arrow=arrowContainer]': {
      backgroundColor: 'rgba(0, 0, 0, 0.16)'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  infoTextContainerNoHover: {
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover [data-arrow=arrowContainer]': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:active [data-arrow=arrowContainer]': {
      backgroundColor: 'rgba(0, 0, 0, 0.16)'
    }
  },
  border: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
  },
  infoTextContainerLastRow: {
    paddingTop: 15,
    paddingBottom: 15
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 20,
    transition: 'transform 0.15s ease-in-out'
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  colOffset: {
    marginLeft: -17.5,
    marginRight: 17.5
  }
}));

function ArrowArea(props: { children?: React.ReactNode; onClick?: any; noTick?: boolean }) {
  const classes = useStyles();
  const { children, onClick, noTick } = props;
  return (
    <Grid item container justifyContent="flex-end" xs={2}>
      {children}
      {!noTick && (
        <div className={classes.arrowContainer} data-arrow="arrowContainer">
          <img onClick={onClick} src={arrowRight} alt="arrow" />
        </div>
      )}
    </Grid>
  );
}

export function InfoLine({
  name,
  value,
  onClick,
  children,
  clickable,
  hasBorder = true
}: PropTypesI) {
  const classes = useStyles();

  return (
    <Grid
      onClick={() => (onClick ? onClick() : {})}
      container
      className={`
      ${clickable ? classes.infoTextContainer : classes.infoTextContainerNoHover} ${
        hasBorder ? classes.border : ''
      }`}
      // className={
      //   props?.isLastRow
      //     ? classes.infoTextContainerLastRow
      //     : classes.infoTextContainer
      // }
    >
      {name && name.trim() !== '' && (
        <Grid item xs={3}>
          <Typography display="inline" variant="caption" component="span" color="textSecondary">
            {name.toUpperCase()}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        xs={name && name.trim() !== '' ? 7 : 10}
        className={name && name.trim() !== '' ? classes.colOffset : ''}>
        {clickable ? (
          <p className={classes.clickable}>
            <Typography variant="body1" component="span" color="textPrimary">
              {value}
            </Typography>
          </p>
        ) : (
          <p>
            <Typography variant="body1" component="span" color="textPrimary">
              {value}
            </Typography>
          </p>
        )}
      </Grid>
      {children}
    </Grid>
  );
}
InfoLine.ArrowArea = ArrowArea;
