const GET_COLLECTION_TIME = 'GET_COLLECTION_TIME';
const GET_COLLECTION_TIME_SUCCESS = 'GET_COLLECTION_TIME_SUCCESS';
const GET_COLLECTION_TIME_FAILURE = 'GET_COLLECTION_TIME_FAILURE';
const CLEAR_GET_COLLECTION_TIME = 'CLEAR_GET_COLLECTION_TIME';
const GetCollectionTimeSymbols = {
  GET_COLLECTION_TIME_SUCCESS,
  GET_COLLECTION_TIME_FAILURE,
  GET_COLLECTION_TIME,
  CLEAR_GET_COLLECTION_TIME
};
export default GetCollectionTimeSymbols;
