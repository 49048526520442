import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';
import { Theme, ThemeOptions, createTheme } from '@material-ui/core/styles';

interface IPaletteColor extends PaletteColor {
  92: string;
  76: string;
  50: string;
  32: string;
  24: string;
  16: string;
  12: string;
  8: string;
}
interface IPalette extends Palette {
  primary: IPaletteColor;
  secondary: IPaletteColor;
  error: IPaletteColor;
  warning: IPaletteColor;
  success: IPaletteColor;
}
export interface ITheme extends Theme {
  palette: IPalette;
}
interface IThemeOptions extends ThemeOptions {
  palette: IPalette;
}

export const colours = {
  primary: '#FF0EAF',
  highEmphasis: 'rgb(20, 20, 20)', // #141414,
  highEmphasis04: 'rgba(20, 20, 20, 0.04)',
  highEmphasis16: 'rgba(20, 20, 20, 0.16)',
  highEmphasis54: 'rgba(20, 20, 20, 0.54)',
  highEmpasis008: 'rgb(39, 39, 39)',
  highEmpasis24: 'rgb(76, 76, 76)',
  lightEmphasis: 'white',
  light12: 'rgba(255, 255, 255, 0.12)',
  dark12: 'rgba(0, 0, 0, 0.12)',
  light190: 'rgb(190, 190, 190)',
  light109: 'rgb(109, 109, 109)',
  dark08: 'rgba(0, 0, 0, 0.08)',
  lightGreen: 'rgb(148, 203, 164)',
  lightRed: '#cb9494'
};

export const theme = createTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontFamily: "'Poppins', sans-serif",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.25px'
      }
    }
  },
  palette: {
    primary: {
      main: colours.primary,
      92: 'rgba(158, 0, 93, 0.92)',
      76: 'rgba(158, 0, 93, 0.76)',
      32: 'rgba(255, 14, 175, 0.32)',
      16: 'rgba(255, 14, 175, 0.12)'
    },
    secondary: {
      main: 'rgba(158, 0, 93, 0.08)',
      16: 'rgba(158, 0, 93, 0.16)',
      24: 'rgba(158, 0, 93, 0.24)',
      32: 'rgba(158, 0, 93, 0.32)'
    },
    success: {
      main: 'rgba(58, 131, 86, 1)',
      92: 'rgba(58, 131, 86, 0.92)',
      76: 'rgba(58, 131, 86, 0.76)',
      16: 'rgba(58, 131, 86, 0.16)'
    },
    error: {
      main: 'rgba(229, 76, 53, 1)',
      8: 'rgba(229, 76, 53, 0.08)',
      16: 'rgba(229, 76, 53, 0.16)',
      24: 'rgba(229, 76, 53, 0.24)',
      32: 'rgba(229, 76, 53, 0.32)'
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.38)',
      disabledBackground: 'rgba(0, 0, 0, 0.04)'
    },
    text: {
      primary: colours.highEmphasis,
      secondary: colours.highEmphasis,
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(255, 255, 255, 1)'
    },
    background: {
      default: 'rgba(255, 255, 255, 1)'
    },
    warning: {
      main: 'rgba(237, 108, 2, 1)',
      8: 'rgba(237, 108, 2, 0.08)',
      12: 'rgba(237, 108, 2, 0.12)',
      16: 'rgba(237, 108, 2, 0.16)',
      50: 'rgba(237, 108, 2, 0.50)'
    }
  },
  typography: {
    h1: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '96px',
      lineHeight: '104px',
      letterSpacing: '-1.5px'
    },
    h2: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '60px',
      lineHeight: '68px',
      letterSpacing: '-0.5px'
    },
    h3: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '48px',
      lineHeight: '56px'
    },
    h4: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '34px',
      lineHeight: '36px',
      letterSpacing: '0.25px'
    },
    h5: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '28px',
      lineHeight: '32px',
      textTransform: 'uppercase'
    },
    h6: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '22px',
      lineHeight: '26px',
      letterSpacing: '0.15px',
      textTransform: 'uppercase'
    },
    subtitle1: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '22px',
      lineHeight: '26px',
      letterSpacing: '0.15px'
    },
    subtitle2: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0.15px'
    },
    body1: {
      fontFamily: "'Poppins', sans-serif",
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px'
    },
    body2: {
      fontFamily: "'Poppins', sans-serif",
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px'
    },
    caption: {
      fontFamily: "'Poppins', sans-serif",
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px'
    },
    button: {
      fontFamily: "'Poppins', sans-serif",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      textTransform: 'none'
    },
    overline: {
      fontFamily: "'Poppins', sans-serif",
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '1.5px'
    }
  }
} as IThemeOptions);
