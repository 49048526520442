import RESET_PASSWORD from '../symbols/resetPassword';
import { HttpRequestPost } from '../../service';

const resetPasswordAction = () => ({
  type: RESET_PASSWORD.RESET_PASSWORD
});
const resetPasswordSuccessAction = (data: any) => {
  return {
    type: RESET_PASSWORD.RESET_PASSWORD_SUCCESS,
    payload: data.Result
  };
};
const resetPasswordFailureAction = (data: any) => ({
  type: RESET_PASSWORD.RESET_PASSWORD_FAILURE,
  payload: data
});
const clearResetPasswordAction = () => ({
  type: RESET_PASSWORD.CLEAR_RESET_PASSWORD
});
interface ResetPasswordRequestModel {
  Token: string;
  Password: string;
}
export function ResetPasswordRequest(data: ResetPasswordRequestModel, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(resetPasswordAction());
    HttpRequestPost(
      '/pro/forgot-password/reset',
      dispatch,
      resetPasswordSuccessAction,
      resetPasswordFailureAction,
      true,
      data,
      callback
    );
  };
}

export function clearResetPassword() {
  return (dispatch: any) => {
    dispatch(clearResetPasswordAction());
  };
}
