/* eslint-disable react/destructuring-assignment */
import ORDER_DETAIL from '../symbols/orderDetail';

export interface Product {
  ProductImageUrl: string;
  ProductTitle: string;
  OrderLineQuantity: number;
  OrderLineAmountPerUnit: number;
  SubTotal: number;
  ProductOptionName1: string;
  ProductOptionName2: string;
  ProductOptionName3: string;
  OrderLineVariantOption1: string;
  OrderLineVariantOption2: string;
  OrderLineVariantOption3: string;
  OrderLineCancelled: string;
  OrderLineColorNote: string;
  OrderLineOriginalQuantity: number;
  OrderLineQuantityUpdated: any;
  OrderLineVariantImageUrl: string;
  CancellationReason: string;
  RejectionReason: string;
  CancelledItemsTotalAmount: number;
}

export interface DeliveryTotalResult {
  OriginalTotal: number;
  Total: number;
  CancelledAmount: number;
}
export interface Delivery {
  DeliveryId: string;
  MerchantName: string;
  ShopName: string;
  ShopTelNo: string;
  MerchantTelNo: string;
  MerchantEmail: string;
  TimeSlotFrom: string;
  TimeSlotTo: string;
  DeliveryStatus: string;
  Products: Product[];
  DeliveryTotalResult: DeliveryTotalResult;
}

export interface OrderTotalResult {
  DeliveryFeeTotal: number;
  DiscountAmount: number;
  DiscountPercentage: number;
  DiscountTotal: number;
  OriginalDeliveryFeeTotal: number;
  OriginalDiscountTotal: number;
  OriginalTotal: number;
  OriginalVat: number;
  TaxRate: number;
  Total: number;
  TotalRefundedAmount: number;
  Vat: number;
}

export interface CollectBookingFee {
  CreditUsage?: number;
  PayAsYouGoPrice?: number;
}

export interface CollectPricing {
  Delivery: number;
  BookingFee?: CollectBookingFee;
  PremiumFee?: number;
  Vat: number;
  Total: number;
  TaxRate: number;
}

export type ICourierServiceType = 'regular' | 'direct';
export interface CourierPriceResult {
  Min: CollectPricing;
  Max: CollectPricing;
}

export interface OrderFileModel {
  FileName: string;
  Url: string;
}

export interface OrderDetail {
  OrderId: string;
  OrderNumber: number;
  OrderPlaced: Date;
  OrderStatus: string;
  Amount: number;
  DeliveryAddressName: string;
  DeliveryAddress: string;
  DeliveryAddressInstructions: string;
  DeliveryAddressNameOnDoor: string;
  PurchasedBy: string;
  OrderJobNo: string;
  OrderPaymentIntentClientSecret: string;
  DeliveryInvoiceFileUrl: OrderFileModel[];
  OrderInvoiceFileUrl: OrderFileModel[];
  Deliveries: Delivery[];
  OrderTotalResult: OrderTotalResult;
}
interface State {
  data: OrderDetail[];
  loading: boolean;
  error: string;
}

const initialState: State = {
  loading: false,
  error: '',
  data: []
};

// eslint-disable-next-line default-param-last
function OrderDetailReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case ORDER_DETAIL.ORDER_DETAIL:
      return { ...state, loading: true, error: false };
    case ORDER_DETAIL.ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.payload.Result as OrderDetail[],
        loading: false,
        error: false
      };
    case ORDER_DETAIL.ORDER_DETAIL_FAILURE:
      return {
        ...state,
        token: null,
        loading: false,
        error: action.payload
      };
    case ORDER_DETAIL.CLEAR_ORDER_DETAIL:
      return {
        ...state,
        token: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default OrderDetailReducer;
