import { Box, CssBaseline, Container, makeStyles } from '@material-ui/core';
import { HeaderWithButtons } from '../components-molecules/HeaderWithButtons';
import { OrganisationBasicInfo, OrganisationPaymentMethod } from '../components-organisms';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '@media (min-width: 900px)': {
      maxWidth: '840px'
    }
  }
}));

export function Organisation() {
  const classes = useStyles();
  return (
    <Box>
      <CssBaseline />
      <Container className={classes.root}>
        <CssBaseline />
        <HeaderWithButtons noButton title="Organisation Profile" />
        <OrganisationBasicInfo />
        <OrganisationPaymentMethod />
      </Container>
    </Box>
  );
}
