const ORDERS = 'ORDERS';
const ORDERS_SUCCESS = 'ORDERS_SUCCESS';
const ORDERS_FAILURE = 'ORDERS_FAILURE';
const CLEAR_ORDERS = 'CLEAR_ORDERS';

const OrdersSymbols = {
  ORDERS_SUCCESS,
  ORDERS_FAILURE,
  ORDERS,
  CLEAR_ORDERS
};

export default OrdersSymbols;
