import AddressesIcon from '../components-atoms/icons/addresses';
import InvoiceIcon from '../components-atoms/icons/invoices';
import OrdersIcon from '../components-atoms/icons/orders';
import OrganisationIcon from '../components-atoms/icons/organisation';
import TeamIcon from '../components-atoms/icons/team';
import UserIcon from '../components-atoms/icons/user';
import CollectionOrdersIcon from '../components-atoms/icons/collectionOrders';
import CollectionDashboardIcon from '../components-atoms/icons/collectionDashboard';
// interface DrawerIcon {
//  active: string;
//  passive: string;
// }
export interface DrawerItem {
  sidebar: string;
  route: string;
  secondRoutes?: string[];
  icon: React.FC;
}

// TODO: Add collection order with if condition.
// Replace icons with MUI icons

const DRAWER_ITEMS: DrawerItem[] = [
  {
    sidebar: 'Orders',
    route: '/orders',
    secondRoutes: ['order'],
    icon: OrdersIcon
  },
  {
    sidebar: 'Invoices',
    route: '/invoices',
    icon: InvoiceIcon
  },
  {
    sidebar: 'Team Members',
    route: '/team',
    icon: TeamIcon
  },
  {
    sidebar: 'Delivery Addresses',
    route: '/deliveryAddresses',
    icon: AddressesIcon
  },
  {
    sidebar: 'Organisation Profile',
    route: '/organisation',
    icon: OrganisationIcon
  },
  {
    sidebar: 'User Settings',
    route: '/user',
    icon: UserIcon
  }
];

export const DELIVERY_ORDERS = {
  sidebar: 'Delivery Orders',
  route: '/delivery-orders',
  secondRoutes: ['delivery-order', 'new-delivery-order'],
  icon: CollectionOrdersIcon
};

export const DELIVERY_DASHBOARD = {
  sidebar: 'Delivery Dashboard',
  route: '/delivery-dashboard',
  icon: CollectionDashboardIcon
};

export default DRAWER_ITEMS;
