const COLLECTION_ORDER_DETAIL = 'ORDER_DETAIL';
const COLLECTION_ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS';
const COLLECTION_ORDER_DETAIL_FAILURE = 'ORDER_DETAIL_FAILURE';

const CollectionOrderDetailSymbols = {
  COLLECTION_ORDER_DETAIL_SUCCESS,
  COLLECTION_ORDER_DETAIL_FAILURE,
  COLLECTION_ORDER_DETAIL
};

export default CollectionOrderDetailSymbols;
