const USER_ROLE = 'USER_ROLE';
const USER_ROLE_SUCCESS = 'USER_ROLE_SUCCESS';
const USER_ROLE_FAILURE = 'USER_ROLE_FAILURE';
const CLEAR_USER_ROLE = 'CLEAR_USER_ROLE';

const UserRoleSymbols = {
  USER_ROLE_SUCCESS,
  USER_ROLE_FAILURE,
  USER_ROLE,
  CLEAR_USER_ROLE
};

export default UserRoleSymbols;
