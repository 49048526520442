import { Container, Grid, IconButton, makeStyles, Modal, Typography } from '@material-ui/core';
import { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import BackArrow from '../components-atoms/icons/backArrow';
import { theme } from '../constants/theme';

interface IProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  isOpen: boolean;
  title: string;
  description?: string;
  onClose: () => void;
  onBack?: () => void;
  buttons?: ReactNode;
  fitContent?: boolean;
}
const useStyles = makeStyles({
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '24px',
    paddingTop: '24px',
    display: 'flex',
    '@media(max-width:500px)': {
      paddingBottom: 0,
      paddingTop: 0
    }
  },
  container: {
    maxWidth: '480px',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: '16px',
    '@media(max-width:500px)': {
      borderRadius: 0
    }
  },
  fitContent: {
    height: 'fit-content !important',
    '@media(max-width:500px)': {
      height: '100% !important'
    }
  },
  contentContainer: {
    flexGrow: 1,
    overflow: 'hidden auto'
  },
  titleContainer: { columnGap: '16px' },
  buttonContainer: { columnGap: '8px' }
});
export function ResponsiveModal({
  children,
  isOpen,
  title,
  description,
  onClose,
  onBack,
  buttons,
  fitContent
}: IProps) {
  const classes = useStyles();
  return (
    <Modal open={isOpen} className={classes.modal} onClose={onClose}>
      <Container className={`${classes.container} ${fitContent ? classes.fitContent : ''}`}>
        <Grid item container direction="row" alignItems="center" className={classes.titleContainer}>
          {onBack ? (
            <IconButton
              onClick={onBack}
              style={{ backgroundColor: theme.palette.secondary.main }}
              size="small">
              <BackArrow />
            </IconButton>
          ) : null}
          <Typography variant="h6" component="h6" color="textPrimary">
            {title}
          </Typography>
        </Grid>
        <Grid className={classes.contentContainer}>
          <Grid item>
            <Typography
              style={{ whiteSpace: 'pre-line' }}
              variant="body2"
              component="span"
              color="textPrimary">
              {description}
            </Typography>
          </Grid>
          {children}
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          direction="row"
          className={classes.buttonContainer}>
          {buttons}
        </Grid>
      </Container>
    </Modal>
  );
}
