import REGISTER from '../symbols/register';
import { HttpRequestPost } from '../../service';

const registerAction = () => ({
  type: REGISTER.REGISTER
});
const registerSuccessAction = (data: any) => {
  return {
    type: REGISTER.REGISTER_SUCCESS,
    payload: data
  };
};
const registerFailureAction = (data: any) => ({
  type: REGISTER.REGISTER_FAILURE,
  payload: data
});
const clearRegisterAction = () => ({
  type: REGISTER.CLEAR_REGISTER
});
const clearRegisterErrorAction = () => ({
  type: REGISTER.CLEAR_REGISTER_ERROR
});

interface RegisterRequestModel {
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  Password: string;
  TeamName: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  PostalCode: string;
  City: string;
  County: string;
  VATNumber: string;
  AddressName: string;
  StripePaymentIdentifier: string;
  StripeCustomerId: string;
  AcceptMarketingEmails: boolean;
  ApprovalThreshold?: number;
  ProUserRoleId: string;
  CustomerId: string;
  IsRegistered: boolean;
  IsPasswordSet: boolean;
  ProInvitationToken: string;
}
// eslint-disable-next-line no-unused-vars
export function Register(data: RegisterRequestModel, callback: (resp?: any) => void) {
  return (dispatch: any) => {
    dispatch(registerAction());
    HttpRequestPost(
      '/pro/register', // This should be /shop/register
      dispatch,
      registerSuccessAction,
      registerFailureAction,
      false,
      data,
      callback
    );
  };
}

export function clearRegisterError() {
  return (dispatch: any) => {
    dispatch(clearRegisterErrorAction());
  };
}

export function clearRegister() {
  return (dispatch: any) => {
    dispatch(clearRegisterAction());
  };
}
