import CHECK_FORGOT_PASSWORD_TOKEN from '../symbols/checkForgotPasswordToken';
import { HttpRequestPost } from '../../service';

const checkForgotPasswordTokenAction = () => ({
  type: CHECK_FORGOT_PASSWORD_TOKEN.CHECK_FORGOT_PASSWORD_TOKEN
});
const checkForgotPasswordTokenSuccessAction = (data: any) => {
  return {
    type: CHECK_FORGOT_PASSWORD_TOKEN.CHECK_FORGOT_PASSWORD_TOKEN_SUCCESS,
    payload: data.Result
  };
};
const checkForgotPasswordTokenFailureAction = (data: any) => ({
  type: CHECK_FORGOT_PASSWORD_TOKEN.CHECK_FORGOT_PASSWORD_TOKEN_FAILURE,
  payload: data
});
const clearCheckForgotPasswordTokenAction = () => ({
  type: CHECK_FORGOT_PASSWORD_TOKEN.CLEAR_CHECK_FORGOT_PASSWORD_TOKEN
});
interface CheckForgotPasswordTokenRequestModel {
  Token: string;
}
export function CheckForgotPasswordToken(
  data: CheckForgotPasswordTokenRequestModel,
  callback?: () => void
) {
  return (dispatch: any) => {
    dispatch(checkForgotPasswordTokenAction());
    HttpRequestPost(
      '/pro/forgot-password/check-token',
      dispatch,
      checkForgotPasswordTokenSuccessAction,
      checkForgotPasswordTokenFailureAction,
      true,
      data,
      callback
    );
  };
}

export function clearCheckForgotPasswordToken() {
  return (dispatch: any) => {
    dispatch(clearCheckForgotPasswordTokenAction());
  };
}
