import { Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { colours } from '../constants/theme';

const AntTabs = withStyles({
  root: {},
  indicator: {
    backgroundColor: colours.primary
  }
})(Tabs);

export function CustomTabs(props: any) {
  const { value, onChange, children } = props;
  return (
    <AntTabs style={{ display: 'flex', flexDirection: 'column' }} value={value} onChange={onChange}>
      {children}
    </AntTabs>
  );
}
