import { makeStyles, Typography, Button, ButtonProps } from '@material-ui/core';
import { ITheme } from '../constants/theme';
import { textButtonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles((theme: ITheme) => ({
  button: {
    minWidth: 64,
    height: 36,
    borderRadius: 8,
    padding: '6px 8px',
    color: theme.palette.primary.main,
    ...textButtonHoverAndActive
  },
  disabled: {
    minWidth: 64,
    height: 36,
    borderRadius: 8,
    padding: '6px 8px'
  }
}));
export function ButtonTextPrimary({ onClick, startIcon, disabled, ...rest }: ButtonProps) {
  const classes = useStyles();

  return (
    <Button disabled={disabled} className={classes.button} onClick={onClick} {...rest}>
      <Typography variant="button" component="p" color="inherit">
        {rest.children}
      </Typography>
    </Button>
  );
}
