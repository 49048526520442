/* eslint-disable react/function-component-definition */
import { Stamp } from '../components-atoms';

export type CollectOrderStatus =
  | 'Delivered'
  | 'Cancelled'
  | 'Failed'
  | 'Queued'
  | 'AtDropOffLocation'
  | 'AtPickUpLocation'
  | 'OutForDelivery'
  | 'Assigned'
  | 'Placed'
  | 'OpenPremium';

export const CollectOrderStamp = ({ status }: { status: CollectOrderStatus }) => {
  switch (status) {
    case 'Delivered':
      return <Stamp type="success" text="Delivered" />;

    case 'Cancelled':
      return <Stamp type="error" text="Cancelled Delivery" />;

    case 'Failed':
      return <Stamp type="error" text="Failed Delivery" />;

    case 'Queued':
      return <Stamp type="warning" text="Queued" />;

    case 'AtDropOffLocation':
      return <Stamp type="warning" text="At The Drop Off Location" />;

    case 'AtPickUpLocation':
      return <Stamp type="warning" text="At The Pick Up Location" />;

    case 'OutForDelivery':
      return <Stamp type="warning" text="Out for Delivery" />;

    case 'Assigned':
      return <Stamp type="warning" text="Assigned" />;

    case 'Placed':
      return <Stamp type="warning" text="Placed" />;

    case 'OpenPremium':
      return <Stamp type="warning" text="Open" />;

    default:
      return <div />;
  }
};
