import { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import './api';
import './App.css';
import clsx from 'clsx';
import {
  Box,
  Container,
  CssBaseline,
  Drawer,
  ThemeProvider,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Login from './components-pages/Login';
import { ITheme, colours, theme } from './constants/theme';
import { useAppDispatch, useAppSelector } from './hooks/store';
import { Header } from './components-molecules';
import { AppMenu } from './components-organisms';
import { Team } from './components-pages/Team';
import { Setup } from './components-pages/Setup';
import { Orders } from './components-pages/Orders';
import { Order } from './components-pages/Order';
import { Invoices } from './components-pages/Invoices';
import { Organisation } from './components-pages/Organisation';
import { User } from './components-pages/User';
import { DeliveryAddresses } from './components-pages/DeliveryAddresses';
import { ForgotPassword } from './components-pages/ForgotPassword';
import { ResetPassword } from './components-pages/ResetPassword';
import { EnvironmentRibbon } from './components-atoms/EnvironmentRibbon';
import ScrollToTop from './components-atoms/ScrollToTop';
import { CollectionOrders } from './components-pages/CollectionOrders';
import { CollectionOrder } from './components-pages/CollectionOrder';
import { NewCollectionOrder } from './components-pages/NewCollectionOrder';
import { GetProfile } from './redux/actions/profile';
import { CollectionDashboard } from './components-pages/CollectionDashboard';
import { UserProfile } from './redux/reducers/profile';
import CollectionOrderHubspotWidget from './components-molecules/CollectionOrderHubspotWidget';

const sidenavWidth = 256;
const headerHeight = 64;
const useStyles = makeStyles((myTheme: ITheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    '@media (min-width: 1280px)': {
      marginLeft: sidenavWidth
    }
  },
  // Main app container
  appContainer: {
    display: 'flex',
    flex: 1,
    height: 'fit-content',
    marginTop: headerHeight
  },
  // Sidenav
  drawerPaper: {
    width: sidenavWidth,
    height: `calc(100% - ${headerHeight}px)`,
    marginTop: headerHeight,
    paddingTop: myTheme.spacing(2),
    paddingBottom: myTheme.spacing(2),
    whiteSpace: 'nowrap',
    borderRight: 'none',
    background: colours.highEmphasis
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: '#fff'
  },
  container: {
    paddingTop: myTheme.spacing(37 / 8),
    paddingBottom: myTheme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
    '@media (min-width: 900px)': {
      paddingLeft: 30,
      paddingRight: 30
    }
  }
}));

function RedirectToOrders({ isCollectEnabled }: any) {
  if (isCollectEnabled) {
    return <Navigate replace to={{ pathname: '/delivery-dashboard' }} />;
  }
  return <Navigate replace to={{ pathname: '/orders' }} />;
}

function App() {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.login.token);
  const [userDetailedData] = useAppSelector((state) => [
    (state.profile as any).data as UserProfile
  ]);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const laptopView = useMediaQuery('(min-width: 1280px)', { noSsr: true });

  useEffect(() => {
    if (token) {
      dispatch(GetProfile());
    }
  }, [dispatch, token]);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <EnvironmentRibbon />
        <Router>
          <ScrollToTop />
          {token ? (
            <Box className={clsx('App', classes.root)}>
              <Header isLoggedIn handleMenuClick={handleMenuClick} />
              <Box className={classes.appContainer}>
                {laptopView ? (
                  <Drawer
                    variant="permanent"
                    classes={{
                      paper: classes.drawerPaper
                    }}>
                    <AppMenu />
                  </Drawer>
                ) : (
                  <Drawer
                    variant="temporary"
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    classes={{
                      paper: classes.drawerPaper
                    }}>
                    <AppMenu />
                  </Drawer>
                )}
                <main className={classes.content}>
                  <Container maxWidth="lg" className={classes.container}>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <RedirectToOrders isCollectEnabled={userDetailedData.IsCollectEnabled} />
                        }
                      />
                      <Route path="/delivery-dashboard" element={<CollectionDashboard />} />
                      <Route path="/orders" element={<Orders />} />
                      <Route path="/delivery-orders" element={<CollectionOrders />} />
                      <Route path="/team" element={<Team />} />
                      <Route path="/order/:id" element={<Order />} />
                      <Route path="/delivery-order/:id" element={<CollectionOrder />} />
                      <Route path="/new-delivery-order" element={<NewCollectionOrder />} />
                      <Route path="/invoices" element={<Invoices />} />
                      <Route path="/organisation" element={<Organisation />} />
                      <Route path="/deliveryAddresses" element={<DeliveryAddresses />} />
                      <Route path="/user" element={<User />} />
                      <Route path="/login" element={<Navigate to="/orders" />} />
                      <Route
                        index
                        element={
                          <RedirectToOrders isCollectEnabled={userDetailedData.IsCollectEnabled} />
                        }
                      />
                    </Routes>
                  </Container>
                </main>
              </Box>
            </Box>
          ) : (
            <Routes>
              <Route path="/" element={<Navigate to="login" replace />} />
              <Route path="/setup" element={<Setup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/resetPassword/:token" element={<ResetPassword />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route element={<Navigate to="login" replace />} />
            </Routes>
          )}
        </Router>

        {token && token.length > 0 ? <CollectionOrderHubspotWidget /> : null}
      </ThemeProvider>
    </>
  );
}

export default App;
