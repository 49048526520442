import { Box, CssBaseline, Container, makeStyles } from '@material-ui/core';
import { HeaderWithButtons } from '../components-molecules/HeaderWithButtons';
import { UserBasicInfo } from '../components-organisms';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '@media (min-width: 900px)': {
      maxWidth: '840px'
    }
  }
}));
export function User() {
  const classes = useStyles();

  return (
    <Box>
      <CssBaseline />
      <Container className={classes.root}>
        <CssBaseline />
        <HeaderWithButtons noButton title="User Settings" />
        <UserBasicInfo />
      </Container>
    </Box>
  );
}
