/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useScript } from '../hooks/useScript';
import { IGetHSTokenResult, getHSToken } from '../redux/actions/collectOrders';
import config from '../config';

export default function CollectionOrderHubspotWidget() {
  useScript(config.hsLiveChatUrl, 'hubspot');

  async function getUserToken() {
    getHSToken(
      (res: IGetHSTokenResult) => {
        (window as any).hsConversationsSettings = {
          identificationEmail: res.Email,
          identificationToken: res.Token
        };
        (window as any).HubSpotConversations.widget.load();
      },
      (err: any) => {
        // eslint-disable-next-line no-console
        console.log('Err', err);
      }
    );
  }

  useEffect(() => {
    // HS Settings
    (window as any).hsConversationsSettings = {
      loadImmediately: false
    };

    // If loaded get token
    if ((window as any).HubSpotConversations) {
      getUserToken();
    }
  }, [(window as any).HubSpotConversations]);

  return <></>;
}
