/* eslint-disable react/destructuring-assignment */
import UPDATE_PAYMENT_ID from '../symbols/updatePaymentId';

const initialState = {
  data: '',
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function UpdatePaymentIdReducer(state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_PAYMENT_ID.UPDATE_PAYMENT_ID:
      return { ...state, loading: true, error: false };
    case UPDATE_PAYMENT_ID.UPDATE_PAYMENT_ID_SUCCESS:
      return {
        ...state,
        data: action.payload.Result,
        loading: false,
        error: false
      };
    case UPDATE_PAYMENT_ID.UPDATE_PAYMENT_ID_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case UPDATE_PAYMENT_ID.CLEAR_UPDATE_PAYMENT_ID:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    case UPDATE_PAYMENT_ID.UPDATE_PAYMENT_ID_SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}
export default UpdatePaymentIdReducer;
