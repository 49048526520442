import REMOVE_DELIVERY_ADDRESS from '../symbols/removeDeliveryAddress';
import { HttpRegularDelete } from '../../service';

const removeDeliveryAddressAction = () => ({
  type: REMOVE_DELIVERY_ADDRESS.REMOVE_DELIVERY_ADDRESS
});
const removeDeliveryAddressSuccessAction = (data: any) => {
  return {
    type: REMOVE_DELIVERY_ADDRESS.REMOVE_DELIVERY_ADDRESS_SUCCESS,
    payload: {
      data
    }
  };
};
const removeDeliveryAddressFailureAction = (data: any) => ({
  type: REMOVE_DELIVERY_ADDRESS.REMOVE_DELIVERY_ADDRESS_FAILURE,
  payload: {
    data
  }
});
const clearEditDeliveryAddressAction = () => ({
  type: REMOVE_DELIVERY_ADDRESS.CLEAR_REMOVE_DELIVERY_ADDRESS
});
export function RemoveDeliveryAddress(id: string, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(removeDeliveryAddressAction());
    HttpRegularDelete(`/pro/delivery-address/${id}`, {
      dispatch,
      actionSuccess: removeDeliveryAddressSuccessAction,
      actionFailure: removeDeliveryAddressFailureAction,
      callback
    });
  };
}

export function clearEditDeliveryAddress() {
  return (dispatch: any) => {
    dispatch(clearEditDeliveryAddressAction());
  };
}
