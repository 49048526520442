/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Box, CircularProgress, IconButton, Link, makeStyles, Typography } from '@material-ui/core';
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { colours, theme } from '../constants/theme';
import { getVehicleTypes, IGetVehicleTypeModel, VehicleType } from '../redux/actions/collectOrders';
import { getVehiclePopupContent, VEHICLE_TYPES } from '../utils/vehicleAssetUtil';
import { ErrorModal } from './ErrorModal';
import { Caption } from '../components-atoms/Caption';

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'auto',
    display: 'flex',
    scrollSnapType: 'x mandatory',
    gap: 12
  },
  item: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    cursor: 'pointer',
    transition: '0.2s all',
    scrollSnapAlign: 'start',
    minWidth: 212,
    height: 280,
    marginBottom: 12,
    backgroundColor: colours.highEmphasis
  },
  image: {},
  infoText: {
    backgroundColor: theme.palette.warning.main,
    padding: '2px 6px',
    margin: '-6px 8px 8px 8px',
    borderRadius: 4,
    textAlign: 'center',
    '& span': {
      color: 'white',
      fontSize: 13,
      fontWeight: '600'
    }
  },
  infoButtonContainer: {
    position: 'absolute',
    top: 8,
    right: 8
  },
  infoButton: {
    width: 40,
    height: 40
  }
}));

export interface IVehicleTypeSelectorProps {
  // eslint-disable-next-line no-unused-vars
  onChange(e: VehicleType): void;
  style: CSSProperties;
  hasError: boolean;
}

export const CONTACT_PHONE = '+44 1202 653 305';

export function VehicleTypeSelector(props: IVehicleTypeSelectorProps) {
  const { style, hasError, onChange } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const [selectedType, setselectedType] = useState<VehicleType>();
  const [loading, setLoading] = useState(true);
  const [modalName, setModalName] = useState('');
  const [vehicleTypes, setVehicleTypes] = useState<IGetVehicleTypeModel[]>([]);
  // Set current vehicle id for popup
  const [currentVehicleId, setCurrentVehicleId] = useState<string | number>();
  const currentVehicle = useMemo(
    () => vehicleTypes.find((v) => v.Id === currentVehicleId),
    [currentVehicleId]
  );

  const scrollToStart = () => {
    setTimeout(() => {
      if (containerRef?.current) {
        containerRef.current.scrollLeft = 0;
      }
    }, 100);
  };

  useEffect(() => {
    getVehicleTypes(
      (res: IGetVehicleTypeModel[]) => {
        setVehicleTypes(res);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    if (vehicleTypes?.length > 0) {
      scrollToStart();
    }
  }, [vehicleTypes]);

  const invalidDeliveryModalBody = useCallback(
    () => (
      <Box>
        <Typography
          variant="body2"
          component="p"
          color="textPrimary"
          style={{ marginTop: 24, marginBottom: 16 }}>
          {modalName} is not available for selection at the moment. If you would like to have your
          order delivered in a {modalName} please contact us
        </Typography>
        <Link
          href={`tel:${CONTACT_PHONE.replace(/ /g, '')}`}
          style={{ color: theme.palette.primary.main, fontSize: 20 }}>
          {CONTACT_PHONE}
        </Link>
      </Box>
    ),
    [modalName]
  );

  const vehicleModalBody = useCallback(
    () => (
      <Box>
        {getVehiclePopupContent(currentVehicle!).map((c) => (
          <Typography
            key={c.value}
            variant="body2"
            component="p"
            color="textPrimary"
            style={{ marginTop: 24, marginBottom: 16 }}>
            <strong>{c.title}:</strong> {c.value}
          </Typography>
        ))}
      </Box>
    ),
    [modalName, currentVehicle]
  );

  const onSelect = (v: any) => {
    if (!v.Active) {
      setModalName(v.Name);
      return;
    }
    setselectedType(v.Id);
    onChange(v.Id);
  };

  const showInvalidDelivery = () => {
    return (
      <ErrorModal
        visible={Boolean(modalName?.length > 0)}
        title={`${modalName} Delivery`}
        buttonTitle="Dismiss"
        customBody={() => invalidDeliveryModalBody()}
        onClose={() => {
          setModalName('');
        }}
      />
    );
  };

  const renderInfoPopup = () => {
    if (vehicleTypes.length === 0) {
      return null;
    }
    return (
      <ErrorModal
        visible={Boolean(currentVehicleId)}
        title={currentVehicle?.Name || ''}
        buttonTitle="Dismiss"
        customBody={() => vehicleModalBody()}
        onClose={() => {
          setCurrentVehicleId(undefined);
        }}
      />
    );
  };

  const renderInfoButton = (v: IGetVehicleTypeModel) => {
    return (
      <Box className={classes.infoButtonContainer}>
        <IconButton
          className={classes.infoButton}
          onClick={(e) => {
            e.stopPropagation();
            setCurrentVehicleId(v.Id);
          }}>
          <InfoOutlinedIcon htmlColor={colours.light190} fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  if (loading) {
    return (
      <Box mt={2} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div
      ref={containerRef}
      data-testid="vehicle-type-container"
      style={style}
      className={classes.container}>
      {vehicleTypes.map((v: IGetVehicleTypeModel) => {
        const isSelected = selectedType?.toString() === v.Id?.toString();
        const isDisabled = !v.Active;
        const src = isSelected ? VEHICLE_TYPES[v.Code]?.fillImage : VEHICLE_TYPES[v.Code]?.image;
        const infoText = v.Info;
        return (
          <Box
            key={v.Id}
            data-testid={v.Id}
            onClick={() => onSelect(v)}
            className={classes.item}
            style={{
              borderColor: isSelected ? colours.primary : !isDisabled && hasError ? '#d45740' : ''
            }}>
            {renderInfoButton(v)}
            <img
              key={v.Id}
              alt={v.Name}
              draggable="false"
              src={src}
              className={classes.image}
              style={{
                opacity: isDisabled ? 0.5 : 1
              }}
            />
            <Box mb={2} display="flex" flexDirection="column" alignItems="center">
              {infoText ? (
                <Box className={classes.infoText}>
                  <Caption>{infoText}</Caption>
                </Box>
              ) : (
                <Box height={24} padding="2px 6px" margin="-6px 8px 8px 8px" />
              )}
              <Caption
                style={{
                  fontWeight: 'bold',
                  marginBottom: 8,
                  color: isSelected ? colours.primary : colours.lightEmphasis
                }}>
                {v.Name}
              </Caption>
              <Caption
                style={{
                  color: isSelected ? colours.primary : colours.lightEmphasis
                }}>
                Max Size
              </Caption>
              <Caption
                style={{
                  fontWeight: 'bold',
                  marginBottom: 8,
                  color: isSelected ? colours.primary : colours.lightEmphasis
                }}>
                {v.MaxSize}
              </Caption>
              <Caption
                variant="caption"
                style={{
                  color: isSelected ? colours.primary : colours.lightEmphasis
                }}>
                Max Weight{' '}
                <Caption
                  style={{
                    fontWeight: 'bold',
                    color: isSelected ? colours.primary : colours.lightEmphasis
                  }}>
                  {v.MaxWeight}
                </Caption>
              </Caption>
            </Box>
          </Box>
        );
      })}

      {showInvalidDelivery()}
      {renderInfoPopup()}
    </div>
  );
}
