import { Typography, TypographyProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  helperText: {
    fontSize: 13,
    fontWeight: 400,
    letterSpacing: 0.4
  }
}));

export interface ICaptionProps extends TypographyProps {
  children: React.ReactNode | string;
}

export function Caption(props: ICaptionProps) {
  const { children, ...rest } = props;
  const classes = useStyles();
  return (
    <Typography variant="caption" color="textSecondary" className={classes.helperText} {...rest}>
      {children}
    </Typography>
  );
}
