import { Box, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { getOrderDate } from '../utils';
import { CollectOrderListModel } from '../redux/actions/collectOrders';
import { CollectOrderStamp } from './CollectOrderStamp';
import { textButtonHoverAndActive } from '../utils/styles/buttonStyles';
import { Stamp } from '../components-atoms/Stamp';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 24,
    marginBottom: '24px',
    padding: '24px',
    boxSizing: 'border-box',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '20px',
    cursor: 'pointer',
    transition: 'all 200ms ease',
    '@media (max-width: 900px)': {
      flexDirection: 'column',
      alignItems: ' flex-start',
      border: 'unset'
    },
    ...textButtonHoverAndActive
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    '& > .MuiTypography-body2': {
      marginTop: 4
    }
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    textAlign: 'right',
    '& > .MuiTypography-subtitle2': {
      marginTop: 4
    },
    '@media (max-width: 899px)': {
      alignItems: 'flex-start',
      textAlign: 'left',
      marginTop: 8
    }
  }
}));
interface ICollectionOrderItemProps {
  data: CollectOrderListModel;
  // eslint-disable-next-line no-unused-vars
  onClick: (id: string) => void;
  index: number;
}
export function CollectionOrderItem({ index, data, onClick }: ICollectionOrderItemProps) {
  const classes = useStyles();

  const orderDate = getOrderDate(data?.Drafted?.toString());
  return (
    <Container
      data-testid="delivery-order-item"
      data-order="delivery-order-item"
      data-index={index}
      onClick={() => onClick(data.Id)}
      className={classes.root}>
      <Grid container>
        <Grid item xs className={classes.leftContainer}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" component="span" color="textPrimary">
              #{data.ReferenceNumber}
            </Typography>

            {data.Status && (
              <Box>
                <CollectOrderStamp status={data.Status} />
              </Box>
            )}
            {data.PremiumSelected && (
              <Box>
                <Stamp type="success" text="Concierge" />
              </Box>
            )}
          </Box>
          <Typography variant="body2" component="span" color="textSecondary">
            {orderDate}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
