import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import SignOutAndClear from '../symbols/signOutAndClearAllReducers';
import login from './login';
import error from './error';
import inviteMember from './inviteMember';
import userRole from './userRole';
import store from '../store';
import validateToken from './validateToken';
import register from './register';
import createSession from './createSession';
import createPaymentId from './createPaymentId';
import team from './team';
import grantAccess from './grantAccess';
import profile from './profile';
import removeMember from './removeMember';
import getMember from './getMember';
import editMember from './editMember';
import getTeamDetail from './getTeamDetail';
import editTeam from './editTeam';
import changePassword from './changePassword';
import deliveryAddresses from './deliveryAddresses';
import searchAddress from './searchAddress';
import searchAddressDetails from './searchAddressDetails';
import addDeliveryAddress from './addDeliveryAddress';
import editDeliveryAddress from './editDeliveryAddress';
import removeDeliveryAddress from './removeDeliveryAddress';
import updateSession from './updateSession';
import updatePaymentId from './updatePaymentId';
import getInvoices from './getInvoices';
import orders from './orders';
import approveOrder from './approveOrder';
import rejectOrder from './rejectOrder';
import orderDetail from './orderDetail';
import getOrderStatus from './getOrderStatus';
import resendInvitation from './resendInvitation';
import getTeamMembers from './getTeamMembers';
import getPaymentId from './getPaymentId';
import sendForgotPasswordEmail from './sendForgotPasswordEmail';
import checkForgotPasswordToken from './checkForgotPasswordToken';
import resetPassword from './resetPassword';
import orderSearch from './orderSearch';
import failedPayment from './failedPayment';
import collectOrdersFilters from './collectOrdersFilters';
import getCollectionTime from './getCollectionTime';
import credits from './credits';

const appReducer = combineReducers({
  login,
  error,
  inviteMember,
  userRole,
  register,
  validateToken,
  createSession,
  createPaymentId,
  team,
  grantAccess,
  profile,
  removeMember,
  getMember,
  editMember,
  getTeamDetail,
  editTeam,
  changePassword,
  deliveryAddresses,
  searchAddress,
  searchAddressDetails,
  addDeliveryAddress,
  editDeliveryAddress,
  removeDeliveryAddress,
  updateSession,
  updatePaymentId,
  getInvoices,
  orders,
  approveOrder,
  rejectOrder,
  orderDetail,
  getOrderStatus,
  resendInvitation,
  getTeamMembers,
  getPaymentId,
  sendForgotPasswordEmail,
  checkForgotPasswordToken,
  resetPassword,
  orderSearch,
  failedPayment,
  collectOrdersFilters,
  getCollectionTime,
  credits
});

const rootReducer = (state: any, action: any) => {
  if (action.type === SignOutAndClear.SIGN_OUT_AND_CLEAR_ALL_REDUCERS) {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;

export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;
