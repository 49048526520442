import {
  Box,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { useState, useLayoutEffect, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams, useNavigate } from 'react-router-dom';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTextPrimary,
  ButtonWithLoading,
  ControlledInput,
  RoundedCheckbox
} from '../components-atoms';
import { Header } from '../components-molecules';
import { ITheme, theme } from '../constants/theme';
import {
  EMAIL_VALIDATION,
  PASSWORD_VALIDATION,
  PHONE_VALIDATION_V3,
  REQUIRED_VALIDATION,
  UK_POST_CODE_VALIDATION
} from '../constants/validation';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { CreatePaymentId, SetCreatePaymentIdLoading } from '../redux/actions/createPaymentId';
import { clearCreateSession, CreateSession } from '../redux/actions/createSession';
import { clearRegisterError, Register } from '../redux/actions/register';
import { ValidateToken } from '../redux/actions/validateToken';
import { ValidateTokenResponse } from '../redux/reducers/validateToken';
import { loginSuccessAction } from '../redux/actions/login';
import { GetProfile } from '../redux/actions/profile';
import { GetTeamDetail } from '../redux/actions/teamDetail';
import { getPhoneNumber } from '../utils/phoneUtils';

const headerHeight = 64;
const useStyles = makeStyles((myTheme: ITheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  // Main app container
  appContainer: {
    display: 'flex',
    flex: 1,
    height: 'fit-content',
    marginTop: headerHeight
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: '#fff'
  },
  container: {
    paddingTop: myTheme.spacing(37 / 8),
    paddingBottom: myTheme.spacing(4)
  },
  setupContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    boxSizing: 'border-box',
    borderRadius: '20px',
    maxWidth: '450px',
    width: '100%'
  }
}));
export interface SetupForm {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  password: string;
  passwordRepeat: string;
  marketing: boolean;
}
export interface OrganisationForm {
  organisationName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postcode: string;
  city: string;
  county: string;
  vat: string;
}
export function Setup() {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const stripeStatus = searchParams.get('stripeStatus');
  const stripeCheckoutSessionId = searchParams.get('stripeCheckoutSessionId');
  const [loading, validationData, validateError, registerError]: [
    boolean,
    ValidateTokenResponse,
    any,
    any
  ] = useAppSelector((state) => [
    state.validateToken.loading,
    state.validateToken.data as ValidateTokenResponse,
    state.validateToken.error,
    (state.register as any).error
  ]);
  const [stripeData, stripeLoading] = useAppSelector((state) => [
    (state.createPaymentId as any).data,
    (state.createPaymentId as any).loading
  ]);
  const savedData = useAppSelector((state) => (state.createSession as any).data);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [hasPaymentDetail, setHasPaymentDetail] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [step, setStep] = useState(0);
  const [marketing, setMarketing] = useState(false);
  const [resetted, setResetted] = useState(false);
  const {
    control,
    formState: { isValid },
    trigger,
    watch,
    setValue,
    setError: setSetupFormError,
    getValues,
    reset
  } = useForm<SetupForm>({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: { phone: '', password: '', marketing: false }
  });
  const register: SubmitHandler<OrganisationForm> = (data) => {
    const user = getValues();
    dispatch(
      Register(
        {
          FirstName: user.firstname,
          LastName: user.lastname,
          Email: user.email,
          PhoneNumber: getPhoneNumber(user.phone),
          Password: user.password,
          TeamName: data.organisationName,
          AddressLine1: data.addressLine1,
          AddressLine2: data.addressLine2,
          AddressLine3: data.addressLine3,
          PostalCode: data.postcode,
          City: data.city,
          County: data.county,
          VATNumber: data.vat,
          AddressName: '',
          StripePaymentIdentifier: stripeData.StripePaymentId,
          StripeCustomerId: stripeData.StripeCustomerId,
          AcceptMarketingEmails: user.marketing,
          ProUserRoleId: validationData.UserInfo.ProUserRoleId,
          CustomerId: validationData.UserInfo.CustomerId || '',
          IsRegistered: validationData.IsRegistered,
          IsPasswordSet: validationData.IsPasswordSet,
          ProInvitationToken: token as string
        },
        (resp) => {
          dispatch(clearCreateSession());
          dispatch(loginSuccessAction(resp));
          dispatch(GetProfile());
          dispatch(GetTeamDetail());
          navigate('/team', { replace: true });
        }
      )
    );
  };
  const {
    control: organisationControl,
    formState: { isValid: organisationIsValid },
    watch: watchOrganisation,
    handleSubmit,
    setError: setOrganisationError,
    reset: resetOrganisation,
    getValues: getOrganisationValues,
    trigger: organisationTrigger,
    clearErrors: clearOrganisationErrors
  } = useForm<OrganisationForm>({
    mode: 'all',
    reValidateMode: 'onBlur'
  });
  useEffect(() => {
    if (registerError) {
      switch (registerError) {
        case 'A customer with this phone number already exists.':
          setStep(0);
          setSetupFormError('phone', { message: registerError });
          break;
        default:
          setOrganisationError('organisationName', { message: registerError });
          break;
      }
    }
  }, [registerError, setSetupFormError, setOrganisationError]);
  useEffect(() => {
    dispatch(clearRegisterError());
    clearOrganisationErrors('organisationName');
  }, [dispatch, clearOrganisationErrors]);
  useEffect(() => {
    organisationTrigger();
  }, [resetted, organisationTrigger]);
  useLayoutEffect(() => {
    if (stripeStatus && stripeStatus === 'success') {
      setHasPaymentDetail(true);
      dispatch(
        CreatePaymentId(
          {
            StripeCheckoutSessionId: stripeCheckoutSessionId as string
          },
          () => {
            setStep(1);
            resetOrganisation(savedData?.organisation);
            reset(savedData?.user);
            setResetted((prev) => !prev);
          }
        )
      );
    } else if (stripeStatus && stripeStatus === 'cancelled') {
      dispatch(SetCreatePaymentIdLoading(false));
      setStep(1);
      resetOrganisation(savedData?.organisation);
      reset(savedData?.user);
      setResetted((prev) => !prev);
    }
  }, [stripeStatus, stripeCheckoutSessionId, dispatch, resetOrganisation, savedData, reset]);
  useLayoutEffect(() => {
    if (token && !stripeStatus && !stripeCheckoutSessionId) {
      dispatch(clearCreateSession());
      dispatch(
        ValidateToken(token, (data) => {
          setValue('email', data.Result.UserInfo.Email, {
            shouldDirty: true,
            shouldValidate: true
          });
          setValue('firstname', data.Result.UserInfo.FirstName, {
            shouldDirty: true,
            shouldValidate: true
          });
          setValue('lastname', data.Result.UserInfo.LastName, {
            shouldDirty: true,
            shouldValidate: true
          });
          if (data.Result.IsRegistered && data.Result.IsPasswordSet) {
            setStep(1);
          }
        })
      );
    }
  }, [token, setValue, dispatch, stripeStatus, stripeCheckoutSessionId]);
  const createSetupSession = () => {
    dispatch(
      CreateSession(
        {
          InvitationToken: token as string,
          TeamName: watchOrganisation('organisationName')
        },
        getValues(),
        getOrganisationValues(),
        (resp) => window.open(resp.Result.Url, '_self')
      )
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);
  return (
    <Box className={clsx('App', classes.root)}>
      <Header />
      <Box className={classes.appContainer}>
        <main className={classes.content}>
          {loading || stripeLoading ? (
            <Container style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Container>
          ) : validateError ? (
            <Grid container item direction="column" style={{ marginTop: '96px', rowGap: '12px' }}>
              <Typography variant="h6" color="textPrimary" component="span">
                This invite link is no longer active
              </Typography>
              <Typography variant="body1" color="textSecondary" component="span">
                Please check with the person who invited you to TradeKartPro to resend the
                invitation.
              </Typography>
            </Grid>
          ) : step === 0 ? (
            <Container key="userForm" maxWidth="lg" className={classes.container}>
              <Typography style={{ padding: '28px 0 0' }} variant="h4" component="h4">
                Welcome to TradeKart Pro!
              </Typography>
              <Typography
                style={{ padding: '16px 0 32px' }}
                variant="body1"
                component="p"
                color="textSecondary">
                Complete your profile information to get started
              </Typography>
              <Box>
                <Container className={classes.setupContainer}>
                  <Grid container item xs={12}>
                    <Grid item style={{ paddingBottom: '16px' }}>
                      <Typography variant="h6" component="h6" color="textPrimary">
                        User information
                      </Typography>
                    </Grid>
                    <Grid xs={12} item container>
                      <Grid xs={6} item style={{ paddingRight: '8px' }}>
                        <ControlledInput
                          name="firstname"
                          rules={{ required: REQUIRED_VALIDATION }}
                          label="First name"
                          control={control}
                        />
                      </Grid>
                      <Grid xs={6} item style={{ paddingLeft: '8px' }}>
                        <ControlledInput
                          name="lastname"
                          rules={{ required: REQUIRED_VALIDATION }}
                          label="Last name"
                          control={control}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={12} item container>
                      <ControlledInput
                        name="email"
                        disabled
                        rules={{ required: REQUIRED_VALIDATION, pattern: EMAIL_VALIDATION }}
                        label="Email"
                        style={{ backgroundColor: theme.palette.action.disabledBackground }}
                        control={control}
                      />
                    </Grid>
                    <Grid xs={12} item container>
                      <ControlledInput
                        name="phone"
                        InputProps={{ readOnly: !isFocused }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => {
                          setIsFocused(false);
                          trigger('phone');
                        }}
                        rules={{ required: REQUIRED_VALIDATION, pattern: PHONE_VALIDATION_V3 }}
                        label="Contact phone"
                        control={control}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <Divider style={{ width: '100%', margin: '24px 0' }} />
                    </Grid>
                    <Grid
                      xs={12}
                      item
                      container
                      direction="column"
                      alignItems="flex-start"
                      style={{ paddingBottom: '16px' }}>
                      <Typography variant="subtitle1" component="p">
                        Password
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        align="left"
                        style={{ paddingTop: '4px' }}>
                        Choose a secure password to create your account. Password should contain at
                        least 8 characters, at least one number and at least one uppercase letter.
                      </Typography>
                    </Grid>
                    <Grid xs={12} item container>
                      <ControlledInput
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        rules={{ required: REQUIRED_VALIDATION, pattern: PASSWORD_VALIDATION }}
                        label="Enter password"
                        control={control}
                      />
                    </Grid>
                    <Grid xs={12} item container>
                      <ControlledInput
                        type={showPassword ? 'text' : 'password'}
                        name="passwordRepeat"
                        rules={{
                          required: REQUIRED_VALIDATION,
                          pattern: PASSWORD_VALIDATION,
                          validate: (val) => val === watch('password') || 'Passwords do not match'
                        }}
                        label="Confirm password"
                        control={control}
                      />
                      <FormControlLabel
                        control={
                          <RoundedCheckbox
                            color="primary"
                            checked={showPassword}
                            onChange={(e) => setShowPassword(e.target.checked)}
                          />
                        }
                        style={{ gap: '4px' }}
                        label="Show password"
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <Divider style={{ width: '100%', margin: '24px 0 32px' }} />
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-start">
                      <FormControlLabel
                        control={
                          <RoundedCheckbox
                            color="primary"
                            checked={termsChecked}
                            onChange={(e) => setTermsChecked(e.target.checked)}
                          />
                        }
                        style={{ gap: '4px' }}
                        role="link"
                        label={
                          <>
                            I’ve read and agree with the{' '}
                            <a
                              href="https://www.tradekart.com/legal/tradekart-pro-terms-and-conditions/"
                              target="_blank"
                              style={{ textDecoration: 'none', color: theme.palette.primary.main }}
                              rel="noreferrer">
                              Terms and Conditions
                            </a>
                          </>
                        }
                      />
                    </Grid>

                    <Grid container item xs={12} justifyContent="flex-start">
                      <FormControlLabel
                        control={
                          <RoundedCheckbox
                            checked={marketing}
                            color="primary"
                            onChange={(_, checked) => {
                              setMarketing(checked);
                              setValue('marketing', checked, {
                                shouldDirty: true,
                                shouldValidate: true
                              });
                            }}
                          />
                        }
                        style={{ gap: '4px' }}
                        label="I accept to receive marketing emails"
                      />
                    </Grid>
                    <Grid xs={12} container item direction="row-reverse">
                      <ButtonPrimary
                        disabled={!isValid || !termsChecked}
                        onClick={() => setStep(1)}>
                        Next
                      </ButtonPrimary>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Container>
          ) : (
            <Container maxWidth="lg" key="organisationForm" className={classes.container}>
              <Typography style={{ padding: '28px 0 0' }} variant="h4" component="h4">
                Almost there, {watch('firstname')}
              </Typography>
              <Typography
                style={{ padding: '16px 0 32px' }}
                variant="body1"
                component="p"
                color="textSecondary">
                Enter your organisation information and your teams payment details to complete the
                setup
              </Typography>
              <Box>
                <Container className={classes.setupContainer}>
                  <form onSubmit={handleSubmit(register)}>
                    <Grid container item xs={12}>
                      <Grid item style={{ paddingBottom: '16px' }}>
                        <Typography variant="h6" component="h6" color="textPrimary">
                          Organisation information
                        </Typography>
                      </Grid>
                      <Grid xs={12} item container>
                        <ControlledInput
                          name="organisationName"
                          rules={{ required: REQUIRED_VALIDATION }}
                          label="Organisation name"
                          control={organisationControl}
                        />
                      </Grid>
                      <Grid xs={12} item>
                        <Divider style={{ width: '100%', margin: '24px 0 16px' }} />
                      </Grid>
                      <Grid xs={12} item container>
                        <ControlledInput
                          name="addressLine1"
                          rules={{ required: REQUIRED_VALIDATION }}
                          label="Company address line 1"
                          control={organisationControl}
                        />
                      </Grid>
                      <Grid xs={12} item container>
                        <ControlledInput
                          name="addressLine2"
                          label="Company address line 2 (optional)"
                          control={organisationControl}
                        />
                      </Grid>
                      <Grid xs={12} item container>
                        <ControlledInput
                          name="addressLine3"
                          label="Company address line 3 (optional)"
                          control={organisationControl}
                        />
                      </Grid>
                      <Grid xs={12} item container>
                        <Grid xs={6} item style={{ paddingRight: '8px' }}>
                          <ControlledInput
                            name="postcode"
                            rules={{
                              required: REQUIRED_VALIDATION,
                              pattern: UK_POST_CODE_VALIDATION
                            }}
                            label="Postcode"
                            control={organisationControl}
                          />
                        </Grid>
                        <Grid xs={6} item style={{ paddingLeft: '8px' }}>
                          <ControlledInput
                            name="city"
                            rules={{ required: REQUIRED_VALIDATION }}
                            label="City"
                            control={organisationControl}
                          />
                        </Grid>
                      </Grid>

                      <Grid xs={12} item container>
                        <ControlledInput
                          name="county"
                          rules={{ required: REQUIRED_VALIDATION }}
                          label="County"
                          control={organisationControl}
                        />
                      </Grid>
                      <Grid xs={12} item>
                        <Divider style={{ width: '100%', margin: '24px 0 16px' }} />
                      </Grid>
                      <Grid xs={12} item container>
                        <ControlledInput
                          name="vat"
                          rules={{ required: REQUIRED_VALIDATION }}
                          label="VAT number"
                          control={organisationControl}
                        />
                      </Grid>
                      <Grid xs={12} item>
                        <Divider style={{ width: '100%', margin: '24px 0 32px' }} />
                      </Grid>
                      <Grid
                        xs={12}
                        item
                        style={{
                          paddingBottom: '32px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          rowGap: '8px'
                        }}>
                        <Typography
                          variant="subtitle1"
                          component="p"
                          align="left"
                          style={{
                            color: hasPaymentDetail
                              ? theme.palette.success.main
                              : theme.palette.text.primary,
                            alignItems: 'center',
                            display: 'flex'
                          }}>
                          {hasPaymentDetail && (
                            <CheckCircleOutlinedIcon
                              style={{ marginRight: '8px' }}
                              htmlColor={theme.palette.success.main}
                            />
                          )}
                          {hasPaymentDetail
                            ? 'Payment Method Successfully Added'
                            : 'Add Payment Method'}
                        </Typography>
                        {!hasPaymentDetail ? (
                          <>
                            <Typography variant="body2" component="p" align="left">
                              You will be redirected to Stripe to store your payment details.
                              Payment details will not be shared with TradeKart.
                            </Typography>
                            <Typography
                              style={{ paddingTop: '8px' }}
                              variant="body2"
                              component="p"
                              align="left">
                              This payment method will be used by your team members when checking
                              out in the TradeKart app.
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            component="p"
                            align="left"
                            style={{ paddingTop: '8px' }}>
                            Your payment information has been successfully stored on Stripe. You can
                            change your payment details by clicking Update Payment Details below or
                            later in the Organisation Settings section in the TradeKart Pro portal.
                          </Typography>
                        )}
                        <Grid xs={12} item container direction="row-reverse">
                          <ButtonSecondary
                            disabled={!watchOrganisation('organisationName')}
                            onClick={() => {
                              createSetupSession();
                            }}
                            style={{ marginTop: '16px' }}>
                            {hasPaymentDetail ? 'Update Payment Details' : 'Add Payment Details'}
                          </ButtonSecondary>
                        </Grid>
                      </Grid>
                      <Grid xs={12} item container direction="row-reverse">
                        <ButtonWithLoading
                          loading={false}
                          type="submit"
                          disabled={!organisationIsValid || !hasPaymentDetail}
                          title="Finish Setup"
                        />
                        {!(validationData.IsRegistered && validationData.IsPasswordSet) && (
                          <ButtonTextPrimary
                            style={{ marginRight: '8px' }}
                            onClick={() => setStep(0)}>
                            Back
                          </ButtonTextPrimary>
                        )}
                      </Grid>
                    </Grid>
                  </form>
                </Container>
              </Box>
            </Container>
          )}
        </main>
      </Box>
    </Box>
  );
}
