import UPDATE_SESSION from '../symbols/updateSession';
import { HttpRequestPut } from '../../service';

const updateSessionAction = () => ({
  type: UPDATE_SESSION.UPDATE_SESSION
});
const updateSessionSuccessAction = (data: any) => {
  return {
    type: UPDATE_SESSION.UPDATE_SESSION_SUCCESS,
    payload: data
  };
};
const updateSessionFailureAction = (data: any) => ({
  type: UPDATE_SESSION.UPDATE_SESSION_FAILURE,
  payload: data
});
const clearUpdateSessionAction = () => ({
  type: UPDATE_SESSION.CLEAR_UPDATE_SESSION
});

export function UpdateSession(
  // eslint-disable-next-line no-unused-vars
  callback?: (resp: any) => void
) {
  return (dispatch: any) => {
    dispatch(updateSessionAction());
    HttpRequestPut(
      'pro/payment/update-setup-session',
      dispatch,
      updateSessionSuccessAction,
      updateSessionFailureAction,
      true,
      undefined,
      callback
    );
  };
}

export function clearUpdateSession() {
  return (dispatch: any) => {
    dispatch(clearUpdateSessionAction());
  };
}
