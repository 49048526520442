/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  TypographyProps,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ButtonPrimary } from '../components-atoms';
import { ConfirmModal, ErrorModal } from '../components-molecules';
import { Countdown } from '../components-molecules/Countdown';
import { InfoSectionContainer } from '../components-molecules/InfoSectionContainer';
import { colours, theme } from '../constants/theme';
import CollectionOrderCourierSelection from './CollectionOrderCourierSelection';
import { IAvailableCourierItemProps } from '../components-molecules/AvailableCourierItem';
import { useAppWebSocket } from '../hooks/useAppWebSocket';

const FIFTEEN_MINS = 15 * 60;

export interface INewCollectionOrderTotalProps {
  lastUpdate: Date;
  lastGetPrice: Date;
  orderId?: string;
  getPriceLoading: boolean;
  onGetPrice: () => void;
  onExpire: () => void;
  onCourierChange: (courier: IAvailableCourierItemProps) => void;
  selectedCouriers: string[];
  setSelectedCouriers: (value: string[]) => void;
  onSubmit: (id: string) => void;
}

interface ErrorMessage {
  Error: string; // "No valid quotes available for this order."
}

interface AvailableCourierPrice {
  Delivery: number;
  Vat: number;
  Total: number;
  TaxRate: number;
}

interface AvailableCourier {
  Id: string;
  Name: string;
  Logo: string;
  Price: {
    Min: AvailableCourierPrice;
    Max?: AvailableCourierPrice;
  };
}

interface ResponseModel {
  AvailableCouriers?: AvailableCourier[];
  Premium?: AvailableCourier;
}

interface ResultMessage {
  AvailableCouriersResponseModel: ResponseModel;
}

const useStyles = makeStyles(() => ({
  infoButtonContainer: {
    marginTop: -6
  },
  infoButton: {
    width: 40,
    height: 40
  }
}));

// Fix this type
// type CollectQuoteMessage = ErrorMessage | ResultMessage;

export function NewCollectionOrderTotal(props: any) {
  const {
    getPriceLoading,
    lastUpdate,
    lastGetPrice,
    onGetPrice,
    orderId,
    onExpire,
    handleError,
    hasError,
    onCourierChange,
    selectedCouriers,
    setSelectedCouriers,
    onSubmit
  } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [countdownStarted, setCountdownStarted] = useState(true);
  const [availableCouriers, setAvailableCouriers] = useState<any>();
  const [estimatedPriceVisible, setEstimatedPriceVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const mobileView = useMediaQuery('(max-width: 899px)', { noSsr: true });
  const token = useSelector((state: any) => state.login.token);

  const { lastJsonMessage } = useAppWebSocket<any>(
    '/ws/collect-quotes',
    {
      queryParams: { pcid: orderId, t: token }
    },
    Boolean(orderId) && Boolean(token)
  );

  const COUNTDOWN_TIME = useMemo(() => {
    const expiryDate = lastJsonMessage?.AvailableCouriersResponseModel?.ExpiryDate;
    if (expiryDate) {
      const endDate = moment.utc(expiryDate);
      const diff = endDate.diff(moment().utc(), 'seconds');
      return diff;
    }
    return FIFTEEN_MINS;
  }, [lastJsonMessage]);

  const countdownProps: TypographyProps = {
    display: 'inline',
    variant: 'caption',
    style: {
      marginTop: mobileView ? 0 : 24,
      marginBottom: mobileView ? 0 : -12,
      color: theme.palette.error.main,
      fontWeight: 400,
      letterSpacing: 0.15
    }
  };

  useEffect(() => {
    if (
      lastJsonMessage?.AvailableCouriersResponseModel?.AvailableCouriers?.length > 0 ||
      lastJsonMessage?.AvailableCouriersResponseModel?.Premium?.Id
    ) {
      setAvailableCouriers(lastJsonMessage?.AvailableCouriersResponseModel);
      setLoading(false);
      setButtonDisabled(false);
      setCountdownStarted(true);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    setCountdownStarted(false);
    setLoading(false);
    setButtonDisabled(false);
  }, [lastUpdate]);

  useEffect(() => {
    if (getPriceLoading) {
      setLoading(true);
    }
  }, [getPriceLoading]);

  const handleGetPrice = () => {
    if (hasError) {
      setButtonDisabled(true);
    }
    onGetPrice();
  };

  const handleCreateNewOrder = () => {
    setLoading(true);
  };

  useEffect(() => {
    // Get price button clicked.
    // Order created.
    // Get available couriers.
    if (orderId) {
      handleCreateNewOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastGetPrice]);

  const onCountdownEnd = () => {
    setCountdownStarted(false);
    setButtonDisabled(false);
    setModalOpen(true);
  };

  const renderInfoPopup = () => {
    return (
      <ErrorModal
        visible={estimatedPriceVisible}
        title="Estimated price"
        buttonTitle="Dismiss"
        errorBody="Each pick up and drop off has a 10 minute grace period. After 10 minutes, incremental wait time will be charged at 50p per minute."
        onClose={() => {
          setEstimatedPriceVisible(false);
        }}
      />
    );
  };

  const renderInfoButton = () => {
    return (
      <Box className={classes.infoButtonContainer}>
        <IconButton className={classes.infoButton} onClick={() => setEstimatedPriceVisible(true)}>
          <InfoOutlinedIcon htmlColor={colours.highEmphasis54} fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const headerRight = () => {
    if (!countdownStarted) {
      return (
        <ButtonPrimary
          data-testid="get-price"
          onClick={handleGetPrice}
          disabled={getPriceLoading || hasError || buttonDisabled}>
          Get Price
        </ButtonPrimary>
      );
    }
    return (
      <Typography {...countdownProps}>
        Price valid for{' '}
        <Countdown time={COUNTDOWN_TIME} onEnd={onCountdownEnd} {...countdownProps} />
      </Typography>
    );
  };

  const renderInfo = () => {
    return (
      <>
        <Typography variant="body2">
          Fill out the form above and click Get Price button to get the price of the order.
        </Typography>
        <Typography variant="body2">The price for the order is valid for 15 min.</Typography>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <CircularProgress style={{ marginBottom: 16 }} />
        <Typography>Getting the best price for you.</Typography>
        <Typography>This process can take up to two minutes.</Typography>
      </Box>
    );
  };

  const expiredModal = () => {
    return (
      <ConfirmModal
        visible={modalOpen}
        title="Your quote has expired"
        buttonTitle="Get Price"
        errorBody="The price quote is only valid for a period of 15 min. Press the Get Price button again to get a new quote and proceed with the order."
        onSubmit={() => {
          handleGetPrice();
          setModalOpen(false);
        }}
        onClose={() => {
          onExpire?.();
          setModalOpen(false);
        }}
      />
    );
  };

  return (
    <InfoSectionContainer
      title="Estimated price"
      helperText="The quote includes our standard wait time agreement, which includes loading/unloading. If there are excessive wait times additional charges will apply."
      headerLeft={() => renderInfoButton()}
      headerRight={() => headerRight()}
      headerStyle={{ maxWidth: '80%' }}
      sx={{ mt: 3 }}>
      <Box mt={2}>
        {loading ? (
          renderLoading()
        ) : countdownStarted ? (
          <CollectionOrderCourierSelection
            couriers={availableCouriers}
            onChange={(e) => onCourierChange(e)}
            hasError={hasError}
            selectedCouriers={selectedCouriers}
            setSelectedCouriers={setSelectedCouriers}
            onSubmit={onSubmit}
          />
        ) : (
          renderInfo()
        )}
      </Box>
      {renderInfoPopup()}
      {expiredModal()}
    </InfoSectionContainer>
  );
}
