import UPDATE_PAYMENT_ID from '../symbols/updatePaymentId';
import { HttpRequestPut } from '../../service';

const updatePaymentIdAction = () => ({
  type: UPDATE_PAYMENT_ID.UPDATE_PAYMENT_ID
});
const updatePaymentIdSuccessAction = (data: any) => {
  return {
    type: UPDATE_PAYMENT_ID.UPDATE_PAYMENT_ID_SUCCESS,
    payload: data
  };
};
const updatePaymentIdFailureAction = (data: any) => ({
  type: UPDATE_PAYMENT_ID.UPDATE_PAYMENT_ID_FAILURE,
  payload: data
});
const clearUpdatePaymentIdAction = () => ({
  type: UPDATE_PAYMENT_ID.CLEAR_UPDATE_PAYMENT_ID
});
const setCreatePaymentLoadingAction = (loading: boolean) => ({
  type: UPDATE_PAYMENT_ID.UPDATE_PAYMENT_ID_SET_LOADING,
  payload: loading
});
interface UpdatePaymentIdRequestModel {
  StripeCheckoutSessionId: string;
}
// eslint-disable-next-line no-unused-vars
export function UpdatePaymentId(data: UpdatePaymentIdRequestModel, callback?: (resp: any) => void) {
  return (dispatch: any) => {
    dispatch(updatePaymentIdAction());
    HttpRequestPut(
      'pro/payment/update-payment-identifier',
      dispatch,
      updatePaymentIdSuccessAction,
      updatePaymentIdFailureAction,
      true,
      data,
      callback
    );
  };
}

export function clearUpdatePaymentId() {
  return (dispatch: any) => {
    dispatch(clearUpdatePaymentIdAction());
  };
}

export function SetUpdatePaymentIdLoading(loading: boolean) {
  return (dispatch: any) => {
    dispatch(setCreatePaymentLoadingAction(loading));
  };
}
