import React from 'react';
import { makeStyles, Container, Modal, Grid, Typography, IconButton } from '@material-ui/core';
import BackArrow from '../components-atoms/icons/backArrow';
import { theme } from '../constants/theme';

interface IProps {
  title?: string;
  description?: string;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  children?: JSX.Element | JSX.Element[];
  onBack?: () => void;
}
const useStyles = makeStyles({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  modalContainer: {
    width: '100%',
    maxWidth: '480px',
    maxHeight: 'calc(100% - 48px)',
    backgroundColor: 'white',
    boxShadow:
      '0px 6px 36px rgba(0, 0, 0, 0.15), 0px 2.21728px 14.2392px rgba(0, 0, 0, 0.111884), 0px 0.93179px 6.99399px rgba(0, 0, 0, 0.0997219), 0px 0.37078px 3.36556px rgba(0, 0, 0, 0.0894965), 0px 0.120827px 1.42249px rgba(0, 0, 0, 0.0753326), 0px 0.0225271px 0.422798px rgba(0, 0, 0, 0.0508791)',
    borderRadius: '20px',
    padding: '24px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    marginBottom: '50px',
    display: 'flex',
    flexDirection: 'column'
  }
});

function GeneralModal({ title, description, onClose, visible, children, onBack }: IProps) {
  const classes = useStyles();
  return (
    <Modal open={visible} onClose={onClose} className={classes.modal}>
      <Container className={classes.modalContainer}>
        <Grid>
          <Grid item container direction="row" alignItems="center" style={{ columnGap: '16px' }}>
            {onBack ? (
              <IconButton
                style={{ backgroundColor: theme.palette.secondary.main }}
                size="small"
                onClick={() => onBack()}>
                <BackArrow />
              </IconButton>
            ) : null}
            <Typography variant="h6" component="h6" color="textPrimary">
              {title}
            </Typography>
          </Grid>
        </Grid>

        <Grid style={{ overflowY: 'auto', overflowX: 'hidden', marginTop: '16px' }}>
          <Grid container>
            <Grid item>
              <Typography
                style={{ whiteSpace: 'pre-line' }}
                variant="body2"
                component="span"
                color="textPrimary">
                {description}
              </Typography>
            </Grid>
            {children ?? null}
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
}
export default GeneralModal;
