import ERROR from '../symbols/error';

const initialState = {
  errorModalVisible: false,
  errorTitle: '',
  errorMessage: ''
};
// eslint-disable-next-line default-param-last
function ErrorReducer(state = initialState, action: any) {
  switch (action.type) {
    case ERROR.SET_ERROR:
      return {
        ...state,
        errorModalVisible: true,
        errorTitle: '',
        errorMessage: action.payload
      };
    case ERROR.CLEAR_ERROR:
      return {
        ...state,
        errorModalVisible: false,
        errorTitle: '',
        errorMessage: ''
      };

    default:
      return state;
  }
}

export default ErrorReducer;
