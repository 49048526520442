import GET_TEAM_DETAIL from '../symbols/getTeamDetail';
import { HttpRequestGet } from '../../service';

const getTeamDetailAction = () => ({
  type: GET_TEAM_DETAIL.GET_TEAM_DETAIL
});
const getTeamDetailSuccessAction = (data: any) => {
  return {
    type: GET_TEAM_DETAIL.GET_TEAM_DETAIL_SUCCESS,
    payload: data
  };
};
const getTeamDetailFailureAction = (data: any) => ({
  type: GET_TEAM_DETAIL.GET_TEAM_DETAIL_FAILURE,
  payload: {
    data
  }
});
const clearGetTeamDetailAction = () => ({
  type: GET_TEAM_DETAIL.CLEAR_GET_TEAM_DETAIL
});

export function GetTeamDetail() {
  return (dispatch: any) => {
    dispatch(getTeamDetailAction());
    HttpRequestGet(
      '/pro/team/profile',
      dispatch,
      getTeamDetailSuccessAction,
      getTeamDetailFailureAction,
      true
    );
  };
}

export function clearGetTeamDetail() {
  return (dispatch: any) => {
    dispatch(clearGetTeamDetailAction());
  };
}
