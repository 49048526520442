const ORDER_DETAIL = 'ORDER_DETAIL';
const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS';
const ORDER_DETAIL_FAILURE = 'ORDER_DETAIL_FAILURE';
const CLEAR_ORDER_DETAIL = 'CLEAR_ORDER_DETAIL';

const OrderDetailSymbols = {
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAILURE,
  ORDER_DETAIL,
  CLEAR_ORDER_DETAIL
};

export default OrderDetailSymbols;
