import RESEND_INVITATION from '../symbols/resendInvitation';
import { HttpRequestPost } from '../../service';

const resendInvitationAction = () => ({
  type: RESEND_INVITATION.RESEND_INVITATION
});
const resendInvitationSuccessAction = () => {
  return {
    type: RESEND_INVITATION.RESEND_INVITATION_SUCCESS
  };
};
const resendInvitationFailureAction = (data: any) => ({
  type: RESEND_INVITATION.RESEND_INVITATION_FAILURE,
  payload: {
    data
  }
});
const clearResendInvitationAction = () => ({
  type: RESEND_INVITATION.CLEAR_RESEND_INVITATION
});

export function ResendInvitation(id: string, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(resendInvitationAction());
    HttpRequestPost(
      `/pro/invitation/${id}/resend`,
      dispatch,
      resendInvitationSuccessAction,
      resendInvitationFailureAction,
      true,
      callback
    );
  };
}

export function clearResendInvitation() {
  return (dispatch: any) => {
    dispatch(clearResendInvitationAction());
  };
}
