/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import { Box, Divider, Typography, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import AvailableCourierItem, {
  IAvailableCourierItemProps
} from '../components-molecules/AvailableCourierItem';
import { colours } from '../constants/theme';

interface IOnChange {
  ids: string[];
  isPremium?: boolean;
}
interface ICollectionOrderCourierSelectionProps {
  couriers?: {
    AvailableCouriers?: IAvailableCourierItemProps[];
    Premium?: IAvailableCourierItemProps;
  };
  onChange: (body: IOnChange) => void;
  hasError: boolean;
  selectedCouriers: string[];
  setSelectedCouriers: (value: string[]) => void;
  onSubmit: (id: string) => void;
}

const useStyles = makeStyles(() => ({
  description: {
    marginTop: 8
  }
}));

export default function CollectionOrderCourierSelection(
  props: ICollectionOrderCourierSelectionProps
) {
  const { couriers, onChange, hasError, selectedCouriers, setSelectedCouriers, onSubmit } = props;
  const classes = useStyles();

  function onAccept(id: string) {
    const isPremium = couriers?.Premium?.Id === id;
    const newSelectedCouriers = selectedCouriers.includes(id)
      ? selectedCouriers.filter((sc) => sc !== id)
      : [...selectedCouriers, id];
    setSelectedCouriers(newSelectedCouriers);
    onChange({ ids: newSelectedCouriers, isPremium });
  }

  const onPremierAccept = (id: string) => {
    onSubmit(id);
  };

  function onRemove(id: string) {
    const newSelectedCouriers = selectedCouriers.filter((sc) => sc !== id);
    setSelectedCouriers(newSelectedCouriers);
    onChange({ ids: newSelectedCouriers });
  }

  function renderCouriers() {
    if (!couriers?.AvailableCouriers) {
      return null;
    }
    return (
      <>
        <Typography variant="h6" color="primary">
          Available couriers
        </Typography>
        <Typography variant="body2" className={classes.description}>
          Select the courier or couriers you prefer to deliver your parcel. You can select multiple
          couriers to maximise the chance of a speedy assignment.
        </Typography>

        {couriers.AvailableCouriers?.map((c, i) => (
          <Box key={c.Name} style={{ marginTop: i === 0 ? 16 : 0 }}>
            <AvailableCourierItem
              {...c}
              onAccept={onAccept}
              onRemove={onRemove}
              selected={Boolean(selectedCouriers.find((sc) => sc === c.Id))}
              hasError={hasError}
            />
            {couriers.AvailableCouriers?.length !== i + 1 && (
              <Divider style={{ margin: '16px 0', background: colours.dark08 }} />
            )}
          </Box>
        ))}
      </>
    );
  }

  function renderPremierService() {
    if (!couriers?.Premium) {
      return null;
    }
    return (
      <Box mt={2}>
        <Typography variant="h6" color="primary">
          Concierge Service
        </Typography>
        <Typography variant="body2" className={classes.description}>
          Leave it to us! Our Concierge team selects the best courier to meet your needs, ensuring a
          timely delivery
        </Typography>

        <Box mt={2}>
          <AvailableCourierItem
            onAccept={onAccept}
            onRemove={onRemove}
            selected={Boolean(selectedCouriers.find((sc) => sc === couriers?.Premium?.Id))}
            {...couriers?.Premium}
            hasError={hasError}
            isPremier
            onPremierAccept={onPremierAccept}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      {renderCouriers()}
      {renderPremierService()}
    </Box>
  );
}
