import GetCollectionTime from '../symbols/getCollectionTime';
import { HttpRequestGet } from '../../service';

export const TIMESLOT_URL = `/collect/timeslot`;

const getCollectionTimeAction = () => ({
  type: GetCollectionTime.GET_COLLECTION_TIME
});
const getCollectionTimeSuccessAction = (data: any) => ({
  type: GetCollectionTime.GET_COLLECTION_TIME_SUCCESS,
  payload: data
});
const getCollectionTimeFailureAction = (data: any) => ({
  type: GetCollectionTime.GET_COLLECTION_TIME_FAILURE,
  payload: data
});
const clearGetCollectionTimeAction = () => ({
  type: GetCollectionTime.CLEAR_GET_COLLECTION_TIME
});
export function getCollectionTime() {
  return (dispatch: any) => {
    dispatch(getCollectionTimeAction());
    HttpRequestGet(
      TIMESLOT_URL,
      dispatch,
      getCollectionTimeSuccessAction,
      getCollectionTimeFailureAction,
      true,
      () => {}
    );
  };
}

export function clearCollectionTime() {
  return (dispatch: any) => {
    dispatch(clearGetCollectionTimeAction());
  };
}
