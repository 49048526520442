import { MouseEvent, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import { makeStyles, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ITheme } from '../constants/theme';

const useStyles = makeStyles((myTheme: ITheme) => ({
  menuList: {
    padding: '24px 0',
    '& .MuiListItem-button': {
      borderRadius: '8px',
      margin: '8px 4px',
      padding: '8px 12px'
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: `${myTheme.palette.secondary.main} !important`
    }
  }
}));
interface IProps {
  children: JSX.Element | JSX.Element[];
}
export default function ThreeDotMenu({ children }: IProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="three-dot-menu"
        aria-haspopup="true"
        style={{ padding: 8 }}
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        PaperProps={{
          style: {
            minWidth: '256px',
            borderRadius: '12px'
          }
        }}
        MenuListProps={{
          className: classes.menuList
        }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        onClick={handleClose}>
        {children}
      </Menu>
    </div>
  );
}
