import { CircularProgress, Container } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GroupHeader } from '../components-atoms';
import { AddressItem } from '../components-molecules';
import { useAppSelector } from '../hooks/store';
import { DeliveryAddress } from '../redux/reducers/deliveryAddresses';

interface IProps {
  loadMore: () => void;
  // eslint-disable-next-line no-unused-vars
  onEdit: (address: DeliveryAddress) => void;
  // eslint-disable-next-line no-unused-vars
  onRemove: (id: string) => void;
}
export function AddressList({ loadMore, onEdit, onRemove }: IProps) {
  const [addresses] = useAppSelector((state) => [state.deliveryAddresses.data]);
  return (
    <InfiniteScroll
      style={{ overflow: 'hidden' }}
      dataLength={addresses.Result.map((m) => m.Result).flat().length} // This is important field to render the next data
      next={() => loadMore()}
      scrollThreshold={0.8}
      hasMore={addresses.Result.map((m) => m.Result).flat().length !== addresses.Count}
      loader={
        <Container style={{ width: '100%' }}>
          <CircularProgress />
        </Container>
      }>
      {addresses.Result.map((item, index) => (
        <div key={item.GroupName}>
          {item.Result.length > 0 && (
            <GroupHeader
              idx={index}
              data-automation="number-of-addresses"
              headlineType="Addresses"
              header={item.GroupName}
              count={item.Result.length}
            />
          )}
          {item.Result.map((address) => (
            <AddressItem onEdit={onEdit} onRemove={onRemove} key={address.Name} data={address} />
          ))}
        </div>
      ))}
    </InfiniteScroll>
  );
}
