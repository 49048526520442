import { SVGProps } from 'react';

function RightArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask
        id="rightArrow"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={8}
        y={6}
        width={8}
        height={12}>
        <path
          d="M9 15.875L12.88 11.995L9 8.11498C8.81275 7.92814 8.70752 7.67449 8.70752 7.40998C8.70752 7.14546 8.81275 6.89181 9 6.70498C9.39 6.31498 10.02 6.31498 10.41 6.70498L15 11.295C15.39 11.685 15.39 12.315 15 12.705L10.41 17.295C10.02 17.685 9.39 17.685 9 17.295C8.62 16.905 8.61 16.265 9 15.875Z"
          fill="black"
        />
      </mask>
      <g mask="url(#rightArrow)">
        <path d="M24 0H0V24H24V0Z" fill="black" fillOpacity="0.54" />
      </g>
    </svg>
  );
}

export default RightArrow;
