/* eslint-disable react/destructuring-assignment */

import ORDERS from '../symbols/orders';

export interface Order {
  OrderId: string;
  OrderNo: number;
  OrderPlaced: Date;
  Amount: number;
  Customer: string;
  IsMultipleDelivery: boolean;
  StartDate: Date;
  EndDate: Date;
  DeliveryStatuses: { Status: string; Count: number }[];
  TkProOrderStatus: string;
}
export interface OrderResponseModel {
  Count: number;
  Result: [string, Order[]][];
}
interface State {
  data: OrderResponseModel;
  loading: boolean;
  error: any;
}
const initialState: State = {
  data: { Count: 0, Result: [] },
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function OrdersReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case ORDERS.ORDERS:
      return { ...state, loading: true, error: false };
    case ORDERS.ORDERS_SUCCESS:
      return {
        ...state,
        data: action.payload as OrderResponseModel,
        loading: false,
        error: false
      };
    case ORDERS.ORDERS_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case ORDERS.CLEAR_ORDERS:
      return {
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default OrdersReducer;
