import ERROR from '../symbols/error';

const setErrorAction = (payload: any) => ({
  type: ERROR.SET_ERROR,
  payload
});
const clearErrorAction = () => ({
  type: ERROR.CLEAR_ERROR
});

export function setError(errorMessage: string | unknown) {
  return (dispatch: any) => {
    dispatch(setErrorAction(errorMessage));
  };
}

export function clearError() {
  return (dispatch: any) => {
    dispatch(clearErrorAction());
  };
}
