import { makeStyles } from '@material-ui/core';
import { ITheme } from '../../constants/theme';
import { headerButtonsResponsive, textButtonHoverAndActive } from './buttonStyles';

export const collectionOrdersStyles = makeStyles((theme: ITheme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '@media (min-width: 900px)': {
      maxWidth: '840px'
    }
  },
  buttonSet: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    minHeight: 40,
    '@media (max-width: 899px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '8px 4px',
      padding: '8px 12px'
    }
  },
  searchIcon: {
    width: 24,
    height: 24,
    '@media(max-width: 899px)': {
      width: 20,
      height: 20,
      marginLeft: -4,
      marginRight: 8
    }
  },
  searchIconButton: {
    width: 40,
    height: 40,
    ...headerButtonsResponsive,
    ...textButtonHoverAndActive
  },
  uPaddingLeft: {
    paddingLeft: 24,
    '@media (max-width: 899px)': {
      paddingLeft: 16
    }
  },
  tabContainer: {
    marginTop: 13,
    paddingLeft: '24px',
    paddingRight: '24px',
    backgroundColor: theme.palette.background.paper
  },
  addNewBtn: {
    height: 36,
    '@media(max-width: 899px)': {
      width: '100%'
    }
  }
}));
