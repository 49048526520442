import ORDER_SEARCH from '../symbols/orderSearch';

const orderSearchSetQueryAction = (data: string) => ({
  type: ORDER_SEARCH.SET_QUERY,
  payload: data
});
const clearOrdersAction = () => ({
  type: ORDER_SEARCH.CLEAR_ORDER_SEARCH
});
export function setOrderSearchQuery(query: string) {
  return (dispatch: any) => {
    dispatch(orderSearchSetQueryAction(query));
  };
}

export function clearOrderSearchQuery() {
  return (dispatch: any) => {
    dispatch(clearOrdersAction());
  };
}
