import { SVGProps } from 'react';

function AddMemberIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="addMember"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={3}
        width={18}
        height={12}>
        <path
          d="M11.25 9a3 3 0 1 0 0-6 3 3 0 1 0 0 6Zm0-4.5c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5-1.5-.675-1.5-1.5.675-1.5 1.5-1.5Zm.136 6.002-.136-.002c-2.002 0-6 1.005-6 3v.75c0 .414.336.75.75.75h10.5a.75.75 0 0 0 .75-.75v-.75c0-1.95-3.822-2.955-5.864-2.998ZM6.75 13.5c.165-.54 2.482-1.5 4.5-1.5 2.025 0 4.35.967 4.5 1.5h-9Zm-2.25-3V9H6a.75.75 0 0 0 0-1.5H4.5V6A.75.75 0 0 0 3 6v1.5H1.5a.75.75 0 0 0 0 1.5H3v1.5a.75.75 0 0 0 1.5 0Z"
          fill="#000"
        />
      </mask>
      <g mask="url(#addMember)">
        <path d="M18 0H0v18h18V0Z" fill="#FF0EAF" />
      </g>
    </svg>
  );
}

export default AddMemberIcon;
