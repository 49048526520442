import * as React from 'react';
import { SVGProps } from 'react';

function UncheckedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="unchecked"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={3}
        width={18}
        height={18}>
        <path
          d="M17 3a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h10Zm0 2H7a2 2 0 0 0-1.995 1.85L5 7v10a2 2 0 0 0 1.85 1.994L7 19h10a2 2 0 0 0 1.994-1.85L19 17V7a2 2 0 0 0-1.85-1.995L17 5Z"
          fill="#000"
        />
      </mask>
      <g mask="url(#unchecked)">
        <path d="M24 0H0v24h24V0Z" fill="#000" fillOpacity={0.54} />
      </g>
    </svg>
  );
}

export default UncheckedIcon;
