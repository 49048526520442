/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MouseEvent, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  IconButton,
  Menu,
  Typography,
  useMediaQuery
} from '@material-ui/core/';
import { ThemeProvider, createStyles, makeStyles } from '@material-ui/core/styles';
import { AccountCircleOutlined } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { DropdownMenuButton, Stamp } from '../components-atoms';
import { signOutAndClearReducers } from '../redux/actions/signOutAndClearAllReducers';
import { colours, theme } from '../constants/theme';
import tradeKartLogo from '../assets/icons/tradeKartLogo.svg';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { useAppWebSocket } from '../hooks/useAppWebSocket';

interface IProps {
  isLoggedIn?: boolean;
  selectedSortType?: number;
  handleMenuClick?: () => void;
}
const useStyles = makeStyles(
  createStyles({
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 1201,
      display: 'flex',
      backgroundColor: colours.highEmphasis
    },
    subContainer: {
      height: 64,
      alignItems: 'center',
      paddingLeft: 24,
      paddingRight: 24,
      '@media (max-width: 1279px)': {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    title: {
      color: colours.primary,
      fontWeight: 700,
      alignSelf: 'center',
      marginLeft: '20px',
      marginRight: '20px'
    },
    secondaryTitle: {
      color: colours.primary,
      alignSelf: 'center'
    },
    divider: {
      position: 'static',
      width: '1px',
      height: '36px',
      left: '128px',
      top: '0px',
      alignSelf: 'center',
      marginLeft: 20,
      marginRight: 20,
      background: 'rgba(255, 255, 255, 0.38)'
    },
    menu: {
      marginTop: 40,
      borderRadius: 12
    },
    root: {
      width: 256,
      padding: '12px 4px',
      borderRadius: 12
    },
    menuButton: {
      color: 'white',
      padding: 8,
      marginTop: -2,
      marginRight: 8,
      '@media (min-width: 1280px)': {
        display: 'none'
      }
    },
    userProfileButton: {
      padding: 8
    }
  })
);

interface SocketMessage {
  HasCredits: boolean;
  RemainingCredits: number;
}

function Header({ isLoggedIn, selectedSortType, handleMenuClick }: IProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const greaterThan900 = useMediaQuery('(min-width: 900px)', { noSsr: true });
  const [profile, token] = useAppSelector((state) => [
    (state.profile as any).data,
    state.login.token
  ]);
  const dispatch = useAppDispatch();
  const { lastJsonMessage } = useAppWebSocket<SocketMessage>(
    '/ws/remaining-credits',
    {
      queryParams: { t: token }
    },
    Boolean(token)
  );

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    dispatch(signOutAndClearReducers());
    if ((window as any).HubSpotConversations && (window as any).HubSpotConversations.widget) {
      (window as any).HubSpotConversations.widget.remove();
    }
  };

  return (
    <Box className={classes.container}>
      <ThemeProvider theme={theme}>
        <Grid container className={classes.subContainer}>
          <Grid container justifyContent="flex-start" item style={{ height: 36 }}>
            {isLoggedIn && (
              <IconButton onClick={handleMenuClick} className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
            )}
            <img
              onClick={() => {
                if (token) {
                  window.location.href = '/delivery-dashboard';
                }
              }}
              src={tradeKartLogo}
              style={{ width: '187px', height: '36px', cursor: token ? 'pointer' : 'auto' }}
              alt="TradeKart logo"
            />
            {profile?.ProTeamName && greaterThan900 ? (
              <>
                <div className={classes.divider} />
                <Typography className={classes.secondaryTitle} variant="h6" component="p">
                  {profile.ProTeamName}
                </Typography>
              </>
            ) : null}
            {isLoggedIn && (
              <Box display="flex" flex={1} justifyContent="flex-end" alignItems="center">
                <Box>
                  {lastJsonMessage?.HasCredits ? (
                    <Stamp
                      text={`${lastJsonMessage.RemainingCredits} credits left`}
                      type={lastJsonMessage.RemainingCredits > 10 ? 'primary' : 'error'}
                    />
                  ) : null}
                </Box>
                <IconButton
                  id="user-profile-button"
                  aria-label="User profile"
                  aria-controls={open ? 'user-profile-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  className={classes.userProfileButton}
                  onClick={handleClick}>
                  <AccountCircleOutlined htmlColor="#fff" />
                </IconButton>
                <Menu
                  id="user-profile-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'user-profile-button',
                    role: 'listbox'
                  }}
                  className={classes.menu}>
                  <Container className={classes.root}>
                    <DropdownMenuButton
                      title="Logout"
                      handleClick={signOut}
                      selected={selectedSortType === 1}
                    />
                  </Container>
                </Menu>
              </Box>
            )}
          </Grid>
        </Grid>
      </ThemeProvider>
    </Box>
  );
}

export default Header;
