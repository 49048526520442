/* eslint-disable prettier/prettier */
const defaultConfig = {
  api: {
    baseUrl: 'https://tk-api.hncprod.co.uk'
  },
  ws: {
    baseUrl: 'wss://tk-api.hncprod.co.uk'
  },
  env: 'prod',
  stripeKey:
    'pk_live_51J69beHIomXFmWEwviHIInEe1Y3kHcvz1TyONMP31tpMSwB8lvXHSxfMiETbqfXCzStQx2tsEavnosGe8ZXIXSFl00Eoy82UH3',
  googleApiKey: 'AIzaSyBA7deQW90be3BcQdL-8WY-Wbo1dzVSHiQ',
  showOrkestroChat: false,
  hsLiveChatUrl: 'https://js.hs-scripts.com/25206466.js'
};
export default defaultConfig;
