import { Box, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { getDeliveryWindow, getOrderDate } from '../utils';

import { Order } from '../redux/reducers/orders';
import { OrderStamp } from '.';
import { ITheme } from '../constants/theme';
import { Stamp } from '../components-atoms';
import { ORDER_STAMP_ERROR_STATUSES, ORDER_STAMP_WARNING_STATUSES } from '../constants/global';
import { textButtonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles((theme: ITheme) => ({
  container: {
    marginBottom: '24px'
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 16px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'all 200ms ease',
    '@media (min-width: 900px)': {
      padding: 24,
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '20px'
    },
    ...textButtonHoverAndActive
  },
  rootWarning: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 16px',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.warning[8],
    cursor: 'pointer',
    transition: 'all 200ms ease',
    '@media (min-width: 900px)': {
      padding: 24,
      border: `1px solid ${theme.palette.warning[50]}`,
      borderRadius: '20px'
    },
    '&:hover': {
      background: theme.palette.warning[12]
    }
  },
  gridMargin: {
    marginTop: 8
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: 'auto',
    '@media (max-width: 899px)': {
      marginLeft: 0,
      alignItems: 'flex-start'
    }
  },
  leftContainer: {
    display: 'flex',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  topContainer: {
    '@media (max-width: 899px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  stamp: {
    '@media (max-width: 599px)': {
      alignSelf: 'flex-start',
      marginTop: 8,
      marginBottom: 8,
      marginLeft: 0
    }
  }
}));
interface IProps {
  data: Order;
  // eslint-disable-next-line no-unused-vars
  onClick: (id: string) => void;
  isWarning?: boolean;
}
export function OrderItem({ data, onClick, isWarning }: IProps) {
  const classes = useStyles();
  return (
    <Box
      data-order="order-item"
      onClick={() => onClick(data.OrderId)}
      className={classes.container}>
      <Container className={isWarning ? classes.rootWarning : classes.root}>
        <Grid item container>
          <Grid container alignItems="center" className={classes.topContainer}>
            <Grid className={classes.leftContainer}>
              <Typography variant="h6" component="span" color="textPrimary">
                {data.OrderNo}
              </Typography>
              {ORDER_STAMP_WARNING_STATUSES.includes(data.TkProOrderStatus) ? (
                <Stamp
                  type="warningActive"
                  text={data.TkProOrderStatus}
                  className={classes.stamp}
                />
              ) : ORDER_STAMP_ERROR_STATUSES.includes(data.TkProOrderStatus) ? (
                <OrderStamp text={data.TkProOrderStatus} className={classes.stamp} />
              ) : (
                data.DeliveryStatuses.map((m) => (
                  <OrderStamp
                    key={m.Status}
                    text={m.Status}
                    count={m.Count}
                    className={classes.stamp}
                  />
                ))
              )}
            </Grid>
            <Grid className={classes.rightContainer}>
              <Typography variant="body2" component="span" color="textSecondary">
                Delivery window
              </Typography>
              <Typography
                variant="subtitle2"
                component="span"
                color="textPrimary"
                className={classes.gridMargin}>
                {data.IsMultipleDelivery
                  ? 'Multiple delivery windows'
                  : getDeliveryWindow(data.StartDate.toString(), data.EndDate.toString())}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container className={classes.gridMargin}>
            <Typography
              variant="body2"
              component="span"
              color="textSecondary"
              style={{ textAlign: 'left' }}>
              {getOrderDate(data.OrderPlaced.toString())} · £{data.Amount / 100} · Purchased by:{' '}
              {data.Customer}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
