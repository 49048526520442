// @ts-nocheck

export const getMultipleValuesAsText = (v: Array<string>) => {
  if (v && v.length > 0) {
    return new Intl.ListFormat('en', { style: 'narrow' }).format(
      v.filter((i) => i && i.length > 1)
    );
  }
  return '';
};

export const getMultipleAddressAsText = (v: Array<string>) => {
  return v.join(' ').trim();
};
