import APPROVE_ORDER from '../symbols/approveOrder';
import { HttpRequestPost } from '../../service';

const approveOrderAction = () => ({
  type: APPROVE_ORDER.APPROVE_ORDER
});
const approveOrderSuccessAction = (data: any) => {
  return {
    type: APPROVE_ORDER.APPROVE_ORDER_SUCCESS,
    payload: data
  };
};
const approveOrderFailureAction = (data: any) => {
  return {
    type: APPROVE_ORDER.APPROVE_ORDER_FAILURE,
    payload: {
      data: data.data
    }
  };
};
const clearApproveOrderAction = () => ({
  type: APPROVE_ORDER.CLEAR_APPROVE_ORDER
});
// eslint-disable-next-line no-unused-vars
export function ApproveOrder(id: string, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(approveOrderAction());
    HttpRequestPost(
      `/pro/order/${id}/approve`,
      dispatch,
      approveOrderSuccessAction,
      approveOrderFailureAction,
      true,
      callback
    );
  };
}

export function clearApproveOrder() {
  return (dispatch: any) => {
    dispatch(clearApproveOrderAction());
  };
}
