const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
const CLEAR_CHANGE_PASSWORD = 'CLEAR_CHANGE_PASSWORD';
const CLEAR_ERROR = 'CLEAR_ERROR';

const ChangePasswordSymbols = {
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD,
  CLEAR_CHANGE_PASSWORD,
  CLEAR_ERROR
};

export default ChangePasswordSymbols;
