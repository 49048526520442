/* eslint-disable react/destructuring-assignment */
import { TextFieldProps } from '@material-ui/core';
import { useController, UseControllerProps } from 'react-hook-form';
import { TextFieldInput } from '.';

type IProps<T> = UseControllerProps<T> & TextFieldProps;

export function ControlledInput<T>(rest: IProps<T>) {
  const { field, fieldState } = useController(rest);
  return (
    <TextFieldInput
      id={field.name}
      autoComplete={field.name}
      {...field}
      {...rest}
      error={fieldState.invalid}
      helperText={fieldState.error ? fieldState.error.message || rest.helperText : rest.helperText}
    />
  );
}
