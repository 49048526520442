import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useState } from 'react';

import imagePlaceHolder from '../assets/icons/imagePlaceHolder.svg';
import { theme } from '../constants/theme';
import { Product } from '../redux/reducers/orderDetail';

const useStyles = makeStyles(() => ({
  topContainer: {
    flexWrap: 'nowrap',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignContent: 'flex-start'
    }
  },
  imageContainer: {
    width: 'fit-content'
  },
  titleContainer: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    textAlign: 'left',
    paddingLeft: 16,
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: 16,
      paddingLeft: 0
    }
  },
  itemImage: {
    width: '56px',
    height: '56px',
    borderRadius: 8,
    objectFit: 'contain'
  },
  uCapitalize: {
    textTransform: 'capitalize'
  },
  itemDetailContainer: {
    marginTop: 15,
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'stretch'
    }
  },
  detailContainer: {
    marginLeft: 48,
    maxHeight: 49,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media (max-width: 599px)': {
      flexDirection: 'row',
      marginTop: 9,
      marginLeft: 0
    }
  },
  margin: {
    marginLeft: 16,
    '@media (max-width: 599px)': {
      marginTop: 16,
      marginLeft: 0
    }
  },
  mt4: {
    marginTop: 4,
    '@media (max-width: 599px)': {
      marginTop: 0
    }
  },
  cancelledColor: {
    color: 'rgba(229, 76, 53, 1)',
    fontWeight: 600
  },
  fw400: {
    fontWeight: 400
  }
}));
interface IProps {
  item: Product;
  status: string;
}
export function OrderedItemsListItem({ item, status }: IProps) {
  const classes = useStyles();
  const [imageLoadingError, setImageLoadingError] = useState(false);
  const itemImage = item.ProductImageUrl;

  const itemNotAvailable = !!(item.OrderLineQuantity === 0 || status === 'RejectedDelivery');
  const quantityUpdated = item.OrderLineOriginalQuantity !== item.OrderLineQuantity;
  const hasErrorColor = item.OrderLineCancelled || quantityUpdated;
  const itemNotAvailableColor = () => {
    if (status === 'FailedDelivery') {
      return theme.palette.warning.main;
    }
    return itemNotAvailable ? theme.palette.error.main : undefined;
  };

  const getItemOptions = (product: Product) => {
    const options = [
      {
        Name: product.ProductOptionName1,
        Value: product.OrderLineVariantOption1
      },
      {
        Name: product.ProductOptionName2,
        Value: product.OrderLineVariantOption2
      },
      {
        Name: product.ProductOptionName3,
        Value: product.OrderLineVariantOption3
      }
    ];

    const response: JSX.Element[] = [];
    if (item.OrderLineColorNote) {
      response.push(
        <div className={classes.detailContainer}>
          <Typography
            variant="caption"
            component="span"
            align="right"
            color={hasErrorColor ? 'error' : 'textSecondary'}
            style={{
              color: itemNotAvailableColor(),
              display: 'inline-block',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
            className={classes.fw400}>
            Colour note
          </Typography>
          <Typography
            variant="body2"
            component="span"
            align="right"
            color={hasErrorColor ? 'error' : 'textPrimary'}
            style={{
              color: itemNotAvailableColor(),
              fontWeight: 600
            }}
            className={classes.mt4}>
            {item.OrderLineColorNote}
          </Typography>
        </div>
      );
    }

    options.map((optionItem: any) => {
      if (!optionItem.Name || optionItem.Name === 'Title') {
        return null;
      }
      response.push(
        <div key={optionItem.Name} className={classes.detailContainer}>
          <Typography
            variant="caption"
            component="span"
            align="right"
            color={hasErrorColor ? 'error' : 'textSecondary'}
            style={{
              color: itemNotAvailableColor()
            }}
            className={classes.fw400}>
            {optionItem.Name}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            align="right"
            color={hasErrorColor ? 'error' : 'textPrimary'}
            style={{
              color: itemNotAvailableColor(),
              fontWeight: 600,
              marginTop: 4
            }}
            className={classes.uCapitalize}>
            {optionItem.Value}
          </Typography>
        </div>
      );
      return null;
    });

    return response;
  };

  const getQuantityText = () => {
    const { OrderLineQuantity, OrderLineOriginalQuantity } = item;
    let text: string | number = OrderLineQuantity;

    if (itemNotAvailable) {
      text = 'Not available';
    }
    if (quantityUpdated) {
      text = `${OrderLineQuantity} of ${OrderLineOriginalQuantity} (Updated)`;
    }

    return text;
  };

  const getCancelledItemsPrice = () => {
    const { CancelledItemsTotalAmount } = item;
    return (CancelledItemsTotalAmount / 100).toFixed(2);
  };

  const getSubtotal = () => {
    const { SubTotal } = item;
    return (SubTotal / 100).toFixed(2);
  };

  return (
    <>
      <Grid container item direction="row" className={classes.topContainer}>
        <Grid item container alignItems="center" className={classes.imageContainer}>
          {imageLoadingError || itemImage === undefined ? (
            <img src={imagePlaceHolder} className={classes.itemImage} alt="Item" />
          ) : (
            <img
              src={itemImage}
              className={classes.itemImage}
              alt="Item"
              onError={() => {
                setImageLoadingError(true);
              }}
            />
          )}
        </Grid>
        <Grid item container alignItems="center" className={classes.titleContainer}>
          <Typography
            variant="body1"
            component="p"
            color={hasErrorColor ? 'error' : 'textPrimary'}
            style={{
              color: itemNotAvailableColor(),
              fontWeight: 600
            }}
            className={classes.uCapitalize}>
            {item.ProductTitle}
          </Typography>
          {item.OrderLineCancelled ? (
            <Grid className={classes.margin}>
              <Typography
                variant="body2"
                component="span"
                align="right"
                className={classes.cancelledColor}>
                Cancelled
              </Typography>
            </Grid>
          ) : quantityUpdated ? (
            <Grid className={classes.margin}>
              <Typography
                variant="body2"
                component="span"
                align="right"
                className={classes.cancelledColor}
                style={{ whiteSpace: 'nowrap' }}>
                Quantity Updated
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        justifyContent="flex-end"
        className={classes.itemDetailContainer}>
        {getItemOptions(item)}
        <div className={classes.detailContainer}>
          <Typography
            variant="caption"
            component="span"
            color={hasErrorColor ? 'error' : 'textSecondary'}
            align="right"
            style={{
              color: itemNotAvailableColor()
            }}
            className={classes.fw400}>
            Quantity
          </Typography>
          <Typography
            variant="body2"
            component="span"
            color={hasErrorColor ? 'error' : 'textPrimary'}
            align="right"
            style={{
              color: itemNotAvailableColor(),
              fontWeight: 600
            }}
            className={classes.mt4}>
            {getQuantityText()}
          </Typography>
        </div>
        <div className={classes.detailContainer}>
          <Typography
            variant="caption"
            component="span"
            color={hasErrorColor ? 'error' : 'textSecondary'} // replace true with orderCancelled:boolean
            align="right"
            style={{
              color: itemNotAvailableColor()
            }}
            className={classes.fw400}>
            Price per unit
          </Typography>
          <Typography
            variant="body2"
            component="span"
            color={hasErrorColor ? 'error' : 'textPrimary'}
            align="right"
            style={{
              color: itemNotAvailableColor(),
              fontWeight: 600
            }}
            className={classes.mt4}>
            £{(item.OrderLineAmountPerUnit / 100).toFixed(2)}
          </Typography>
        </div>
        {hasErrorColor && (
          <div className={classes.detailContainer}>
            <Typography
              variant="caption"
              component="span"
              color={hasErrorColor ? 'error' : 'textSecondary'} // replace true with orderCancelled:boolean
              align="right"
              style={{
                color: itemNotAvailableColor()
              }}
              className={classes.fw400}>
              Cancelled items
            </Typography>
            <Typography
              variant="body2"
              component="span"
              color={hasErrorColor ? 'error' : 'textPrimary'}
              align="right"
              style={{
                color: itemNotAvailableColor(),
                fontWeight: 600
              }}
              className={classes.mt4}>
              £{getCancelledItemsPrice()}
            </Typography>
          </div>
        )}
        <div className={classes.detailContainer}>
          <Typography
            variant="caption"
            component="span"
            color={hasErrorColor ? 'error' : 'textSecondary'}
            align="right"
            style={{
              color: itemNotAvailableColor()
            }}
            className={classes.fw400}>
            Subtotal
          </Typography>
          <Typography
            variant="body2"
            component="span"
            color={hasErrorColor ? 'error' : 'textPrimary'}
            align="right"
            style={{
              color: itemNotAvailableColor(),
              fontWeight: 600
            }}
            className={classes.mt4}>
            £{getSubtotal()}
          </Typography>
        </div>
      </Grid>
    </>
  );
}
