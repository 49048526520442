/* eslint-disable react/destructuring-assignment */
function OrdersIcon(props: any) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="orders"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={2}
        width={18}
        height={20}>
        <path
          d="M8 16h5a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2Zm0-4h8a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2Zm4-10a2.5 2.5 0 0 1 2.45 2H17a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h2.55A2.5 2.5 0 0 1 12 2ZM7 6a2 2 0 0 0-1.995 1.85L5 8v10a2 2 0 0 0 1.85 1.994L7 20h10a2 2 0 0 0 1.994-1.85L19 18V8a2 2 0 0 0-1.85-1.995L17 6H7Zm9 2a1 1 0 1 1 0 2H8a1 1 0 0 1 0-2h8Zm-4-4.25a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z"
          fill={props.color || '#000'}
        />
      </mask>
      <g mask="url(#orders)">
        <path d="M24 0H0v24h24V0Z" fill={props.color || '#000'} fillOpacity={props.opacity || 1} />
      </g>
    </svg>
  );
}

export default OrdersIcon;
