import { Typography, TypographyProps } from '@material-ui/core';
import { useEffect, useState } from 'react';

interface ICountdownProps extends TypographyProps {
  time: number;
  onEnd: () => void;
}

export function Countdown(props: ICountdownProps) {
  const { time, onEnd, ...rest } = props;
  const [delay, setDelay] = useState<number>(time);
  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);
  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay <= 0) {
      clearInterval(timer);
      onEnd();
    }

    return () => {
      clearInterval(timer);
    };
  });
  return (
    <Typography {...rest}>
      {minutes > 0 ? `${minutes} min ` : ''}
      {`${seconds}s`}
    </Typography>
  );
}
