import SEARCH_ADDRESS_DETAILS from '../symbols/searchAddressDetails';
import { HttpRequestGet } from '../../service';

const searchAddressDetailsAction = () => ({
  type: SEARCH_ADDRESS_DETAILS.SEARCH_ADDRESS_DETAILS
});
const searchAddressDetailsSuccessAction = (data: any) => {
  return {
    type: SEARCH_ADDRESS_DETAILS.SEARCH_ADDRESS_DETAILS_SUCCESS,
    payload: data.Result
  };
};
const searchAddressDetailsFailureAction = (data: any) => ({
  type: SEARCH_ADDRESS_DETAILS.SEARCH_ADDRESS_DETAILS_FAILURE,
  payload: {
    data
  }
});
const clearSearchAddressDetailsAction = () => ({
  type: SEARCH_ADDRESS_DETAILS.CLEAR_SEARCH_ADDRESS_DETAILS
});

export function SearchAddressDetails(id: string, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(searchAddressDetailsAction());
    HttpRequestGet(
      `/places/${id}`,
      dispatch,
      searchAddressDetailsSuccessAction,
      searchAddressDetailsFailureAction,
      true,
      callback
    );
  };
}

export function clearSearchAddressDetails() {
  return (dispatch: any) => {
    dispatch(clearSearchAddressDetailsAction());
  };
}
