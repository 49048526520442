import useWebSocket, { Options } from 'react-use-websocket';
import config from '../config';

export function useAppWebSocket<T>(
  url: string | (() => string | Promise<string>) | null,
  options?: Options | undefined,
  connect?: boolean | undefined
) {
  const interval = 30 * 1000;
  const timeout = 7 * 24 * 60 * 60 * 1000; // 10 * 60 * 1000;
  const message = 'ping';

  return useWebSocket<T>(
    `${config.ws.baseUrl}${url}`,
    {
      ...options,
      heartbeat: { message, interval, timeout }
    },
    connect
  );
}
