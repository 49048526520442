const UPDATE_PAYMENT_ID = 'UPDATE_PAYMENT_ID';
const UPDATE_PAYMENT_ID_SUCCESS = 'UPDATE_PAYMENT_ID_SUCCESS';
const UPDATE_PAYMENT_ID_FAILURE = 'UPDATE_PAYMENT_ID_FAILURE';
const CLEAR_UPDATE_PAYMENT_ID = 'CLEAR_UPDATE_PAYMENT_ID';
const UPDATE_PAYMENT_ID_SET_LOADING = 'UPDATE_PAYMENT_ID_SET_LOADING';

const UpdatePaymentIdSymbols = {
  UPDATE_PAYMENT_ID_SUCCESS,
  UPDATE_PAYMENT_ID_FAILURE,
  UPDATE_PAYMENT_ID,
  CLEAR_UPDATE_PAYMENT_ID,
  UPDATE_PAYMENT_ID_SET_LOADING
};

export default UpdatePaymentIdSymbols;
