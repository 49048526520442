/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import SwapCallsRoundedIcon from '@material-ui/icons/SwapCallsRounded';
import FlashOnRoundedIcon from '@material-ui/icons/FlashOnRounded';
import { CourierPriceResult, ICourierServiceType } from '../redux/reducers/orderDetail';
import { ButtonPrimary, ButtonSecondary } from '../components-atoms';
import { colours } from '../constants/theme';

export interface IAvailableCourierItemProps {
  Id: string;
  Name: string;
  Logo: string;
  Price: CourierPriceResult;
  ServiceType: ICourierServiceType;
  selected?: boolean;
  onAccept?: (e: string) => void;
  onPremierAccept?: (e: string) => void;
  onRemove?: (e: string) => void;
  hasError?: boolean;
  isPremier?: boolean;
}

const useStyles = makeStyles(() => ({
  imageAndNameContainer: {
    display: 'flex',
    gap: 16,
    alignItems: 'center'
  },
  image: {
    borderRadius: 4,
    border: `1px solid ${colours.dark12}`,
    objectFit: 'contain'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  serviceTypeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    gap: 8,
    '& > .MuiTypography-caption': {
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'capitalize'
    }
  },
  serviceTypeIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: colours.dark08,
    '& > svg': {
      width: 18,
      height: 18
    }
  }
}));

export default function AvailableCourierItem(props: IAvailableCourierItemProps) {
  const classes = useStyles();
  const [conciergeLoading, setConciergeLoading] = useState(false);
  const {
    Id,
    Name,
    Logo,
    Price,
    ServiceType,
    selected,
    hasError,
    onAccept,
    onPremierAccept,
    onRemove,
    isPremier
  } = props;

  const getServiceIcon = (serviceType: ICourierServiceType) => {
    switch (serviceType) {
      case 'direct':
        return <FlashOnRoundedIcon htmlColor="#edc700" />;
      default:
        return <SwapCallsRoundedIcon htmlColor={colours.primary} />;
    }
  };

  const getServiceName = (serviceType: ICourierServiceType) => {
    switch (serviceType) {
      case 'regular':
        return 'Standard';
      default:
        return serviceType;
    }
  };

  return (
    <Grid container key={Id}>
      <Grid container item xs={4} className={classes.imageAndNameContainer}>
        <Grid item>
          <img width={64} height={64} src={Logo} alt={`${Name} logo`} className={classes.image} />
        </Grid>
        <Grid item xs>
          <Typography>{Name}</Typography>
          {ServiceType && ServiceType.length > 0 && (
            <Box className={classes.serviceTypeContainer}>
              <Box className={classes.serviceTypeIcon}>{getServiceIcon(ServiceType)}</Box>
              <Typography variant="caption" color="textPrimary">
                {getServiceName(ServiceType)}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      {/* Pricing */}
      <Grid container item xs={5} style={{ marginLeft: 24 }}>
        <Grid container item xs direction="column" spacing={1}>
          {/* Delivery Fee */}
          <Grid container item>
            <Grid container item xs={8}>
              <Typography
                variant="caption"
                color="textPrimary"
                style={{ fontWeight: 400, paddingLeft: 48 }}>
                Delivery fee
              </Typography>
            </Grid>
            <Grid xs item container justifyContent="flex-end">
              <Typography variant="body2" component="p" color="textPrimary">
                £{(Price.Min.Delivery / 100).toFixed(2)}
                {Price.Max && Price.Max.Delivery && ` - £${(Price.Max.Delivery / 100).toFixed(2)}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid container item xs={8}>
              <Typography
                variant="caption"
                color="textPrimary"
                style={{ fontWeight: 400, paddingLeft: 48 }}>
                Booking fee
              </Typography>
            </Grid>
            <Grid xs item container justifyContent="flex-end">
              {Price.Min.BookingFee?.CreditUsage && (
                <Typography variant="body2" component="p" color="textPrimary">
                  {`${Price.Min.BookingFee.CreditUsage} credit`}
                </Typography>
              )}
              {Price.Min.BookingFee?.PayAsYouGoPrice && (
                <Typography variant="body2" component="p" color="textPrimary">
                  {`£${(Price.Min.BookingFee.PayAsYouGoPrice / 100).toFixed(2)}`}
                </Typography>
              )}
            </Grid>
          </Grid>
          {Price.Min.PremiumFee && (
            <Grid container item>
              <Grid container item xs={8}>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  style={{ fontWeight: 400, paddingLeft: 48 }}>
                  Concierge fee
                </Typography>
              </Grid>
              <Grid xs item container justifyContent="flex-end">
                <Typography variant="body2" component="p" color="textPrimary">
                  £{(Price.Min.PremiumFee / 100).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          )}
          {/* Delivery Fee */}
          <Grid container item>
            <Grid container item xs={6}>
              <Typography
                variant="caption"
                color="textPrimary"
                style={{ fontWeight: 400, paddingLeft: 48 }}>
                VAT
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" component="p" color="textPrimary" align="right">
                £{(Price.Min.Vat / 100).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          {/* Order Total */}
          <Grid container item xs style={{ fontWeight: 400, paddingLeft: 48 }}>
            <Grid container item xs>
              <Typography variant="body2" color="textPrimary" style={{ fontWeight: 700 }}>
                Total
              </Typography>
            </Grid>
            <Grid xs item container justifyContent="flex-end">
              <Typography
                variant="body2"
                component="p"
                color="textPrimary"
                style={{ fontWeight: 700 }}>
                £{(Price.Min.Total / 100).toFixed(2)}
                {Price.Max && Price.Max.Total && ` - £${(Price.Max.Total / 100).toFixed(2)}`}
              </Typography>
            </Grid>
          </Grid>
          {/* Order Total */}
        </Grid>
      </Grid>
      {/* Pricing */}
      <Grid item xs className={classes.buttonContainer}>
        {selected ? (
          <ButtonPrimary onClick={() => onRemove?.(Id)}>Remove Quote</ButtonPrimary>
        ) : isPremier ? (
          <ButtonSecondary
            disabled={conciergeLoading || hasError}
            onClick={() => {
              setConciergeLoading(true);
              onPremierAccept?.(Id);
            }}>
            Book concierge
          </ButtonSecondary>
        ) : (
          <ButtonSecondary disabled={hasError} onClick={() => onAccept?.(Id)}>
            Accept Quote
          </ButtonSecondary>
        )}
      </Grid>
    </Grid>
  );
}
