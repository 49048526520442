import TEAM_MEMBERS from '../symbols/getTeamMembers';
import { HttpRequestGet } from '../../service';

const teamMembersAction = () => ({
  type: TEAM_MEMBERS.TEAM_MEMBERS
});
const teamMembersSuccessAction = (data: any) => {
  return {
    type: TEAM_MEMBERS.TEAM_MEMBERS_SUCCESS,
    payload: data
  };
};
const teamMembersFailureAction = (data: any) => ({
  type: TEAM_MEMBERS.TEAM_MEMBERS_FAILURE,
  payload: {
    data
  }
});
const clearTeamMembersAction = () => ({
  type: TEAM_MEMBERS.CLEAR_TEAM_MEMBERS
});
export function GetTeamMembersForFilter() {
  return (dispatch: any) => {
    dispatch(teamMembersAction());
    HttpRequestGet(
      '/pro/team/members',
      dispatch,
      teamMembersSuccessAction,
      teamMembersFailureAction,
      true
    );
  };
}

export function clearTeamMembers() {
  return (dispatch: any) => {
    dispatch(clearTeamMembersAction());
  };
}
