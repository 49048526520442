const EDIT_MEMBER = 'EDIT_MEMBER';
const EDIT_MEMBER_SUCCESS = 'EDIT_MEMBER_SUCCESS';
const EDIT_MEMBER_FAILURE = 'EDIT_MEMBER_FAILURE';
const CLEAR_EDIT_MEMBER = 'CLEAR_EDIT_MEMBER';

const EditMemberSymbols = {
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_FAILURE,
  EDIT_MEMBER,
  CLEAR_EDIT_MEMBER
};

export default EditMemberSymbols;
