/* eslint-disable react/destructuring-assignment */
import INVITE_MEMBER from '../symbols/inviteMember';

const initialState = {
  loading: false,
  error: '',
  data: []
};

// eslint-disable-next-line default-param-last
function InviteMemberReducer(state = initialState, action: any) {
  switch (action.type) {
    case INVITE_MEMBER.INVITE_MEMBER:
      return { ...state, loading: true, error: '' };
    case INVITE_MEMBER.INVITE_MEMBER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: ''
      };
    case INVITE_MEMBER.INVITE_MEMBER_FAILURE:
      return {
        ...state,
        token: null,
        loading: false,
        error: action.payload
      };
    case INVITE_MEMBER.CLEAR_INVITE_MEMBER:
      return {
        ...state,
        token: null,
        loading: false,
        error: ''
      };
    case INVITE_MEMBER.CLEAR_INVITE_MEMBER_ERROR:
      return {
        ...state,
        error: ''
      };
    default:
      return state;
  }
}
export default InviteMemberReducer;
