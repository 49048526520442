import VALIDATE_TOKEN from '../symbols/validateToken';
import { HttpRequestGet } from '../../service';

const validateTokenAction = () => ({
  type: VALIDATE_TOKEN.VALIDATE_TOKEN
});
const validateTokenSuccessAction = (data: any) => {
  return {
    type: VALIDATE_TOKEN.VALIDATE_TOKEN_SUCCESS,
    payload: data
  };
};
const validateTokenFailureAction = (data: any) => ({
  type: VALIDATE_TOKEN.VALIDATE_TOKEN_FAILURE,
  payload: data
});
const clearValidateTokenAction = () => ({
  type: VALIDATE_TOKEN.CLEAR_VALIDATE_TOKEN
});

// eslint-disable-next-line no-unused-vars
export function ValidateToken(token: string, callback: (data: any) => void) {
  return (dispatch: any) => {
    dispatch(validateTokenAction());
    HttpRequestGet(
      `/pro/invitation/team/validate-token/${token}`, // This should be /shop/validateToken
      dispatch,
      validateTokenSuccessAction,
      validateTokenFailureAction,
      false,
      callback
    );
  };
}

export function clearValidateToken() {
  return (dispatch: any) => {
    dispatch(clearValidateTokenAction());
  };
}
