/* eslint-disable react/destructuring-assignment */
import UPDATE_SESSION from '../symbols/updateSession';

interface State {
  data: { Url: string };
  loading: boolean;
  error: boolean;
}
const initialState = {
  data: { Url: '' },
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function UpdateSessionReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case UPDATE_SESSION.UPDATE_SESSION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case UPDATE_SESSION.UPDATE_SESSION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false
      };
    case UPDATE_SESSION.UPDATE_SESSION_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case UPDATE_SESSION.CLEAR_UPDATE_SESSION:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default UpdateSessionReducer;
