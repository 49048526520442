import { getMultipleAddressAsText, getMultipleValuesAsText } from './getMultipleValuesAsText';
import { IGooglePlaceResponse } from './googlePlacesUtil';

const UK_POSTCODE_REGEX =
  /^(GIR ?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/;

export const isValidPostCode = (code: string) => {
  return UK_POSTCODE_REGEX.test(code);
};

export const prepareAddressBody = (e: IGooglePlaceResponse) => {
  const {
    Line1,
    Line1Short,
    Line2,
    Line3,
    StreetNumber,
    PostCode,
    Premise,
    City,
    Lat,
    Long,
    Name,
    Vicinity
  } = e;

  let ReqLine2 = Line2;
  const hasLine1IncludeName = Name?.includes(Line1Short);
  if (Name && !hasLine1IncludeName) {
    ReqLine2 = `${Name} ${Line2}`.trim();
  }

  const body = {
    Line1: getMultipleValuesAsText([Premise, getMultipleAddressAsText([StreetNumber, Line1])]),
    Line2: ReqLine2,
    Line3,
    PostCode,
    City,
    Lat,
    Long,
    Name,
    Vicinity
  };
  return body;
};
