/* eslint-disable react/no-unstable-nested-components */
import { forwardRef } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface IProps {
  className: string;
  onClick: () => void;
  link: string;
  children: JSX.Element;
}

function AppMenuItemComponent({ className, onClick, link, children }: IProps) {
  // If link is not set return the ordinary ListItem
  if (!link || typeof link !== 'string') {
    return (
      <ListItem button className={className} onClick={onClick}>
        {children}
      </ListItem>
    );
  }

  // Return a ListItem with a link component
  return (
    <ListItem
      button
      className={className}
      component={forwardRef((props: NavLinkProps, ref: any) => (
        <NavLink {...props} ref={ref} />
      ))}
      to={link}>
      {children}
    </ListItem>
  );
}

export default AppMenuItemComponent;
