import moment from 'moment';

export default function getDeliveryWindow(timeSlotFrom: string, timeSlotTo: string) {
  const fromDate = moment(timeSlotFrom).utc().local().calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: '[Last] ddd',
    nextWeek: 'dddd',
    sameElse: 'DD MMM YYYY'
  });
  const toDate = moment(timeSlotTo).utc().local().calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: '[Last] ddd',
    nextWeek: 'dddd',
    sameElse: 'DD MMM YYYY'
  });
  const fromTime = moment.utc(timeSlotFrom).local().format('h:mmA');
  const toTime = moment.utc(timeSlotTo).local().format('h:mmA');

  if (fromDate === toDate) {
    return `${fromDate}, ${fromTime} - ${toTime}`;
  }
  return '';
}
