/* eslint-disable react/destructuring-assignment */
import LOGIN from '../symbols/login';

const initialState = {
  token: null,
  loading: false,
  error: false,
  isUserAdmin: false,
  role: '',
  shopName: '',
  merchantLogo: ''
};

// eslint-disable-next-line default-param-last
function LoginReducer(state = initialState, action: any) {
  switch (action.type) {
    case LOGIN.LOGIN:
      return { ...state, loading: true, error: false };
    case LOGIN.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.data.Result?.Token ?? action.payload.data.Result,
        loading: false,
        error: false
      };
    case LOGIN.LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        loading: false,
        error: action.payload.data.Error
      };
    case LOGIN.CLEAR_ERROR:
      return {
        ...state,
        error: false
      };
    case LOGIN.CLEAR_LOGIN:
      return {
        ...state,
        token: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default LoginReducer;
