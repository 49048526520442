import { makeStyles } from '@material-ui/core/styles';
import config from '../config';
import demo from '../assets/ribbons/demo.svg';
import dev from '../assets/ribbons/dev.svg';
import training from '../assets/ribbons/training.svg';

const useStyles = makeStyles({
  ribbon: {
    position: 'fixed',
    top: '50%',
    right: 0,
    width: '89.07px',
    height: '152.1px',
    transform: 'translateY(-50%)',
    zIndex: 1
  }
});

export function EnvironmentRibbon() {
  const classes = useStyles();
  let ribbon;
  switch (config.env) {
    case 'dev':
      ribbon = dev;
      break;
    case 'demo':
      ribbon = demo;
      break;
    case 'training':
      ribbon = training;
      break;
    default:
      break;
  }

  if (config.env === 'prod') {
    return null;
  }

  return (
    <img src={ribbon} className={classes.ribbon} alt={`${config.env.toUpperCase()} environment`} />
  );
}
