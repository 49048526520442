/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

function TeamIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="team"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={5}
        width={20}
        height={14}>
        <path
          d="M9.171 13.752 9 13.75c-2.34 0-7 1.17-7 3.5V18a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-.75c0-2.274-4.44-3.444-6.829-3.498ZM4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H4.34ZM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5 5.5 6.57 5.5 8.5 7.07 12 9 12Zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7Zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h3a1 1 0 0 0 1-1v-.75c0-2.02-3.5-3.17-5.96-3.44ZM15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35Z"
          fill={props.color || '#000'}
        />
      </mask>
      <g mask="url(#team)">
        <path d="M24 0H0v24h24V0Z" fill={props.color || '#000'} fillOpacity={props.opacity || 1} />
      </g>
    </svg>
  );
}

export default TeamIcon;
