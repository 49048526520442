/* eslint-disable react/destructuring-assignment */
import PROFILE from '../symbols/profile';

export interface UserProfile {
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  ProUserRoleName: string;
  ProTeamId: string;
  ProTeamName: string;
  IsCollectEnabled: boolean;
  TermsAccepted: boolean;
}
interface State {
  data: UserProfile;
  loading: boolean;
  error: any;
}

const initialState: State = {
  data: {
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    ProUserRoleName: '',
    ProTeamId: '',
    ProTeamName: '',
    IsCollectEnabled: false,
    TermsAccepted: false
  },
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function ProfileReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case PROFILE.PROFILE:
      return { ...state, loading: true, error: false };
    case PROFILE.PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload.data.Result,
        loading: false,
        error: false
      };
    case PROFILE.PROFILE_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case PROFILE.CLEAR_PROFILE:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default ProfileReducer;
