import GET_INVOICES from '../symbols/getInvoices';
import { HttpRequestPost } from '../../service';

const getInvoicesAction = () => ({
  type: GET_INVOICES.GET_INVOICES
});
const getInvoicesSuccessAction = (data: any) => {
  return {
    type: GET_INVOICES.GET_INVOICES_SUCCESS,
    payload: data
  };
};
const getInvoicesFailureAction = (data: any) => ({
  type: GET_INVOICES.GET_INVOICES_FAILURE,
  payload: {
    data
  }
});
const clearGetInvoicesAction = () => ({
  type: GET_INVOICES.CLEAR_GET_INVOICES
});
interface GetInvoicesRequestModel {
  Query?: string;
  Size: number;
  Page: number;
  Order: string;
  StartDate?: Date;
  EndDate?: Date;
  TeamMemberIds?: string[];
}
// eslint-disable-next-line no-unused-vars
export function GetInvoices(data: GetInvoicesRequestModel, callback: (resp: any) => void) {
  return (dispatch: any) => {
    dispatch(getInvoicesAction());

    HttpRequestPost(
      '/pro/invoice',
      dispatch,
      (response: any) => {
        const formattedData = callback(response.Result);
        return getInvoicesSuccessAction(formattedData);
      },
      getInvoicesFailureAction,
      true,
      data
    );
  };
}

export function clearGetInvoices() {
  return (dispatch: any) => {
    dispatch(clearGetInvoicesAction());
  };
}
