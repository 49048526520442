/* eslint-disable no-console */
import { CircularProgress, Container } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { GroupHeader } from '../components-atoms';
import { CollectionOrderItem } from '../components-molecules/CollectionOrderItem';
// import { CollectOrderListModel } from '../redux/actions/collectOrders';

interface ICollectionOrdersListProps {
  data: any;
  // TODO: Update response model
  // data: CollectOrderListModel[];
  loadMore: () => void;
  totalCount: number;
}

export function CollectionOrdersList({ data, loadMore, totalCount }: ICollectionOrdersListProps) {
  const navigate = useNavigate();
  const handleItemClick = (id: string) => {
    navigate(`/delivery-order/${id}`);
  };

  const currentCount = data.map((m: any) => m.Deliveries).flat().length;
  const isTKAdmin = false;
  return (
    <InfiniteScroll
      style={{ overflow: 'hidden' }}
      dataLength={currentCount} // This is important field to render the next data
      next={() => loadMore()}
      scrollThreshold={0.8}
      hasMore={totalCount > currentCount}
      loader={
        <Container style={{ width: '100%' }}>
          <CircularProgress />
        </Container>
      }>
      {data.map((item: any, index: number) => (
        <div key={item.Merchant}>
          {isTKAdmin && item?.Deliveries.length > 0 && (
            <GroupHeader
              idx={index}
              data-automation="number-of-orders"
              headlineType="Delivery Orders"
              header={item?.Merchant}
              count={item?.TotalCountByMerchant}
              style={{ marginBottom: -24 }}
            />
          )}
          {item.Deliveries.map((order: any, idx: any) => (
            <CollectionOrderItem
              index={idx}
              key={order.Id}
              data={order}
              onClick={handleItemClick}
            />
          ))}
        </div>
      ))}
    </InfiniteScroll>
  );
}
