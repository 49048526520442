import FAILED_PAYMENT from '../symbols/failedPayment';

const failedPaymentSetIdAction = (id: string) => {
  return {
    type: FAILED_PAYMENT.FAILED_PAYMENT_SET_ID,
    payload: id
  };
};
const failedPaymentSetCountAction = (count: number) => ({
  type: FAILED_PAYMENT.FAILED_PAYMENT_SET_COUNT,
  payload: count
});
const clearFailedPaymentCountAction = () => ({
  type: FAILED_PAYMENT.CLEAR_FAILED_PAYMENT
});
// eslint-disable-next-line no-unused-vars
export function SetFailedPaymentId(id: string) {
  return (dispatch: any) => {
    dispatch(failedPaymentSetIdAction(id));
  };
}
export function SetFailedPaymentCount(count: number) {
  return (dispatch: any) => {
    dispatch(failedPaymentSetCountAction(count));
  };
}

export function clearFailedPayment() {
  return (dispatch: any) => {
    dispatch(clearFailedPaymentCountAction());
  };
}
