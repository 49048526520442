const GRANT_ACCESS = 'GRANT_ACCESS';
const GRANT_ACCESS_SUCCESS = 'GRANT_ACCESS_SUCCESS';
const GRANT_ACCESS_FAILURE = 'GRANT_ACCESS_FAILURE';
const CLEAR_GRANT_ACCESS = 'CLEAR_GRANT_ACCESS';

const GrantAccessSymbols = {
  GRANT_ACCESS_SUCCESS,
  GRANT_ACCESS_FAILURE,
  GRANT_ACCESS,
  CLEAR_GRANT_ACCESS
};

export default GrantAccessSymbols;
