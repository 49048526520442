import EDIT_TEAM from '../symbols/editTeam';
import { HttpRequestPut } from '../../service';

const editTeamAction = () => ({
  type: EDIT_TEAM.EDIT_TEAM
});
const editTeamSuccessAction = (data: any) => {
  return {
    type: EDIT_TEAM.EDIT_TEAM_SUCCESS,
    payload: data
  };
};
const editTeamFailureAction = (data: any) => ({
  type: EDIT_TEAM.EDIT_TEAM_FAILURE,
  payload: {
    data
  }
});
const clearEditTeamAction = () => ({
  type: EDIT_TEAM.CLEAR_EDIT_TEAM
});
interface UpdateProTeamBillingAddress {
  Line1: string;
  Line2: string;
  Line3: string;
  PostalCode: string;
  County: string;
  City: string;
}
export interface EditTeamRequestModel {
  TeamName?: string;
  PhoneNumber?: string;
  VATNumber?: string;
  BillingAddress?: UpdateProTeamBillingAddress;
}
export function EditTeam(data: EditTeamRequestModel, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(editTeamAction());
    HttpRequestPut(
      '/pro/team/profile',
      dispatch,
      editTeamSuccessAction,
      editTeamFailureAction,
      true,
      data,
      callback
    );
  };
}

export function clearEditTeam() {
  return (dispatch: any) => {
    dispatch(clearEditTeamAction());
  };
}
