/* eslint-disable react/destructuring-assignment */
import USER_ROLE from '../symbols/userRole';

const initialState = {
  loading: false,
  error: false,
  data: []
};

// eslint-disable-next-line default-param-last
function UserRoleReducer(state = initialState, action: any) {
  switch (action.type) {
    case USER_ROLE.USER_ROLE:
      return { ...state, loading: true, error: false };
    case USER_ROLE.USER_ROLE_SUCCESS:
      return {
        ...state,
        data: action.payload.data.Result,
        loading: false,
        error: false
      };
    case USER_ROLE.USER_ROLE_FAILURE:
      return {
        ...state,
        token: null,
        loading: false,
        error: action.payload
      };
    case USER_ROLE.CLEAR_USER_ROLE:
      return {
        ...state,
        token: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default UserRoleReducer;
