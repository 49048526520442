import { IFilterItem } from '../components-molecules/FilterButton';

function orderObject(unordered: { [key: string]: any }, last?: string): { [key: string]: any } {
  let ordered = {};
  Object.keys(unordered)
    .sort((a, b) => {
      return a === last ? 1 : a > b ? 1 : -1;
    })
    .forEach((key) => {
      ordered = { ...ordered, [key]: unordered[key] };
    });

  return ordered;
}

function groupFilterItems(items: IFilterItem[]): { [key: string]: IFilterItem[] } {
  const temp: { [key: string]: IFilterItem[] } = {};
  items.forEach((item) => {
    if (/[a-z]/i.test(item.title)) {
      const letter = item.title[0].toLocaleUpperCase();
      if (letter in temp) {
        temp[letter].push(item);
      } else {
        temp[letter] = [item];
      }
    } else if ('Other' in temp) {
      temp.Other.push(item);
    } else {
      temp.Other = [item];
    }
  });
  return orderObject(temp, 'Other');
}
export default groupFilterItems;
