/* eslint-disable react/destructuring-assignment */
import GET_INVOICES from '../symbols/getInvoices';

export interface Invoice {
  OrderId: string;
  OrderNumber: number;
  JobNumber: string;
  Placed: Date;
  InvoiceId: string;
  InvoiceNo: string;
  InvoiceFileUrl: string;
  AddressName: string;
  Line1: string;
}
interface InvoiceItem {
  GroupName: string;
  Result: Invoice[];
}
export interface InvoiceResponseModel {
  Count: number;
  Result: InvoiceItem[];
}
interface State {
  loading: boolean;
  error: any;
  data: InvoiceResponseModel;
}
const initialState: State = {
  loading: false,
  error: false,
  data: { Count: 0, Result: [] }
};

// eslint-disable-next-line default-param-last
function GetInvoicesReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case GET_INVOICES.GET_INVOICES:
      return { ...state, loading: true, error: false };
    case GET_INVOICES.GET_INVOICES_SUCCESS:
      return {
        ...state,
        data: action.payload as InvoiceResponseModel,
        loading: false,
        error: false
      };
    case GET_INVOICES.GET_INVOICES_FAILURE:
      return {
        ...state,
        token: null,
        loading: false,
        error: action.payload
      };
    case GET_INVOICES.CLEAR_GET_INVOICES:
      return {
        ...state,
        token: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default GetInvoicesReducer;
