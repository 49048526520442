/* eslint-disable react/destructuring-assignment */
import { TextFieldProps, TextField, makeStyles } from '@material-ui/core';
import { ITheme, colours } from '../constants/theme';

type IProps = TextFieldProps;

const useStyles = makeStyles((theme: ITheme) => ({
  cssOutlinedInput: {
    display: 'flex',
    '&$cssFocused $notchedOutline': {
      border: `1px solid ${colours.primary}`
    }
  },
  cssFocused: {},
  notchedOutline: {
    border: '1px solid rgba(0, 0, 0, 0.12)'
  },
  helperText: {
    paddingTop: 4,
    fontWeight: 400,
    letterSpacing: 0.4
  },
  helperTextFocused: {
    color: theme.palette.primary.main
  }
}));

export function TextFieldInput(props: IProps) {
  const { InputProps, ...rest } = props;
  const classes = useStyles();
  return (
    <TextField
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
          focused: classes.helperTextFocused
        }
      }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline
        },
        ...InputProps
      }}
      margin="normal"
      fullWidth
      {...rest}
      variant="outlined"
    />
  );
}
