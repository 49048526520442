import {
  Box,
  CssBaseline,
  Container,
  IconButton,
  makeStyles,
  Grid,
  Typography,
  FormControlLabel,
  useMediaQuery
} from '@material-ui/core';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { debounce } from 'lodash';
import {
  ButtonWithLoading,
  ControlledInput,
  ButtonSecondary,
  RoundedCheckbox
} from '../components-atoms';
import { ConfirmModal, ErrorModal, Searchbar, SortButton } from '../components-molecules';
import { ButtonArea, HeaderWithButtons } from '../components-molecules/HeaderWithButtons';
import { TeamMemberList } from '../components-organisms';
import { ITheme, theme } from '../constants/theme';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { GetTeamMembers } from '../redux/actions/team';
import { TeamMember } from '../redux/reducers/team';
import { GrantAccess } from '../redux/actions/grantAccess';
import { clearInviteMemberError, InviteMember } from '../redux/actions/inviteMember';
import { GetUserRoles } from '../redux/actions/userRole';
import { RemoveMember } from '../redux/actions/removeMember';
import { clearGetMember, GetMember } from '../redux/actions/getMember';
import { Member } from '../redux/reducers/getMember';
import { EditMember } from '../redux/actions/editMember';
import { EMAIL_VALIDATION } from '../constants/validation';
import { ResendInvitation } from '../redux/actions/resendInvitation';
import { ResponsiveModal } from '../components-molecules/NewModal';
import { textButtonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles((myTheme: ITheme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '@media (min-width: 900px)': {
      maxWidth: '840px'
    }
  },
  buttonSet: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '24px',
    gap: '8px',
    '@media (max-width: 899px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '8px 4px',
      padding: '8px 12px'
    }
  },
  searchIcon: {
    width: 24,
    height: 24,
    '@media(max-width: 899px)': {
      width: 20,
      height: 20,
      marginLeft: -4,
      marginRight: 8
    }
  },
  searchIconButton: {
    width: 40,
    height: 40,
    ...textButtonHoverAndActive,
    '@media(max-width: 899px)': {
      width: '100%',
      height: 36,
      display: 'flex',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(158, 0, 93, 0.08)',
      borderRadius: '8px',
      padding: '6px 16px'
    }
  },
  secondaryButton: {
    '@media(max-width: 899px)': {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start'
    }
  },
  prefix: {
    color: myTheme.palette.action.disabled,
    marginRight: 5
  },
  uPaddingLeft: {
    paddingLeft: 24,
    '@media (max-width: 899px)': {
      paddingLeft: 16
    }
  }
}));
type SortType = 'Status' | 'Role' | 'AlphabeticalAscending' | 'AlphabeticalDescending';
interface SortOption {
  value: SortType;
  title: string;
}
const sortTypes: SortOption[] = [
  { title: 'By status', value: 'Status' },
  { title: 'User role', value: 'Role' },
  { title: 'Alphabetical ascending', value: 'AlphabeticalAscending' },
  { title: 'Alphabetical descending', value: 'AlphabeticalDescending' }
];
export interface AddMemberType {
  firstname: string;
  lastname: string;
  email: string;
  approval?: boolean;
  threshold?: string;
}
export function Team() {
  const classes = useStyles();
  const timeoutDuration = 300;
  const mobileView = useMediaQuery('(max-width: 899px)', { noSsr: true });
  const [query, setQuery] = useState('');
  const [sortType, setSortType] = useState<SortType>('Status');
  const [searchOpen, setSearchOpen] = useState(false);
  const [modal, setModal] = useState<'add' | 'edit' | 'remove' | ''>('');
  const [selectedMember, inviteLoading, editLoading, inviteError]: [
    Member,
    boolean,
    boolean,
    string
  ] = useAppSelector((state) => [
    state.getMember.data,
    (state.inviteMember as any).loading,
    state.editMember.loading,
    (state.inviteMember as any).error
  ]);

  const dispatch = useAppDispatch();

  const [memberToRemove, setMemberToRemove] = useState<{ id: string; active: boolean } | null>(
    null
  );

  const {
    control,
    formState: { isValid },
    watch,
    reset,
    setValue,
    getValues
  } = useForm<AddMemberType>({
    reValidateMode: 'onBlur',
    mode: 'all',
    defaultValues: { approval: false, threshold: '' }
  });

  const {
    control: editControl,
    formState: { isValid: editIsValid },
    getValues: editGetValues,
    setValue: editSetValue,
    watch: editWatch,
    reset: editReset
  } = useForm<AddMemberType>({ reValidateMode: 'onBlur', mode: 'all' });

  const inviteMember: SubmitHandler<AddMemberType> = (data) => {
    dispatch(
      InviteMember(
        {
          FirstName: data.firstname,
          LastName: data.lastname,
          Email: data.email,
          ApprovalRequired: data.approval || false,
          ApprovalThreshold: data.threshold && data.approval ? +data.threshold * 100 : undefined
        },
        () => {
          dispatch(GetTeamMembers({ Group: sortType }));
          setModal('');
          reset();
        }
      )
    );
  };

  const editMember: SubmitHandler<AddMemberType> = (data) => {
    dispatch(
      EditMember(
        {
          ApprovalRequired: data.approval || false,
          FirstName: data.firstname,
          LastName: data.lastname,
          ApprovalThreshold: data.approval && data.threshold ? +data.threshold * 100 : undefined
        },
        selectedMember.CustomerId,
        () => {
          dispatch(GetTeamMembers({ Group: sortType }));
          dispatch(clearGetMember());
          setModal('');
          editReset();
        }
      )
    );
  };

  const onGrantAccess = (member: TeamMember) => {
    if (member.Active) {
      dispatch(
        GrantAccess({ CustomerId: member.CustomerId }, () =>
          dispatch(GetTeamMembers({ Group: sortType }))
        )
      );
    }
  };

  const onRemove = (id: string, active: boolean) => {
    setModal('remove');
    setMemberToRemove({ id, active });
  };

  const removeTeamMember = (member: { id: string; active: boolean }) => {
    dispatch(RemoveMember(member.id, () => dispatch(GetTeamMembers({ Group: sortType }))));
  };

  const onEdit = (invitationId: string) => {
    dispatch(GetMember(invitationId));
  };

  useEffect(() => {
    dispatch(GetUserRoles());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      GetTeamMembers({
        Query: query || undefined,
        Group: sortType
      })
    );
  }, [dispatch, sortType, query]);

  useEffect(() => {
    if (selectedMember) {
      setModal('edit');
      editReset({
        approval: selectedMember.ApprovalRequired,
        email: selectedMember.Email,
        firstname: selectedMember.FirstName,
        lastname: selectedMember.LastName,
        threshold: selectedMember.ApprovalThreshold
          ? (selectedMember.ApprovalThreshold / 100).toString()
          : ''
      });
    }
  }, [selectedMember, editReset]);

  const resendInvitation = (id: string) => {
    dispatch(ResendInvitation(id, () => dispatch(GetTeamMembers({ Group: sortType }))));
  };

  return (
    <Box>
      <CssBaseline />
      <Container className={classes.root}>
        <CssBaseline />
        <HeaderWithButtons title="Team Members">
          <ButtonArea>
            <div className={classes.buttonSet}>
              <SortButton
                data-automation="sort-button"
                menuItems={sortTypes.map(({ title, value }: SortOption) => ({
                  title,
                  value,
                  handleSelect: () => {
                    setSortType(value);
                  }
                }))}
                selectedSortType={sortType}
              />
              <IconButton
                data-automation="search-button"
                className={classes.searchIconButton}
                color="primary"
                onClick={() => setSearchOpen(!searchOpen)}>
                <SearchRoundedIcon color="primary" className={classes.searchIcon} />
                {mobileView && (
                  <Typography variant="button" component="span" color="primary">
                    Search
                  </Typography>
                )}
              </IconButton>
              <ButtonSecondary
                data-automation="add-code-button"
                startIcon={<PersonAddOutlinedIcon color="primary" />}
                onClick={() => setModal('add')}
                className={classes.secondaryButton}>
                Invite Team Member
              </ButtonSecondary>
            </div>
          </ButtonArea>
        </HeaderWithButtons>
        <Searchbar
          query={query}
          isOpen={searchOpen}
          placeholder="Search Team Members"
          onCancel={() => {
            setSearchOpen(false);
            setQuery('');
          }}
          onChange={(event) =>
            debounce(
              (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                setQuery(e.target.value),
              timeoutDuration
            )(event)
          }
        />
        <TeamMemberList
          onGrantAccess={onGrantAccess}
          onRemove={onRemove}
          onEdit={onEdit}
          onInvite={() => setModal('add')}
          onResend={(id) => resendInvitation(id)}
        />
      </Container>
      <ResponsiveModal
        fitContent
        isOpen={modal === 'add'}
        buttons={
          <>
            <ButtonSecondary
              onClick={() => {
                setModal('');
                reset();
              }}
              style={{ height: '48px' }}>
              Cancel
            </ButtonSecondary>
            <ButtonWithLoading
              title="Invite"
              disabled={inviteLoading || !isValid}
              loading={inviteLoading}
              onClick={() => inviteMember(getValues())}
            />
          </>
        }
        onClose={() => setModal('')}
        title="Invite team member"
        description="Invited team member will receive an invitation email with a link to download TradeKart app and activate their account by setting a password.">
        <form style={{ width: '100%' }}>
          <Grid xs={12} item container justifyContent="space-between" direction="row">
            <Grid xs={6} item style={{ paddingRight: '8px' }}>
              <ControlledInput
                name="firstname"
                rules={{
                  required: true,
                  validate: {
                    trim: (v) => v === String(v).trim()
                  }
                }}
                control={control}
                fullWidth
                label="First name"
                inputProps={{
                  autoCapitalize: 'words'
                }}
              />
            </Grid>
            <Grid xs={6} item style={{ paddingLeft: '8px' }}>
              <ControlledInput
                name="lastname"
                rules={{
                  required: true,
                  validate: {
                    trim: (v) => v === String(v).trim()
                  }
                }}
                control={control}
                label="Last name"
                inputProps={{
                  autoCapitalize: 'words'
                }}
              />
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <ControlledInput
              name="email"
              rules={{ required: true, pattern: EMAIL_VALIDATION }}
              control={control}
              label="Email"
            />
          </Grid>
          <Grid xs={12} item style={{ paddingTop: '24px' }}>
            <Typography variant="subtitle2">Member Settings</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <RoundedCheckbox
                  checked={watch('approval')}
                  color="primary"
                  onChange={(_, val) =>
                    setValue('approval', val, { shouldValidate: true, shouldDirty: true })
                  }
                />
              }
              label="Requires your approval for purchases"
            />
          </Grid>
          <Grid xs={12} item>
            <ControlledInput
              style={{
                backgroundColor: !watch('approval')
                  ? theme.palette.action.disabledBackground
                  : '#fff'
              }}
              disabled={!watch('approval')}
              name="threshold"
              control={control}
              onChange={(e) => {
                if (e.target.value === '' || (e.target.value && !!+e.target.value)) {
                  setValue('threshold', e.target.value, {
                    shouldDirty: true,
                    shouldValidate: true
                  });
                }
              }}
              value={watch('threshold')}
              InputProps={{
                startAdornment: <span className={classes.prefix}>£</span>
              }}
              label="Approval threshold (Opt.)"
            />
          </Grid>
        </form>
      </ResponsiveModal>
      <ResponsiveModal
        fitContent
        isOpen={modal === 'edit'}
        onClose={() => {
          setModal('');
          editReset(undefined);
          dispatch(clearGetMember());
        }}
        buttons={
          <>
            <ButtonSecondary
              onClick={() => {
                setModal('');
                editReset(undefined);
                dispatch(clearGetMember());
              }}
              style={{ height: '48px' }}>
              Cancel
            </ButtonSecondary>
            <ButtonWithLoading
              title="Submit"
              disabled={editLoading || !editIsValid}
              loading={editLoading}
              onClick={() => editMember(editGetValues())}
            />
          </>
        }
        title="Edit team member">
        <form style={{ width: '100%' }}>
          <Grid xs={12} item container>
            <Grid xs={6} item style={{ paddingRight: '8px' }}>
              <ControlledInput
                name="firstname"
                rules={{ required: true }}
                control={editControl}
                fullWidth={false}
                label="First name"
              />
            </Grid>
            <Grid xs={6} item style={{ paddingLeft: '8px' }}>
              <ControlledInput
                name="lastname"
                rules={{ required: true }}
                control={editControl}
                label="Last name"
              />
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <ControlledInput
              disabled
              name="email"
              rules={{ required: true, pattern: EMAIL_VALIDATION }}
              control={editControl}
              label="Email"
            />
          </Grid>
          <Grid xs={12} item style={{ paddingTop: '24px' }}>
            <Typography variant="subtitle2">Member Settings</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              disabled={selectedMember && selectedMember.RoleName !== 'Team Member'}
              control={
                <RoundedCheckbox
                  checked={editWatch('approval')}
                  color="primary"
                  onChange={(_, val) =>
                    editSetValue('approval', val, { shouldValidate: true, shouldDirty: true })
                  }
                />
              }
              label="Requires your approval for purchases"
            />
          </Grid>
          <Grid xs={12} item>
            <ControlledInput
              style={{
                backgroundColor: !editWatch('approval')
                  ? theme.palette.action.disabledBackground
                  : '#fff'
              }}
              disabled={!editWatch('approval')}
              InputProps={{
                startAdornment: <span className={classes.prefix}>£</span>
              }}
              name="threshold"
              control={editControl}
              onChange={(e) => {
                if (e.target.value === '' || (e.target.value && !!+e.target.value)) {
                  editSetValue('threshold', e.target.value, {
                    shouldDirty: true,
                    shouldValidate: true
                  });
                }
              }}
              value={editWatch('threshold')}
              rules={{ validate: (val) => (val ? !!+(val || true) : true) }}
              label="Approval threshold (Opt.)"
            />
          </Grid>
        </form>
      </ResponsiveModal>
      <ConfirmModal
        title="Remove team member"
        buttonTitle="Yes, remove team member"
        errorBody="Are you sure you want to remove this team member from your organisation?"
        onSubmit={() => {
          removeTeamMember(memberToRemove as { id: string; active: boolean });
          setModal('');
          setMemberToRemove(null);
        }}
        onClose={() => {
          setModal('');
          setMemberToRemove(null);
        }}
        visible={modal === 'remove'}
      />
      <ErrorModal
        visible={!!inviteError}
        errorBody={inviteError}
        title="User cannot be invited"
        onClose={() => dispatch(clearInviteMemberError())}
      />
    </Box>
  );
}
