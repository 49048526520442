/* eslint-disable react/destructuring-assignment */
import SEND_FORGOT_PASSWORD_EMAIL from '../symbols/sendForgotPasswordEmail';

interface State {
  loading: boolean;
  data: string;
  error: any;
}
const initialState: State = {
  loading: false,
  error: false,
  data: ''
};

// eslint-disable-next-line default-param-last
function SendForgotPasswordEmailReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case SEND_FORGOT_PASSWORD_EMAIL.SEND_FORGOT_PASSWORD_EMAIL:
      return { ...state, loading: true, error: false };
    case SEND_FORGOT_PASSWORD_EMAIL.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false
      };
    case SEND_FORGOT_PASSWORD_EMAIL.SEND_FORGOT_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case SEND_FORGOT_PASSWORD_EMAIL.CLEAR_SEND_FORGOT_PASSWORD_EMAIL:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default SendForgotPasswordEmailReducer;
