import { Grid, Typography } from '@material-ui/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { ButtonSecondary, ButtonWithLoading, ControlledInput } from '../components-atoms';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import background from '../assets/images/deliveryAddressBackground.png';
import { DeliveryAddress } from '../redux/reducers/deliveryAddresses';
import { EditDeliveryAddress } from '../redux/actions/editDeliveryAddress';
import { PHONE_VALIDATION_V3 } from '../constants/validation';
import { ResponsiveModal } from '../components-molecules/NewModal';
import { getPhoneNumber } from '../utils/phoneUtils';

interface AddressForm {
  name: string;
  nameOnDoorbell: string;
  instructions: string;
  phone: string;
}
interface IProps {
  address: DeliveryAddress | null;
  visible: boolean;
  onClose: () => void;
  onSubmitSuccess: () => void;
}
export function EditAddressModal({ visible, onClose, onSubmitSuccess, address }: IProps) {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => (state.editDeliveryAddress as any).loading);
  const {
    control,
    formState: { isValid },
    watch,
    setValue,
    reset,
    handleSubmit,
    getValues
  } = useForm<AddressForm>({
    mode: 'all',
    reValidateMode: 'onBlur'
  });
  useEffect(() => {
    reset({
      name: address?.Name,
      instructions: address?.Instructions,
      nameOnDoorbell: address?.NameOnDoor,
      phone: getPhoneNumber(address?.ContactPhoneNumber)
    });
  }, [address, reset]);
  const onModalClose = () => {
    onClose();
    reset();
  };
  const editAddress: SubmitHandler<AddressForm> = (data) => {
    if (address) {
      dispatch(
        EditDeliveryAddress(
          {
            AddressName: data.name,
            City: address?.City,
            Country: address?.Country,
            County: address?.County,
            Lat: address?.Lat,
            Long: address?.Long,
            Line1: address?.Line1,
            Line2: address?.Line2,
            Line3: address?.Line3,
            PostalCode: address?.PostalCode,
            DoorbellName: data.nameOnDoorbell,
            InstructionsForCourier: data.instructions,
            ContactPhoneNumber: data.phone
          },
          address?.Id,
          () => {
            onModalClose();
            onSubmitSuccess();
          }
        )
      );
    }
  };
  return (
    <ResponsiveModal
      isOpen={visible}
      description="Check your address and add details"
      onClose={() => {
        onModalClose();
      }}
      title="Edit Delivery Address"
      buttons={
        <>
          <ButtonSecondary
            onClick={() => {
              onModalClose();
            }}
            style={{ height: '48px' }}>
            Cancel
          </ButtonSecondary>
          <ButtonWithLoading
            title="Save"
            disabled={!isValid || loading}
            loading={loading}
            onClick={() => editAddress(getValues())}
          />
        </>
      }>
      <form onSubmit={handleSubmit(editAddress)} style={{ width: '100%', paddingTop: '16px' }}>
        <Grid
          item
          container
          xs={12}
          style={{
            border: ' 1px solid rgba(0, 0, 0, 0.12)',
            rowGap: '8px',
            padding: '24px 0 24px 16px',
            borderRadius: '8px',
            backgroundImage: `linear-gradient(to right, #fff, #fff, transparent), url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
          direction="column"
          alignItems="flex-start">
          <Typography variant="body1" component="span">
            {address?.Line1}
          </Typography>
          <Typography variant="body1" component="span">
            {address?.Line2}
          </Typography>
          <Typography variant="body1" component="span">
            {address?.Line3}
          </Typography>
          <Typography variant="body1" component="span">
            {address?.City}, {address?.County}, {address?.PostalCode}
          </Typography>
          <Typography variant="body1" component="span">
            {address?.Country}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '24px' }}>
          <Typography variant="subtitle2" component="span">
            Address name
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <ControlledInput
            name="name"
            rules={{ required: true }}
            control={control}
            label="Address name"
          />
        </Grid>
        <Typography variant="subtitle2" component="span">
          Delivery Instructions
        </Typography>
        <Grid xs={12} item>
          <ControlledInput
            name="nameOnDoorbell"
            control={control}
            label="Name on the doorbell (Opt.)"
          />
        </Grid>
        <Grid xs={12} item>
          <ControlledInput
            name="instructions"
            multiline
            FormHelperTextProps={{ style: { textAlign: 'right' } }}
            control={control}
            helperText={`${watch('instructions')?.length || 0}/320`}
            onChange={(e) => {
              if (e.target.value.length > 320) {
                return;
              }
              setValue('instructions', e.target.value, {
                shouldDirty: true,
                shouldValidate: true
              });
            }}
            rules={{ maxLength: 320 }}
            label="Instructions for the courier (Opt.)"
          />
        </Grid>
        <Grid xs={12} item>
          <ControlledInput
            name="phone"
            control={control}
            rules={{ pattern: PHONE_VALIDATION_V3 }}
            label="Contact phone number (Opt.)"
          />
        </Grid>
      </form>
    </ResponsiveModal>
  );
}
