/* eslint-disable react/destructuring-assignment */

import ORDER_SEARCH from '../symbols/orderSearch';

interface State {
  data: string;
}
const initialState: State = {
  data: ''
};

// eslint-disable-next-line default-param-last
function OrderSearchReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case ORDER_SEARCH.SET_QUERY:
      return {
        data: action.payload as string
      };
    case ORDER_SEARCH.CLEAR_ORDER_SEARCH:
      return {
        data: ''
      };
    default:
      return state;
  }
}
export default OrderSearchReducer;
