/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

function AddressesIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="addresses"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={5}
        y={2}
        width={14}
        height={20}>
        <path
          d="M12 2C8.13 2 5 5.13 5 9c0 4.17 4.42 9.92 6.24 12.11.4.48 1.13.48 1.53 0C14.58 18.92 19 13.17 19 9c0-3.87-3.13-7-7-7ZM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9Zm5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
          fill={props.color || '#000'}
        />
      </mask>
      <g mask="url(#addresses)">
        <path d="M24 0H0v24h24V0Z" fill={props.color || '#000'} fillOpacity={props.opacity || 1} />
      </g>
    </svg>
  );
}

export default AddressesIcon;
