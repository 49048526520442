/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { Typography, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ITheme, colours, theme } from '../constants/theme';
import { textButtonHoverAndActive } from '../utils/styles/buttonStyles';

interface IProps {
  handleClick: (value: any) => void;
  title: string;
  selected: any;
  value?: any;
}
const useStyles = makeStyles((theme: ITheme) => ({
  button: {
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: '40px',
    marginTop: '4px',
    marginBottom: '4px',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderRadius: '8px',
    ...textButtonHoverAndActive
  },
  printButtonText: {
    textTransform: 'none',
    fontWeight: 600
  }
}));

export function DropdownMenuButton({ handleClick, title, selected, value }: IProps) {
  const classes = useStyles();
  return (
    <Button
      id="basic-button"
      aria-controls="basic-menu"
      aria-haspopup="true"
      style={{
        backgroundColor: selected ? colours.highEmphasis04 : undefined
      }}
      onClick={() => handleClick(value)}
      className={classes.button}>
      <Typography
        variant="body2"
        component="span"
        color={selected ? 'primary' : 'textPrimary'}
        className={classes.printButtonText}>
        {title}
      </Typography>
    </Button>
  );
}
