import { makeStyles } from '@material-ui/core';
import { ITheme } from '../../constants/theme';
import { headerButtonsResponsive, textButtonHoverAndActive } from './buttonStyles';

export const newCollectionStyles = makeStyles((myTheme: ITheme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '@media (min-width: 900px)': {
      maxWidth: '840px'
    }
  },
  buttonSet: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '24px',
    gap: '8px',
    '@media (max-width: 899px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '8px 4px',
      padding: '8px 12px'
    }
  },
  searchIcon: {
    width: 24,
    height: 24,
    '@media(max-width: 899px)': {
      width: 20,
      height: 20,
      marginLeft: -4,
      marginRight: 8
    }
  },
  searchIconButton: {
    width: 40,
    height: 40,
    ...textButtonHoverAndActive,
    ...headerButtonsResponsive
  },
  secondaryButton: {
    '@media(max-width: 899px)': {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start'
    }
  },
  prefix: {
    color: myTheme.palette.action.disabled,
    marginRight: 5
  },
  uPaddingLeft: {
    paddingLeft: 24,
    '@media (max-width: 899px)': {
      paddingLeft: 16
    }
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media(max-width: 899px)': {
      justifyContent: 'flex-start',
      paddingLeft: 16,
      marginTop: 12
    }
  },
  headerContainerNoMedia: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  quoteErrorBody: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    gap: 16
  }
}));
