/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

export enum SortType {
  asc = 'asc',
  desc = 'desc'
}

export interface SortOption {
  value: SortType;
  title: string;
}
export const ASC_SORT: SortOption[] = [
  { title: 'Date ascending', value: SortType.asc },
  { title: 'Date descending', value: SortType.desc }
];
