import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import {
  ButtonSecondary,
  ButtonTextPrimary,
  ButtonWithLoading,
  ControlledInput,
  TextFieldInput
} from '../components-atoms';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { clearSearchAddress, SearchAddress } from '../redux/actions/searchAddress';
import {
  clearSearchAddressDetails,
  SearchAddressDetails
} from '../redux/actions/searchAddressDetails';
import background from '../assets/images/deliveryAddressBackground.png';
import { AddDeliveryAddress } from '../redux/actions/addDeliveryAddress';
import { PHONE_VALIDATION_V3 } from '../constants/validation';
import { ResponsiveModal } from '../components-molecules/NewModal';
import { getPhoneNumber } from '../utils/phoneUtils';

interface AddressForm {
  name: string;
  nameOnDoorbell: string;
  instructions: string;
  phone: string;
}
interface IProps {
  visible: boolean;
  onClose: () => void;
  onSubmitSuccess: () => void;
}
export function AddressSearchModal({ visible, onClose, onSubmitSuccess }: IProps) {
  const [query, setQuery] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [step, setStep] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState('');
  const dispatch = useAppDispatch();
  const [addresses, loading, addressDetail, detailLoading, addAddressLoading] = useAppSelector(
    (state) => [
      state.searchAddress.data,
      state.searchAddress.loading,
      state.searchAddressDetails.data,
      state.searchAddressDetails.loading,
      (state.addDeliveryAddress as any).loading
    ]
  );
  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAddress((event.target as HTMLInputElement).value);
  };

  // Cancel request
  const cancelTokenSource = axios.CancelToken.source();
  const timeoutDuration = 500;
  useEffect(() => {
    if (query) {
      dispatch(SearchAddress(query, undefined, cancelTokenSource.token));
    }
    return () => {
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, dispatch]);

  const onNext = () => {
    dispatch(SearchAddressDetails(selectedAddress));
    setStep(1);
  };
  const {
    control,
    setValue,
    formState: { isValid },
    getValues,
    watch,
    reset
  } = useForm<AddressForm>({ mode: 'all', reValidateMode: 'onBlur' });
  const onModalClose = () => {
    onClose();
    dispatch(clearSearchAddress());
    dispatch(clearSearchAddressDetails());
    setQuery('');
    setSearchValue('');
    setStep(0);
  };
  const addAddress: SubmitHandler<AddressForm> = (data) => {
    if (addressDetail) {
      dispatch(
        AddDeliveryAddress(
          {
            AddressName: data.name,
            City: addressDetail?.TownOrCity,
            Country: addressDetail?.Country,
            County: addressDetail?.County,
            Lat: addressDetail?.Lat,
            Long: addressDetail?.Long,
            Line1: addressDetail?.Line1,
            Line2: addressDetail?.Line2,
            Line3: addressDetail?.Line3,
            PostalCode: addressDetail?.Postcode,
            DoorbellName: data.nameOnDoorbell,
            InstructionsForCourier: data.instructions,
            ContactPhoneNumber: getPhoneNumber(data.phone)
          },
          () => {
            onModalClose();
            reset();
            setSelectedAddress('');
            onSubmitSuccess();
          }
        )
      );
    }
  };
  return (
    <ResponsiveModal
      isOpen={visible}
      description={
        step === 0
          ? 'Start by entering  your postcode or address.'
          : 'Check your address and add details'
      }
      onBack={step === 0 ? undefined : () => setStep(0)}
      onClose={() => {
        onModalClose();
        reset();
        setSelectedAddress('');
      }}
      title="Add Delivery Address"
      buttons={
        step === 0 ? (
          <>
            <ButtonSecondary
              onClick={() => {
                onModalClose();
                reset();
                setSelectedAddress('');
              }}
              style={{ height: '48px' }}>
              Cancel
            </ButtonSecondary>
            <ButtonWithLoading
              title="Next"
              type="button"
              style={{ height: '48px' }}
              onClick={() => onNext()}
              disabled={!selectedAddress}
              loading={false}
            />
          </>
        ) : (
          <>
            <ButtonSecondary
              onClick={() => {
                onModalClose();
              }}
              style={{ height: '48px' }}>
              Cancel
            </ButtonSecondary>
            <ButtonWithLoading
              title="Add"
              type="submit"
              disabled={!isValid || addAddressLoading}
              loading={addAddressLoading}
              onClick={() => addAddress(getValues())}
            />
          </>
        )
      }>
      {step === 0 ? (
        <Grid container item xs={12} style={{ maxHeight: '710px' }}>
          <Grid style={{ width: '100%', maxHeight: '100%' }}>
            <TextFieldInput
              label="Address or postcode"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                debounce((event) => setQuery(event.target.value), timeoutDuration)(e);
              }}
            />
            <Grid item xs={12} style={{ maxHeight: 'fit-content', overflowY: 'auto' }}>
              <RadioGroup
                name="address"
                value={selectedAddress}
                onChange={handleAddressChange}
                style={{
                  rowGap: '16px',
                  width: '100%',
                  paddingLeft: '12px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start'
                }}>
                {loading ? (
                  <Grid
                    style={{ width: '100%', minHeight: '100px' }}
                    container
                    item
                    justifyContent="center">
                    <CircularProgress />
                  </Grid>
                ) : query && addresses.length === 0 ? (
                  <Grid item container style={{ paddingTop: '40px' }}>
                    <Typography variant="subtitle1">
                      No addresses found with this postcode
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Check your postcode and try again
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item container style={{ rowGap: '8px' }}>
                    {addresses.map((item) => (
                      <Grid item container>
                        <FormControlLabel
                          key={item.Id}
                          value={item.Id}
                          control={<Radio color="primary" />}
                          label={item.Address}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
      ) : detailLoading ? (
        <Grid
          style={{ width: '100%', height: '694px' }}
          container
          item
          justifyContent="center"
          alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <form style={{ width: '100%', paddingTop: '16px' }}>
          <Grid
            item
            container
            xs={12}
            style={{
              border: ' 1px solid rgba(0, 0, 0, 0.12)',
              rowGap: '8px',
              padding: '24px 0 0 16px',
              borderRadius: '8px',
              backgroundImage: `linear-gradient(to right, #fff, #fff, transparent), url(${background})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}
            direction="column"
            alignItems="flex-start">
            <Typography variant="body1" component="span">
              {addressDetail?.Line1}
            </Typography>
            <Typography variant="body1" component="span">
              {addressDetail?.Line2}
            </Typography>
            <Typography variant="body1" component="span">
              {addressDetail?.Line3}
            </Typography>
            <Typography variant="body1" component="span">
              {addressDetail?.TownOrCity}, {addressDetail?.County}, {addressDetail?.Postcode}
            </Typography>
            <Typography variant="body1" component="span">
              {addressDetail?.Country}
            </Typography>
            <ButtonTextPrimary
              onClick={() => setStep(0)}
              style={{ marginLeft: '-8px', marginBottom: '12px' }}>
              Change delivery location
            </ButtonTextPrimary>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: '24px' }}>
            <Typography variant="subtitle2" component="span">
              Address name
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <ControlledInput
              name="name"
              rules={{ required: true }}
              control={control}
              label="Address name"
            />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: '16px' }}>
            <Typography variant="subtitle2" component="span">
              Delivery Instructions
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <ControlledInput
              name="nameOnDoorbell"
              control={control}
              label="Name on the doorbell (Opt.)"
            />
          </Grid>
          <Grid xs={12} item>
            <ControlledInput
              multiline
              FormHelperTextProps={{ style: { textAlign: 'right' } }}
              name="instructions"
              control={control}
              helperText={`${watch('instructions')?.length || 0}/320`}
              onChange={(e) => {
                if (e.target.value.length > 320) {
                  return;
                }
                setValue('instructions', e.target.value, {
                  shouldDirty: true,
                  shouldValidate: true
                });
              }}
              rules={{ maxLength: 320 }}
              label="Instructions for the courier (Opt.)"
            />
          </Grid>
          <Grid xs={12} item>
            <ControlledInput
              name="phone"
              control={control}
              rules={{ pattern: PHONE_VALIDATION_V3 }}
              label="Contact phone number (Opt.)"
            />
          </Grid>
        </form>
      )}
    </ResponsiveModal>
  );
}
