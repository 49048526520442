import DELIVERY_ADDRESSES from '../symbols/deliveryAddresses';
import { HttpRequestPost } from '../../service';
import { DeliveryAddressItem } from '../reducers/deliveryAddresses';

const deliveryAddressesAction = () => ({
  type: DELIVERY_ADDRESSES.DELIVERY_ADDRESSES
});
const deliveryAddressesSuccessAction = (data: DeliveryAddressItem) => {
  return {
    type: DELIVERY_ADDRESSES.DELIVERY_ADDRESSES_SUCCESS,
    payload: data
  };
};
const deliveryAddressesFailureAction = (data: any) => ({
  type: DELIVERY_ADDRESSES.DELIVERY_ADDRESSES_FAILURE,
  payload: data
});
const clearDeliveryAddressesAction = () => ({
  type: DELIVERY_ADDRESSES.CLEAR_DELIVERY_ADDRESSES
});

interface GetDeliveryAddressesRequestModel {
  Query?: string;
  Size: number;
  Page: number;
}
export function GetDeliveryAddresses(
  data: GetDeliveryAddressesRequestModel,
  // eslint-disable-next-line no-unused-vars
  callback: (resp: DeliveryAddressItem) => DeliveryAddressItem
) {
  return (dispatch: any) => {
    dispatch(deliveryAddressesAction());
    HttpRequestPost(
      '/pro/delivery-address/search', // This should be /shop/team
      dispatch,
      (response: any) => {
        const formattedData = callback(response.Result);
        return deliveryAddressesSuccessAction(formattedData);
      },
      deliveryAddressesFailureAction,
      true,
      data
    );
  };
}

export function clearDeliveryAddresses() {
  return (dispatch: any) => {
    dispatch(clearDeliveryAddressesAction());
  };
}
