/* eslint-disable react/destructuring-assignment */
import GRANT_ACCESS from '../symbols/grantAccess';

const initialState = {
  loading: false,
  error: false,
  data: []
};

// eslint-disable-next-line default-param-last
function GrantAccessReducer(state = initialState, action: any) {
  switch (action.type) {
    case GRANT_ACCESS.GRANT_ACCESS:
      return { ...state, loading: true, error: false };
    case GRANT_ACCESS.GRANT_ACCESS_SUCCESS:
      return {
        ...state,
        data: action.payload.data.Result,
        loading: false,
        error: false
      };
    case GRANT_ACCESS.GRANT_ACCESS_FAILURE:
      return {
        ...state,
        token: null,
        loading: false,
        error: action.payload
      };
    case GRANT_ACCESS.CLEAR_GRANT_ACCESS:
      return {
        ...state,
        token: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default GrantAccessReducer;
