/* eslint-disable react/destructuring-assignment */
import APPROVE_ORDER from '../symbols/approveOrder';

const initialState = {
  data: '',
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function ApproveOrderReducer(state = initialState, action: any) {
  switch (action.type) {
    case APPROVE_ORDER.APPROVE_ORDER:
      return { ...state, loading: true, error: false };
    case APPROVE_ORDER.APPROVE_ORDER_SUCCESS:
      return {
        ...state,
        data: action.payload.Result,
        loading: false,
        error: false
      };
    case APPROVE_ORDER.APPROVE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case APPROVE_ORDER.CLEAR_APPROVE_ORDER:
      return {
        ...state,
        data: '',
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default ApproveOrderReducer;
