/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Container, IconButton, TextField, Typography, makeStyles, Grid } from '@material-ui/core';
import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import CloseRounded from '@material-ui/icons/CloseRounded';
import DateFnsUtils from '@date-io/date-fns';
import { FilterList } from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ITheme, theme } from '../constants/theme';
import {
  FilterItems,
  FilterSelectionButton,
  KeyboardDatePickerInput,
  ButtonPrimary,
  ButtonTextPrimary
} from '../components-atoms';
import { TabPanel } from '.';
import backArrow from '../assets/icons/backArrow.svg';
import groupFilterItems from '../utils/groupByName';
import { getOrderFilterDate } from '../utils/getOrderDate';
import {
  buttonHoverAndActive,
  headerButtonsResponsive,
  textButtonHoverAndActive
} from '../utils/styles/buttonStyles';

const useStyles = makeStyles((myTheme: ITheme) => ({
  printButtonText: {
    textTransform: 'none'
  },
  titleContainer: {
    minWidth: 320,
    padding: '8px 8px 16px 8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  buttonContainer: {
    paddingLeft: 4
  },
  bottomButtonContainer: {
    padding: '24px 16px 16px 16px',
    '@media(max-height:700px)': {
      paddingTop: '0px'
    }
  },
  tabContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 320,
    paddingLeft: 4,
    paddingRight: 4,
    maxHeight: '350px',
    overflowY: 'auto'
  },
  menuList: {
    width: 320,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  menu: {
    marginTop: 65,
    marginRight: 120,
    '& [class*=MuiPaper-rounded-]': {
      top: 79,
      borderRadius: 12
    }
  },
  textButton: {
    borderRadius: 8,
    ...headerButtonsResponsive,
    ...textButtonHoverAndActive
  },
  backImg: {
    width: 40,
    height: 40,
    borderRadius: 20,
    cursor: 'pointer',
    ...buttonHoverAndActive
  },
  searchInput: {
    width: '100%'
  },
  clearSearchIconButton: {
    paddingRight: 0
  },
  clearSearchIcon: {
    color: myTheme.palette.text.secondary
  },
  menuPaper: {
    borderRadius: 12,
    overflow: 'hidden',
    '@media(max-height: 700px)': { top: '79px !important', maxWidth: '320px !important' }
  },
  noResultsText: {
    paddingTop: 8,
    color: myTheme.palette.text.secondary
  }
}));

export interface IFilterItem {
  title: string;
  value: string | boolean | null;
}

export interface IFilterOption {
  [key: string]: IFilterItem[];
}

export interface IFilter {
  [key: string]: Array<string | boolean | MaterialUiPickersDate> | undefined;
}

interface IProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (thisFilter: IFilter) => void;
  options: IFilterOption;
  filter: IFilter;
  defaultOption: string;
  dateKey?: string;
  searchableList?: string[];
  singleDate?: boolean;
  groupedKeys?: string[];
  clearAll?: boolean;
  onClear?: (filter: IFilter) => void;
}

export function FilterButton({
  onSubmit,
  options,
  filter,
  defaultOption,
  dateKey,
  searchableList = [],
  singleDate,
  groupedKeys = [],
  clearAll = false,
  onClear
}: IProps) {
  const classes = useStyles();
  const searchInputRef = useRef<any>();
  const [thisFilter, setThisFilter] = useState<IFilter>(filter);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState<string>(defaultOption);
  const [submittedFilter, setSubmittedFilter] = useState<IFilter>(filter || {});
  const [optionsCopy, setOptionsCopy] = useState(options);
  const [dateOptions, setDateOptions] = useState<{ [key: string]: ParsableDate }>({});
  const [index, setIndex] = useState(0);
  const [dateError, setDateError] = useState<ReactNode>();
  const [filterSearch, setFilterSearch] = useState<string>('');
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setIndex(0);
    setAnchorEl(null);
  };

  const filterButtonClicked = (option: string): void => {
    setSelectedOption(option);
    setIndex(1);
  };

  const onItemChange = (checked: boolean, value: string | boolean | null): void => {
    if (checked) {
      const tempItem = thisFilter[selectedOption] || [];
      setThisFilter({ ...thisFilter, [selectedOption]: [...tempItem, value] });
    } else {
      const tempSelectedOption = thisFilter[selectedOption]?.filter((f) => f !== value);
      setThisFilter({ ...thisFilter, [selectedOption]: tempSelectedOption });
    }
  };

  const isFilterEmpty = (): boolean => {
    let result: boolean = true;
    if (index === 1) {
      return thisFilter[selectedOption]?.length === 0;
    }
    Object.keys(thisFilter).forEach((key) => {
      const tempItem = thisFilter[key] || [];
      if (tempItem.length > 0) {
        result = false;
      }
    });
    return result;
  };

  const getActiveFilterCount = (): number =>
    Object.keys(submittedFilter).filter(
      (key: string) => (submittedFilter[key]?.length as number) > 0
    ).length;

  useEffect(() => {
    setOptionsCopy(options);
  }, [options]);

  const clearFilter = (): void => {
    if (index === 1) {
      setThisFilter({ ...thisFilter, [selectedOption]: [] });
    } else {
      let tempFilter = {};
      Object.keys(thisFilter).forEach((key) => {
        tempFilter = { ...tempFilter, [key]: [] };
      });
      setThisFilter(tempFilter);
    }
    setDateOptions({});
    setFilterSearch('');
  };

  const onClearAll = () => {
    if (onClear) {
      onClear(thisFilter);
    } else {
      const emptyThisFilter: any = Object.fromEntries(
        Object.keys(thisFilter).map((key) => [key, []])
      );
      setThisFilter(emptyThisFilter);
      setSubmittedFilter(emptyThisFilter);
      onSubmit(emptyThisFilter);
    }

    setDateOptions({});
    setFilterSearch('');
    handleClose();
  };

  const selectAll = (): void => {
    const tempSelectedOptions = options[selectedOption].map(
      (filterItem: IFilterItem) => filterItem.value
    );
    setThisFilter({ ...thisFilter, [selectedOption]: tempSelectedOptions });
  };

  const isValid = (): boolean => {
    if (singleDate) {
      return !dateError;
    }
    return !(
      !!dateError ||
      (thisFilter.Date && thisFilter.Date.length === 1) ||
      (thisFilter.Date && thisFilter.Date.length === 2 && !thisFilter.Date[0])
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Button
        id="filter-button"
        data-automation="filter-button"
        aria-label="Filter"
        aria-controls={open ? 'filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        startIcon={<FilterList color="primary" />}
        onClick={handleClick}
        style={{
          borderRadius: 8,
          backgroundColor: open
            ? theme.palette.secondary.main
            : getActiveFilterCount()
            ? (theme as ITheme).palette.secondary[24]
            : undefined
        }}
        className={classes.textButton}>
        <Typography
          variant="button"
          component="span"
          color="primary"
          className={classes.printButtonText}>
          Filter{!!getActiveFilterCount() && ` · ${getActiveFilterCount()}`}
        </Typography>
      </Button>

      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ className: classes.menuPaper }}
        MenuListProps={{
          'aria-labelledby': 'filter-button',
          role: 'listbox',
          className: classes.menuList
        }}
        className={classes.menu}>
        <Container
          onFocusCapture={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (searchInputRef && searchInputRef.current) {
              searchInputRef.current.focus();
            }
          }}
          className={classes.titleContainer}>
          {index === 1 && (
            <img
              onClick={() => setIndex(0)}
              src={backArrow}
              alt="back arrow icon"
              className={classes.backImg}
            />
          )}
          <Typography
            variant="h6"
            component="span"
            color="textPrimary"
            style={{ marginLeft: index === 1 ? 16 : 8 }}>
            {index === 0 ? 'Filter' : selectedOption === 'Date' ? 'Order Date' : selectedOption}
          </Typography>
        </Container>
        <Container className={classes.buttonContainer}>
          {index === 1 && selectedOption !== dateKey && (
            <ButtonTextPrimary
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              title="Select All"
              onClick={() => selectAll()}
              style={{ padding: '6px 12px' }}>
              Select All
            </ButtonTextPrimary>
          )}
          <ButtonTextPrimary
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            title="Clear All"
            disabled={isFilterEmpty()}
            onClick={() => (clearAll ? onClearAll() : clearFilter())}
            style={{ padding: '6px 12px' }}>
            Clear All
          </ButtonTextPrimary>
        </Container>
        <TabPanel value={index} index={0}>
          <Container className={classes.tabContainer}>
            {Object.keys(options).map((optionKey) => {
              const tempItem = thisFilter[optionKey] || [];
              return (
                <FilterSelectionButton
                  key={optionKey}
                  title={optionKey}
                  onClick={() => filterButtonClicked(optionKey)}
                  isDate={optionKey === dateKey}
                  // @ts-ignore
                  selectedFilters={
                    optionKey === dateKey
                      ? thisFilter[optionKey]?.map((item) => getOrderFilterDate(item as string))
                      : options[optionKey]
                          .filter((f) => tempItem.includes(f.value))
                          .map((item) => item.title)
                  }
                />
              );
            })}
          </Container>
        </TabPanel>
        <Container className={classes.tabContainer} style={{ padding: '8px 16px' }}>
          <TabPanel style={{ paddingTop: 0 }} value={index} index={1}>
            {searchableList.includes(selectedOption) ? (
              <TextField
                size="small"
                inputRef={searchInputRef}
                className={classes.searchInput}
                variant="outlined"
                placeholder={`Search ${selectedOption}`}
                label={`Search ${selectedOption}`}
                value={filterSearch}
                onChange={(e) => {
                  e.preventDefault();
                  setFilterSearch(e.target.value);
                  setOptionsCopy({
                    ...optionsCopy,
                    [selectedOption]: options[selectedOption].filter((item: IFilterItem) =>
                      item.title.toLowerCase().includes(e.target.value.toLocaleLowerCase())
                    )
                  });
                }}
                InputProps={{
                  endAdornment: filterSearch ? (
                    <IconButton
                      edge="end"
                      onClick={() => {
                        setFilterSearch('');
                        setOptionsCopy({
                          ...optionsCopy,
                          [selectedOption]: options[selectedOption]
                        });
                      }}
                      size="small"
                      className={classes.clearSearchIconButton}>
                      <CloseRounded className={classes.clearSearchIcon} fontSize="small" />
                    </IconButton>
                  ) : undefined
                }}
              />
            ) : undefined}
            {optionsCopy[selectedOption]?.length > 0 ? (
              selectedOption === dateKey ? (
                optionsCopy[selectedOption].map((filterItem: IFilterItem) => {
                  const tempItem = thisFilter[selectedOption]
                    ? [
                        ...(thisFilter[selectedOption] as Array<
                          string | boolean | MaterialUiPickersDate
                        >)
                      ]
                    : [];
                  const isFrom = filterItem.title === 'From';
                  const optionsValue = dateOptions[filterItem.title];
                  // eslint-disable-next-line no-unneeded-ternary
                  const datePickerValue = optionsValue
                    ? optionsValue
                    : isFrom
                    ? tempItem[0]
                    : tempItem[1];
                  return (
                    <KeyboardDatePickerInput
                      key={filterItem.title}
                      placeholder="DD/MM/YYYY"
                      label={filterItem.title}
                      defaultValue={null}
                      onError={(e) => {
                        if (!e && dateError) {
                          setDateError(undefined);
                          return;
                        }
                        setDateError(e);
                      }}
                      minDate={!isFrom ? dateOptions.From : undefined}
                      minDateMessage="End date should be after the start date"
                      maxDateMessage="Please select a date prior to 2100"
                      onChange={(d) => {
                        setDateOptions({
                          ...dateOptions,
                          [filterItem.title]: d
                        });
                        if (isFrom) {
                          tempItem[0] = d;
                        } else {
                          tempItem[1] = d;
                        }
                        setThisFilter({
                          ...thisFilter,
                          [selectedOption]: tempItem
                        });
                      }}
                      // @ts-ignore
                      value={datePickerValue || null}
                    />
                  );
                })
              ) : !groupedKeys.includes(selectedOption) ? (
                <>
                  {optionsCopy[selectedOption].map((filterItem) => {
                    const tempItem = thisFilter[selectedOption]
                      ? [
                          ...(thisFilter[selectedOption] as Array<
                            string | boolean | MaterialUiPickersDate
                          >)
                        ]
                      : [];
                    return (
                      <FilterItems
                        checked={tempItem.filter((f) => f === filterItem.value).length > 0}
                        key={filterItem.value?.toString()}
                        title={filterItem.title}
                        onChange={(checked: boolean) => {
                          onItemChange(checked, filterItem.value);
                        }}
                      />
                    );
                  })}
                </>
              ) : (
                Object.entries(groupFilterItems(optionsCopy[selectedOption])).map((group) => {
                  const tempItem = thisFilter[selectedOption]
                    ? [
                        ...(thisFilter[selectedOption] as Array<
                          string | boolean | MaterialUiPickersDate
                        >)
                      ]
                    : [];
                  return (
                    <Fragment key={group[0]}>
                      <Grid
                        container
                        item
                        direction="row"
                        alignItems="center"
                        style={{ columnGap: '8px' }}>
                        <Typography variant="subtitle2">{group[0]}</Typography>
                        <Typography variant="caption" color="textSecondary">
                          {group[1].length}
                          {group[1].length > 1 ? ' Items' : ' Item'}
                        </Typography>
                      </Grid>
                      {group[1].map((filterItem) => {
                        return (
                          <FilterItems
                            checked={tempItem.filter((f) => f === filterItem.value).length > 0}
                            key={filterItem.value?.toString()}
                            title={filterItem.title}
                            onChange={(checked: boolean) => {
                              onItemChange(checked, filterItem.value);
                            }}
                          />
                        );
                      })}
                    </Fragment>
                  );
                })
              )
            ) : (
              <Typography
                variant="caption"
                component="p"
                color="inherit"
                className={classes.noResultsText}>
                No Results
              </Typography>
            )}
          </TabPanel>
        </Container>

        <Container className={classes.bottomButtonContainer}>
          <ButtonPrimary
            disabled={dateKey ? !isValid() : false}
            style={{ width: '100%', height: 36 }}
            onClick={() => {
              setSubmittedFilter(thisFilter);
              onSubmit(thisFilter);
              handleClose();
            }}>
            Apply Filters
          </ButtonPrimary>
        </Container>
      </Menu>
    </MuiPickersUtilsProvider>
  );
}
