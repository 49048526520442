const FAILED_PAYMENT_SET_ID = 'FAILED_PAYMENT_SET_ID';
const FAILED_PAYMENT_SET_COUNT = 'FAILED_PAYMENT_SET_COUNT';
const CLEAR_FAILED_PAYMENT = 'CLEAR_FAILED_PAYMENT';

const FailedPaymentSymbols = {
  FAILED_PAYMENT_SET_ID,
  FAILED_PAYMENT_SET_COUNT,
  CLEAR_FAILED_PAYMENT
};

export default FailedPaymentSymbols;
