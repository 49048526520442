const REMOVE_DELIVERY_ADDRESS = 'REMOVE_DELIVERY_ADDRESS';
const REMOVE_DELIVERY_ADDRESS_SUCCESS = 'REMOVE_DELIVERY_ADDRESS_SUCCESS';
const REMOVE_DELIVERY_ADDRESS_FAILURE = 'REMOVE_DELIVERY_ADDRESS_FAILURE';
const CLEAR_REMOVE_DELIVERY_ADDRESS = 'CLEAR_REMOVE_DELIVERY_ADDRESS';

const RemoveDeliveryAddressSymbols = {
  REMOVE_DELIVERY_ADDRESS_SUCCESS,
  REMOVE_DELIVERY_ADDRESS_FAILURE,
  REMOVE_DELIVERY_ADDRESS,
  CLEAR_REMOVE_DELIVERY_ADDRESS
};

export default RemoveDeliveryAddressSymbols;
