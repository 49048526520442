import { Container, Grid, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ButtonSecondary, ButtonWithLoading, TextFieldInput } from '../components-atoms';
import { GeneralModal } from '../components-molecules';
import { PHONE_NUMBER_PATTERN, UK_POST_CODE_PATTERN } from '../constants/validation';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { EditTeam, EditTeamRequestModel } from '../redux/actions/editTeam';
import { GetProfile } from '../redux/actions/profile';
import { GetTeamDetail } from '../redux/actions/teamDetail';
import { Organisation } from '../redux/reducers/getTeamDetail';
import RightArrow from '../components-atoms/icons/rightArrow';

const useStyles = makeStyles(() => ({
  basicInformationContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    boxSizing: 'border-box',
    textAlign: 'left',
    marginTop: '24px',
    padding: '24px 16px 0',
    '@media (min-width: 900px)': {
      padding: '18px 24px 0',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '20px'
    }
  },
  infoTitle: {
    marginBottom: 20
  },
  infoTextContainer: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    '&:hover [data-arrow=arrowContainer]': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:active [data-arrow=arrowContainer]': {
      backgroundColor: 'rgba(0, 0, 0, 0.16)'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  infoTextContainerLastRow: {
    paddingTop: 15,
    paddingBottom: 15,
    alignItems: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    '&:hover [data-arrow=arrowContainer]': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:active [data-arrow=arrowContainer]': {
      backgroundColor: 'rgba(0, 0, 0, 0.16)'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  infoTextTitle: {
    minWidth: 164,
    '@media (max-width: 599px)': {
      marginBottom: 4
    }
  },
  infoTextButtonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (min-width: 600px)': {
      marginLeft: 16
    }
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 40,
    height: 40,
    borderRadius: 20,
    transition: 'transform 0.15s ease-in-out',
    marginLeft: 16
  }
}));

export function OrganisationBasicInfo() {
  const classes = useStyles();
  const data = useAppSelector((state) => state.getTeamDetail.data as Organisation);
  const [modal, setModal] = useState('');
  const [organisation, setOrganisation] = useState(data);

  useEffect(() => {
    setOrganisation(data);
  }, [data]);

  const dispatch = useAppDispatch();

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setOrganisation((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const editOrganisation = (
    key: keyof EditTeamRequestModel,
    value: EditTeamRequestModel[keyof EditTeamRequestModel]
  ) => {
    dispatch(
      EditTeam({ [key]: value }, () => {
        dispatch(GetTeamDetail());
        dispatch(GetProfile());
        setModal('');
      })
    );
  };

  const isAddressInValid = (d: Organisation): boolean => {
    return !d.Line1 || !d.County || !d.City || !d.PostalCode;
  };

  return (
    <div>
      {!data || data === null ? (
        <CircularProgress />
      ) : (
        <Container className={classes.basicInformationContainer}>
          <Grid container>
            <Grid container className={classes.infoTitle}>
              <Typography variant="h6" component="span">
                Basic information
              </Typography>
            </Grid>
            <Grid container className={classes.infoTextContainer} onClick={() => setModal('name')}>
              <Typography
                display="inline"
                variant="caption"
                component="span"
                color="textSecondary"
                data-automation="order-id-main"
                className={classes.infoTextTitle}>
                ORGANISATION NAME
              </Typography>
              <Grid item className={classes.infoTextButtonContainer}>
                <Typography variant="body1" component="span" color="textPrimary">
                  {data.TeamName}
                </Typography>
                <div className={classes.arrowContainer} data-arrow="arrowContainer">
                  <RightArrow onClick={() => setModal('name')} />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.infoTextContainer}
              onClick={() => setModal('address')}>
              <Typography
                display="inline"
                variant="caption"
                component="span"
                color="textSecondary"
                className={classes.infoTextTitle}>
                ADDRESS
              </Typography>
              <Grid item className={classes.infoTextButtonContainer}>
                <Typography variant="body1" component="span" color="textPrimary">
                  {data.Line1}, {data.City} {data.PostalCode}, {data.County}
                </Typography>
                <div className={classes.arrowContainer} data-arrow="arrowContainer">
                  <RightArrow onClick={() => setModal('address')} />
                </div>
              </Grid>
            </Grid>
            <Grid container className={classes.infoTextContainer} onClick={() => setModal('phone')}>
              <Typography
                display="inline"
                variant="caption"
                component="span"
                color="textSecondary"
                className={classes.infoTextTitle}>
                CONTACT PHONE
              </Typography>
              <Grid item className={classes.infoTextButtonContainer}>
                <Typography variant="body1" component="span" color="textPrimary">
                  {data.PhoneNumber}
                </Typography>
                <div className={classes.arrowContainer} data-arrow="arrowContainer">
                  <RightArrow onClick={() => setModal('phone')} />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.infoTextContainerLastRow}
              onClick={() => setModal('vat')}>
              <Typography
                variant="caption"
                component="span"
                color="textSecondary"
                className={classes.infoTextTitle}>
                VAT NUMBER
              </Typography>
              <Grid item className={classes.infoTextButtonContainer}>
                <Typography variant="body1" component="span" color="textPrimary">
                  {data.VATNumber}
                </Typography>
                <div className={classes.arrowContainer} data-arrow="arrowContainer">
                  <RightArrow onClick={() => setModal('vat')} />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <GeneralModal
            visible={modal === 'name'}
            onClose={() => {
              setModal('');
              setOrganisation((prev) => ({ ...prev, TeamName: data.TeamName }));
            }}
            title="Edit organisation name">
            <Grid container item xs={12}>
              <form style={{ width: '100%' }}>
                <Grid xs={12} item>
                  <TextFieldInput
                    value={organisation.TeamName}
                    name="TeamName"
                    onChange={onChange}
                    label="Organisation name"
                  />
                </Grid>
                <Grid
                  xs={12}
                  container
                  item
                  justifyContent="flex-end"
                  style={{ paddingTop: '24px', columnGap: '8px' }}>
                  <ButtonSecondary
                    onClick={() => {
                      setModal('');
                      setOrganisation((prev) => ({ ...prev, TeamName: data.TeamName }));
                    }}
                    style={{ height: '48px' }}>
                    Cancel
                  </ButtonSecondary>
                  <ButtonWithLoading
                    title="Submit"
                    type="button"
                    onClick={() => editOrganisation('TeamName', organisation.TeamName)}
                    disabled={!organisation.TeamName}
                    loading={false}
                  />
                </Grid>
              </form>
            </Grid>
          </GeneralModal>
          <GeneralModal
            visible={modal === 'vat'}
            onClose={() => {
              setModal('');
              setOrganisation((prev) => ({ ...prev, VATNumber: data.VATNumber }));
            }}
            title="Edit organisation VAT number">
            <Grid container item xs={12}>
              <form style={{ width: '100%' }}>
                <Grid xs={12} item>
                  <TextFieldInput
                    value={organisation.VATNumber}
                    name="VATNumber"
                    onChange={onChange}
                    label="Organisation VAT number"
                  />
                </Grid>
                <Grid
                  xs={12}
                  container
                  item
                  justifyContent="flex-end"
                  style={{ paddingTop: '24px', columnGap: '8px' }}>
                  <ButtonSecondary
                    onClick={() => {
                      setModal('');
                      setOrganisation((prev) => ({ ...prev, VATNumber: data.VATNumber }));
                    }}
                    style={{ height: '48px' }}>
                    Cancel
                  </ButtonSecondary>
                  <ButtonWithLoading
                    title="Submit"
                    type="button"
                    onClick={() => editOrganisation('VATNumber', organisation.VATNumber)}
                    disabled={!organisation.VATNumber}
                    loading={false}
                  />
                </Grid>
              </form>
            </Grid>
          </GeneralModal>
          <GeneralModal
            visible={modal === 'phone'}
            onClose={() => {
              setModal('');
              setOrganisation((prev) => ({ ...prev, TeamName: data.TeamName }));
            }}
            title="Edit organisation contact phone">
            <Grid container item xs={12}>
              <form style={{ width: '100%' }}>
                <Grid xs={12} item>
                  <TextFieldInput
                    value={organisation.PhoneNumber}
                    name="PhoneNumber"
                    onChange={onChange}
                    error={!PHONE_NUMBER_PATTERN.test(organisation.PhoneNumber)}
                    helperText={
                      !PHONE_NUMBER_PATTERN.test(organisation.PhoneNumber)
                        ? 'Enter a valid phone number.'
                        : ''
                    }
                    label="Contact phone"
                  />
                </Grid>
                <Grid
                  xs={12}
                  container
                  item
                  justifyContent="flex-end"
                  style={{ paddingTop: '24px', columnGap: '8px' }}>
                  <ButtonSecondary
                    onClick={() => {
                      setModal('');
                      setOrganisation((prev) => ({ ...prev, PhoneNumber: data.PhoneNumber }));
                    }}
                    style={{ height: '48px' }}>
                    Cancel
                  </ButtonSecondary>
                  <ButtonWithLoading
                    title="Submit"
                    type="button"
                    onClick={() => editOrganisation('PhoneNumber', organisation.PhoneNumber)}
                    disabled={
                      !organisation.PhoneNumber &&
                      !PHONE_NUMBER_PATTERN.test(organisation.PhoneNumber)
                    }
                    loading={false}
                  />
                </Grid>
              </form>
            </Grid>
          </GeneralModal>
          <GeneralModal
            visible={modal === 'address'}
            onClose={() => {
              setModal('');
              setOrganisation((prev) => ({
                ...prev,
                City: data.City,
                County: data.County,
                Line1: data.Line1,
                Line2: data.Line2,
                Line3: data.Line3,
                PostalCode: data.PostalCode
              }));
            }}
            title="Edit organisation billing address">
            <Grid container item xs={12}>
              <form style={{ width: '100%' }}>
                <Grid xs={12} item>
                  <TextFieldInput
                    value={organisation.Line1}
                    name="Line1"
                    onChange={onChange}
                    label="Address Line 1"
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextFieldInput
                    value={organisation.Line2}
                    name="Line2"
                    onChange={onChange}
                    label="Address Line 2"
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextFieldInput
                    value={organisation.Line3}
                    name="Line3"
                    onChange={onChange}
                    label="Address Line 3"
                  />
                </Grid>
                <Grid xs={12} item container direction="row">
                  <Grid item xs={6} style={{ paddingRight: '8px' }}>
                    <TextFieldInput
                      value={organisation.City}
                      name="City"
                      onChange={onChange}
                      label="City"
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: '8px' }}>
                    <TextFieldInput
                      value={organisation.PostalCode}
                      name="PostalCode"
                      onChange={onChange}
                      label="Postcode"
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} item>
                  <TextFieldInput
                    value={organisation.County}
                    name="County"
                    onChange={onChange}
                    label="County"
                  />
                </Grid>
                <Grid
                  xs={12}
                  container
                  item
                  justifyContent="flex-end"
                  style={{ paddingTop: '24px', columnGap: '8px' }}>
                  <ButtonSecondary
                    onClick={() => {
                      setModal('');
                      setOrganisation((prev) => ({ ...prev, PhoneNumber: data.PhoneNumber }));
                    }}
                    style={{ height: '48px' }}>
                    Cancel
                  </ButtonSecondary>
                  <ButtonWithLoading
                    title="Submit"
                    type="button"
                    onClick={() =>
                      editOrganisation('BillingAddress', {
                        Line1: organisation.Line1,
                        Line2: organisation.Line2,
                        Line3: organisation.Line3,
                        City: organisation.City,
                        PostalCode: organisation.PostalCode,
                        County: organisation.County
                      })
                    }
                    disabled={
                      isAddressInValid(organisation) ||
                      !organisation.PostalCode.match(UK_POST_CODE_PATTERN)?.length
                    }
                    loading={false}
                  />
                </Grid>
              </form>
            </Grid>
          </GeneralModal>
        </Container>
      )}
    </div>
  );
}
