/* eslint-disable react/destructuring-assignment */
import { SetupForm, OrganisationForm } from '../../components-pages/Setup';
import CREATE_SESSION from '../symbols/createSession';

interface State {
  data: { Url: string; user: SetupForm | null; organisation: OrganisationForm | null };
  loading: boolean;
  error: boolean;
}
const initialState = {
  data: { Url: '', user: null, organisation: null },
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function CreateSessionReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case CREATE_SESSION.CREATE_SESSION:
      return {
        ...state,
        loading: true,
        error: false,
        data: {
          ...state.data,
          user: action.payload.data.user,
          organisation: action.payload.data.organisation
        }
      };
    case CREATE_SESSION.CREATE_SESSION_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload.Result },
        loading: false,
        error: false
      };
    case CREATE_SESSION.CREATE_SESSION_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case CREATE_SESSION.CLEAR_CREATE_SESSION:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default CreateSessionReducer;
