import EDIT_MEMBER from '../symbols/editMember';
import { HttpRequestPut } from '../../service';

const editMemberAction = () => ({
  type: EDIT_MEMBER.EDIT_MEMBER
});
const editMemberSuccessAction = (data: any) => {
  return {
    type: EDIT_MEMBER.EDIT_MEMBER_SUCCESS,
    payload: data
  };
};
const editMemberFailureAction = (data: any) => ({
  type: EDIT_MEMBER.EDIT_MEMBER_FAILURE,
  payload: {
    data
  }
});
const clearEditMemberAction = () => ({
  type: EDIT_MEMBER.CLEAR_EDIT_MEMBER
});

interface EditMemberRequestModel {
  ApprovalThreshold?: number;
  ApprovalRequired: boolean;
  FirstName: string;
  LastName: string;
}
export function EditMember(data: EditMemberRequestModel, id: string, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(editMemberAction());
    HttpRequestPut(
      `/pro/user/${id}`,
      dispatch,
      editMemberSuccessAction,
      editMemberFailureAction,
      true,
      data,
      callback
    );
  };
}

export function clearEditMember() {
  return (dispatch: any) => {
    dispatch(clearEditMemberAction());
  };
}
