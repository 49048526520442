/* eslint-disable react/destructuring-assignment */
import SEARCH_ADDRESS from '../symbols/searchAddress';

interface SearchAddressResult {
  Id: string;
  Address: string;
}
interface State {
  loading: boolean;
  data: SearchAddressResult[];
  error: any;
}
const initialState: State = {
  loading: false,
  error: false,
  data: []
};

// eslint-disable-next-line default-param-last
function SearchAddressReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case SEARCH_ADDRESS.SEARCH_ADDRESS:
      return { ...state, loading: true, error: false };
    case SEARCH_ADDRESS.SEARCH_ADDRESS_SUCCESS:
      return {
        ...state,
        data: action.payload as SearchAddressResult[],
        loading: false,
        error: false
      };
    case SEARCH_ADDRESS.SEARCH_ADDRESS_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload
      };
    case SEARCH_ADDRESS.CLEAR_SEARCH_ADDRESS:
      return {
        ...state,
        data: [],
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default SearchAddressReducer;
