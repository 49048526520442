import CHANGE_PASSWORD from '../symbols/changePassword';
import { HttpRequestPut } from '../../service';

const changePasswordAction = () => ({
  type: CHANGE_PASSWORD.CHANGE_PASSWORD
});
const changePasswordSuccessAction = (data: any) => {
  return {
    type: CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS,
    payload: data
  };
};
const changePasswordFailureAction = (data: any) => {
  return {
    type: CHANGE_PASSWORD.CHANGE_PASSWORD_FAILURE,
    payload: {
      data: data.data
    }
  };
};
const clearChangePasswordAction = () => ({
  type: CHANGE_PASSWORD.CLEAR_CHANGE_PASSWORD
});
interface ChangePasswordRequestModel {
  OldPassword: string;
  NewPassword: string;
}
// eslint-disable-next-line no-unused-vars
export function ChangePassword(data: ChangePasswordRequestModel, callback?: (resp: any) => void) {
  return (dispatch: any) => {
    dispatch(changePasswordAction());
    HttpRequestPut(
      '/pro/user/password',
      dispatch,
      changePasswordSuccessAction,
      changePasswordFailureAction,
      true,
      data,
      callback
    );
  };
}

export function clearChangePassword() {
  return (dispatch: any) => {
    dispatch(clearChangePasswordAction());
  };
}
export function clearPasswordError() {
  return (dispatch: any) => {
    dispatch({ type: CHANGE_PASSWORD.CLEAR_ERROR });
  };
}
