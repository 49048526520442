import { Container, Grid, ThemeProvider, Typography, makeStyles } from '@material-ui/core';
import { Fragment } from 'react';
import { theme } from '../constants/theme';

import { OrderedItemsListItem, OrderStamp } from '../components-molecules';
import { Delivery } from '../redux/reducers/orderDetail';
import { getDeliveryWindow } from '../utils';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '18px 16px 12px',
    boxSizing: 'border-box',
    marginTop: '24px',
    textAlign: 'left',
    '@media (min-width: 900px)': {
      padding: '18px 24px 12px',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '20px'
    }
  },
  infoTitle: {
    marginBottom: 10,
    textAlign: 'left',
    alignItems: 'center',
    '@media (max-width: 899px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  orderedItemsContainer: {
    paddingTop: 32,
    paddingBottom: 5
  },
  divider: {
    marginTop: '15px',
    marginBottom: '16px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    width: '100%'
  },
  infoTextContainer: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  infoTextTitle: {
    minWidth: 164,
    '@media (max-width: 599px)': {
      marginBottom: 4
    }
  },
  infoBodyContainer: {
    width: '100%',
    '@media (min-width: 600px)': {
      marginLeft: 16
    }
  },
  stamp: {
    '@media (max-width: 899px)': {
      alignSelf: 'flex-start',
      marginTop: 8,
      marginBottom: 8,
      marginLeft: 0
    }
  }
}));

interface IProps {
  index: number;
  data: Delivery;
}
export function OrderedItems({ data, index }: IProps) {
  const classes = useStyles();
  const hasCancelled =
    data.Products.filter(
      (f) => f.OrderLineCancelled || f.OrderLineOriginalQuantity !== f.OrderLineQuantity
    ).length > 0;

  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.container}>
        <Grid container>
          <Grid container direction="row" className={classes.infoTitle}>
            <Grid item>
              <Typography variant="h6" component="span" color="textPrimary" align="left">
                Delivery {index + 1}
              </Typography>
            </Grid>
            {data.DeliveryStatus && (
              <Grid item>
                <OrderStamp text={data.DeliveryStatus} className={classes.stamp} />
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.infoTextContainer}>
            <Typography
              display="inline"
              variant="caption"
              component="span"
              color="textSecondary"
              data-automation="order-id-main"
              className={classes.infoTextTitle}>
              MERCHANT NAME
            </Typography>
            <Grid item className={classes.infoBodyContainer}>
              <Typography variant="body1" component="span" color="textPrimary">
                {data.MerchantName}
                {data.MerchantName && data.ShopName ? ', ' : ''}
                {data.ShopName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.infoTextContainer}>
            <Typography
              display="inline"
              variant="caption"
              component="span"
              color="textSecondary"
              data-automation="order-id-main"
              className={classes.infoTextTitle}>
              EMAIL
            </Typography>
            <Grid item className={classes.infoBodyContainer}>
              {data.MerchantEmail !== '' ? (
                <Typography variant="body1" component="span" color="textPrimary">
                  {data.MerchantEmail}
                </Typography>
              ) : (
                <Typography variant="body1" component="span" color="textSecondary">
                  Not Available
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container className={classes.infoTextContainer}>
            <Typography
              display="inline"
              variant="caption"
              component="span"
              color="textSecondary"
              data-automation="order-id-main"
              className={classes.infoTextTitle}>
              PHONE
            </Typography>
            <Grid item className={classes.infoBodyContainer}>
              <Typography variant="body1" component="span" color="textPrimary">
                {data.ShopTelNo}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.infoTextContainer}>
            <Typography
              display="inline"
              variant="caption"
              component="span"
              color="textSecondary"
              data-automation="order-id-main"
              className={classes.infoTextTitle}>
              DELIVERY WINDOW
            </Typography>
            <Grid item className={classes.infoBodyContainer}>
              <Typography variant="body1" component="span" color="textPrimary">
                {getDeliveryWindow(data.TimeSlotFrom.toString(), data.TimeSlotTo.toString())}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.orderedItemsContainer}>
            {data.Products.map((item, idx, list) => (
              <Fragment key={`${item.ProductTitle}${idx + 1}`}>
                <OrderedItemsListItem item={item} status="pending" />
                {list.length - 1 === idx ? null : <div className={classes.divider} />}
              </Fragment>
            ))}
          </Grid>
          <Grid container item direction="column">
            {hasCancelled && (
              <>
                <div className={classes.divider} />
                <Grid container item direction="row" justifyContent="space-between">
                  <Typography variant="body1" component="p" color="textPrimary">
                    Initial delivery subtotal
                  </Typography>
                  <Typography variant="body1" component="p" color="textPrimary">
                    £{(data.DeliveryTotalResult.OriginalTotal / 100).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="space-between"
                  style={{ paddingTop: '8px' }}>
                  <Typography
                    variant="body1"
                    component="p"
                    color="error"
                    style={{ fontWeight: 700 }}>
                    Cancelled items
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    color="error"
                    style={{ fontWeight: 700 }}>
                    -£
                    {(data.DeliveryTotalResult.CancelledAmount / 100).toFixed(2)}
                  </Typography>
                </Grid>
              </>
            )}
            <div className={classes.divider} />
            <Grid container item direction="row" justifyContent="space-between">
              <Typography
                variant="body1"
                component="p"
                color="textPrimary"
                style={{ fontWeight: 700 }}>
                {hasCancelled ? 'Updated delivery subtotal' : 'Delivery subtotal'}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                color="textPrimary"
                style={{ fontWeight: 700 }}>
                £{(data.DeliveryTotalResult.Total / 100).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
