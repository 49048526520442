import { Box, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { textButtonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '24px'
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '24px 16px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'all 200ms ease',
    '@media (min-width: 900px)': {
      padding: '24px 16px 24px 24px ',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '20px'
    },
    ...textButtonHoverAndActive
  },
  rootDisabled: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '24px 16px',
    boxSizing: 'border-box',
    '@media (min-width: 900px)': {
      padding: '24px 16px 24px 24px ',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '20px'
    }
  },
  gridMargin: {
    marginTop: '8px'
  },
  bodyText: {
    paddingTop: 8,
    paddingBottom: 16
  },
  bottomText: {
    textAlign: 'left',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  rightContainer: {
    width: '40px',
    marginLeft: '16px'
  }
}));

interface InfoData {
  topText?: string | JSX.Element;
  body?: string;
  bottomText?: string;
}
interface IProps {
  data: InfoData;
  onClick?: () => void;
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
}
export function InfoCard({ data, onClick, disabled, children, style }: IProps) {
  const classes = useStyles();
  return (
    <Box onClick={onClick} className={classes.container}>
      <Container className={disabled ? classes.rootDisabled : classes.root} style={style}>
        <Grid item container justifyContent="center">
          <Grid item style={{ width: '100%' }}>
            <Typography variant="h6" component="h3" color="textPrimary" align="left">
              {data.topText}
            </Typography>
          </Grid>
          {data.body && (
            <Grid item container className={classes.bodyText}>
              <Typography variant="body1" component="span" color="textPrimary">
                {data.body}
              </Typography>
            </Grid>
          )}
          <Grid item container className={classes.gridMargin}>
            <Typography
              variant="body2"
              component="span"
              color="textSecondary"
              className={classes.bottomText}>
              {data.bottomText}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="flex-end" className={classes.rightContainer}>
          {children}
        </Grid>
      </Container>
    </Box>
  );
}
