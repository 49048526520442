import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  children?: React.ReactNode;
  value: number;
  index: number;
  style?: React.CSSProperties;
}

const useStyles = makeStyles({
  container: {
    paddingTop: '32px'
  }
});

export function TabPanel(props: IProps) {
  const { children, value, index, style, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box style={style} className={classes.container}>
          {children}
        </Box>
      )}
    </div>
  );
}
