const GET_ORDER_STATUS = 'GET_ORDER_STATUS';
const GET_ORDER_STATUS_SUCCESS = 'GET_ORDER_STATUS_SUCCESS';
const GET_ORDER_STATUS_FAILURE = 'GET_ORDER_STATUS_FAILURE';
const CLEAR_GET_ORDER_STATUS = 'CLEAR_GET_ORDER_STATUS';

const GetOrderStatusSymbols = {
  GET_ORDER_STATUS_SUCCESS,
  GET_ORDER_STATUS_FAILURE,
  GET_ORDER_STATUS,
  CLEAR_GET_ORDER_STATUS
};

export default GetOrderStatusSymbols;
