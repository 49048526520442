const CREATE_PAYMENT_ID = 'CREATE_PAYMENT_ID';
const CREATE_PAYMENT_ID_SUCCESS = 'CREATE_PAYMENT_ID_SUCCESS';
const CREATE_PAYMENT_ID_FAILURE = 'CREATE_PAYMENT_ID_FAILURE';
const CLEAR_CREATE_PAYMENT_ID = 'CLEAR_CREATE_PAYMENT_ID';
const CREATE_PAYMENT_ID_SET_LOADING = 'CREATE_PAYMENT_ID_SET_LOADING';

const CreatePaymentIdSymbols = {
  CREATE_PAYMENT_ID_SUCCESS,
  CREATE_PAYMENT_ID_FAILURE,
  CREATE_PAYMENT_ID,
  CLEAR_CREATE_PAYMENT_ID,
  CREATE_PAYMENT_ID_SET_LOADING
};

export default CreatePaymentIdSymbols;
