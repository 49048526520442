import GetCollectionTime from '../symbols/getCollectionTime';

interface IState {
  data: string;
  loading: boolean;
  error: boolean;
}
const initialState: IState = {
  data: '',
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
export default function GetCollectionTimeReducer(state = initialState, action: any) {
  switch (action.type) {
    case GetCollectionTime.GET_COLLECTION_TIME:
      return { ...state, loading: true, error: false };
    case GetCollectionTime.GET_COLLECTION_TIME_SUCCESS:
      return {
        ...state,
        data: action.payload.Result,
        loading: false,
        error: false
      };
    case GetCollectionTime.GET_COLLECTION_TIME_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: true
      };
    case GetCollectionTime.CLEAR_GET_COLLECTION_TIME:
      return {
        ...state,
        data: [],
        loading: false,
        error: false
      };

    default:
      return state;
  }
}
