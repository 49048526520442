const UPDATE_SESSION = 'UPDATE_SESSION';
const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
const UPDATE_SESSION_FAILURE = 'UPDATE_SESSION_FAILURE';
const CLEAR_UPDATE_SESSION = 'CLEAR_UPDATE_SESSION';

const UpdateSessionSymbols = {
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAILURE,
  UPDATE_SESSION,
  CLEAR_UPDATE_SESSION
};

export default UpdateSessionSymbols;
