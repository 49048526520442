import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { OrderFileModel } from '../redux/reducers/orderDetail';

const useStyles = makeStyles(() => ({
  customerInformationContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '18px 16px 0',
    marginTop: '24px',
    boxSizing: 'border-box',
    textAlign: 'left',
    '@media (min-width: 900px)': {
      padding: '18px 24px 0',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '20px'
    }
  },
  infoTitle: {
    marginBottom: 20
  },
  infoTextContainer: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  infoTextContainerLastRow: {
    paddingTop: 15,
    paddingBottom: 15,
    alignItems: 'center',
    flexWrap: 'nowrap',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  infoTextTitle: {
    minWidth: 164,
    '@media (max-width: 599px)': {
      marginBottom: 4
    }
  },
  infoBodyContainer: {
    width: '100%',
    '@media (min-width: 600px)': {
      marginLeft: 16
    }
  }
}));

export function OrderInvoices({ data }: { data: OrderFileModel[] }) {
  const classes = useStyles();
  return (
    <Container className={classes.customerInformationContainer}>
      <Grid container>
        <Grid container className={classes.infoTitle}>
          <Typography variant="h6" component="span">
            Order invoices
          </Typography>
        </Grid>
        {data.map((item, idx: number) => (
          <Grid
            key={item.FileName}
            container
            className={
              idx === data.length - 1 ? classes.infoTextContainerLastRow : classes.infoTextContainer
            }>
            <Typography
              display="inline"
              variant="caption"
              component="span"
              color="textSecondary"
              className={classes.infoTextTitle}>
              INVOICE {idx + 1}
            </Typography>
            <Grid item className={classes.infoBodyContainer}>
              <Typography
                variant="body1"
                component="span"
                color="primary"
                role="link"
                style={{ cursor: 'pointer' }}
                onClick={() => window.open(item.Url, '_blank')}>
                {item.FileName}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
