import { Button, ButtonProps, CircularProgress, Typography } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { CSSProperties, MouseEventHandler } from 'react';
import { theme } from '../constants/theme';
import { buttonHoverAndActive } from '../utils/styles/buttonStyles';

// eslint-disable-next-line no-shadow
const useStyles = makeStyles(() => ({
  primaryButton: {
    justifyContent: 'center',
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: 16,
    borderRadius: '8px',
    minWidth: '84px',
    ...buttonHoverAndActive
  },
  circularProgress: {
    marginRight: '8px'
  }
}));
interface IProps extends ButtonProps {
  loading: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  title: string;
  style?: CSSProperties;
}
function ButtonWithLoadingIndicator({ loading, onClick, disabled, type, title, style }: IProps) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        className={classes.primaryButton}
        style={{ paddingLeft: loading ? '12px' : '16px', ...style }}
        onClick={onClick}
        disabled={disabled}
        type={type}>
        {loading ? (
          <CircularProgress color="inherit" size={18} className={classes.circularProgress} />
        ) : null}
        <Typography variant="button" component="p">
          {title}
        </Typography>
      </Button>
    </ThemeProvider>
  );
}

export default ButtonWithLoadingIndicator;
