import { Stamp } from '../components-atoms';
import { StampType } from '../components-atoms/Stamp';

interface IProps {
  text: string;
  count?: number;
  className?: string;
}
const stampTypeByText: { [key: string]: string[] } = {
  primary: [
    'Pending',
    'Order Placed',
    'Ready for Collection',
    'Order Dispatched',
    'Out for Delivery',
    'Manually Refunded',
    'Packed',
    'Dispatched'
  ],
  error: ['Delivery Failed', 'Refunded', 'Cancelled', 'Rejected', 'Rejected By Manager'],
  success: ['Delivered']
};

const getTypeByText = (text: string): StampType => {
  return (
    (Object.keys(stampTypeByText).find((f) => stampTypeByText[f].includes(text)) as StampType) ||
    'primary'
  );
};
export function OrderStamp({ text, count, className }: IProps) {
  return <Stamp text={`${count || ''} ${text}`} type={getTypeByText(text)} className={className} />;
}
