import GRANT_ACCESS from '../symbols/grantAccess';
import { HttpRequestPost } from '../../service';

const grantAccessAction = () => ({
  type: GRANT_ACCESS.GRANT_ACCESS
});
const grantAccessSuccessAction = (data: any) => {
  return {
    type: GRANT_ACCESS.GRANT_ACCESS_SUCCESS,
    payload: {
      data
    }
  };
};
const grantAccessFailureAction = (data: any) => ({
  type: GRANT_ACCESS.GRANT_ACCESS_FAILURE,
  payload: {
    data
  }
});
const clearGrantAccessAction = () => ({
  type: GRANT_ACCESS.CLEAR_GRANT_ACCESS
});

export function GrantAccess(data: { CustomerId: string }, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(grantAccessAction());
    HttpRequestPost(
      '/pro/user/grant-access',
      dispatch,
      grantAccessSuccessAction,
      grantAccessFailureAction,
      true,
      data,
      callback
    );
  };
}

export function clearGrantAccess() {
  return (dispatch: any) => {
    dispatch(clearGrantAccessAction());
  };
}
