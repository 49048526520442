/* eslint-disable no-useless-escape */
const EMAIL_PATTERN =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_NUMBER_PATTERN =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
export const PHONE_NUMBER_PATTERN_V2 = /^(0) ?\d{4} ?\d{6}$/g;
export const PHONE_NUMBER_PATTERN_V3 = /^(?:\+44\s?)?(\d\s?){10,12}$/g;

export const UK_POST_CODE_PATTERN =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/g;
const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,16})/;

export const STARTS_WITH_NUMBER_PATTERN = /^\d+/;

export const STARTS_WITH_NUMBER_OR_LETTER_PATTERN = /^\w+/;

export const ONLY_NUMERIC_WITHOUT_ZERO_INPUT =
  /^(?:[1-9]\d*|0(?!(?:(\.|\,)0+)?$))?(?:(\.|\,)\d+)?$/;

export const EMAIL_VALIDATION = {
  value: EMAIL_PATTERN,
  message: 'Enter a valid e-mail address.'
};
export const PHONE_VALIDATION = {
  value: PHONE_NUMBER_PATTERN,
  message: 'Enter a valid phone number.'
};
export const PHONE_VALIDATION_V2 = {
  value: PHONE_NUMBER_PATTERN_V2,
  message: 'Enter a valid phone number.'
};
export const PHONE_VALIDATION_V3 = {
  value: PHONE_NUMBER_PATTERN_V3,
  message: 'Enter a valid phone number.'
};
export const REQUIRED_VALIDATION = {
  value: true,
  message: 'This field is required.'
};
export const UK_POST_CODE_VALIDATION = {
  value: UK_POST_CODE_PATTERN,
  message: 'Enter a valid postcode.'
};
export const PASSWORD_VALIDATION = {
  value: PASSWORD_PATTERN,
  message:
    'Passwords must be minimum eight characters with at least one lowercase and one uppercase letter and one number.'
};
export const ONLY_NUMERIC_WITHOUT_ZERO_INPUT_VALIDATION = {
  value: ONLY_NUMERIC_WITHOUT_ZERO_INPUT,
  message: 'Enter a valid order value.'
};
