const TEAM_MEMBERS = 'TEAM_MEMBERS';
const TEAM_MEMBERS_SUCCESS = 'TEAM_MEMBERS_SUCCESS';
const TEAM_MEMBERS_FAILURE = 'TEAM_MEMBERS_FAILURE';
const CLEAR_TEAM_MEMBERS = 'CLEAR_TEAM_MEMBERS';

const TeamMembersSymbols = {
  TEAM_MEMBERS_SUCCESS,
  TEAM_MEMBERS_FAILURE,
  TEAM_MEMBERS,
  CLEAR_TEAM_MEMBERS
};

export default TeamMembersSymbols;
