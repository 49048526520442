import SignOut from '../symbols/signOutAndClearAllReducers';

const clearReduxAction = () => ({
  type: SignOut.SIGN_OUT_AND_CLEAR_ALL_REDUCERS
});

export function signOutAndClearReducers() {
  return (dispatch: any) => {
    dispatch(clearReduxAction());
    window.location.href = '/login';
  };
}
