/* eslint-disable react/destructuring-assignment */
import FAILED_PAYMENT from '../symbols/failedPayment';

const initialState: { id: string; count: number } = {
  id: '',
  count: 0
};

// eslint-disable-next-line default-param-last
function FailedPaymentReducer(state = initialState, action: any) {
  switch (action.type) {
    case FAILED_PAYMENT.FAILED_PAYMENT_SET_ID:
      return { ...state, id: action.payload };
    case FAILED_PAYMENT.FAILED_PAYMENT_SET_COUNT:
      return {
        ...state,
        count: action.payload
      };
    case FAILED_PAYMENT.CLEAR_FAILED_PAYMENT:
      return {
        ...state,
        id: '',
        count: 0
      };
    default:
      return state;
  }
}
export default FailedPaymentReducer;
