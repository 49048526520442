import PROFILE from '../symbols/profile';
import { HttpRequestGet } from '../../service';

const profileAction = () => ({
  type: PROFILE.PROFILE
});
const profileSuccessAction = (data: any) => {
  return {
    type: PROFILE.PROFILE_SUCCESS,
    payload: {
      data
    }
  };
};
const profileFailureAction = (data: any) => ({
  type: PROFILE.PROFILE_FAILURE,
  payload: {
    data,
    isUserAdmin: false
  }
});
const clearProfileAction = () => ({
  type: PROFILE.CLEAR_PROFILE
});

export function GetProfile() {
  return (dispatch: any) => {
    dispatch(profileAction());
    HttpRequestGet('/pro/user/profile', dispatch, profileSuccessAction, profileFailureAction, true);
  };
}

export function clearProfile() {
  return (dispatch: any) => {
    dispatch(clearProfileAction());
  };
}
