const ADD_DELIVERY_ADDRESS = 'ADD_DELIVERY_ADDRESS';
const ADD_DELIVERY_ADDRESS_SUCCESS = 'ADD_DELIVERY_ADDRESS_SUCCESS';
const ADD_DELIVERY_ADDRESS_FAILURE = 'ADD_DELIVERY_ADDRESS_FAILURE';
const CLEAR_ADD_DELIVERY_ADDRESS = 'CLEAR_ADD_DELIVERY_ADDRESS';

const AddDeliveryAddressSymbols = {
  ADD_DELIVERY_ADDRESS_SUCCESS,
  ADD_DELIVERY_ADDRESS_FAILURE,
  ADD_DELIVERY_ADDRESS,
  CLEAR_ADD_DELIVERY_ADDRESS
};

export default AddDeliveryAddressSymbols;
