/* eslint-disable consistent-return */
/* eslint-disable default-case */
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { Box, Link, Typography, makeStyles } from '@material-ui/core';
import { Done, HourglassEmpty, BlockOutlined, CardMembership } from '@material-ui/icons';
import { useCallback } from 'react';
import moment from 'moment';
import { colours } from '../constants/theme';

interface ICollectionDashboardItemProps {
  title: string;
  count: number;
}

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: colours.highEmphasis,
    color: colours.primary,
    padding: 16,
    borderRadius: 8,
    textAlign: 'left',
    position: 'relative'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    marginBottom: 16
  },
  icon: {
    marginTop: 4
  },
  count: {
    color: colours.lightEmphasis
  },
  description: {
    color: colours.lightEmphasis
  },
  link: {
    flex: 1,
    textDecoration: 'none !important'
  },
  openInNew: {
    fontSize: 14
  },
  cardIcon: {
    '& > svg': {
      fontSize: 70,
      color: colours.lightEmphasis,
      opacity: 0.54
    },
    position: 'absolute',
    bottom: 8,
    right: 16
  },
  ctaContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    gap: 8
  }
}));

export default function CollectionDashboardItem(props: ICollectionDashboardItemProps) {
  const { title, count } = props;
  const classes = useStyles();

  const getIcon = () => {
    switch (title) {
      case 'Open':
        return HourglassEmpty;
      case 'Open Concierge Orders':
        return CardMembership;
      case 'Out for Delivery':
        return LocalShippingOutlinedIcon;
      case 'Delivered':
        return Done;
      case 'Failed':
        return BlockOutlined;
    }
  };

  const getUrl = useCallback(() => {
    const baseUrl = `/delivery-orders?from=dashboard&startDate=${moment()
      .startOf('day')
      .toLocaleString()}`;
    switch (title) {
      case 'Open':
        return `${baseUrl}&tabIndex=0`;
      case 'Open Concierge Orders':
        return `${baseUrl}&tabIndex=0&conciergeOnly=true`;
      case 'Out for Delivery':
        return `${baseUrl}&tabIndex=1`;
      case 'Delivered':
        return `${baseUrl}&tabIndex=2&status=Delivered`;
      case 'Failed':
        return `${baseUrl}&tabIndex=2&status=Failed&status=Cancelled`;
    }
  }, [title]);

  const Icon: any = getIcon();
  return (
    <Link href={getUrl()} className={classes.link}>
      <Box className={classes.container}>
        <Box>
          <Typography variant="h6">{title}</Typography>
        </Box>

        <Typography variant="h2" className={classes.count}>
          {count || 0}
        </Typography>
        <Box className={classes.ctaContainer}>
          <Typography variant="body2">View more</Typography>
          <OpenInNewIcon className={classes.openInNew} />
        </Box>

        <Box className={classes.cardIcon}>
          <Icon />
        </Box>
      </Box>
    </Link>
  );
}
