const APPROVE_ORDER = 'APPROVE_ORDER';
const APPROVE_ORDER_SUCCESS = 'APPROVE_ORDER_SUCCESS';
const APPROVE_ORDER_FAILURE = 'APPROVE_ORDER_FAILURE';
const CLEAR_APPROVE_ORDER = 'CLEAR_APPROVE_ORDER';

const ApproveOrderSymbols = {
  APPROVE_ORDER_SUCCESS,
  APPROVE_ORDER_FAILURE,
  APPROVE_ORDER,
  CLEAR_APPROVE_ORDER
};

export default ApproveOrderSymbols;
