import TEAM from '../symbols/team';
import { HttpRequestPost } from '../../service';

const teamAction = () => ({
  type: TEAM.TEAM
});
const teamSuccessAction = (data: any) => {
  return {
    type: TEAM.TEAM_SUCCESS,
    payload: {
      data
    }
  };
};
const teamFailureAction = (data: any) => ({
  type: TEAM.TEAM_FAILURE,
  payload: {
    data
  }
});
const clearTeamAction = () => ({
  type: TEAM.CLEAR_TEAM
});
interface GetTeamMembersRequestModel {
  Query?: string;
  Group: string;
}
export function GetTeamMembers(data: GetTeamMembersRequestModel) {
  return (dispatch: any) => {
    dispatch(teamAction());
    HttpRequestPost('/pro/user/search', dispatch, teamSuccessAction, teamFailureAction, true, data);
  };
}

export function clearTeam() {
  return (dispatch: any) => {
    dispatch(clearTeamAction());
  };
}
