const SEARCH_ADDRESS = 'SEARCH_ADDRESS';
const SEARCH_ADDRESS_SUCCESS = 'SEARCH_ADDRESS_SUCCESS';
const SEARCH_ADDRESS_FAILURE = 'SEARCH_ADDRESS_FAILURE';
const CLEAR_SEARCH_ADDRESS = 'CLEAR_SEARCH_ADDRESS';

const SearchAddressSymbols = {
  SEARCH_ADDRESS_SUCCESS,
  SEARCH_ADDRESS_FAILURE,
  SEARCH_ADDRESS,
  CLEAR_SEARCH_ADDRESS
};

export default SearchAddressSymbols;
