import REMOVE_MEMBER from '../symbols/removeMember';
import { HttpRegularDelete } from '../../service';

const removeMemberAction = () => ({
  type: REMOVE_MEMBER.REMOVE_MEMBER
});
const removeMemberSuccessAction = () => {
  return {
    type: REMOVE_MEMBER.REMOVE_MEMBER_SUCCESS
  };
};
const removeMemberFailureAction = (data: any) => ({
  type: REMOVE_MEMBER.REMOVE_MEMBER_FAILURE,
  payload: {
    data
  }
});
const clearRemoveMemberAction = () => ({
  type: REMOVE_MEMBER.CLEAR_REMOVE_MEMBER
});

export function RemoveMember(id: string, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(removeMemberAction());
    HttpRegularDelete(`/pro/user/${id}`, {
      dispatch,
      actionSuccess: removeMemberSuccessAction,
      actionFailure: removeMemberFailureAction,
      callback
    });
  };
}
export function clearRemoveMember() {
  return (dispatch: any) => {
    dispatch(clearRemoveMemberAction());
  };
}
