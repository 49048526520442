import ORDER_DETAIL from '../symbols/orderDetail';
import { HttpRequestGet } from '../../service';

const orderDetalAction = () => ({
  type: ORDER_DETAIL.ORDER_DETAIL
});
const orderDetalSuccessAction = (data: any) => {
  return {
    type: ORDER_DETAIL.ORDER_DETAIL_SUCCESS,
    payload: data
  };
};
const orderDetalFailureAction = (data: any) => ({
  type: ORDER_DETAIL.ORDER_DETAIL_FAILURE,
  payload: {
    data
  }
});
const clearOrderDetailAction = () => ({
  type: ORDER_DETAIL.CLEAR_ORDER_DETAIL
});

export function GetOrderDetail(id: string, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(orderDetalAction());
    HttpRequestGet(
      `/pro/order/${id}`,
      dispatch,
      orderDetalSuccessAction,
      orderDetalFailureAction,
      true,
      callback
    );
  };
}

export function clearOrderDetail() {
  return (dispatch: any) => {
    dispatch(clearOrderDetailAction());
  };
}
