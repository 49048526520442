/* eslint-disable default-param-last */
/* eslint-disable no-unused-vars */
import { AxiosError } from 'axios';
import { HttpRequestGet, HttpRequestPost, HttpRequestPut } from '../../service';
import { CollectOrderStatus } from '../../components-molecules/CollectOrderStamp';

export interface GetOrdersRequestModel {
  Query?: string;
  Size: number;
  Page: number;
  Order: string;
  Filter: {
    Status?: string[];
    StartDate: string | null;
    EndDate: string | null;
  };
}

export interface CollectOrderListModel {
  Id: string;
  MerchantName: string;
  ReferenceNumber: string;
  PickUpTime: string;
  Drafted: string;
  VehicleTypeCode: string;
  CustomerEmail: string;
  CustomerFirstName: string;
  CustomerLastName: string;
  OrderValue: number;
  PortalStatus: string;
  Status: CollectOrderStatus;
  MerchantOrderCount: number;
  PremiumSelected: boolean;
}

export interface CollectOrderRootModel {
  TotalCountByMerchant: number;
  Merchant: string;
  Deliveries: CollectOrderListModel[];
}
export interface GetOrderResultModel {
  Data: CollectOrderRootModel[];
  Count: number;
}
export interface GetOrdersResponseModel {
  Result: GetOrderResultModel;
  Route: string;
}

export interface GetOrdersErrorModel {
  Result: string;
  Route: string;
}

export function GetCollectOrders(
  url: string = 'open',
  data: GetOrdersRequestModel,
  callback: (resp: GetOrderResultModel) => void,
  errCallback: (err: GetOrdersErrorModel) => void
) {
  HttpRequestPost(
    `/collect/list/${url}`,
    () => undefined,
    (response: GetOrdersResponseModel) => {
      callback(response.Result);
    },
    (err: GetOrdersErrorModel) => errCallback(err),
    true,
    data
  );
}

export interface ICreateCollectOrder {
  Route: string;
  Result: string;
}

export function CreateCollectOrder(
  data: any,
  // eslint-disable-next-line no-unused-vars
  callback: (resp: string) => void,
  failCallback: (resp: string) => void
) {
  HttpRequestPost(
    '/collect',
    () => undefined,
    (response: ICreateCollectOrder) => {
      callback(response.Result);
    },
    (err: any) => failCallback(err),
    true,
    data
  );
}

export type VehicleType = 'motorcycle' | 'car' | 'van_small' | 'van' | '35t_van' | 'xlwb';
export interface IGetVehicleTypeModel {
  Id: number;
  Code: string;
  IconUrl: string;
  FullIconUrl: string;
  Name: string;
  Active: boolean;
  MaxSize: string;
  MaxWeight: string;
  MaxLength: string;
  Info: string;
  Speed: string;
  Availability: string;
  Example: string;
}

export interface IGetVehicleTypesResponseModel {
  Route: string;
  Result: IGetVehicleTypeModel[];
}

export function getVehicleTypes(
  success: (response: any) => void,
  failure: (error: AxiosError<any>) => void
): void {
  HttpRequestGet(
    `/collect/vehicle-type`,
    () => undefined,
    (response: IGetVehicleTypesResponseModel) => success(response.Result),
    (error: any) => failure(error),
    true
  );
}

export interface IGetCollectionContactDetailsModel {
  FirstName: string;
  LastName: string;
  Phone: string;
}

export interface IGetCollectionContactDetailsResponseModel {
  Route: string;
  Result: IGetCollectionContactDetailsModel;
}

export function getCollectionContactDetails(
  success: (response: IGetCollectionContactDetailsModel) => void,
  failure: (error: AxiosError<any>) => void
): void {
  HttpRequestGet(
    `/collect/collection-contact-details`,
    () => undefined,
    (response: IGetCollectionContactDetailsResponseModel) => success(response.Result),
    (error: any) => failure(error),
    true
  );
}

export function getQuote(
  body: any,
  success: (response: any) => void,
  failure: (error: AxiosError<any>) => void
): void {
  HttpRequestPost(
    `/collect/${body.orderId}/quote`,
    () => undefined,
    (response: any) => {
      success(response);
    },
    (err: any) => failure(err),
    true,
    body
  );
}

export function placeCollectOrder(
  body: any,
  success: (response: any) => void,
  failure: (error: AxiosError<any>) => void
): void {
  HttpRequestPost(
    `/collect/place`,
    () => undefined,
    (response: any) => success(response.Result),
    (error: any) => failure(error),
    true,
    body
  );
}

export interface IDeliveryStatusType {
  Id: number;
  Name: string;
  Code: string;
}

export interface IDeliveryStatusResponseModel {
  Route: string;
  Result: IDeliveryStatusType[];
}

export function getDeliveryStatusTypes(
  success: (response: IDeliveryStatusType[]) => void,
  failure: (error: AxiosError<any>) => void
): void {
  HttpRequestGet(
    `/collect/delivery-status-type`,
    () => undefined,
    (response: IDeliveryStatusResponseModel) => success(response.Result),
    (error: any) => failure(error),
    true
  );
}

export function acceptTerms(
  success: (response: any[]) => void,
  failure: (error: AxiosError<any>) => void
): void {
  HttpRequestPut(
    `/customer/accept-terms`,
    () => undefined,
    (response: any) => success(response),
    (error: any) => failure(error),
    true
  );
}

export interface ICancelOrderCreationBody {
  orderId?: string;
}

export interface ICancelCollectOrderErrorResponse {
  Route: string;
  Error: string;
  Details: string;
}

export function cancelOrderCreation(
  data: ICancelOrderCreationBody,
  callback: (resp: GetOrderResultModel) => void,
  errCallback: (err: string) => void
) {
  HttpRequestPost(
    `/collect/${data.orderId}/cancel`,
    () => undefined,
    (response: any) => {
      callback(response.Result);
    },
    (err: string) => errCallback(err),
    true,
    {}
  );
}

export function acceptOrderCreation(
  orderId: string,
  code: string,
  data: any,
  callback: (resp: any) => void
) {
  delete data.Name;
  delete data.Line3;

  const url =
    code === 'pickup_location_changed' ? 'update-pickup-address' : 'update-dropoff-address';

  HttpRequestPut(
    `/collect/${orderId}/${url}`,
    () => undefined,
    (response: any) => {
      callback(response.Result);
    },
    (err: any) => callback(err),
    true,
    data
  );
}

export function getStatusOfCollectOrder(body: any, success: (response: any) => void): void {
  HttpRequestGet(
    `/collect/${body.orderId}/status`,
    () => undefined,
    (response: any) => success(response.Result),
    () => undefined,
    true
  );
}

export interface IGetDeliveryTypeModel {
  Key: string;
  Title: string;
  Description: string;
}

export interface IGetDeliveryTypeResponseModel {
  Route: string;
  Result: IGetDeliveryTypeModel[];
}

export function getDeliveryTypes(
  success: (response: any) => void,
  failure: (error: AxiosError<any>) => void
): void {
  HttpRequestGet(
    `/collect/delivery-type`,
    () => undefined,
    (response: IGetDeliveryTypeResponseModel) => success(response.Result),
    (error: any) => failure(error),
    true
  );
}

export interface IGetHSTokenResult {
  Email: string;
  Token: string;
}
export interface IGetHSTokenResponse {
  Route: string;
  Result: IGetHSTokenResult;
}

export function getHSToken(
  success: (response: any) => void,
  failure: (error: AxiosError<any>) => void
): void {
  HttpRequestGet(
    `/hubspot/generate-pro-token`,
    () => undefined,
    (response: IGetHSTokenResponse) => success(response.Result),
    (error: any) => failure(error),
    true
  );
}
