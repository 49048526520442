import { createStore, applyMiddleware } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
// import logger from "redux-logger";
import { persistReducer } from 'redux-persist';
import LocalStorage from 'redux-persist/lib/storage';
import rootReducer from './reducers';

const persistBlacklist: string[] = ['getCollectionTime'];

const persistConfig = {
  key: 'root',
  storage: LocalStorage,
  blacklist: persistBlacklist
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// export default createStore(persistedReducer, applyMiddleware(thunk, logger)); // UAT logger should be removed when switching to production mode
export default createStore(persistedReducer, applyMiddleware(thunk)); // PROD
