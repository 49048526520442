import { CancelToken } from 'axios';
import SEARCH_ADDRESS from '../symbols/searchAddress';
import { HttpRequestGet } from '../../service';

const searchAddressAction = () => ({
  type: SEARCH_ADDRESS.SEARCH_ADDRESS
});
const searchAddressSuccessAction = (data: any) => {
  return {
    type: SEARCH_ADDRESS.SEARCH_ADDRESS_SUCCESS,
    payload: data.Result
  };
};
const searchAddressFailureAction = (data: any) => ({
  type: SEARCH_ADDRESS.SEARCH_ADDRESS_FAILURE,
  payload: {
    data
  }
});
const clearSearchAddressAction = () => ({
  type: SEARCH_ADDRESS.CLEAR_SEARCH_ADDRESS
});

export function SearchAddress(query: string, callback?: () => void, cancelToken?: CancelToken) {
  return (dispatch: any) => {
    dispatch(searchAddressAction());
    HttpRequestGet(
      `/places/autocomplete/${query}`,
      dispatch,
      searchAddressSuccessAction,
      searchAddressFailureAction,
      true,
      callback,
      cancelToken
    );
  };
}

export function clearSearchAddress() {
  return (dispatch: any) => {
    dispatch(clearSearchAddressAction());
  };
}
