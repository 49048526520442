import {
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  MenuItem,
  Typography
} from '@material-ui/core';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { ButtonPrimary, GroupHeader, Stamp } from '../components-atoms';
import { ThreeDotMenu } from '../components-molecules';
import { InfoCard } from '../components-molecules/InfoCard';
import { ITheme, theme } from '../constants/theme';
import mailIcon from '../assets/icons/mailIcon.svg';
import { useAppSelector } from '../hooks/store';
import { TeamMember, TeamMemberResponse } from '../redux/reducers/team';
import { UserProfile } from '../redux/reducers/profile';
import deleteIcon from '../assets/icons/deleteIcon.svg';
import AddMemberIcon from '../components-atoms/icons/addMember';

const useStyles = makeStyles((myTheme: ITheme) => ({
  component: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 24
  },
  noRecordsFound: {
    display: 'flex',
    paddingLeft: '24px',
    flexDirection: 'column'
  },
  active: {
    color: myTheme.palette.success.main,
    fontWeight: 700,
    margin: '12px 0 -12px 0',
    '@media (max-width: 599px)': {
      margin: '4px 0'
    }
  },
  invited: {
    color: myTheme.palette.warning.main,
    fontWeight: 700,
    margin: '12px 0 -12px 0',
    '@media (max-width: 599px)': {
      margin: '4px 0'
    }
  },
  noTeamMembers: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '320px',
    rowGap: '12px',
    marginTop: '96px'
  },
  topTextContainer: {
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignContent: 'flex-start',
      alignItems: 'flex-start'
    }
  },
  nameStampContainer: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 599px)': {
      flexDirection: 'column',
      alignContent: 'flex-start',
      alignItems: 'flex-start'
    }
  },
  stamp: {
    '@media (max-width: 599px)': {
      alignSelf: 'flex-start',
      marginLeft: 0
    }
  }
}));

interface IProps {
  query?: string;
  // eslint-disable-next-line no-unused-vars
  onGrantAccess: (member: TeamMember) => void;
  // eslint-disable-next-line no-unused-vars
  onRemove: (id: string, active: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  onEdit: (id: string) => void;
  onInvite: () => void;
  // eslint-disable-next-line no-unused-vars
  onResend: (id: string) => void;
}
export function TeamMemberList({
  query,
  onGrantAccess,
  onRemove,
  onEdit,
  onInvite,
  onResend
}: IProps) {
  const classes = useStyles();
  const [members, loading, profile]: [TeamMemberResponse[], boolean, UserProfile] = useAppSelector(
    (state) => [state.team.data, state.team.loading, (state.profile as any).data]
  );
  const thresholdText = (member: TeamMember): string =>
    member.ApprovalThreshold
      ? `Approval required for purchases over £${member.ApprovalThreshold / 100}`
      : member.ApprovalThreshold === 0
      ? 'Approval required for all purchases'
      : '';
  return (
    <Container className={classes.component}>
      <InfoCard
        style={{ backgroundColor: theme.palette.secondary.main }}
        data={{
          bottomText: profile.Email,
          topText: (
            <Grid
              item
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.topTextContainer}>
              {profile.FirstName} {profile.LastName}
              <Stamp type="primary" text="Team Manager" className={classes.stamp} />
            </Grid>
          )
        }}
        disabled
      />
      {loading ? (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '96px' }}>
          <CircularProgress />
        </Grid>
      ) : members.length === 0 ? (
        !query ? (
          <Container className={classes.noTeamMembers}>
            <Typography variant="h6" component="span" color="textPrimary">
              You don’t have any team members yet
            </Typography>
            <Typography variant="body2" color="textSecondary" component="span">
              To get started, invite your first team member
            </Typography>
            <ButtonPrimary
              startIcon={<AddMemberIcon />}
              style={{ margin: '16px 0 0', width: '202px' }}
              onClick={() => onInvite()}>
              Invite Team Member
            </ButtonPrimary>
          </Container>
        ) : (
          <Container className={classes.noRecordsFound}>
            <Typography variant="h6" component="span" color="textPrimary">
              0 search results found
            </Typography>
            <Typography variant="body2" component="span" color="textSecondary">
              Adjust your search term and try again
            </Typography>
          </Container>
        )
      ) : (
        members.length > 0 &&
        members.map((item, index) => (
          <div key={item.GroupName}>
            {item.Result.length > 0 && (
              <GroupHeader
                idx={index}
                data-automation="number-of-members"
                headlineType="Members"
                header={item.GroupName}
                count={item.Result.length}
              />
            )}
            {item.Result.map((member) => (
              <InfoCard
                key={member.CustomerId}
                data={{
                  bottomText: `${member.Email}${
                    thresholdText(member) ? ` · ${thresholdText(member)}` : ''
                  }`,
                  topText: (
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.topTextContainer}>
                      <div className={classes.nameStampContainer}>
                        {`${member.FirstName} ${member.LastName}`}
                        {member.ProUserRole === 'Team Manager' && (
                          <Stamp type="primary" text="Team Manager" className={classes.stamp} />
                        )}
                      </div>
                      <Typography
                        data-automation="promotion-code"
                        variant="body2"
                        className={member.Active ? classes.active : classes.invited}
                        component="span">
                        {member.Active ? 'Active' : 'Invited'}
                      </Typography>
                    </Grid>
                  )
                }}
                disabled>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <ThreeDotMenu>
                    <MenuItem
                      onClick={() => {
                        if (member.Active) {
                          onGrantAccess(member);
                        } else {
                          onResend(member.CustomerId);
                        }
                      }}>
                      {member.Active ? (
                        <SupervisedUserCircleOutlinedIcon color="action" />
                      ) : (
                        <img src={mailIcon} alt="resend" />
                      )}
                      <Typography variant="body2" component="p" style={{ paddingLeft: '16px' }}>
                        {member.Active
                          ? !(member.ProUserRole === 'Team Manager')
                            ? 'Grant management access'
                            : 'Remove management access'
                          : 'Resend invitation'}
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => onEdit(member.CustomerId)}>
                      <EditOutlinedIcon color="action" />
                      <Typography variant="body2" component="p" style={{ paddingLeft: '16px' }}>
                        Edit member
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      style={{ color: theme.palette.error.main }}
                      onClick={() => onRemove(member.CustomerId, member.Active)}>
                      <img alt="remove" src={deleteIcon} />
                      <Typography variant="body2" component="p" style={{ paddingLeft: '16px' }}>
                        Remove member
                      </Typography>
                    </MenuItem>
                  </ThreeDotMenu>
                </Grid>
              </InfoCard>
            ))}
          </div>
        ))
      )}
    </Container>
  );
}
