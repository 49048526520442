const RESET_PASSWORD = 'RESET_PASSWORD';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
const CLEAR_RESET_PASSWORD = 'CLEAR_RESET_PASSWORD';

const ResetPasswordSymbols = {
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD,
  CLEAR_RESET_PASSWORD
};

export default ResetPasswordSymbols;
