import { Box, Container, Grid, Typography, makeStyles, MenuItem } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ThreeDotMenu from './ThreeDotMenu';
import deleteIcon from '../assets/icons/deleteIcon.svg';
import { theme } from '../constants/theme';
import { DeliveryAddress } from '../redux/reducers/deliveryAddresses';
import { formatDeliveryAddress } from '../utils';
import { textButtonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '24px'
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 16px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: 'all 200ms ease',
    '@media (min-width: 900px)': {
      padding: '24px 16px 24px 24px',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '20px'
    },
    ...textButtonHoverAndActive
  },
  gridMargin: {
    marginTop: '8px'
  }
}));
interface IProps {
  data: DeliveryAddress;
  // eslint-disable-next-line no-unused-vars
  onEdit: (address: DeliveryAddress) => void;
  // eslint-disable-next-line no-unused-vars
  onRemove: (id: string) => void;
}
export function AddressItem({ data, onEdit, onRemove }: IProps) {
  const classes = useStyles();
  return (
    <Box data-order="address-item" className={classes.container}>
      <Container className={classes.root}>
        <Grid item container xs={10} justifyContent="flex-start">
          <Grid item>
            <Typography variant="h6" component="span" color="textPrimary">
              {data.Name}
            </Typography>
          </Grid>
          <Grid item container className={classes.gridMargin}>
            <Typography
              variant="body2"
              component="span"
              color="textSecondary"
              style={{ textAlign: 'left' }}>
              {formatDeliveryAddress(data)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={2} justifyContent="flex-end">
          <ThreeDotMenu>
            <MenuItem onClick={() => onEdit(data)}>
              <EditOutlinedIcon color="action" />
              <Typography variant="body2" component="p" style={{ paddingLeft: '16px' }}>
                Edit address
              </Typography>
            </MenuItem>
            <MenuItem style={{ color: theme.palette.error.main }} onClick={() => onRemove(data.Id)}>
              <img alt="remove" src={deleteIcon} />
              <Typography variant="body2" component="p" style={{ paddingLeft: '16px' }}>
                Remove address
              </Typography>
            </MenuItem>
          </ThreeDotMenu>
        </Grid>
      </Container>
    </Box>
  );
}
