/* eslint-disable react/destructuring-assignment */
import GET_PAYMENT_ID from '../symbols/getPaymentId';

interface State {
  data: { StripePaymentIdentifier: string };
  loading: boolean;
  error: any;
}
const initialState: State = {
  data: { StripePaymentIdentifier: '' },
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function GetPaymentIdReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case GET_PAYMENT_ID.GET_PAYMENT_ID:
      return { ...state, loading: true, error: false };
    case GET_PAYMENT_ID.GET_PAYMENT_ID_SUCCESS:
      return {
        ...state,
        data: action.payload.Result as { StripePaymentIdentifier: string },
        loading: false,
        error: false
      };
    case GET_PAYMENT_ID.GET_PAYMENT_ID_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case GET_PAYMENT_ID.CLEAR_GET_PAYMENT_ID:
      return {
        data: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default GetPaymentIdReducer;
