/* eslint-disable react/destructuring-assignment */
import REGISTER from '../symbols/register';

const initialState = {
  data: '',
  loading: false,
  error: false
};

// eslint-disable-next-line default-param-last
function RegisterReducer(state = initialState, action: any) {
  switch (action.type) {
    case REGISTER.REGISTER:
      return { ...state, loading: true, error: false };
    case REGISTER.REGISTER_SUCCESS:
      return {
        ...state,
        data: action.payload.Result,
        loading: false,
        error: false
      };
    case REGISTER.REGISTER_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload
      };
    case REGISTER.CLEAR_REGISTER:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    case REGISTER.CLEAR_REGISTER_ERROR:
      return {
        ...state,
        error: ''
      };
    default:
      return state;
  }
}
export default RegisterReducer;
