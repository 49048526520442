const REMOVE_MEMBER = 'REMOVE_MEMBER';
const REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS';
const REMOVE_MEMBER_FAILURE = 'REMOVE_MEMBER_FAILURE';
const CLEAR_REMOVE_MEMBER = 'CLEAR_REMOVE_MEMBER';

const RemoveMemberSymbols = {
  REMOVE_MEMBER_SUCCESS,
  REMOVE_MEMBER_FAILURE,
  REMOVE_MEMBER,
  CLEAR_REMOVE_MEMBER
};

export default RemoveMemberSymbols;
