import EDIT_DELIVERY_ADDRESS from '../symbols/editDeliveryAddress';
import { HttpRequestPut } from '../../service';

const editDeliveryAddressAction = () => ({
  type: EDIT_DELIVERY_ADDRESS.EDIT_DELIVERY_ADDRESS
});
const editDeliveryAddressSuccessAction = (data: any) => {
  return {
    type: EDIT_DELIVERY_ADDRESS.EDIT_DELIVERY_ADDRESS_SUCCESS,
    payload: {
      data
    }
  };
};
const editDeliveryAddressFailureAction = (data: any) => ({
  type: EDIT_DELIVERY_ADDRESS.EDIT_DELIVERY_ADDRESS_FAILURE,
  payload: {
    data
  }
});
const clearEditDeliveryAddressAction = () => ({
  type: EDIT_DELIVERY_ADDRESS.CLEAR_EDIT_DELIVERY_ADDRESS
});
interface EditDeliveryAddressRequestModel {
  AddressName: string;
  Line1: string;
  Line2?: string;
  Line3?: string;
  PostalCode: string;
  City: string;
  County: string;
  Country: string;
  DoorbellName?: string;
  InstructionsForCourier?: string;
  ContactPhoneNumber?: string;
  Note?: string;
  Long: number;
  Lat: number;
}
export function EditDeliveryAddress(
  data: EditDeliveryAddressRequestModel,
  id: string,
  callback?: () => void
) {
  return (dispatch: any) => {
    dispatch(editDeliveryAddressAction());
    HttpRequestPut(
      `/pro/delivery-address/${id}`,
      dispatch,
      editDeliveryAddressSuccessAction,
      editDeliveryAddressFailureAction,
      true,
      data,
      callback
    );
  };
}

export function clearEditDeliveryAddress() {
  return (dispatch: any) => {
    dispatch(clearEditDeliveryAddressAction());
  };
}
