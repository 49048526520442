import { AxiosError } from 'axios';
import COLLECTION_ORDER_DETAIL from '../symbols/collectionOrderDetail';
import { HttpRequestGet } from '../../service';
import { CollectOrderStatus } from '../../components-molecules/CollectOrderStamp';
import { CollectPricing } from '../reducers/orderDetail';

// TODO: Rename symbols, maybe
const orderDetalAction = () => ({
  type: COLLECTION_ORDER_DETAIL.COLLECTION_ORDER_DETAIL
});
const orderDetalSuccessAction = (data: any) => {
  return {
    type: COLLECTION_ORDER_DETAIL.COLLECTION_ORDER_DETAIL_SUCCESS,
    payload: data
  };
};
const orderDetalFailureAction = (data: any) => ({
  type: COLLECTION_ORDER_DETAIL.COLLECTION_ORDER_DETAIL_FAILURE,
  payload: {
    data
  }
});

export interface ProofOfDelivery {
  ImageName: string;
  ImageUrl: string;
}
export interface ICollectOrderDetailResponseModel {
  [index: number]: any;
  Id: string;
  BookedBy?: string;
  MerchantName: string;
  PickUpPhone: string;
  ReferenceNumber: string;
  OrderValue: number;
  OrderDetails: string;
  PickUpTime: string;
  Drafted: string;
  Quoted: any;
  Placed: any;
  Queued: any;
  Assigned: any;
  AtPickUpLocation: any;
  OutForDelivery: any;
  AtDropOffLocation: any;
  Delivered: any;
  Failed: any;
  Cancelled: any;
  VehicleTypeId: number;
  VehicleTypeCode: string;
  VehicleTypeName: string;
  DropOffAddressLine1: string;
  DropOffAddressLine2: string;
  DropOffAddressLine3: string;
  DropOffAddressPostCode: string;
  DropOffAddressCity: string;
  DropOffAddressLat: number;
  DropOffAddressLong: number;
  PickUpAddressLine1: string;
  PickUpAddressLine2: string;
  PickUpAddressLine3: string;
  PickUpAddressPostCode: string;
  PickUpAddressCity: string;
  PickUpAddressLat: number;
  PickUpAddressLong: number;
  CustomerEmail: string;
  CustomerFirstName: string;
  CustomerLastName: string;
  CustomerTelNo: string;
  CustomerInstructions: string;
  TrackingUrl: string;
  PickUpInstructions: string;
  PortalStatus: string;
  DeliveryFee: number;
  PlatformFee: number;
  WinningQuotePrice?: CollectPricing;
  ChatJwtToken?: string;
  ProofOfDelivery?: ProofOfDelivery;
  DeliveryTypeId: string;
  OrkestroOrderId?: string;
  DeliveryTypeKey: string;
  PremiumSelected?: boolean;
  HasRestrictedItems: boolean;
}

const COLLECTION_ORDER_DETAIL_ENDPOINT = `/collect/`;
export function GetCollectionOrderDetailRequest(
  id: string,
  success: (response: any) => void,
  failure: (error: AxiosError<any>) => void
): void {
  HttpRequestGet(
    `${COLLECTION_ORDER_DETAIL_ENDPOINT}${id}`,
    () => undefined,
    (response: ICollectOrderDetailResponseModel) =>
      success((response as any).Result as ICollectOrderDetailResponseModel),
    (error: any) => failure(error),
    true
  );
}

export function GetCollectionOrderDetail(id: string, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(orderDetalAction());
    HttpRequestGet(
      `${COLLECTION_ORDER_DETAIL_ENDPOINT}${id}`,
      dispatch,
      orderDetalSuccessAction,
      orderDetalFailureAction,
      true,
      callback
    );
  };
}
