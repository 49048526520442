import EDIT_USER from '../symbols/editUser';
import { HttpRequestPut } from '../../service';

const editUserAction = () => ({
  type: EDIT_USER.EDIT_USER
});
const editUserSuccessAction = (data: any) => {
  return {
    type: EDIT_USER.EDIT_USER_SUCCESS,
    payload: data
  };
};
const editUserFailureAction = (data: any) => ({
  type: EDIT_USER.EDIT_USER_FAILURE,
  payload: {
    data
  }
});
const clearEditUserAction = () => ({
  type: EDIT_USER.CLEAR_EDIT_USER
});
export interface EditUserRequestModel {
  FirstName?: string;
  LastName?: string;
  PhoneNumber?: string;
}
export function EditUser(data: EditUserRequestModel, callback?: () => void) {
  return (dispatch: any) => {
    dispatch(editUserAction());
    HttpRequestPut(
      '/pro/user/profile',
      dispatch,
      editUserSuccessAction,
      editUserFailureAction,
      true,
      data,
      callback
    );
  };
}

export function clearEditUser() {
  return (dispatch: any) => {
    dispatch(clearEditUserAction());
  };
}
