import { Grid, Typography, makeStyles } from '@material-ui/core';
import { ITheme } from '../constants/theme';

const useStyles = makeStyles((theme: ITheme) => ({
  container: {
    marginLeft: 14,
    alignSelf: 'center',
    whiteSpace: 'nowrap'
  },
  wrapper: {
    padding: '2px 6px',
    borderRadius: '4px'
  },
  text: {
    lineHeight: 1.23,
    fontWeight: 400
  },
  textActive: {
    lineHeight: 1.23,
    fontWeight: 600
  },
  primary: {
    backgroundColor: theme.palette.secondary[16],
    color: theme.palette.primary.main
  },
  warning: {
    backgroundColor: theme.palette.warning[16],
    color: theme.palette.warning.main
  },
  warningActive: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff'
  },
  error: {
    backgroundColor: theme.palette.error[16],
    color: theme.palette.error.main
  },
  success: {
    backgroundColor: theme.palette.success[16],
    color: theme.palette.success.main
  }
}));
export type StampType = 'primary' | 'warning' | 'error' | 'success' | 'warningActive';
interface IProps {
  text: string;
  type: StampType;
  className?: string;
}

export function Stamp({ text, type, className }: IProps) {
  const classes = useStyles();

  return (
    <Grid item className={`${className} ${classes.container}`}>
      <Grid className={`${classes.wrapper} ${classes[type]}`}>
        <Typography
          variant="caption"
          component="p"
          className={type.includes('Active') ? classes.textActive : classes.text}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}
