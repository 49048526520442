import { createStyles, withStyles } from '@material-ui/core/styles';
import { Tab } from '@material-ui/core';

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      minWidth: 0,
      marginRight: '10px',
      paddingLeft: '4px',
      paddingRight: '4px',
      fontFamily: "'Poppins', sans-serif",
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '17px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.87)',
      textTransform: 'none'
    }
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export function CustomTab(props: any): JSX.Element {
  return <StyledTab {...props} />;
}
