import { SVGProps } from 'react';
import { colours } from '../../constants/theme';

function CheckedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="checked"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={3}
        width={18}
        height={18}>
        <path
          d="M3 7v10a4 4 0 0 0 4 4h10a4 4 0 0 0 4-4V7a4 4 0 0 0-4-4H7a4 4 0 0 0-4 4Zm7.71 9.29a.996.996 0 0 1-1.41 0L5.71 12.7a.996.996 0 1 1 1.41-1.41L10 14.17l6.88-6.88a.996.996 0 1 1 1.41 1.41l-7.58 7.59Z"
          fill="#000"
        />
      </mask>
      <g mask="url(#checked)">
        <path d="M24 0H0v24h24V0Z" fill={colours.primary} />
      </g>
    </svg>
  );
}

export default CheckedIcon;
