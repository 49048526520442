import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { colours } from '../constants/theme';

type IProps = KeyboardDatePickerProps;

const useStyles = makeStyles(() => ({
  cssOutlinedInput: {
    paddingRight: 4,
    display: 'flex',
    '&$cssFocused $notchedOutline': {
      border: `1px solid ${colours.primary}`
    }
  },
  cssFocused: {}
}));

export function KeyboardDatePickerInput(props: IProps) {
  const { InputProps, ...rest } = props;
  const classes = useStyles();
  return (
    <KeyboardDatePicker
      {...rest}
      variant="dialog"
      data-automation="end-date-picker"
      margin="normal"
      fullWidth
      inputVariant="outlined"
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused
        },
        ...InputProps
      }}
      format="dd/MM/yyyy"
    />
  );
}
