/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */

import { getMultipleValuesAsText } from './getMultipleValuesAsText';

const ADDRESS_LINE_2_EXCLUDE_LIST = [
  'street_number',
  'route',
  'postal_town',
  'administrative_area_level_1',
  'administrative_area_level_2',
  'country',
  'postal_code',
  'premise',
  'subpremise'
];
export const addressParser = (e: any) => {
  const { address_components, geometry, name } = e;
  const Vicinity = e.vicinity || '';
  const Name = name;
  let Premise: string = '';
  let Line1: string = '';
  let Line1Short: string = '';
  const Line2: any = [];
  const Line3: string = '';
  let PostCode: string = '';
  let City: string = '';
  let StreetNumber: string = '';
  let Lat: string = '';
  let Long: string = '';

  address_components.forEach((a: any) => {
    // Subpremise - Address line 1
    if (a.types.includes('subpremise')) {
      Premise = a.long_name;
    }

    // Premise - Address line 1
    if (a.types.includes('premise')) {
      Premise = Premise.length > 0 ? getMultipleValuesAsText([Premise, a.long_name]) : a.long_name;
    }

    // Address line 1
    if (a.types.includes('route')) {
      Line1 = a.long_name;
      Line1Short = a.short_name;
    }

    // Address line 1
    if (a.types.includes('street_number')) {
      StreetNumber += a.long_name;
    }

    // Post code
    if (a.types.includes('postal_code')) {
      PostCode = a.long_name;
    }

    // County
    if (a.types.includes('postal_town')) {
      City = a.long_name;
    }

    // Address line 2
    if (!ADDRESS_LINE_2_EXCLUDE_LIST.includes(a.types[0])) {
      Line2.push(a.long_name);
    }

    if (Line1.length === 0) {
      Line1 = Vicinity;
    }
  });

  Lat = geometry.location.lat();
  Long = geometry.location.lng();

  return {
    Line1,
    Line1Short,
    Line2: getMultipleValuesAsText(Line2) as string,
    Line3,
    PostCode,
    City,
    StreetNumber,
    Lat,
    Long,
    Name,
    Vicinity,
    Premise
  };
};

export enum GooglePlaceError {
  MISSING_STREET_NUMBER = 'address.missing.streetNumber',
  MISSING_ADDRESS = 'address.missing.address',
  MISSING_CITY = 'address.missing.city',
  MISSING_POSTCODE = 'address.missing.postCode',
  MISSING_COUNTRY = 'address.missing.country',
  MISSING_LAT_OR_LONG = 'address.missing.lat.or.long'
}

const validation: { [key: string]: string } = {
  'address.missing.streetNumber':
    'This address is missing or has an incorrect street/flat number, please pick another address.',
  'address.missing.address':
    'This address is missing or has an incorrect street name, please add it to the notes.',
  'address.missing.city':
    'This address is missing or has an incorrect city, please add it to the notes.',
  'address.missing.postCode':
    'This address is missing or has an incorrect postcode, please add it to the notes.',
  'address.missing.country':
    'This address is missing or has an incorrect country, please add it to the notes.',
  'address.missing.lat.or.long':
    'This address is missing or has an incorrect latitude/longitude, please pick another address.'
};

export const getErrorMsg = (err: string) => {
  return validation[err];
};

export interface IGooglePlaceResponse {
  Name?: string;
  Line1: string;
  Line1Short: string;
  Line2: string;
  Line3: string;
  PostCode: string;
  City: string;
  StreetNumber: string;
  Lat: string | number;
  Long: string | number;
  Vicinity: string;
  Premise: string;
  hasError?: string | GooglePlaceError;
}

export const getVicinity = (vicinity: string, city: string) => {
  return vicinity
    .split(', ')
    .filter((v) => !v.includes(city))
    .join(', ');
};
