import { Container, Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'start',
    padding: '24px 16px',
    boxSizing: 'border-box',
    borderRadius: '20px',
    '@media (min-width: 900px)': {
      padding: 24
    }
  },
  headLine: {
    marginLeft: 8,
    fontWeight: 400
  }
}));
interface IProps {
  header?: string;
  count?: number;
  headlineType: string;
  idx: number;
  style?: React.CSSProperties;
}
export function GroupHeader({ header, count, headlineType, idx, style }: IProps) {
  const classes = useStyles();
  return (
    <Container className={classes.root} style={{ marginTop: idx === 0 ? '24px' : 0, ...style }}>
      <Grid item container xs={12} alignItems="baseline">
        <Typography variant="subtitle2" component="h2" color="textPrimary">
          {header}
        </Typography>
        <Typography
          variant="caption"
          component="span"
          color="textSecondary"
          className={classes.headLine}>
          {count ? `${count} ${headlineType}` : ''}
        </Typography>
      </Grid>
    </Container>
  );
}
