const DELIVERY_ADDRESSES = 'DELIVERY_ADDRESSES';
const DELIVERY_ADDRESSES_SUCCESS = 'DELIVERY_ADDRESSES_SUCCESS';
const DELIVERY_ADDRESSES_FAILURE = 'DELIVERY_ADDRESSES_FAILURE';
const CLEAR_DELIVERY_ADDRESSES = 'CLEAR_DELIVERY_ADDRESSES';

const DeliveryAddressesSymbols = {
  DELIVERY_ADDRESSES_SUCCESS,
  DELIVERY_ADDRESSES_FAILURE,
  DELIVERY_ADDRESSES,
  CLEAR_DELIVERY_ADDRESSES
};

export default DeliveryAddressesSymbols;
