import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { AppMenuItem } from '../components-molecules';
import { UserProfile } from '../redux/reducers/profile';
import { useAppSelector } from '../hooks/store';
import DRAWER_ITEMS, { DELIVERY_DASHBOARD, DELIVERY_ORDERS, DrawerItem } from '../constants/drawer';

const drawerWidth = 256;

const useStyles = makeStyles(() =>
  createStyles({
    appMenu: {
      width: '100%',
      paddingInline: '4px'
    },
    navList: {
      width: drawerWidth
    },
    menuItem: {
      width: drawerWidth
    },
    menuItemIcon: {
      color: '#97c05c'
    }
  })
);

function AppMenu() {
  const classes = useStyles();
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState<string>(location.pathname);
  const [userData] = useAppSelector((state) => [(state.profile as any).data as UserProfile]);
  const [items, setItems] = useState<DrawerItem[]>([]);

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);

  useEffect(() => {
    const tempItems = [...DRAWER_ITEMS];
    // If collect enabled,
    // append item to second order.
    if (userData?.IsCollectEnabled) {
      tempItems.splice(1, 0, DELIVERY_ORDERS);
      tempItems.splice(2, 0, DELIVERY_DASHBOARD);
    }
    setItems(tempItems);
  }, [userData]);

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {items.map((item) => {
        const isSecondLevel = item.secondRoutes?.some((s) => {
          return s === activeRoute.split('/')[1];
        });
        return (
          <AppMenuItem
            {...{
              name: item.sidebar,
              link: item.route,
              active: item.route === activeRoute,
              isSecondLevel: !!isSecondLevel
            }}
            key={item.route}
            icon={item.icon}
          />
        );
      })}
    </List>
  );
}

export default AppMenu;
