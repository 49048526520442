import { ICollectOrderDetailResponseModel } from '../redux/actions/collectionOrderDetail';

export const prepareFormBody = (res: ICollectOrderDetailResponseModel) => ({
  MerchantName: res.MerchantName,
  ReferenceNumber: res.ReferenceNumber,
  OrderDetails: res.OrderDetails,
  PickUpInstructions: res.PickUpInstructions,
  FirstName: res.CustomerFirstName,
  LastName: res.CustomerLastName,
  TelNo: res.CustomerTelNo,
  Email: res.CustomerEmail,
  CustomerInstructions: res.CustomerInstructions,
  PickUpPhone: res.PickUpPhone,
  HasRestrictedItems: res.HasRestrictedItems,
  CollectionAddress: {
    Line1: res.PickUpAddressLine1,
    Line2: res.PickUpAddressLine2,
    Line3: res.PickUpAddressLine3,
    PostCode: res.PickUpAddressPostCode,
    City: res.PickUpAddressCity,
    Lat: res.PickUpAddressLat,
    Long: res.PickUpAddressLong
  },
  DeliveryAddress: {
    Line1: res.DropOffAddressLine1,
    Line2: res.DropOffAddressLine2,
    Line3: res.DropOffAddressLine3,
    PostCode: res.DropOffAddressPostCode,
    City: res.DropOffAddressCity,
    Lat: res.DropOffAddressLat,
    Long: res.DropOffAddressLong
  }
});
