/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { ErrorModal, Header } from '../components-molecules';
import ButtonWithLoading from '../components-atoms/ButtonWithLoading';
import { clearLoginError, Login } from '../redux/actions/login';
import { ITheme, colours, theme } from '../constants/theme';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { GetProfile } from '../redux/actions/profile';
import { GetTeamDetail } from '../redux/actions/teamDetail';
import { RoundedCheckbox } from '../components-atoms';
import { GetTeamMembers } from '../redux/actions/team';

const useStyles = makeStyles((theme: ITheme) => ({
  paper: {
    padding: ' 24px',
    display: 'flex',
    flexDirection: 'column',

    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    borderRadius: '20px',
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 'inherit'
  },
  signIntitle: {
    marginBottom: 12
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    alignItems: 'center'
  },
  submit: {
    backgroundColor: colours.primary,
    borderRadius: '8px',
    padding: '6px 16px'
  },
  textField: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      border: `1px solid ${colours.primary}`
    }
  },

  cssFocused: {},

  notchedOutline: {
    border: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

export default function ShopSignIn() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [signInLoading, loginError] = useAppSelector((state: any) => [
    state.login.loading,
    state.login.error
  ]);
  useEffect(() => {
    if (loginError) {
      setError(true);
    }
  }, [loginError]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const signInButtonPressed = () => {
    dispatch(
      Login(email, password, () => {
        dispatch(GetProfile());
        dispatch(GetTeamDetail());
        dispatch(GetTeamMembers({ Group: 'Status' }));
        navigate('/orders');
      })
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container component="main" maxWidth="xs">
        <Container className={classes.paper}>
          <ThemeProvider theme={theme}>
            <Typography component="h6" variant="h6" className={classes.signIntitle}>
              Sign In
            </Typography>
            <Typography component="p" variant="body2" color="textSecondary">
              Use your email and password to sign in.
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline
                  }
                }}
              />
              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline
                  }
                }}
              />
              <Grid
                item
                sm
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-start">
                <FormControlLabel
                  control={
                    <RoundedCheckbox
                      value="remember"
                      color="primary"
                      onChange={(e: any) => setShowPassword(e.target.checked)}
                    />
                  }
                  label=""
                  style={{ marginRight: 0 }}
                />
                <Typography variant="body2" component="p">
                  Show password
                </Typography>
              </Grid>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item sm={8}>
                  <Link href="/forgotPassword" variant="body2">
                    <Typography variant="button" component="p">
                      Forgot password?
                    </Typography>
                  </Link>
                </Grid>
                <Grid item container justifyContent="flex-end" sm={4}>
                  <ButtonWithLoading
                    type="button"
                    disabled={signInLoading}
                    onClick={signInButtonPressed}
                    loading={signInLoading}
                    title="Sign In"
                  />
                </Grid>
              </Grid>
            </form>
          </ThemeProvider>
        </Container>
      </Container>
      <ErrorModal
        visible={error}
        errorBody="Email address / password combination is incorrect"
        title="Invalid credentials"
        onClose={() => {
          setError(false);
          dispatch(clearLoginError());
        }}
      />
    </ThemeProvider>
  );
}
