import { CircularProgress, Container } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { GroupHeader } from '../components-atoms';
import { OrderItem } from '../components-molecules';
import { ORDER_WARNING_STATUSES } from '../constants/global';
import { useAppSelector } from '../hooks/store';
import { OrderResponseModel } from '../redux/reducers/orders';

interface IProps {
  loadMore: () => void;
  onItemClick: () => void;
}

export function OrdersList({ loadMore, onItemClick }: IProps) {
  const navigate = useNavigate();
  const handleItemClick = (id: string) => {
    onItemClick();
    navigate(`/order/${id}`);
  };
  const orders: OrderResponseModel = useAppSelector((state) => (state.orders as any).data);
  return (
    <InfiniteScroll
      style={{ overflow: 'hidden' }}
      dataLength={orders.Result.map((m) => m[1]).flat().length} // This is important field to render the next data
      next={() => loadMore()}
      scrollThreshold={0.8}
      hasMore={orders.Result.map((m) => m[1]).flat().length !== orders.Count}
      loader={
        <Container style={{ width: '100%' }}>
          <CircularProgress />
        </Container>
      }>
      {orders.Result.map((item, index) => (
        <div key={item[0]}>
          {item[1].length > 0 && (
            <GroupHeader
              idx={index}
              data-automation="number-of-orders"
              headlineType="Orders"
              header={item[0]}
              count={item[1].length}
            />
          )}
          {item[1].map((order) => (
            <OrderItem
              isWarning={ORDER_WARNING_STATUSES.includes(order.TkProOrderStatus)}
              key={order.OrderNo}
              data={order}
              onClick={handleItemClick}
            />
          ))}
        </div>
      ))}
    </InfiniteScroll>
  );
}
