/* eslint-disable react/destructuring-assignment */

import { SortType } from '../../utils/sortType';
import FILTERS from '../symbols/collectOrdersFilters';

export const CollectionOrdersFilterOptions = {
  'Date Range': [
    { title: 'From', value: null },
    { title: 'To', value: null }
  ],
  'Order Status': []
};

// TODO: any
const initialState: any = {
  TabIndex: 0,
  SortType: SortType.desc,
  SearchQuery: '',
  SearchOpen: false,
  ListingFilters: {
    StartDate: undefined,
    EndDate: undefined,
    Status: []
  }
};

// eslint-disable-next-line default-param-last
function CollectOrdersFiltersReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case FILTERS.FILTERS:
      return { ...state };
    case FILTERS.FILTER_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case FILTERS.CLEAR_FILTERS:
      return {
        data: null
      };
    case FILTERS.TOGGLE_SEARCHBAR:
      return {
        ...state,
        SearchOpen: action.payload.SearchOpen
      };
    default:
      return state;
  }
}
export default CollectOrdersFiltersReducer;
