import { Box, Link, Typography } from '@material-ui/core';
import { ConfirmModal } from './ConfirmModal';
import { theme } from '../constants/theme';
import { acceptTerms } from '../redux/actions/collectOrders';
import { GetProfile } from '../redux/actions/profile';
import { useAppDispatch } from '../hooks/store';

export const TRADEKART_TC_PAGE =
  'https://www.tradekart.com/legal/tradekart-collect-terms-and-conditions/';

interface IAcceptTermsModalProps {
  visible: boolean;
  onSubmit: () => void;
  onClose: () => void;
}
export function AcceptTermsModal(props: IAcceptTermsModalProps) {
  const { visible, onSubmit, onClose } = props;
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    acceptTerms(
      () => {
        dispatch(GetProfile());
        setTimeout(() => {
          onSubmit();
        }, 200);
      },
      () => {}
    );
  };

  const customBody = () => {
    return (
      <Box>
        <Typography variant="body2" component="p" color="textPrimary">
          By selecting “I Agree” below, you confirm to have read and agreed to{' '}
          <Link
            href={TRADEKART_TC_PAGE}
            target="_blank"
            style={{ color: theme.palette.primary.main }}>
            Terms and Conditions.
          </Link>
        </Typography>
      </Box>
    );
  };

  return (
    <ConfirmModal
      visible={visible}
      customBody={customBody}
      title="Terms & Conditions"
      cancelButtonTitle="Decline"
      buttonTitle="I Agree"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
}
