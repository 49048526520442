/* eslint-disable react/destructuring-assignment */
import { SVGProps } from 'react';

function InvoiceIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="invoice"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={2}
        width={18}
        height={20}>
        <path
          d="M19.5 3.5 18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5ZM19 19.09H5V4.91h14v14.18ZM7 15h10a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2Zm0-4h10a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2Zm0-4h10a1 1 0 1 1 0 2H7a1 1 0 0 1 0-2Z"
          fill={props.color || '#000'}
        />
      </mask>
      <g mask="url(#invoice)">
        <path d="M24 0H0v24h24V0Z" fill={props.color || '#000'} fillOpacity={props.opacity || 1} />
      </g>
    </svg>
  );
}

export default InvoiceIcon;
