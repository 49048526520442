/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import {
  Box,
  CssBaseline,
  Container,
  IconButton,
  makeStyles,
  Typography,
  Grid,
  useMediaQuery,
  CircularProgress
} from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { FilterButton, Searchbar, SortButton } from '../components-molecules';
import { IFilter, IFilterOption } from '../components-molecules/FilterButton';
import { ButtonArea, HeaderWithButtons } from '../components-molecules/HeaderWithButtons';
import { OrdersList } from '../components-organisms';
import { EMPTY_GUID } from '../constants/global';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { GetOrderStatus } from '../redux/actions/getOrderStatus';
import { GetTeamMembersForFilter } from '../redux/actions/getTeamMembers';
import { GetOrders } from '../redux/actions/orders';
import { clearOrderSearchQuery, setOrderSearchQuery } from '../redux/actions/orderSearch';
import { TeamMember } from '../redux/reducers/getTeamMembers';
import { Order, OrderResponseModel } from '../redux/reducers/orders';
import { ITheme } from '../constants/theme';
import { headerButtonsResponsive, textButtonHoverAndActive } from '../utils/styles/buttonStyles';

const useStyles = makeStyles((theme: ITheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '@media (min-width: 900px)': {
      maxWidth: '840px'
    }
  },
  buttonSet: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '24px',
    gap: '8px',
    '@media (max-width: 899px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '8px 4px',
      padding: '8px 12px'
    }
  },
  searchIcon: {
    width: 24,
    height: 24,
    '@media(max-width: 899px)': {
      width: 20,
      height: 20,
      marginLeft: -4,
      marginRight: 8
    }
  },
  searchIconButton: {
    width: 40,
    height: 40,
    ...headerButtonsResponsive,
    ...textButtonHoverAndActive
  },
  uPaddingLeft: {
    paddingLeft: 24,
    '@media (max-width: 899px)': {
      paddingLeft: 16
    }
  }
}));
type SortType = 'asc' | 'desc';
interface SortOption {
  value: SortType;
  title: string;
}
const sortTypes: SortOption[] = [
  { title: 'Date ascending', value: 'asc' },
  { title: 'Date descending', value: 'desc' }
];

interface Filter extends IFilter {
  'Team Members': string[];
  'Date Range': Array<Date | null>;
  'Order Status': string[];
}

export function Orders() {
  const classes = useStyles();
  const timeoutDuration = 300;
  const mobileView = useMediaQuery('(max-width: 899px)', { noSsr: true });
  const location = useLocation();
  const { state } = location;
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [sortType, setSortType] = useState<SortType>('desc');
  const [orders, loading, teamMembers, statusList, orderSearchQuery]: [
    OrderResponseModel,
    boolean,
    TeamMember[],
    { Value: string }[],
    string
  ] = useAppSelector((state) => [
    (state.orders as any).data,
    (state.orders as any).loading,
    state.getTeamMembers.data,
    (state.getOrderStatus as any).data,
    state.orderSearch.data
  ]);
  const shouldKeepSearch =
    (state as any)?.from && (state as any).from === 'order' && orderSearchQuery;
  const [query, setQuery] = useState(shouldKeepSearch ? orderSearchQuery : '');
  const [searchOpen, setSearchOpen] = useState(shouldKeepSearch || false);

  const [filter, setFilter] = useState<Filter>({
    'Team Members': [],
    'Date Range': [],
    'Order Status': []
  });
  const [filterOptions, setFilterOptions] = useState<IFilterOption>({
    'Date Range': [
      { title: 'From', value: null },
      { title: 'To', value: null }
    ],
    'Team Members': [],
    'Order Status': []
  });
  const dispatch = useAppDispatch();
  const onItemClick = () => {
    dispatch(setOrderSearchQuery(query));
  };

  const getOrders = useCallback(
    (size = pageSize, currentPage = 0, prevData: [string, Order[]][] = []) => {
      dispatch(
        GetOrders(
          {
            Order: sortType,
            Page: currentPage,
            Size: size,
            Query: query || undefined,
            TeamMemberIds:
              filter['Team Members'].length > 0 ? (filter['Team Members'] as string[]) : undefined,
            StartDate: filter['Date Range'][0] ? new Date(filter['Date Range'][0]) : undefined,
            EndDate: filter['Date Range'][1] ? new Date(filter['Date Range'][1]) : undefined,
            Status: filter['Order Status'].length > 0 ? filter['Order Status'] : undefined
          },
          (resp: OrderResponseModel) => {
            let temp = [...prevData];
            resp.Result.forEach((m) => {
              const tempMatch = temp.find((f) => f[0] === m[0]);
              if (tempMatch) {
                temp[temp.indexOf(tempMatch)][1] = [...temp[temp.indexOf(tempMatch)][1], ...m[1]];
              } else {
                temp = [...temp, m];
              }
            });
            return { ...resp, Result: temp };
          }
        )
      );
    },
    [sortType, dispatch, query, filter]
  );

  useEffect(() => {
    dispatch(GetTeamMembersForFilter());
    dispatch(GetOrderStatus());
  }, [dispatch]);

  useEffect(() => {
    if (query !== orderSearchQuery) {
      dispatch(clearOrderSearchQuery());
    }
  }, [query, dispatch, orderSearchQuery]);

  useEffect(() => {
    if (!shouldKeepSearch) {
      getOrders();
    }
  }, [getOrders, shouldKeepSearch]);

  useEffect(() => {
    const orderStatusOptions = statusList?.map((m) => ({ title: m.Value, value: m.Value }));
    setFilterOptions((prev) => ({
      ...prev,
      'Order Status': [...(orderStatusOptions || [])]
    }));
    const memberOptions = teamMembers
      .filter((f) => f.CustomerId !== EMPTY_GUID)
      .map((m) => ({ title: `${m.FirstName} ${m.LastName}`, value: m.CustomerId }));
    setFilterOptions((prev) => ({
      ...prev,
      'Team Members': [...memberOptions]
    }));
  }, [teamMembers, statusList]);

  const loadMore = () => {
    getOrders(pageSize, page + 1, orders.Result);
    setPage((prev) => prev + 1);
  };

  const onFilterSubmit = (submittedFilter: IFilter) => {
    setFilter(submittedFilter as Filter);
  };

  return (
    <Box>
      <CssBaseline />
      <Container className={classes.root}>
        <CssBaseline />
        <HeaderWithButtons title="Orders">
          {(orders?.Result && orders?.Result.length > 0) ||
          filter['Date Range'].length > 0 ||
          filter['Order Status'].length > 0 ||
          filter['Team Members'].length > 0 ? (
            <ButtonArea>
              <div className={classes.buttonSet}>
                <SortButton
                  data-automation="sort-button"
                  menuItems={sortTypes.map(({ title, value }: SortOption) => ({
                    title,
                    value,
                    handleSelect: () => {
                      setPage(0);
                      setSortType(value);
                    }
                  }))}
                  selectedSortType={sortType}
                />
                <FilterButton
                  defaultOption="Team Members"
                  groupedKeys={['Team Members']}
                  data-automation="filter-button"
                  onSubmit={onFilterSubmit}
                  options={filterOptions}
                  filter={filter}
                  dateKey="Date Range"
                  singleDate
                />
                <IconButton
                  data-automation="search-button"
                  className={classes.searchIconButton}
                  color="primary"
                  onClick={() => setSearchOpen(!searchOpen)}>
                  <SearchRoundedIcon color="primary" className={classes.searchIcon} />
                  {mobileView && (
                    <Typography variant="button" component="span" color="primary">
                      Search
                    </Typography>
                  )}
                </IconButton>
              </div>
            </ButtonArea>
          ) : undefined}
        </HeaderWithButtons>
        <Searchbar
          query={query}
          isOpen={searchOpen}
          placeholder="Search Orders"
          onCancel={() => {
            setSearchOpen(false);
            setQuery('');
          }}
          onChange={(event) =>
            debounce(
              (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                setQuery(e.target.value.trim()),
              timeoutDuration
            )(event)
          }
        />
        {loading && page === 0 ? (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '96px' }}>
            <CircularProgress />
          </Grid>
        ) : orders?.Result && orders?.Result.length > 0 ? (
          <OrdersList onItemClick={onItemClick} loadMore={loadMore} />
        ) : query ? (
          <Grid container item className={classes.uPaddingLeft} style={{ marginTop: '40px' }}>
            <Grid container item style={{ maxWidth: '320px' }}>
              <Typography variant="h6" component="span">
                0 search results found
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{ marginTop: '12px' }}>
                Adjust your search term and try again
              </Typography>
            </Grid>
          </Grid>
        ) : Object.keys(filter).some((key) => {
            return filter[key]?.length !== 0;
          }) ? (
          <Grid container item className={classes.uPaddingLeft} style={{ marginTop: '40px' }}>
            <Grid container item style={{ maxWidth: '320px' }}>
              <Typography variant="h6" component="span">
                0 results found
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{ marginTop: '12px' }}>
                Adjust your filtering and try again
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '96px' }}>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              style={{ maxWidth: '320px' }}>
              <Typography variant="h6" component="span">
                Your team doesn’t have any orders yet
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
                style={{ marginTop: '12px' }}>
                You will see your orders here once your team members place them in the TradeKart
                mobile app.
              </Typography>
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
}
