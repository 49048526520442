import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { InfoSectionContainer } from '../components-molecules/InfoSectionContainer';
import { CollectPricing } from '../redux/reducers/orderDetail';
import { colours } from '../constants/theme';

export default function CollectionOrderTotal({ price }: { price: CollectPricing }) {
  const renderPriceTable = () => {
    return (
      <Grid container>
        <Grid container item direction="column" spacing={1}>
          <Grid container item justifyContent="space-between">
            <Grid container item xs={7}>
              <Typography variant="body1" color="textPrimary" component="p">
                Delivery Fee
              </Typography>
            </Grid>
            <Grid xs={5} item container justifyContent="flex-end">
              <Grid item xs={6} container justifyContent="flex-end">
                <Typography variant="body2" component="p" color="textPrimary">
                  £{(price.Delivery / 100).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item justifyContent="space-between">
            <Grid container item xs={7}>
              <Typography variant="body1" color="textPrimary" component="p">
                Booking Fee
              </Typography>
            </Grid>
            <Grid xs={5} item container justifyContent="flex-end">
              {price.BookingFee?.CreditUsage && (
                <Typography variant="body2" component="p" color="textPrimary">
                  {`${price.BookingFee.CreditUsage} credit`}
                </Typography>
              )}
              {price.BookingFee?.PayAsYouGoPrice && (
                <Typography variant="body2" component="p" color="textPrimary">
                  {`£${(price.BookingFee.PayAsYouGoPrice / 100).toFixed(2)}`}
                </Typography>
              )}
            </Grid>
          </Grid>
          {price.PremiumFee && (
            <Grid container item justifyContent="space-between">
              <Grid container item xs={7}>
                <Typography variant="body1" color="textPrimary" component="p">
                  Concierge Fee
                </Typography>
              </Grid>
              <Grid xs={5} item container justifyContent="flex-end">
                <Typography variant="body2" component="p" color="textPrimary">
                  £{(price.PremiumFee / 100).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container item justifyContent="space-between">
            <Grid container item xs={7}>
              <Typography variant="body1" color="textPrimary" component="p">
                VAT
              </Typography>
            </Grid>
            <Grid xs={5} item container justifyContent="flex-end">
              <Grid item xs={6} container justifyContent="flex-end" style={{ columnGap: '40px' }}>
                <Typography variant="body2" component="p" color="textPrimary">
                  £{(price.Vat / 100).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item justifyContent="space-between">
            <Grid container item xs={7}>
              <Typography variant="h6" color="textPrimary" component="p">
                Total
              </Typography>
            </Grid>
            <Grid xs={5} item container justifyContent="flex-end">
              <Typography variant="h6" component="p" color="textPrimary">
                £{(price.Total / 100).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <InfoSectionContainer title="Order total" sx={{ mt: 3 }}>
      <Box mt={2}>{renderPriceTable()}</Box>
    </InfoSectionContainer>
  );
}
