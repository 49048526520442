import { useEffect } from 'react';
import { Box, Step, StepLabel, Stepper, Typography, makeStyles } from '@material-ui/core';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import { InfoSectionContainer } from '../components-molecules/InfoSectionContainer';
import { InfoLine } from '../components-molecules/InfoLine';
import { useScript } from '../hooks/useScript';
import { DetailsSocketMessage } from '../components-pages/CollectionOrder';
import config from '../config';

interface ICollectionOrderDeliveryInfoProps {
  orderDate: string;
  token?: string;
  orkestroOrderId?: string;
  deliveryAddress: string;
  deliveryInstructions: string;
  deliveryType?: string;
  socketMessage: DetailsSocketMessage | null;
  showPremiumWarning?: boolean;
}

function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

export interface ITimelineItem {
  id: number;
  title: string;
  completed?: boolean;
  failed?: boolean;
}

const useStyles = makeStyles({
  iframe: { border: 0, borderRadius: 8 },
  driverSection: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  fullFilled: {
    display: 'flex',
    alignItems: 'center',
    gap: 16
  },
  fullFilledImg: {
    borderRadius: 4,
    objectFit: 'contain'
  }
});

export default function CollectionOrderDeliveryInfo({
  orderDate,
  token,
  orkestroOrderId,
  deliveryAddress,
  deliveryInstructions,
  deliveryType,
  socketMessage,
  showPremiumWarning
}: ICollectionOrderDeliveryInfoProps) {
  const classes = useStyles();
  const { orkestro }: any = useScript(
    'https://widgets.sandbox.orkestro.io/widgets/chat_widget@latest.js',
    'orkestro'
  );

  useEffect(() => {
    const isEnabled = config.showOrkestroChat;
    if (isEnabled && window && (window as any).ChatWidget && socketMessage?.DriverInformation) {
      const chatWidget = new (window as any).ChatWidget({
        token,
        orderId: orkestroOrderId,
        selector: '#chat-btn'
      });
      chatWidget.render();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orkestro, (window as any).ChatWidget, token, socketMessage?.DriverInformation]);

  const getDeliveryTime = (time: Date | string) => moment.utc(time).local().format('h:mm A');

  const getETA = (date: string, showLabel: boolean = true) => {
    const day = moment(date).calendar(null, {
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      lastWeek: '[Last] ddd',
      nextWeek: 'dddd',
      sameElse: 'dddd'
    });
    const time = getDeliveryTime(date);
    return `${day}\n${showLabel ? 'ETA ' : ''}${time}\n(${moment.utc(date).local().fromNow()})`;
  };

  const stepNumbers: any = {
    Ordered: 0,
    Queued: 0,
    Dispatched: 1,
    OutForDelivery: 2,
    Delivered: 3,
    Failed: 3,
    Cancelled: 3
  };

  function formatDateTime(str: string) {
    return `${moment.utc(str).local().format('DD MMM YYYY - h:mm A')}\n(${moment
      .utc(str)
      .local()
      .fromNow()})`;
  }

  const lastStep =
    socketMessage?.Status.Step === 'Delivered' && socketMessage?.Status.DeliveredDate
      ? `Delivered at ${formatDateTime(socketMessage.Status.DeliveredDate)}`
      : socketMessage?.Status.Step === 'Failed' && socketMessage?.Status.FailedDate
      ? `Failed to deliver at ${formatDateTime(socketMessage.Status.FailedDate)}`
      : socketMessage?.Status.Step === 'Cancelled' && socketMessage?.Status.CancelledDate
      ? `Cancelled at ${formatDateTime(socketMessage.Status.CancelledDate)}`
      : socketMessage?.ETA
      ? `Arriving ${getETA(socketMessage.ETA)}`
      : 'Arriving today';

  const stepTexts: string[] = [
    `Ordered ${getETA(orderDate, false)}`,
    'Driver assigned',
    'Out for delivery',
    lastStep
  ];

  const fleetName: string = socketMessage?.FleetInformation
    ? socketMessage.FleetInformation.Name
    : '—';
  const fleetLogoUrl = socketMessage?.FleetInformation
    ? socketMessage.FleetInformation.LogoUrl
    : null;
  const driverName: string = socketMessage?.DriverInformation
    ? socketMessage.DriverInformation.Name
    : '—';
  const driverLastUpdate = socketMessage?.DriverInformation
    ? socketMessage.DriverInformation.LastUpdate
    : null;

  return (
    <InfoSectionContainer title="Delivery Information" sx={{ mb: 3 }}>
      {showPremiumWarning && (
        <Alert severity="warning" style={{ borderRadius: '20px', margin: '12px 0 24px 0' }}>
          The TradeKart team are reviewing and selecting the best courier for your delivery
        </Alert>
      )}

      <Stepper
        activeStep={socketMessage ? stepNumbers[socketMessage.Status.Step] : 0}
        alternativeLabel>
        {stepTexts.map((label) => (
          <Step key={label} style={{ whiteSpace: 'pre-line' }}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {socketMessage?.MapWidgetUrl && socketMessage?.MapWidgetUrl.length > 0 && (
        <iframe
          title="Tracking Map"
          width="100%"
          height="480"
          className={classes.iframe}
          src={socketMessage?.MapWidgetUrl}
        />
      )}
      <InfoLine
        name="Delivery Type"
        value={deliveryType ? capitalizeFirstLetter(deliveryType) : '—'}
      />
      <InfoLine
        name="Fullfilled By"
        value={
          <Box className={classes.fullFilled}>
            {fleetLogoUrl ? (
              <img
                src={fleetLogoUrl}
                width={64}
                height={64}
                className={classes.fullFilledImg}
                alt={fleetName}
              />
            ) : null}
            <Typography variant="body1">{fleetName}</Typography>
          </Box>
        }
      />
      <InfoLine
        name="Driver"
        value={
          <Box className={classes.driverSection}>
            <Box>
              <Typography variant="body1">{driverName}</Typography>
              {driverLastUpdate ? (
                <Typography variant="body1" color="primary">
                  Last location update: {moment.utc(driverLastUpdate).local().fromNow()}
                </Typography>
              ) : null}
            </Box>
            {socketMessage?.DriverInformation ? <div id="chat-btn" /> : null}
          </Box>
        }
      />
      <InfoLine
        name="Estimated Delivery Time"
        value={socketMessage?.ETA ? getETA(socketMessage.ETA) : '—'}
      />
      <InfoLine name="Delivery Address" value={deliveryAddress} />
      <InfoLine
        name="Delivery Instructions"
        value={deliveryInstructions && deliveryInstructions.length > 0 ? deliveryInstructions : '—'}
      />
    </InfoSectionContainer>
  );
}
