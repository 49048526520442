/* eslint-disable react/destructuring-assignment */
import DELIVERY_ADDRESSES from '../symbols/deliveryAddresses';

export interface DeliveryAddress {
  Id: string;
  City: string;
  Country: string;
  County: string;
  Instructions: string;
  Lat: number;
  Line1: string;
  Line2: string;
  Line3: string;
  Long: number;
  Name: string;
  NameOnDoor: string;
  Note: string;
  PostalCode: string;
  ProTeamId: string;
  ContactPhoneNumber: string;
}
export interface DeliveryAddressGroup {
  GroupName: string;
  Result: DeliveryAddress[];
}
export interface DeliveryAddressItem {
  Count: number;
  Result: DeliveryAddressGroup[];
}
interface State {
  loading: boolean;
  error: any;
  data: DeliveryAddressItem;
}
const initialState: State = {
  loading: false,
  error: false,
  data: { Count: 0, Result: [] }
};

// eslint-disable-next-line default-param-last
function DeliveryAddressesReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case DELIVERY_ADDRESSES.DELIVERY_ADDRESSES:
      return { ...state, loading: true, error: false };
    case DELIVERY_ADDRESSES.DELIVERY_ADDRESSES_SUCCESS:
      return {
        ...state,
        data: action.payload as DeliveryAddressItem,
        loading: false,
        error: false
      };
    case DELIVERY_ADDRESSES.DELIVERY_ADDRESSES_FAILURE:
      return {
        ...state,
        token: null,
        data: { Count: 0, Result: [] },
        loading: false,
        error: action.payload
      };
    case DELIVERY_ADDRESSES.CLEAR_DELIVERY_ADDRESSES:
      return {
        ...state,
        token: null,
        loading: false,
        error: false
      };
    default:
      return state;
  }
}
export default DeliveryAddressesReducer;
