import { Container, Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import { OrderDetail } from '../redux/reducers/orderDetail';

const useStyles = makeStyles(() => ({
  customerInformationContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '18px 16px 16px',
    marginTop: '24px',
    boxSizing: 'border-box',
    textAlign: 'left',
    '@media (min-width: 900px)': {
      padding: '18px 24px 16px',
      border: '1px solid rgba(0, 0, 0, 0.08)',
      borderRadius: '20px'
    }
  },
  infoTitle: {
    marginBottom: 20,
    textAlign: 'left'
  },
  divider: {
    marginTop: '15px',
    marginBottom: '16px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    width: '100%'
  }
}));
interface IProps {
  data: OrderDetail;
}
export function OrderTotal({ data }: IProps) {
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width: 599px)', { noSsr: true });

  const hasCancelled =
    data.Deliveries.map((m) => m.Products)
      .flat()
      .filter((f) => f.OrderLineCancelled || f.OrderLineOriginalQuantity !== f.OrderLineQuantity)
      .length > 0;

  const isPercentage = !!data.OrderTotalResult.DiscountPercentage;

  return (
    <Container className={classes.customerInformationContainer}>
      <Grid container>
        <Grid container className={classes.infoTitle}>
          <Typography variant="h6" component="span">
            Order total
          </Typography>
        </Grid>
        {mobileView && hasCancelled ? (
          <Grid container item direction="column">
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              style={{ fontWeight: 700, marginBottom: '8px' }}>
              Initial Total
            </Typography>
            {data.Deliveries.map((m, idx) => (
              <Grid
                key={m.DeliveryId}
                container
                item
                justifyContent="space-between"
                style={{ marginBottom: '8px' }}>
                <Typography variant="body1" color="textPrimary" component="p">
                  Delivery {idx + 1}
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary" align="right">
                  £{(m.DeliveryTotalResult.OriginalTotal / 100).toFixed(2)}
                </Typography>
              </Grid>
            ))}
            {data.OrderTotalResult.DiscountTotal ? (
              <Grid container item justifyContent="space-between" style={{ marginBottom: '8px' }}>
                <Typography variant="body1" color="textPrimary" component="p">
                  Discount
                </Typography>
                <Grid
                  container
                  item
                  justifyContent={isPercentage ? 'space-between' : 'flex-end'}
                  style={{ width: '30%' }}>
                  {isPercentage && (
                    <Typography variant="body2" component="p" color="textSecondary" align="right">
                      {data.OrderTotalResult.DiscountPercentage}%
                    </Typography>
                  )}
                  <Typography variant="body2" component="p" color="textSecondary" align="right">
                    -£{(data.OrderTotalResult.OriginalDiscountTotal / 100).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            <Grid container item justifyContent="space-between" style={{ marginBottom: '8px' }}>
              <Typography variant="body1" color="textPrimary" component="p">
                Delivery fee
              </Typography>
              <Typography variant="body2" component="p" color="textSecondary" align="right">
                £{(data.OrderTotalResult.DeliveryFeeTotal / 100).toFixed(2)}
              </Typography>
            </Grid>
            <Grid container item justifyContent="space-between" style={{ marginBottom: '8px' }}>
              <Typography variant="body1" color="textPrimary" component="p">
                VAT
              </Typography>
              <Grid container item justifyContent="space-between" style={{ width: '30%' }}>
                <Typography variant="body2" component="p" color="textSecondary">
                  {data.OrderTotalResult.TaxRate}%
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary" align="right">
                  £{(data.OrderTotalResult.OriginalVat / 100).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item justifyContent="space-between" style={{ marginBottom: '8px' }}>
              <Typography variant="h6" color="textPrimary" component="p">
                Total
              </Typography>
              <Typography variant="h6" component="p" color="textSecondary" align="right">
                £{(data.OrderTotalResult.OriginalTotal / 100).toFixed(2)}
              </Typography>
            </Grid>
            <div className={classes.divider} />
            <Typography
              variant="body1"
              component="p"
              color="textPrimary"
              style={{ fontWeight: 700, marginBottom: '8px' }}>
              Updated Total
            </Typography>
            {data.Deliveries.map((m, idx) => (
              <Grid
                key={m.DeliveryId}
                container
                item
                justifyContent="space-between"
                style={{ marginBottom: '8px' }}>
                <Typography variant="body1" color="textPrimary" component="p">
                  Delivery {idx + 1}
                </Typography>
                <Typography variant="body2" component="p" color="textPrimary">
                  £{(m.DeliveryTotalResult.Total / 100).toFixed(2)}
                </Typography>
              </Grid>
            ))}
            {data.OrderTotalResult.DiscountTotal ? (
              <Grid container item justifyContent="space-between" style={{ marginBottom: '8px' }}>
                <Typography variant="body1" color="textPrimary" component="p">
                  Discount
                </Typography>
                <Grid
                  container
                  item
                  justifyContent={isPercentage ? 'space-between' : 'flex-end'}
                  style={{ width: '30%' }}>
                  {isPercentage && (
                    <Typography variant="body2" component="p" color="textPrimary">
                      {data.OrderTotalResult.DiscountPercentage}%
                    </Typography>
                  )}
                  <Typography variant="body2" component="p" color="textPrimary" align="right">
                    -£{(data.OrderTotalResult.DiscountTotal / 100).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            <Grid container item justifyContent="space-between" style={{ marginBottom: '8px' }}>
              <Typography variant="body1" color="textPrimary" component="p">
                Delivery fee
              </Typography>
              <Typography variant="body2" component="p" color="textPrimary">
                £{(data.OrderTotalResult.DeliveryFeeTotal / 100).toFixed(2)}
              </Typography>
            </Grid>
            <Grid container item justifyContent="space-between" style={{ marginBottom: '8px' }}>
              <Typography variant="body1" color="textPrimary" component="p">
                VAT
              </Typography>
              <Grid container item justifyContent="space-between" style={{ width: '30%' }}>
                <Typography variant="body2" component="p" color="textPrimary" align="right">
                  {data.OrderTotalResult.TaxRate}%
                </Typography>
                <Typography variant="body2" component="p" color="textPrimary">
                  £{(data.OrderTotalResult.Vat / 100).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item justifyContent="space-between" style={{ marginBottom: '8px' }}>
              <Typography variant="h6" color="textPrimary" component="p">
                Total
              </Typography>
              <Typography variant="h6" component="p" color="textPrimary">
                £{(data.OrderTotalResult.Total / 100).toFixed(2)}
              </Typography>
            </Grid>
            <Grid container item justifyContent="space-between" style={{ marginBottom: '8px' }}>
              <Typography style={{ fontWeight: 700 }} variant="body1" color="error" component="p">
                Refund
              </Typography>
              <Typography style={{ fontWeight: 700 }} variant="body2" component="p" color="error">
                £{(data.OrderTotalResult.TotalRefundedAmount / 100).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container item direction="column" spacing={1}>
            {hasCancelled ? (
              <Grid container item direction="row-reverse">
                <Grid xs={5} item container justifyContent="space-between">
                  <Typography
                    variant="body1"
                    component="p"
                    color="textSecondary"
                    align="right"
                    style={{ fontWeight: 700, width: '90px' }}>
                    Initial Total
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    color="textPrimary"
                    style={{ fontWeight: 700 }}>
                    Updated Total
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            {data.Deliveries.map((m, idx) => (
              <Grid key={m.DeliveryId} container item justifyContent="space-between">
                <Grid container item xs={7}>
                  <Typography variant="body1" color="textPrimary" component="p">
                    Delivery {idx + 1}
                  </Typography>
                </Grid>
                <Grid
                  xs={5}
                  item
                  container
                  justifyContent={hasCancelled ? 'space-between' : 'flex-end'}>
                  {hasCancelled ? (
                    <Grid item xs={3}>
                      <Typography
                        variant="body2"
                        component="p"
                        color="textSecondary"
                        align="right"
                        style={{ width: '90px' }}>
                        £{(m.DeliveryTotalResult.OriginalTotal / 100).toFixed(2)}
                      </Typography>
                    </Grid>
                  ) : null}
                  <Typography variant="body2" component="p" color="textPrimary">
                    £{(m.DeliveryTotalResult.Total / 100).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            {data.OrderTotalResult.DiscountTotal ? (
              <Grid container item justifyContent="space-between">
                <Grid container item xs={7}>
                  <Typography variant="body1" color="textPrimary" component="p">
                    Discount
                  </Typography>
                </Grid>
                <Grid
                  xs={5}
                  item
                  container
                  justifyContent={hasCancelled ? 'space-between' : 'flex-end'}>
                  {hasCancelled ? (
                    <Grid container item xs={6}>
                      <div
                        style={{
                          width: '90px',
                          display: 'flex',
                          justifyContent: isPercentage ? 'space-between' : 'flex-end'
                        }}>
                        {isPercentage && (
                          <Typography variant="body2" component="p" color="textSecondary">
                            {data.OrderTotalResult.DiscountPercentage}%
                          </Typography>
                        )}
                        <Typography
                          variant="body2"
                          component="p"
                          color="textSecondary"
                          align="right">
                          -£{(data.OrderTotalResult.OriginalDiscountTotal / 100).toFixed(2)}
                        </Typography>
                      </div>
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={6}
                    container
                    justifyContent="flex-end"
                    style={{ columnGap: hasCancelled ? '22px' : '40px' }}>
                    {isPercentage && (
                      <Typography variant="body2" component="p" color="textPrimary" align="right">
                        {data.OrderTotalResult.DiscountPercentage}%
                      </Typography>
                    )}
                    <Typography variant="body2" component="p" color="textPrimary">
                      -£{(data.OrderTotalResult.DiscountTotal / 100).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid container item justifyContent="space-between">
              <Grid container item xs={7}>
                <Typography variant="body1" color="textPrimary" component="p">
                  Delivery fee
                </Typography>
              </Grid>
              <Grid
                xs={5}
                item
                container
                justifyContent={hasCancelled ? 'space-between' : 'flex-end'}>
                {hasCancelled ? (
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      component="p"
                      color="textSecondary"
                      align="right"
                      style={{ width: '90px' }}>
                      £{(data.OrderTotalResult.OriginalDeliveryFeeTotal / 100).toFixed(2)}
                    </Typography>
                  </Grid>
                ) : null}
                <Typography variant="body2" component="p" color="textPrimary">
                  £{(data.OrderTotalResult.DeliveryFeeTotal / 100).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item justifyContent="space-between">
              <Grid container item xs={7}>
                <Typography variant="body1" color="textPrimary" component="p">
                  VAT
                </Typography>
              </Grid>
              <Grid
                xs={5}
                item
                container
                justifyContent={hasCancelled ? 'space-between' : 'flex-end'}>
                {hasCancelled ? (
                  <Grid container item xs={6}>
                    <div
                      style={{ width: '90px', display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="body2" component="p" color="textSecondary">
                        {data.OrderTotalResult.TaxRate}%
                      </Typography>
                      <Typography variant="body2" component="p" color="textSecondary" align="right">
                        £{(data.OrderTotalResult.OriginalVat / 100).toFixed(2)}
                      </Typography>
                    </div>
                  </Grid>
                ) : null}
                <Grid
                  item
                  xs={6}
                  container
                  justifyContent="flex-end"
                  style={{ columnGap: hasCancelled ? '22px' : '40px' }}>
                  <Typography variant="body2" component="p" color="textPrimary" align="right">
                    {data.OrderTotalResult.TaxRate}%
                  </Typography>
                  <Typography variant="body2" component="p" color="textPrimary">
                    £{(data.OrderTotalResult.Vat / 100).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item justifyContent="space-between">
              <Grid container item xs={7}>
                <Typography variant="h6" color="textPrimary" component="p">
                  Total
                </Typography>
              </Grid>
              <Grid
                xs={5}
                item
                container
                justifyContent={hasCancelled ? 'space-between' : 'flex-end'}>
                {hasCancelled ? (
                  <Grid item xs={6} container>
                    <Typography
                      variant="h6"
                      component="p"
                      color="textSecondary"
                      align="right"
                      style={{ width: '90px' }}>
                      £{(data.OrderTotalResult.OriginalTotal / 100).toFixed(2)}
                    </Typography>
                  </Grid>
                ) : null}
                <Typography variant="h6" component="p" color="textPrimary">
                  £{(data.OrderTotalResult.Total / 100).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            {hasCancelled ? (
              <Grid container item>
                <Grid container item xs={7}>
                  <Typography
                    style={{ fontWeight: 700 }}
                    variant="body1"
                    color="error"
                    component="p">
                    Refund
                  </Typography>
                </Grid>
                <Grid xs={5} item container justifyContent="space-between" direction="row-reverse">
                  <Typography
                    style={{ fontWeight: 700 }}
                    variant="body2"
                    component="p"
                    color="error">
                    £{(data.OrderTotalResult.TotalRefundedAmount / 100).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
