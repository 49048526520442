const ORDERS = 'ORDERS';
const ORDERS_SUCCESS = 'ORDERS_SUCCESS';
const ORDERS_FAILURE = 'ORDERS_FAILURE';

const CollectionOrdersSymbols = {
  ORDERS_SUCCESS,
  ORDERS_FAILURE,
  ORDERS
};

export const getTabUrl = (idx: number) => {
  switch (idx) {
    case 0:
      return 'open';
    case 1:
      return 'outfordelivery';
    case 2:
      return 'archived';
    default:
      return 'open';
  }
};

export const getTabText = (tabIndex: number) => {
  const text = 'You don’t have any';
  switch (tabIndex) {
    case 0:
      return `${text} orders`;
    case 1:
      return `${text} out for delivery orders`;
    case 2:
      return `${text} archived orders`;
    default:
      return `${text} orders`;
  }
};

export default CollectionOrdersSymbols;
